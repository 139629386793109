import React, { useState } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormik } from "../../../../Components/FormComponent/SelectFormik";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { showToast, isLoading } from "../../../../Features";
import { useDispatch } from "react-redux";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { ColorFormik } from "../../../../Components/FormComponent/ColorFormik";
import { ColorPickerFormik } from "../../../../Components/FormComponent/ColorPickerFormik";
import { useEffect } from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { useLocation } from "react-router-dom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
export const AppForm = ({ data, editID }) => {
  const [materialArray, setMaterialArray] = React.useState([]);
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const [loading, setLoading] = React.useState([]);

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  // const getOption = async (idArr, searchString) => {
  //   try {
  //     let result;
  //     let count = 0;
  //     if (searchString) {
  //       result = await postRequest(
  //         `Employees/GetEmployeesDropDown?q=${searchString}`,
  //         idArr
  //       );
  //       let searchData = [];
  //       setTotalListLength(result?.data?.total);
  //       result.data.results?.map((item) => {
  //         return searchData.push({ label: item.text, value: item.id });
  //       });
  //       setOption(searchData);
  //     } else {
  //       result = await postRequest(
  //         `Employees/GetEmployeesDropDown?i=${page}`,
  //         idArr
  //       );
  //       setTotalListLength(result?.data?.total);
  //       let newItems = [];
  //       result.data.results?.forEach((item) => {
  //         const exists = listData.some(
  //           (existingItem) => existingItem.value === item.id
  //         );
  //         if (!exists) {
  //           return newItems.push({ label: item.text, value: item.id });
  //         } else {
  //           count = count + 1;
  //         }
  //       });
  //       setCountNonExist(count);
  //       setListData((prevData) => [...prevData, ...newItems]);
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   } catch (error) {
  //   } finally {
  //   }
  // };
  // const handleScrollToBottom = () => {
  //   if (listData?.length < totalListLength) getOption([]);
  // };

  // useEffect(() => {
  //   document.title = `PeopleSol - App Form`;
  //   return () => {
  //     document.title = "PeopleSol";
  //   };
  // }, []);
  // useEffect(() => {
  //   if (data?.employeeId !== "" && data?.employeeId !== "All Employees") {
  //     data?.employeeId?.split(",")?.map((item) => {
  //       let tempVal = listData?.filter(
  //         (options) => options.value === Number(item)
  //       );
  //       let tempValUpdated = [];
  //       tempVal[0]?.label !== undefined &&
  //         tempValUpdated.push({
  //           label: tempVal[0]?.label,
  //           value: tempVal[0]?.label,
  //         });
  //       if (tempValUpdated.length > 0) {
  //         setMaterialArray((prev) => {
  //           var contains = prev.some((elem) => {
  //             return JSON.stringify(tempValUpdated[0]) === JSON.stringify(elem);
  //           });

  //           if (!contains) {
  //             return [...prev, tempValUpdated[0]];
  //           } else {
  //             return prev;
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, []);

  // React.useEffect(() => {
  //   if (searchParamemployee) {
  //     getOption([], searchParamemployee);
  //   }
  // }, [searchParamemployee]);

  // React.useEffect(() => {
  //   if (editID) {
  //     setPage(0);
  //   } else setPage(1);
  //   setListData([]);
  // }, [currentUrl]);

  // React.useEffect(() => {
  //   if (editID) {
  //     setPage(0);
  //     const string = data && data?.employeeId;
  //     const idArray =
  //       string !== "All Employees" &&
  //       string?.split(",").map((item) => Number(item));
  //     idArray?.length > 0 ? getOption(idArray) : getOption([]);
  //   } else getOption([]);
  // }, [data]);

  // React.useEffect(() => {
  //   setListData([]);
  // }, []);
  // React.useEffect(() => {
  //   if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  // }, [countNonExist]);
  return (
    <>
      {(
        data?.employeeId !== "" && data?.employeeId !== "All Employees"
          ? materialArray?.length !== 0
          : false
      ) ? (
        <Loader />
      ) : (
        <>
          <div
            className="formcustom"
            style={{ gridTemplateColumns: "repeat(2,1fr)" }}
          >
            <div>
              <LabelCustom labelName={"Name"} mandatory={true} />
              <InputCustomFormik
                maxLength={150}
                name="Name"
                type={"text"}
                placeholder={"Enter Name"}
              />
            </div>
            <div>
              <LabelCustom labelName={"URL"} mandatory={true} />
              <InputCustomFormik
                name="Url"
                type={"text"}
                placeholder={"Enter URL"}
              />
              {/* <div
          style={{
            color: "var(--primary)",
            fontSize: "14px",
            marginLeft: "13px",
          }}
        >
          * Eg: http://google.com
        </div> */}
            </div>
            <div>
              <LabelCustom labelName={"App Link"} />
              <InputCustomFormik
                name="AppUrl"
                type={"text"}
                placeholder={"Enter Link"}
              />
            </div>

            <div>
              <LabelCustom labelName={"Is Display in Mobile?"} />
              <YesNoFormik name="IsDisplayInMobile" />
            </div>
            <div>
              <LabelCustom labelName={"Description"} />
              <InputCustomFormik
                maxLength={150}
                name="Description"
                type={"text"}
                placeholder={"Enter Description"}
              />
            </div>
            <div>
              <LabelCustom labelName={" Employee(s)"} />
              <DropdownInfiniteScroll
                multi={true}
                // postMethod={true}
                name="EmployeeIDs"
                // placeholder={"Select Team Members"}
                details={data}
                editID={editID}
                detailsForEdit={data?.employeeId?.split(",").map(Number)}
                // urlProp={"Employees/GetEmployeesDropDown"}
              />
              {/* <SelectFormikMultiString
                name="EmployeeIDs"
                // options={option}
                values={materialArray}
                onMenuScrollToBottom={handleScrollToBottom}
                options={searchParamemployee ? option : listData}
                onInputChange={(e) => setSearchParamemployee(e)}
              /> */}
              <div
                style={{
                  color: "var(--primary)",
                  fontSize: "14px",
                  marginLeft: "13px",
                }}
              >
                * Leave empty for all employees
              </div>
            </div>

            <div>
              <LabelCustom labelName={"Colour"} />
              <ColorPickerFormik name="Colour" />
            </div>
            <div>
              <LabelCustom labelName={"Icon "} mandatory={true} />
              <FileUploadFormik name={"upload"} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
