import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getRequest, postRequest } from "../../../../Services/axios";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";

const opt = [{ label: "select", value: null }];
const purposeOptions = [
  { label: "Official", value: "Official" },
  { label: "Personal", value: "Personal" },
];
const typeOptions = [
  { label: "Self", value: "self" },
  { label: "Employee", value: "employee" },
];

export const CreateGatePassComp = ({
  editID,
  data,
  formValues,
  setInitialValues,
}) => {
  const [listData, setListData] = useState([]);
  const [listDataName, setListDataName] = useState([]);
  const [option, setOption] = useState([]);
  const [page, setPage] = useState(data ? 0 : 1);
  const [pageName, setPageName] = useState(data ? 0 : 1);
  const [totalListLength, setTotalListLength] = useState(1);
  const [searchParamemployee, setSearchParamemployee] = useState("");
  const [countNonExist, setCountNonExist] = useState("");
  const [countNonExistName, setCountNonExistName] = useState("");
  const { LoggedInUser } = useSelector((state) => state.auth);
  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const EmployeeeDetails = async (ID) => {
    try {
      const result = await getRequest(`Employees/EmployeeDetails/${ID}`);
      if (result?.data?.data?._employees?.officePhoneNumber) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          ...formValues,
          phnNo: result?.data?.data?._employees?.officePhoneNumber,
        }));
      } else {
        setInitialValues((prevValues) => ({
          ...prevValues,
          ...formValues,
          phnNo: "",
        }));
      }
    } catch (error) {
    } finally {
    }
  };
  console.log("object formValues", formValues);
  const EmployeeeDetailsContact = async (ID) => {
    try {
      const result = await getRequest(`Employees/EmployeeDetails/${ID}`);

      if (result?.data?.data?._employees?.officePhoneNumber) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          ...formValues,
          AlternatePhnNo: result?.data?.data?._employees?.officePhoneNumber,
        }));
      } else {
        setInitialValues((prevValues) => ({
          ...prevValues,
          ...formValues,
          AlternatePhnNo: "",
        }));
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.employeeId && !editID) {
      EmployeeeDetails(formValues?.employeeId);
    }
  }, [formValues?.employeeId]);
  useEffect(() => {
    if (formValues?.contactPerson && !editID) {
      const id = listDataName?.filter(
        (item) => item.label === formValues?.contactPerson
      );
      EmployeeeDetailsContact(id[0]?.value);
    }
  }, [formValues?.contactPerson]);
  console.log("first listDataName", listDataName);
  console.log("first listData", listData);
  const getOption = async (idArr, searchString, stringName) => {
    try {
      let result;
      let count = 0;
      let countName = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.text });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        let newItemsName = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          const existslistDataName = listDataName.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            newItems.push({ label: item.text, value: item.text });
          } else {
            count = count + 1;
          }
          if (!existslistDataName) {
            newItemsName.push({ label: item.text, value: item.id });
          } else {
            countName = countName + 1;
          }
        });
        setCountNonExist(count);
        setCountNonExistName(countName);
        setListData((prevData) => [...prevData, ...newItems]);
        setListDataName((prevData) => [...prevData, ...newItemsName]);
        setPage((prevPage) => prevPage + 1);
        setPageName((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
    if (listDataName?.length < totalListLength) getOption([]);
  };

  useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  useEffect(() => {
    // if (editID) {
    //   setPage(0);
    // } else setPage(1);
    setListData([]);
    setListDataName([]);
  }, [currentUrl]);

  useEffect(() => {
    if (editID) {
      setPage(0);
      setPageName(0);
      const arr = [data?.data?.contactPerson];
      getOption([], false, arr);
    } else getOption([], false, []);
  }, [data]);

  useEffect(() => {
    setListData([]);
    setListDataName([]);
  }, []);
  useEffect(() => {
    if (!LoggedInUser?.teamnames?.includes("Admin Team") && formValues?.Type) {
      formValues.Type = "self";
    }
  }, [LoggedInUser]);
  useEffect(() => {
    if (formValues?.Type) {
      if (
        // !LoggedInUser?.teamnames?.includes("Admin Team") ||
        formValues?.Type === "self"
      ) {
        formValues.employeeId = LoggedInUser?.id ?? "";
        formValues.phnNo = LoggedInUser?.personalPhoneNumber ?? "";
      } else {
        formValues.employeeId = "";
        formValues.phnNo = "";
      }
    }
  }, [formValues?.Type, LoggedInUser]);
  useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
    getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    if (countNonExistName > 14 && listDataName?.length < totalListLength)
      getOption([]);
  }, [countNonExistName]);
  useEffect(() => {
    document.title = `PeopleSol - Create Gate Pass `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      {LoggedInUser?.teamnames?.includes("Admin Team") && (
        <div>
          <LabelCustom labelName={"Type"} mandatory={true} />
          <SelectForm name="Type" options={typeOptions} />
        </div>
      )}
      {formValues?.Type !== "self" && (
        <div>
          <LabelCustom labelName={"Employee Name"} mandatory={true} />
          <SelectForm
            placeholder={"Select Employee Name"}
            name="employeeId"
            // disabled={formValues?.Type === "self" ? true : false}
            onMenuScrollToBottom={handleScrollToBottom}
            options={searchParamemployee ? option : listDataName}
            onInputChange={(e) => setSearchParamemployee(e)}
          />
        </div>
      )}
      {formValues?.Type !== "self" && (
        <div>
          <LabelCustom labelName={"Employee Contact Number"} mandatory={true} />
          <InputCustomFormik
            type="text"
            maxLength={10}
            name="phnNo"
            placeholder={"Please enter Phone Number"}
          />
        </div>
      )}

      <div>
        <LabelCustom labelName={"Visiting Place"} mandatory={true} />
        <InputCustomFormik
          maxlength={150}
          name="place"
          type={"text"}
          placeholder={"Please Enter Visiting Place"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Visiting Purpose"} mandatory={true} />
        <SelectForm name="purpose" options={purposeOptions} />
      </div>
      <div>
        <LabelCustom labelName={"Return time to be captured?"} />
        <YesNoFormik name="WillReturn" />
      </div>
      <div>
        <LabelCustom labelName={"Alternate Contact Person"} mandatory={true} />
        <SelectForm
          placeholder={"Select Contact Person"}
          name="contactPerson"
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
      <div>
        <LabelCustom labelName={"Date & Out Time"} mandatory={true} />
        <DatePickerFormiks name="date" minDate={new Date()} showTimeSelect />
      </div>
      <div>
        <LabelCustom labelName={"Alternate Contact Number"} mandatory={true} />
        <InputCustomFormik
          type="text"
          maxLength={10}
          name="AlternatePhnNo"
          placeholder={"Please enter Phone Number"}
        />
      </div>

      {formValues?.WillReturn === "Yes" && (
        <div>
          <LabelCustom labelName={"Return Time"} mandatory={true} />
          <TimeCustomFormik
            name="ReturnTime"
            // placeholder="Select Time"
          />
        </div>
      )}
    </div>
  );
};
