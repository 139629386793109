import { IconButton } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import "./ModuleMasterDetailsPage.css";
import { format } from "date-fns";
import { useEffect } from "react";

const ModuleMasterDetailsPage = ({ selectedRecords, closeDetailsFun }) => {
  useEffect(() => {
    document.title = `PeopleSol - Module Master Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const selectedRecordData = location?.state?.selectedRecord ? location.state?.selectedRecord : selectedRecords;
  const closeDetails = () => {
    if (selectedRecords) {
      closeDetailsFun()
      return;
    }
    let currentUrl = location?.pathname;
    if (currentUrl.includes("payrollmaster")) navigate(-1);
    else navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const keysObject = selectedRecordData
    ? Object.keys(selectedRecordData).slice(1)
    : null;
  const valuesObject = selectedRecordData
    ? Object.values(selectedRecordData).slice(1)
    : null;
  const camelToCapital = (key) => {
    var result = key.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const getTableDataValue = (data) => {
    let parsedDate = Date.parse(data);
    if (data === true) {
      return "Yes";
    } else if (data === false) {
      return "No";
    }
    try {
      if (
        data?.toLowerCase()?.includes(".png") ||
        data?.toLowerCase()?.includes(".jpeg") ||
        data?.toLowerCase()?.includes(".jpg") ||
        data?.toLowerCase()?.includes(".pdf") ||
        data?.toLowerCase()?.includes(".csv") ||
        data?.toLowerCase()?.includes(".doc")
      ) {
        return (
          <a
            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/CandidateOnboardingsDocuments/${data}`}
            target="blank"
          >
            {data}
          </a>
        );
      }
      if (
        data?.includes(".com") ||
        data?.includes(".in") ||
        data?.includes(".net") ||
        data?.includes(".org") ||
        data?.includes("www.") ||
        data?.includes(".http://") ||
        data?.includes(".gov")
      ) {
        return (
          <a href={`${data}`} target="blank">
            {data}
          </a>
        );
      }
    } catch {
      return data;
    }
    if (!Number.isNaN(parsedDate)) {
      try {
        if (data?.includes("T") && data.includes(":")) {
          let date = new Date(data);
          return `${format(date, "dd/MM/yyyy hh:mm a")}`;
        } else {
          return data;
        }
      } catch {
        return data;
      }
    } else return data;
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="moduleMasterDetailsPageBody">
          {keysObject?.map((item, index) => {
            return (
              <div key={index} className="moduleMasterDetailsBox">
                <p>{camelToCapital(item)}</p>:
                <p className="moduleMasterDetailsValue">
                  {getTableDataValue(valuesObject[index])}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { ModuleMasterDetailsPage };
