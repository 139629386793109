import React from "react";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { CheckboxFormiks } from "../../../../../Components/FormComponent/CheckboxFormiks";
import { useEffect } from "react";
function AddressDetails({ stateOptions, edit, formValues }) {
  //console.log("stateOptions",stateOptions)
  useEffect(() => {
    document.title = `PeopleSol - Address Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="headingForm" style={{ marginTop: "12px" }}>
        Permanent Address
      </div>
      <div className="formcustom threeColumn">
        {/* <div>
          <LabelCustom labelName={"C/o Name"} />
          <InputCustomFormik
            
            maxLength={150}
            name="PermanentCOname"
            type={"text"}
            placeholder={"Enter C/o Name"}
          />
        </div> */}

        <div>
          <LabelCustom labelName={"Address"} />
          <InputCustomFormik
            maxLength={300}
            name="PermanentAddress"
            type={"text"}
            placeholder={"Enter Address"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Address Line 2"} />
          <InputCustomFormik
            maxLength={300}
            name="permanentAddressLine2"
            type={"text"}
            placeholder={"Enter Permanent Address Line 2"}
          />
        </div>
        <div>
          <LabelCustom labelName={"City"} />
          <InputCustomFormik
            maxLength={150}
            name="permanentCity"
            type={"text"}
            placeholder={"Enter City"}
          />
        </div>

        <div>
          <LabelCustom labelName={"State"} />
          <SelectForm
            name="permanentAddressState"
            options={stateOptions}
            placeholder="Select State"
          />
        </div>
        <div>
          <LabelCustom labelName={"Pincode/Zip Code"} />
          <InputCustomFormik
            minLength={6}
            maxLength={6}
            name="permanentAddressPincode"
            step=".001"
            placeholder={"Enter Pincode/Zip Code"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Country"} />
          <InputCustomFormik
            maxLength={150}
            name="permanentAddressCountry"
            type={"text"}
            placeholder={"Enter Country"}
          />
        </div>
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <div className="headingForm">Current Address</div>
        {!edit && (
          <CheckboxFormiks
            style={{ position: "absolute", right: "6px", top: "-6px" }}
            name="currentPermanentAddressSame"
            labelName="Current address same as permanent address?"
          />
        )}
      </div>
      {!edit && formValues?.currentPermanentAddressSame ? null : (
        <div className="formcustom threeColumn">
          {/* <div>
            <LabelCustom labelName={"C/o Name"} />
            <InputCustomFormik
              
              maxLength={150}
              name="currentConame"
              type={"text"}
              placeholder={"Enter C/o Name"}
            />
          </div> */}

          <div>
            <LabelCustom labelName={"Address"} />
            <InputCustomFormik
              maxLength={300}
              name="currentAddress"
              type={"text"}
              placeholder={"Enter Address"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Address Line 2"} />
            <InputCustomFormik
              maxLength={300}
              name="currentAddressLine2"
              type={"text"}
              placeholder={"Enter Current Address Line 2"}
            />
          </div>
          <div>
            <LabelCustom labelName={"City"} />
            <InputCustomFormik
              maxLength={150}
              name="currentCity"
              type={"text"}
              placeholder={"Enter City"}
            />
          </div>

          <div>
            <LabelCustom labelName={"State"} />
            <SelectForm
              name="currentState"
              options={stateOptions}
              placeholder="Select State"
            />
          </div>
          <div>
            <LabelCustom labelName={"Pincode/Zip Code"} />
            <InputCustomFormik
              minLength={6}
              maxLength={6}
              name="currentPinCode"
              step=".001"
              placeholder={"Enter Pincode/Zip Code"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Country"} />
            <InputCustomFormik
              maxLength={150}
              name="currentAddressCountry"
              type={"text"}
              placeholder={"Enter Country"}
            />
          </div>
        </div>
      )}
    </>
  );
}

export { AddressDetails };
