import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateQuestionComp } from "./FormComp/CreateQuestionComp";
import { useEffect } from "react";

const CreateQuestion = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
// console.log("formValues===", formValues);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`TrainingQuestions/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("TrainingQuestions/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("TrainingQuestions/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Questions`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getInitalAnswerValues = (answers) => {
    let returnArray = [{ answerText: "", isCorrectAnswer: "" }];
    if (answers) {
      returnArray = [];
      answers?.map((item) => {
        return returnArray.push({
          answerText: item.answerText,
          isCorrectAnswer: item.isCorrectAnswer,
        });
      });
    }
    return returnArray;
  };
  const initialvalue = {
    questionText: "",
    questionSetId: "",
    answers: getInitalAnswerValues(detailsform?.answers),
  };
  const validationSchema = Yup.object({
    questionText: Yup.string()
      .max(149, "Question must be less than 150 characters")
      .required("Please Enter Question"),
    questionSetId: Yup.string().required("Please Select Question Set"),
    answers: Yup.array().of(
      Yup.object().shape({
        answerText: Yup.string()
          .max(299, "Answer must be less than 300 characters")
          .required("Please Select Answer"),
        isCorrectAnswer: Yup.string().required("Please Select Answer"),
      })
    ),
  });

  const onSubmit = (values) => {
    // debugger;
    if (values?.answers?.length < 2) {
      dispatch(
        showToast({
          text: "Atleast Two Items are Required ",
          severity: "error",
        })
      );
    } else if (
      values?.answers?.length >= 2 &&
      !values?.answers?.some((answer) => answer.isCorrectAnswer === true)
    ) {
      dispatch(
        showToast({
          text: "Answer set should have atleast One Correct Answer",
          severity: "error",
        })
      );
    } else {
      const edit = {
        ...values,
        Id: detailsform?.id,
      };

      const create = {
        ...values,
        Id: 0,
      };
      data ? Edit(edit) : createPageData(create);
      returnPage();
    }
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/ldmaster/question/questions");
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Questions" : "Create Questions"}
      ButtonLabel={data ? "Edit Questions" : "Create Questions"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              questionText: detailsform?.questionText,
              questionSetId: detailsform?.questionSetId,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateQuestionComp data={detailsform} />}
    />
  );
};

export { CreateQuestion };
