import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { constructNow } from "date-fns";
import Item from "antd/es/list/Item";
export const UploadTicketOptions = ({ id, handleClose, getListData }) => {
  const [detailsform, setDetailsform] = useState(false);
  const [formValues, setFormval] = useState(false);
  const data = id;

  const [initialValues, setInitialValues] = useState({
    Id: data,
    groupItems: [
      {
        TravelingDetailsID: null,
        date: null,
        FromLocation: "",
        ToLocation: "",
        Bookinglist: [
          {
            Remarks: "",
            Amount: "",
            Upload: null,
          },
        ],
      },
    ],
    groupItemsHotel: [
      {
        HotelBookingDetailsID: null,
        HotelcheckInTime: null,
        HotelcheckOutTime: null,
        Hotellocation: "",
        HotelpreferHotel: "",
        HotelBookinglist: [
          {
            HotelRemarks: "",
            HotelAmount: "",
            HotelUpload: null,
          },
        ],
      },
    ],
    // Bookinglist: getInitalValues(),
  });
  useEffect(() => {
    setInitialValues({
      Id: data,
      groupItems:
        detailsform?.travellingdetails?.length > 0
          ? detailsform?.travellingdetails?.map((item, index) => ({
              TravelingDetailsID: item?.id ?? "",
              date: item?.fromDate ? new Date(item?.fromDate) : null,
              FromLocation: item?.pickupLocation ? item?.pickupLocation : "",
              ToLocation: item?.dropLocation ? item?.dropLocation : "",
              Bookinglist:
                detailsform?.travelBookingOptions?.length > 0
                  ? detailsform?.travelBookingOptions
                      ?.filter((iit) => iit?.travelingDetailsID === item?.id)
                      ?.map((Item, index) => ({
                        id: Item?.id ?? null,
                        Remarks: Item?.travelRemarks ?? "",
                        Amount: Item?.amount ?? "",
                        Upload: Item?.travel_Attachment ?? null,
                      }))
                  : [
                      {
                        id: null,
                        Remarks: "",
                        Amount: "",
                        Upload: null,
                      },
                    ],
            }))
          : [
              {
                TravelingDetailsID: "",
                date: null,
                FromLocation: "",
                ToLocation: "",
                Bookinglist: [
                  {
                    id: null,
                    Remarks: "",
                    Amount: "",
                    Upload: null,
                  },
                ],
              },
            ],
      groupItemsHotel:
        detailsform?.hoteldetail?.length > 0
          ? detailsform?.hoteldetail?.map((item, index) => ({
              HotelBookingDetailsID: item?.id ?? "",
              HotelcheckInTime: item?.checkInTime
                ? new Date(item?.checkInTime)
                : null,
              HotelcheckOutTime: item?.checkOutTime
                ? new Date(item?.checkOutTime)
                : null,
              Hotellocation: item?.location ? item?.location : "",
              HotelpreferHotel: item?.preferHotel ? item?.preferHotel : "",
              HotelBookinglist:
                detailsform?.travelBookingOptions?.length > 0
                  ? detailsform?.travelBookingOptions
                      ?.filter((iit) => iit?.hotelBookingDetailsID === item?.id)
                      ?.map((Item, index) => ({
                        id: Item?.id ?? null,
                        HotelRemarks: Item?.hotelRemarks ?? "",
                        HotelAmount: Item?.amountHotel ?? "",
                        HotelUpload: Item?.hotel_Attachment ?? null,
                      }))
                  : [
                      {
                        id: null,
                        HotelRemarks: "",
                        HotelAmount: "",
                        HotelUpload: null,
                      },
                    ],
            }))
          : [
              {
                HotelBookingDetailsID: null,
                HotelcheckInTime: null,
                HotelcheckOutTime: null,
                Hotellocation: "",
                HotelpreferHotel: "",
                HotelBookinglist: [
                  {
                    id: null,
                    HotelRemarks: "",
                    HotelAmount: "",
                    HotelUpload: null,
                  },
                ],
              },
            ],
    });
  }, [detailsform]);

  useEffect(() => {
    document.title = `PeopleSol - Upload Ticket Option`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  console.log("object data id", data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
      // //console.log("result.data", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object formValues", formValues);
  console.log("object initialValues", initialValues);
  useEffect(() => {
    getFormDetails();
  }, []);
  const pushEmployeeHotel = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItemsHotel];
      const newEmployeeDetails = [
        ...newGroupItems[index].HotelBookinglist,
        { id: null, HotelRemarks: "", HotelAmount: "", HotelUpload: null },
      ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        HotelBookinglist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItemsHotel: newGroupItems,
      };
    });
  };
  const popAnswerHotel = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItemsHotel];
      const newEmployeeDetails = newGroupItems[index].HotelBookinglist?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        HotelBookinglist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItemsHotel: newGroupItems,
      };
    });
  };
  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = [
        ...newGroupItems[index].Bookinglist,
        { id: null, Remarks: "", Amount: "", Upload: null },
      ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        Bookinglist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].Bookinglist?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        Bookinglist: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };

  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TravelHospitalityRequests/Upload",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={
          formValues
            ? formValues?.groupItems[0]?.FromLocation?.length > 0 &&
              formValues?.groupItemsHotel[0]?.Hotellocation?.length === 0
              ? Yup.object({
                  groupItems: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          Remarks: Yup.string().required("Remarks Is Required"),
                          Amount: Yup.string().required("Amount Is Required"),
                          Upload: Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
              : formValues?.groupItems[0]?.FromLocation?.length === 0 &&
                formValues?.groupItemsHotel[0]?.Hotellocation?.length > 0
              ? Yup.object({
                  groupItemsHotel: Yup.array().of(
                    Yup.object().shape({
                      HotelBookinglist: Yup.array().of(
                        Yup.object().shape({
                          HotelRemarks: Yup.string().required(
                            "Remarks Is Required"
                          ),
                          HotelAmount:
                            Yup.string().required("Amount Is Required"),
                          HotelUpload:
                            Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
              : formValues?.groupItems[0]?.FromLocation?.length > 0 &&
                formValues?.groupItemsHotel[0]?.Hotellocation?.length > 0 &&
                Yup.object({
                  groupItems: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          Remarks: Yup.string().required("Remarks Is Required"),
                          Amount: Yup.string().required("Amount Is Required"),
                          Upload: Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                  groupItemsHotel: Yup.array().of(
                    Yup.object().shape({
                      HotelBookinglist: Yup.array().of(
                        Yup.object().shape({
                          HotelRemarks: Yup.string().required(
                            "Remarks Is Required"
                          ),
                          HotelAmount:
                            Yup.string().required("Amount Is Required"),
                          HotelUpload:
                            Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
            : Yup.object({})
        }
        enableReinitialize
        onSubmit={(values) => {
          let count = 0;
          const formData = new FormData();
          formData.append(`id`, detailsform?._request.id ?? 0);
          if (values?.groupItems?.length > 0 && values?.groupItems[0]?.date) {
            values?.groupItems.forEach(function (objecto, indexo) {
              objecto?.Bookinglist.forEach(function (object, index) {
                // if (objecto.TravelingDetailsID)
                if (object.Remarks && object.Amount && object.Upload)
                  formData.append(`Bookinglist[${count}].Id`, object.id ?? 0);
                formData.append(
                  `Bookinglist[${count}].TravelingDetailsID`,
                  objecto.TravelingDetailsID
                );
                formData.append(
                  `Bookinglist[${count}].TravelRemarks`,
                  object.Remarks
                );
                formData.append(`Bookinglist[${count}].Amount`, object.Amount);
                if (typeof object.Upload === "object")
                  formData.append(
                    `Bookinglist[${count}].Travel_Upload`,
                    object.Upload
                  );
                else {
                  formData.append(
                    `Bookinglist[${count}].Travel_Attachment`,
                    object.Upload
                  );
                }
                count++;
              });
            });
          }
          if (
            values?.groupItemsHotel?.length > 0 &&
            values?.groupItemsHotel[0]?.HotelcheckInTime
          ) {
            values?.groupItemsHotel.forEach(function (objecto, indexo) {
              objecto?.HotelBookinglist.forEach(function (object, index) {
                if (object.Remarks && object.Amount && object.Upload)
                  formData.append(`Bookinglist[${count}].Id`, object.id ?? 0);
                formData.append(
                  `Bookinglist[${count}].HotelBookingDetailsID`,
                  objecto.HotelBookingDetailsID
                );
                formData.append(
                  `Bookinglist[${count}].HotelRemarks`,
                  object.Remarks
                );
                formData.append(
                  `Bookinglist[${count}].AmountHotel`,
                  object.Amount
                );
                if (typeof object.Upload === "object")
                  formData.append(
                    `Bookinglist[${count}].Hotel_Upload`,
                    object.Upload
                  );
                else {
                  formData.append(
                    `Bookinglist[${count}].Hotel_Attachment`,
                    object.Upload
                  );
                }
                count++;
              });
            });
          }
          create(formData);
        }}
      >
        {(formik) => {
          setFormval(formik?.values);
          return (
            <Form>
              <div style={{ width: "100%" }}>
                <div style={{ display: "grid" }}>
                  {detailsform?.travellingdetails?.length > 0 && (
                    <>
                      <div style={{ marginBottom: "20px" }}>
                        <label className="repeatedHeading">Travel </label>{" "}
                      </div>
                      <div>
                        <FieldArray name="groupItems">
                          {(fieldArrayProps) => {
                            const { remove, insert, form } = fieldArrayProps;
                            const { values } = form;
                            const { groupItems } = values;
                            return (
                              <Table style={{ width: "100%" }}>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"S. No."} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Upload Availabe Options"}
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {groupItems?.map((Questions, index) => (
                                  <TableBody key={index}>
                                    <StyledTableCell
                                      sx={{ width: "96px" }}
                                      align="center"
                                    >
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        maxWidth: "920px",
                                        overflowX: "auto",
                                      }}
                                    >
                                      <div
                                        className="formcustom threeColumn"
                                        style={{
                                          margin: "10px 0px 20px 0px",
                                        }}
                                      >
                                        <div>
                                          <LabelCustom
                                            labelName={"Travelling Date"}
                                            mandatory={true}
                                          />
                                          <DatePickerFormiks
                                            disabled={true}
                                            name={`groupItems[${index}].date`}
                                          />
                                        </div>
                                        <div>
                                          <LabelCustom
                                            labelName={"From Location"}
                                            mandatory={true}
                                          />
                                          <InputCustomFormik
                                            disabled={true}
                                            maxLength={50}
                                            name={`groupItems[${index}].FromLocation`}
                                            placeholder={
                                              "Please Enter Location"
                                            }
                                          />
                                        </div>
                                        <div>
                                          <LabelCustom
                                            labelName={"To Location"}
                                            mandatory={true}
                                          />
                                          <InputCustomFormik
                                            disabled={true}
                                            maxLength={50}
                                            name={`groupItems[${index}].ToLocation`}
                                            placeholder={
                                              "Please Enter Location"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {Questions?.Bookinglist?.map(
                                          (item, indexW) => (
                                            <div
                                              style={{
                                                overflow: "auto",
                                                width: "100%",
                                                height: "165px",
                                                // borderBottom:
                                                //   "1px solid rgba(189, 189, 189, 0.36)",
                                                borderTop:
                                                  "1px solid rgba(189, 189, 189, 0.36)",
                                              }}
                                            >
                                              <div
                                                className="formcustom fiveColumn"
                                                style={{
                                                  minWidth: "900px",
                                                  padding: "18px 0px",
                                                  position: "relative",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "67px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    labelName={"S. No."}
                                                  />

                                                  <div
                                                    style={{
                                                      padding: "21px 8px",
                                                    }}
                                                  >
                                                    {indexW + 1}
                                                  </div>
                                                </div>
                                                <div style={{ width: "215px" }}>
                                                  <LabelCustom
                                                    labelName={"Remark"}
                                                    mandatory={true}
                                                  />

                                                  <InputCustomFormik
                                                    name={`groupItems[${index}].Bookinglist[${indexW}].Remarks`}
                                                  />
                                                </div>
                                                <div style={{ width: "230px" }}>
                                                  <LabelCustom
                                                    labelName={"Attachment"}
                                                    mandatory={true}
                                                  />

                                                  <FileUploadFormik
                                                    name={`groupItems[${index}].Bookinglist[${indexW}].Upload`}
                                                    addPath={"TravelBookings/"}
                                                  />
                                                </div>
                                                <div style={{ width: "226px" }}>
                                                  <LabelCustom
                                                    labelName={"Budget"}
                                                    mandatory={true}
                                                  />
                                                  <InputCustomFormik
                                                    name={`groupItems[${index}].Bookinglist[${indexW}].Amount`}
                                                    type="number"
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    width: "159px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    labelName={" Add/Remove"}
                                                  />

                                                  <button
                                                    type="button"
                                                    className="remove-btn"
                                                    onClick={() =>
                                                      Questions?.Bookinglist
                                                        ?.length > 1
                                                        ? popAnswer(
                                                            index,
                                                            indexW
                                                          )
                                                        : dispatch(
                                                            showToast({
                                                              text: "Atleast One Item is Required ",
                                                              severity: "error",
                                                            })
                                                          )
                                                    }
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="add-btn"
                                                    onClick={() => {
                                                      pushEmployee(index);
                                                    }}
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  </TableBody>
                                ))}
                              </Table>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}
                  {detailsform?.hoteldetail?.length > 0 && (
                    <>
                      {" "}
                      <div style={{ margin: "20px 0px" }}>
                        <label className="repeatedHeading">Hotel </label>{" "}
                      </div>
                      <div>
                        <FieldArray name="groupItemsHotel">
                          {(fieldArrayProps) => {
                            const { remove, insert, form } = fieldArrayProps;
                            const { values } = form;
                            const { groupItemsHotel } = values;
                            return (
                              <Table style={{ width: "100%" }}>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom labelName={"S. No."} />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      padding="0"
                                      style={{
                                        fontSize: "12px",
                                      }}
                                      align="center"
                                    >
                                      <LabelCustom
                                        labelName={"Upload Availabe Options"}
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                {groupItemsHotel?.map((Questions, index) => (
                                  <TableBody key={index}>
                                    <StyledTableCell
                                      sx={{ width: "96px" }}
                                      align="center"
                                    >
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      sx={{
                                        maxWidth: "920px",
                                        overflowX: "auto",
                                      }}
                                    >
                                      <div
                                        className="formcustom fourColumn"
                                        style={{
                                          margin: "10px 0px 20px 0px",
                                        }}
                                      >
                                        <div>
                                          <LabelCustom
                                            labelName={"Check In Time"}
                                            mandatory={true}
                                          />
                                          <DatePickerFormiks
                                            disabled={true}
                                            showTimeSelect
                                            name={`groupItemsHotel[${index}].HotelcheckInTime`}
                                          />
                                        </div>
                                        <div>
                                          <LabelCustom
                                            labelName={"Check Out Time"}
                                            mandatory={true}
                                          />
                                          <DatePickerFormiks
                                            disabled={true}
                                            showTimeSelect
                                            name={`groupItemsHotel[${index}].HotelcheckOutTime`}
                                          />
                                        </div>
                                        <div>
                                          <LabelCustom
                                            labelName={"Location"}
                                            mandatory={true}
                                          />
                                          <InputCustomFormik
                                            disabled={true}
                                            maxLength={50}
                                            name={`groupItemsHotel[${index}].Hotellocation`}
                                            placeholder={
                                              "Please Enter Location"
                                            }
                                          />
                                        </div>
                                        <div>
                                          <LabelCustom
                                            labelName={"Preferred Hotel"}
                                            mandatory={true}
                                          />
                                          <InputCustomFormik
                                            disabled={true}
                                            maxLength={50}
                                            name={`groupItemsHotel[${index}].HotelpreferHotel`}
                                            placeholder={
                                              "Please Enter Location"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          maxHeight: "300px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {Questions?.HotelBookinglist?.map(
                                          (item, indexW) => (
                                            <div
                                              style={{
                                                overflow: "auto",
                                                width: "100%",
                                                height: "165px",
                                                // borderBottom:
                                                //   "1px solid rgba(189, 189, 189, 0.36)",
                                                borderTop:
                                                  "1px solid rgba(189, 189, 189, 0.36)",
                                              }}
                                            >
                                              <div
                                                className="formcustom fiveColumn"
                                                style={{
                                                  minWidth: "900px",
                                                  padding: "18px 0px",
                                                  position: "relative",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "67px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    labelName={"S. No."}
                                                  />

                                                  <div
                                                    style={{
                                                      padding: "21px 8px",
                                                    }}
                                                  >
                                                    {indexW + 1}
                                                  </div>
                                                </div>
                                                <div style={{ width: "215px" }}>
                                                  <LabelCustom
                                                    labelName={"Remark"}
                                                    mandatory={true}
                                                  />

                                                  <InputCustomFormik
                                                    name={`groupItemsHotel[${index}].HotelBookinglist[${indexW}].HotelRemarks`}
                                                  />
                                                </div>
                                                <div style={{ width: "230px" }}>
                                                  <LabelCustom
                                                    labelName={"Attachment"}
                                                    mandatory={true}
                                                  />

                                                  <FileUploadFormik
                                                    name={`groupItemsHotel[${index}].HotelBookinglist[${indexW}].HotelUpload`}
                                                    addPath={"TravelBookings/"}
                                                  />
                                                </div>
                                                <div style={{ width: "226px" }}>
                                                  <LabelCustom
                                                    labelName={"Budget"}
                                                    mandatory={true}
                                                  />
                                                  <InputCustomFormik
                                                    name={`groupItemsHotel[${index}].HotelBookinglist[${indexW}].HotelAmount`}
                                                    type="number"
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    width: "159px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <LabelCustom
                                                    labelName={" Add/Remove"}
                                                  />

                                                  <button
                                                    type="button"
                                                    className="remove-btn"
                                                    onClick={() =>
                                                      Questions
                                                        ?.HotelBookinglist
                                                        ?.length > 1
                                                        ? popAnswerHotel(
                                                            index,
                                                            indexW
                                                          )
                                                        : dispatch(
                                                            showToast({
                                                              text: "Atleast One Item is Required ",
                                                              severity: "error",
                                                            })
                                                          )
                                                    }
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="add-btn"
                                                    onClick={() => {
                                                      pushEmployeeHotel(index);
                                                    }}
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </StyledTableCell>
                                  </TableBody>
                                ))}
                              </Table>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}
                </div>
                <div className="createFootCust" style={{ marginTop: "63px" }}>
                  <div>
                    <button
                      onClick={handleClose}
                      className="createFooterButtonForm1"
                      type="cancel"
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
