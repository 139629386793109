import React, { useState } from "react";
import ForwardMsgSendIcon from "../../Assets/ForwardMsgSendIcon.svg";

function CompanyBroadcast(props) {
  const tempData = [
    {
      Name: "Ahaan",
      Role: "Junior Frontend Developer",
      LastMsgTime: "5:30PM",
      lastMsg: "Hello, Jenny how are you",
      Img: "https://picsum.photos/40/40",
      Active: "online",
      msgs: [],
    },
    {
      Name: "Kapil Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "I am waiting for your reply",
      Img: "https://picsum.photos/35/35",
      Active: "online",
      msgs: [],
    },
  
    {
      Name: "Rahul Singh",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "okay, see you soon!",
      Img: "https://picsum.photos/35/35",
      Active: "online",
      msgs: [],
    },
    {
      Name: "Raju Baba",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      Name: "Devrat Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      Name: "Devrat Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      Name: "Ram Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
  ];
  const [chatList, setChatList] = useState(tempData);
  const [selectedParticipant,setSelectedParticipant] = useState([]);
  const [activeChatIndex, setActiveChatIndex] = useState(0);
  function showStatus(status) {
    if (status === "online") {
      return "greenDot";
    } else if (status === "busy") {
      return "redDot";
    } else if (status === "away") {
      return "yellowDot";
    }
  }
  return (
    <div className="newChatContainer">
      <div className="newChatSearchContainer">
        <input type="text" className="chatListSearch" placeholder="Search" />
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
          <line
            x1="8.70711"
            y1="9"
            x2="12"
            y2="12.2929"
            stroke="#1296B0"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className="newChatListContainer">
        {chatList.map((chat, index) => (
          <div
            onClick={() => {
            }}
            className="newChatListContentContainer"
          >
            <div className="forwardMsgChkbox" onClick={()=>{setSelectedParticipant([...selectedParticipant, chat])}}>
              <input type="checkbox" class="myinput large" />
            </div>
            <div className="newChatActiveImage">
              <img src={chat.Img} alt="Profile" />
              <div className={showStatus(chat.Active)}></div>
            </div>
            <div className="newChatPostMainTextContainer">
              <label className="chatListName">{chat.Name}</label>
              <label className="chatListRole">{chat.Role}</label>
            </div>
          </div>
        ))}
      </div>
      {selectedParticipant.length > 0 && 
      <div className="forwardParticipantListContainer">
        <div className="forwardParticipantList">
          {selectedParticipant.map((d,i)=>{
            return <>
            <span>{d.Name}</span>
            <span>{','}</span>
            </>
          })}
        </div>  
        <div className="sendIcon" onClick={()=>{props.setSelectedChatMsgActive(false); props.setCompanyBroadcast(false); props.setSelectedChatMsg([]);}}>
          <img src={ForwardMsgSendIcon} alt=""/>
        </div>  
      </div>}
    </div>
  );
}

export default CompanyBroadcast;
