/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { UpdateTaskStatus } from "../../../../../Components/FormComponent/PopupComponent/UpdateTaskStatus";
import { confirmAlert } from "react-confirm-alert";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { TimeLineTask } from "./TimeLineTask";
import UploadDetailMulti from "../../../../../Components/UploadDetail/UploadDetailMulti";

const DetailPageTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTaskOption = location?.state?.selectedTaskOption;
  const returnPage = () => {
    navigate(-1);
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(false);

  const getDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`Tasks/Details/${selectedId}`);
      setDetails(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Task Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {details && (
            <>
              {tabIndex === 0 &&
                (details != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div
                        className="detailsEReq"
                        style={{ flexDirection: "column", overflowX: "hidden" }}
                      >
                        <div className="headingForm">Task Details</div>
                        <div
                          className="detailInner"
                          style={{
                            padding: "12px",
                            margin: "12px",
                            boxShadow: "none",
                          }}
                        >
                          <Details
                            detailTitle="Task Number"
                            Details={
                              details?._projecttasks?.requestNumber ?? "-"
                            }
                          />
                          <Details
                            detailTitle="Task Name"
                            Details={details?._projecttasks?.title}
                          />
                          <Details
                            detailTitle="Task Description"
                            Details={details?._projecttasks?.description ?? "-"}
                          />

                          <Details
                            detailTitle="Start Date"
                            Details={
                              details?._projecttasks?.createdOn
                                ? format(
                                    new Date(details?._projecttasks?.startsOn),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <Details
                            detailTitle="End Date"
                            Details={
                              details?._projecttasks?.endsOn
                                ? format(
                                    new Date(details?._projecttasks?.endsOn),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <Details
                            detailTitle="Estimated Hours"
                            Details={
                              details?._projecttasks?.estimatedHours ?? "-"
                            }
                          />
                          <Details
                            detailTitle="Priority"
                            Details={details?._projecttasks?.priority ?? "-"}
                          />
                          <Details
                            detailTitle="Attachment"
                            Details={
                              details?._projecttasks?.attachments ? (
                                <UploadDetailMulti
                                  filepath={`ProjectsTasks/${details?._projecttasks?.attachments}`}
                                />
                              ) : (
                                "-"
                              )
                            }
                          />
                          <Details
                            detailTitle="Project Name"
                            Details={details?.project?.name ?? "-"}
                          />
                          <Details
                            detailTitle="Incentive Associated With Task"
                            Details={
                              details?._projecttasks?.incentive === null
                                ? "-"
                                : details?._projecttasks?.incentive
                            }
                          />
                        </div>
                        <div className="headingForm">Status Details</div>

                        <div className="detailInner">
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    details?._projecttasks?.status
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    details?._projecttasks?.status
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {details?._projecttasks?.status}
                              </div>
                            }
                          />

                          {details?._projecttasks?.percentage && (
                            <Details
                              detailTitle="Progress Percentage"
                              Details={
                                details?._projecttasks?.percentage ?? "-"
                              }
                            />
                          )}
                          {details?._projecttasks?.remarks && (
                            <Details
                              detailTitle="Progress Remarks"
                              Details={details?._projecttasks?.remarks ?? "-"}
                            />
                          )}
                        </div>
                        <div className="headingForm">Requester Details</div>
                        <div className="detailInner">
                          <Details
                            detailTitle="Assign To"
                            Details={details?.assignTo ?? "-"}
                          />
                          <Details
                            detailTitle="Created By"
                            Details={details?.createdBy}
                          />
                          <Details
                            detailTitle="Created On"
                            Details={
                              details?._projecttasks?.createdOn &&
                              format(
                                new Date(details?._projecttasks?.createdOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            }
                          />
                          <Details
                            detailTitle="Last Updated On"
                            Details={
                              details?._projecttasks?.createdOn &&
                              format(
                                new Date(details?._projecttasks?.lastUpdatedOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            }
                          />
                        </div>
                        {details?._projecttasks?.status !== "Confirmed" && (
                          <div className="createFootCust">
                            {" "}
                            <div
                              className="primaryButton updateStatusHolder cursor"
                              style={{
                                borderRadius: "4px",
                                margin: "0 36px 0 0px",
                              }}
                              onClick={() =>
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className="homeScreenPopup">
                                        <UpdateTaskStatus
                                          selectedTaskOption={
                                            selectedTaskOption
                                          }
                                          status={
                                            details?._projecttasks?.status
                                          }
                                          title={details?._projecttasks?.title}
                                          requestNumber={
                                            details?._projecttasks
                                              ?.requestNumber
                                          }
                                          id={selectedId}
                                          onClose={onClose}
                                          dispatch={dispatch}
                                          getTasksData={getDetails}
                                        />
                                      </div>
                                    );
                                  },
                                })
                              }
                            >
                              <label>Update Status</label>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineTask id={selectedId} />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications id={selectedId} module={"Project Works"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailPageTask };
