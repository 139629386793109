import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../Services/axios";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const typeOptions = [
  { label: "Declared", value: "Declared" },
  { label: "Optional", value: "Optional" },
];
export const CreateHolidaysNew = ({ location, setLocation }) => {
  const dispatch = useDispatch();

  const getLocation = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationNames");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getLocation();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create New Holidays `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={50}
            name="Name"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Locations"} mandatory={false} />

          <SelectFormikMulti
            name="Locations"
            options={location}
            placeholder={" Select  Location"}
          />
        </div>
      </div>

      <div>
        <div className="repeatedSectionContainer">
          <LabelCustom labelName={"Details"} />
          <FieldArray name="Items">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { Items } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          S. No
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Holiday Name <span style={{ color: "red" }}>*</span>
                        </StyledTableCell>

                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Upload
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Date <span style={{ color: "red" }}>*</span>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Type <span style={{ color: "red" }}>*</span>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          Add/Remove
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {Items?.map((Itemss, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            type="text"
                            name={`Items[${index}].Title`}
                            placeholder={" Select Title"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <FileUploadFormik name={`Items[${index}].Upload`} />
                          <span
                            style={{
                              paddingLeft: "12px",
                              color: "var(--primary)",
                            }}
                          >
                            Size: 1 MB
                          </span>
                          <span
                            style={{
                              paddingLeft: "12px",
                              color: "var(--primary)",
                            }}
                          >
                            Format: JPEG, PNG
                          </span>
                        </StyledTableCell>
                        {/* 3rd column */}
                        <StyledTableCell>
                          <DatePickerFormiks
                            name={`Items[${index}].Date`}
                            placeholder={" Select  Date"}
                          />
                        </StyledTableCell>
                        {/* 4th column */}
                        <StyledTableCell>
                          <SelectForm
                            name={`Items[${index}].Type`}
                            options={typeOptions}
                            placeholder={" Select  Type"}
                          />
                        </StyledTableCell>
                        {/* 5th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              Items.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({ Title: "", Date: "", Type: "" })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      </div>
    </>
  );
};
