/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";

const DetailHolidays = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    // let currentUrl = location?.pathname;
    // navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getDetailsData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Holiday/Details/${selectedId}`);
      setEventtData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetailsData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Holiday Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Holiday Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
        <div className="moduleMasterDetailsPageBody">
          <DetailsStep 
          detailTitle={"Name"} 
          Details={eventtData?._holiday?.name} 
          />
          <DetailsStep
            detailTitle={"Location"}
            Details={eventtData?.locations?.map((item)=>{return <div> {item.location.name}</div>})}
          />
         
           <DetailsStep
            detailTitle={"Created On"}
            Details={format(
              new Date(eventtData?._holiday?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />

<DetailsStep
            detailTitle={"Created On"}
            Details={format(
              new Date(eventtData?._holiday?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
         
          <div>
            <h5 style={{margin:"10px 0px"}}>Holiday Calendar Items</h5>
          </div>
          <div style={{ width: "100%" }}>
            <table className="tabless tabless-bordered">
              <thead
          
                style={{
                  backgroundColor: "var(--primary-hover)",
                }}
              >
                <tr>
                  <td>S.no</td>
                  <td>Holiday Name</td>
                  <td>Upload</td>
                  <td>Date</td>
                  <td>Type</td>
                </tr>
              </thead>
              <tbody>
                {eventtData?._holiday?.items?.length > 0
                  ? eventtData?._holiday?.items?.map((item, index) => {
                      return (
                        <tr className="trStyle">
                          <td>{index + 1}</td>
                          <td>{item?.title}</td>
                          <td>{<UploadDetail
                              filepath={`Holidays/${item?.attachment}`}
                            />}</td>
                          <td>{item?.date.replace("T", " ")}</td>
                          <td>{item?.type}</td>
                        </tr>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </table>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export { DetailHolidays };
