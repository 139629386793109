import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";

export const PendingHomeRequest = () => {
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil((detailsform?.length || 0) / itemsPerPage);

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/GetMyOpenRequests");
      setDetailsform(result?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormDetails();
    document.title = `PeopleSol - Pending Home Requests`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedData = detailsform?.slice(startIndex, endIndex);

  if (loading) <Loader />;
  else
    return (
      <div className="tableScrollContainer">
        <div className="tableContainer">
          <table className="responsive-table tableHeaderRow">
            {/* Table Header */}
            <thead>
              <tr className="tableHeaderRow">
                <th>S. No</th>
                <th>Request Number</th>
                <th>Requester</th>
                <th>Date</th>
                <th>Module</th>
              </tr>
            </thead>
            <tbody>
              {slicedData?.map((item,index) => (
                <tr key={item?.id}>
                  <td>{index+1}</td>
                  <td>{item?.requestNumber}</td>
                  <td>{item?.requester}</td>
                  <td>
                    {item?.date &&
                      format(new Date(item?.date), "dd/MM/yyyy hh:mm a")}
                  </td>
                  <td>{item?.module}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{detailsform?.length}</span>
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </div>
    );
};
