import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { onBoardingFormSchema } from "./OnboardingFormsSchemas";
import { showToast } from "../../../Features";
import { CandidateForm } from "./Forms/CandidateForm";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const initialvalue = {
  title: "",
  description: "",
  required: "Yes",
  type:""
};
const CreateOnboardingForms = (props) => {
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const dispatch = useDispatch();
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "CandidateOnboardingRequiredForms/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "CandidateOnboardingRequiredForms/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Onboarding Forms`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values) => {

    const edit = {
      ...values,
      Id: data?.Id,
      isActive:true,
      required: values.required === "Yes" ? true : false,
    };

    const create = {
      ...values,
      required: values.required === "Yes" ? true : false,
    };
    data?Edit(edit) : postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/onboarding/onboardingforms");
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Onboarding Forms':'Create Onboarding Forms'}
      FormHeading={data?'Edit Onboarding Forms':'Create Onboarding Forms'}
      validationSchema={onBoardingFormSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: data.Title,
              description: data.Description,
              type:data.Type,
              required: data.Required == true ? "Yes" : "No",
              
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CandidateForm />}
    />
  );
};

export { CreateOnboardingForms };
