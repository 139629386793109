import React from "react";
import { getRequest } from "../../../../Services/axios";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
export const CreatePersonalAreaComp = () => {
  const dispatch = useDispatch();

  const [personalList, setPersonalList] = React.useState([]);

  const getPersonalData = async () => {
    try {
      const result = await getRequest("Payroll/GetStateLists");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item, value: item });
      });

      setPersonalList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Personal Area `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  React.useEffect(() => {
    getPersonalData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
         
         maxLength={50}
          name="name"
          type={"name"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"State"} mandatory={true} />
        <SelectForm
          name="state"
          placeholder={"Select State"}
          options={personalList}
        />
      </div>
    </div>
  );
};
