const likePostClickHandler = (
  id,
  d,
  type,
  postData,
  setPostData,
  dispatch,
  likePost,
  setIsLikeHovered,
  getData
) => {
  // debugger;
  if (d?.isDisLiked === null || !d?.isDisLiked?.isLike) {
    let updatedData = {};
    let likeTypeData = [""];
    let tempNewsData = JSON.parse(JSON.stringify(postData));
    let tempSocialData = tempNewsData.map((data) => {
      if (data?.id === d?.id) {
        if (data?.likedType?.length > 0) {
          likeTypeData = data?.likedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.isLiked !== null) {
          if (data?.isLiked?.isLike) {
            if (data?.likedType?.length > 0) {
              data?.likedType?.map((item, index) => {
                if (
                  item?.name === data?.isLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.likedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...d,
                likedType: [
                  ...data.likedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
              return updatedData;
            }
            if (likeTypeData.includes(type)) {
              let findData = d?.likedType.find(
                (obj) => obj.name === d?.isLiked?.likeType
              );
              let findNewData = d?.likedType.find((obj) => obj.name === type);
              if (findData?.count <= 1) {
                updatedData = {
                  ...d,
                  likedType: d?.likedType?.map((obj) => {
                    if (obj !== findData) {
                      return obj;
                    }
                    if (obj.name === findNewData.name) {
                      return { ...obj, count: obj.count + 1 };
                    }
                  }),
                  isLiked: {
                    isLike: true,
                    likeType: type,
                  },
                };
                return updatedData;
              } else if (findData?.count > 1) {
                updatedData = {
                  ...d,
                  likedType: d?.likedType?.map((obj) => {
                    if (obj.name === findData.name) {
                      return { ...obj, count: obj.count - 1 };
                    }
                    if (obj.name === findNewData.name) {
                      return { ...obj, count: obj.count + 1 };
                    }
                    return obj;
                  }),
                  isLiked: {
                    isLike: true,
                    likeType: type,
                  },
                };
                return updatedData;
              }
            }
          }
          if (!data?.isLiked?.isLike) {
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...d,
                likedType: [
                  ...data.likedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                likes: data.likes + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            } else {
              updatedData = {
                ...d,
                likedType: data.likedType.map((d) => {
                  if (d.name === type) {
                    d.count++;
                  }
                  return d;
                }),
                likes: data.likes + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!likeTypeData.includes(type)) {
            updatedData = {
              ...d,
              likedType: [
                ...data.likedType,
                {
                  name: type,
                  count: 1,
                },
              ],
              likes: data.likes + 1,
              isLiked: {
                isLike: true,
                likeType: type,
              },
            };
          } else {
            updatedData = {
              ...d,
              likes: data.likes + 1,
              isLiked: {
                isLike: true,
                likeType: type,
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });
    dispatch(setPostData(tempSocialData));
    likePost(id, type);
    setIsLikeHovered(false);
    return updatedData ?? d;
  }
};

const disLikePostClickHandler = (
  id,
  d,
  type,
  postData,
  setPostData,
  dispatch,
  disLikePost,
  setIsDisLikeHovered,
  getData
) => {
  if (d?.isLiked === null || !d?.isLiked?.isLike) {
    let updatedData = {};
    let dislikeTypeData = [""];
    let tempNewsData = JSON.parse(JSON.stringify(postData));
    let tempSocialData = tempNewsData.map((data) => {
      if (data?.id === d?.id) {
        if (data?.disLikedType?.length > 0) {
          dislikeTypeData = data?.disLikedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.isDisLiked !== null) {
          if (data?.isDisLiked?.isLike) {
            if (data?.disLikedType?.length > 0) {
              data?.disLikedType?.map((item, index) => {
                if (
                  item?.name === data?.isDisLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.disLikedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...d,
                disLikedType: [
                  ...data.disLikedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
              return updatedData;
            }
            if (dislikeTypeData.includes(type)) {
              let findData = d?.disLikedType.find(
                (obj) => obj.name === d?.isDisLiked?.likeType
              );
              let findNewData = d?.disLikedType.find((obj) => obj.name === type);
              if (findData?.count <= 1) {
                updatedData = {
                  ...d,
                  disLikedType: d?.disLikedType?.map((obj) => {
                    if (obj !== findData) {
                      return obj;
                    }
                    if (obj.name === findNewData.name) {
                      return { ...obj, count: obj.count + 1 };
                    }
                  }),
                  isDisLiked: {
                    isLike: true,
                    likeType: type,
                  },
                };
                return updatedData;
              } else if (findData?.count > 1) {
                updatedData = {
                  ...d,
                  disLikedType: d?.disLikedType?.map((obj) => {
                    if (obj.name === findData.name) {
                      return { ...obj, count: obj.count - 1 };
                    }
                    if (obj.name === findNewData.name) {
                      return { ...obj, count: obj.count + 1 };
                    }
                    return obj;
                  }),
                  isDisLiked: {
                    isLike: true,
                    likeType: type,
                  },
                };
                return updatedData;
              }
            }
          }
          if (!data?.isDisLiked?.isLike) {
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...d,
                disLikedType: [
                  ...data.disLikedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                disLikes: data.disLikes + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            } else {
              updatedData = {
                ...d,
                disLikedType: data.disLikedType.map((d) => {
                  if (d.name === type) {
                    d.count++;
                  }
                  return d;
                }),
                disLikes: data.disLikes + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!dislikeTypeData.includes(type)) {
            updatedData = {
              ...d,
              disLikedType: [...data.disLikedType, { name: type, count: 1 }],
              disLikes: data.disLikes + 1,
              isDisLiked: {
                isLike: true,
                likeType: type,
              },
            };
          } else {
            updatedData = {
              ...d,
              disLikedType: data.disLikedType.map((d) => {
                if (d.name === type) {
                  d.count++;
                }
                return d;
              }),
              disLikes: data.disLikes + 1,
              isDisLiked: {
                isLike: true,
                likeType: type,
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });

    dispatch(setPostData(tempSocialData));
    disLikePost(id, type);
    setIsDisLikeHovered(false);
    return updatedData ?? d;
  }
};

const likeRemoveClickHandler = async (
  id,
  d,
  postData,
  setPostData,
  dispatch,
  removePostLike,
  setIsLikeHovered
) => {
  let updatedData = null;
  if (d?.isLiked !== null) {
    if (d?.isLiked?.isLike) {
      let tempNewsData = JSON.parse(JSON.stringify(postData));
      let tempSocialData = tempNewsData.map((data) => {
        if (data?.id === d?.id) {
          if (data?.likedType?.length > 0) {
            data?.likedType?.map((item, index) => {
              if (item?.name === data?.isLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.likedType?.splice(index, 1);
                } else {
                  data.likedType[index].count -= 1;
                }
              }
            });
          }
          updatedData = {
            ...data,
            likes: data.likes - 1,
            isLiked: {
              isLike: false,
              likeType: data?.isLiked?.likeType,
            },
          };
          return updatedData;
        }
        return data;
      });
      dispatch(setPostData(tempSocialData));
      setIsLikeHovered(false);
      await removePostLike(id);
    }
  }
  return updatedData ?? d;
};

const disLikeRemoveClickHandler = async (
  id,
  d,
  postData,
  setPostData,
  dispatch,
  removePostDisLike,
  setIsDisLikeHovered
) => {
  let updatedData = null;
  if (d?.isDisLiked !== null) {
    if (d?.isDisLiked?.isLike) {
      let tempNewsData = JSON.parse(JSON.stringify(postData));
      let tempSocialData = tempNewsData.map((data) => {
        if (data?.id === d?.id) {
          if (data?.disLikedType?.length > 0) {
            data?.disLikedType?.map((item, index) => {
              if (item?.name === data?.isDisLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.disLikedType?.splice(index, 1);
                } else {
                  data.disLikedType[index].count -= 1;
                }
              }
            });
          }
          updatedData = {
            ...data,
            disLikes: data.disLikes - 1,
            isDisLiked: {
              isLike: false,
              likeType: data?.isDisLiked?.likeType,
            },
          };
          return updatedData;
        }
        return data;
      });
      dispatch(setPostData(tempSocialData));
      setIsDisLikeHovered(false);
      await removePostDisLike(id);
    }
  }
  return updatedData ?? d;
};

const likeCommentClickHandler = (
  id,
  d,
  type,
  allCommentData,
  setAllCommentData,
  likePost,
  setIsCommentLikeHovered,
  getData
) => {
  let updatedData = {};
  if (d?.isDisLiked === null || !d?.isDisLiked?.isLike) {
    let likeTypeData = [""];
    let tempSocialData = allCommentData.map((data) => {
      if (data?.item?.id === d?.id) {
        if (data?.item?.likedType?.length > 0) {
          likeTypeData = data?.item?.likedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.item?.isLiked !== null) {
          if (data?.item?.isLiked?.isLike) {
            if (data?.item?.likedType?.length > 0) {
              data?.item?.likedType?.map((item, index) => {
                if (
                  item?.name === data?.item?.isLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.item?.likedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...data,
                item: {
                  ...d,
                  likedType: [
                    ...data?.item?.likedType,
                    {
                      name: type,
                      count: 1,
                    },
                  ],
                  isLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
              return updatedData;
            }
          }
          if (!data?.item?.isLiked?.isLike) {
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...data,
                item: {
                  ...d,
                  likedType: [
                    ...data?.item?.likedType,
                    {
                      name: type,
                      count: 1,
                    },
                  ],
                  commentLike: data?.item?.commentLike + 1,
                  isLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
            } else {
              updatedData = {
                ...data,
                likedType: data.item.likedType.map((d) => {
                  if (d.name === type) {
                    d.count++;
                  }
                  return d;
                }),
                item: {
                  ...d,
                  commentLike: data?.item?.commentLike + 1,
                  isLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!likeTypeData.includes(type)) {
            updatedData = {
              ...data,
              item: {
                ...d,
                likedType: [
                  ...data?.item?.likedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                commentLike: data?.item?.commentLike + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              },
            };
          } else {
            updatedData = {
              ...data,
              item: {
                ...d,
                commentLike: data?.item?.commentLike + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });
    setAllCommentData(tempSocialData);
    likePost(id, type);
    setIsCommentLikeHovered(false);
  }
  return updatedData?.item ?? d;
};

const disLikeCommentClickHandler = (
  id,
  d,
  type,
  allCommentData,
  setAllCommentData,
  disLikePost,
  setIsCommentDisLikeHovered,
  getData
) => {
  let updatedData = {};
  if (d?.isLiked === null || !d?.isLiked?.isLike) {
    let dislikeTypeData = [""];
    let tempSocialData = allCommentData.map((data) => {
      if (data?.item?.id === d?.id) {
        if (data?.item?.disLikedType?.length > 0) {
          dislikeTypeData = data?.item?.disLikedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.item?.isDisLiked !== null) {
          if (data?.item?.isDisLiked?.isLike) {
            if (data?.item?.disLikedType?.length > 0) {
              data?.item?.disLikedType?.map((item, index) => {
                if (
                  item?.name === data?.item?.isDisLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.item?.disLikedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...data,
                item: {
                  ...d,
                  disLikedType: [
                    ...data?.item?.disLikedType,
                    {
                      name: type,
                      count: 1,
                    },
                  ],
                  isDisLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
              return updatedData;
            }
          }
          if (!data?.item?.isDisLiked?.isLike) {
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...data,
                item: {
                  ...d,
                  disLikedType: [
                    ...data?.item?.disLikedType,
                    {
                      name: type,
                      count: 1,
                    },
                  ],
                  commentDisLike: data?.item?.commentDisLike + 1,
                  isDisLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
            } else {
              updatedData = {
                ...data,
                item: {
                  ...d,
                  commentDisLike: data?.item?.commentDisLike + 1,
                  isDisLiked: {
                    isLike: true,
                    likeType: type,
                  },
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!dislikeTypeData.includes(type)) {
            updatedData = {
              ...data,
              item: {
                ...d,
                disLikedType: [
                  ...data?.item?.disLikedType,
                  { name: type, count: 1 },
                ],
                commentDisLike: data?.item?.commentDisLike + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              },
            };
          } else {
            updatedData = {
              ...data,
              item: {
                ...d,
                commentDisLike: data?.item?.commentDisLike + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });

    setAllCommentData(tempSocialData);
    disLikePost(id, type);
    setIsCommentDisLikeHovered(false);
  }
  return updatedData?.item ?? d;
};

const commentLikeRemoveClickHandler = async (
  id,
  d,
  allCommentData,
  setAllCommentData,
  removePostLike,
  setIsCommentLikeHovered
) => {
  let updatedData = null;
  if (d?.isLiked !== null) {
    if (d?.isLiked?.isLike) {
      let tempSocialData = allCommentData.map((data) => {
        if (data?.item?.id === d?.id) {
          if (data?.item?.likedType?.length > 0) {
            data?.item?.likedType?.map((item, index) => {
              if (item?.name === data?.item?.isLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.item?.likedType?.splice(index, 1);
                }
              }
            });
          }
          updatedData = {
            ...data,
            item: {
              ...d,
              commentLike: data?.item.commentLike - 1,
              isLiked: {
                isLike: false,
                likeType: data?.item?.isLiked?.likeType,
              },
            },
          };
          return updatedData;
        }
        return data;
      });
      setAllCommentData(tempSocialData);
      setIsCommentLikeHovered(false);
      await removePostLike(d?.postId, id);
    }
  }
  return updatedData?.item ?? d;
};

const commentDisLikeRemoveClickHandler = async (
  id,
  d,
  allCommentData,
  setAllCommentData,
  removePostDisLike,
  setIsCommentDisLikeHovered
) => {
  let updatedData = null;
  if (d?.isDisLiked !== null) {
    if (d?.isDisLiked?.isLike) {
      let tempSocialData = allCommentData.map((data) => {
        if (data?.item?.id === d?.id) {
          if (data?.item?.disLikedType?.length > 0) {
            data?.item?.disLikedType?.map((item, index) => {
              if (item?.name === data?.item?.isDisLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.item?.disLikedType?.splice(index, 1);
                }
              }
            });
          }
          updatedData = {
            ...data,
            item: {
              ...d,
              commentDisLike: data?.item.commentDisLike - 1,
              isDisLiked: {
                isLike: false,
                likeType: data?.item?.isDisLiked?.likeType,
              },
            },
          };
          return updatedData;
        }
        return data;
      });
      setAllCommentData(tempSocialData);
      setIsCommentDisLikeHovered(false);
      await removePostDisLike(d?.postId, id);
    }
  }
  return updatedData?.item ?? d;
};

const detailsLikePostClickHandler = (
  id,
  d,
  type,
  commentedPost,
  setCommentedPost,
  likePost,
  setIsLikeHovered
) => {
  if (d?.isDisLiked === null || !d?.isDisLiked?.isLike) {
    let updatedData = {};
    let likeTypeData = [""];
    let tempSocialData = commentedPost.map((data) => {
      if (data?.id === d?.id) {
        if (data?.likedType?.length > 0) {
          likeTypeData = data?.likedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.isLiked !== null) {
          if (data?.isLiked?.isLike) {
            if (data?.likedType?.length > 0) {
              data?.likedType?.map((item, index) => {
                if (
                  item?.name === data?.isLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.likedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...d,
                likedType: [
                  ...data.likedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
              return updatedData;
            }
          }
          if (!data?.isLiked?.isLike) {
            if (!likeTypeData.includes(type)) {
              updatedData = {
                ...d,
                likedType: [
                  ...data.likedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                likes: data.likes + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            } else {
              updatedData = {
                ...d,
                likedType: data.likedType.map((d) => {
                  if (d.name === type) {
                    d.count++;
                  }
                  return d;
                }),
                likes: data.likes + 1,
                isLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!likeTypeData.includes(type)) {
            updatedData = {
              ...d,
              likedType: [
                ...data.likedType,
                {
                  name: type,
                  count: 1,
                },
              ],
              likes: data.likes + 1,
              isLiked: {
                isLike: true,
                likeType: type,
              },
            };
          } else {
            updatedData = {
              ...d,
              likes: data.likes + 1,
              isLiked: {
                isLike: true,
                likeType: type,
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });
    setCommentedPost(tempSocialData[0]);
    // likePost(d?.id, type);
    setIsLikeHovered(false);
    return updatedData ?? d;
  }
};

const detaildDisLikePostClickHandler = (
  id,
  d,
  type,
  commentedPost,
  setCommentedPost,
  disLikePost,
  setIsDisLikeHovered,
  getData
) => {
  if (d?.isLiked === null || !d?.isLiked?.isLike) {
    let updatedData = {};
    let dislikeTypeData = [""];
    let tempSocialData = commentedPost.map((data) => {
      if (data?.id === d?.id) {
        if (data?.disLikedType?.length > 0) {
          dislikeTypeData = data?.disLikedType?.map((item, index) => {
            return item?.name;
          });
        }
        if (data?.isDisLiked !== null) {
          if (data?.isDisLiked?.isLike) {
            if (data?.disLikedType?.length > 0) {
              data?.disLikedType?.map((item, index) => {
                if (
                  item?.name === data?.isDisLiked?.likeType &&
                  item?.name !== type
                ) {
                  if (item?.count === 1) {
                    return data?.disLikedType?.splice(index, 1);
                  }
                }
              });
            }
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...d,
                disLikedType: [
                  ...data.disLikedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
              return updatedData;
            }
          }
          if (!data?.isDisLiked?.isLike) {
            if (!dislikeTypeData.includes(type)) {
              updatedData = {
                ...d,
                disLikedType: [
                  ...data.disLikedType,
                  {
                    name: type,
                    count: 1,
                  },
                ],
                disLikes: data.disLikes + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            } else {
              updatedData = {
                ...d,
                disLikedType: data.disLikedType.map((d) => {
                  if (d.name === type) {
                    d.count++;
                  }
                  return d;
                }),
                disLikes: data.disLikes + 1,
                isDisLiked: {
                  isLike: true,
                  likeType: type,
                },
              };
            }
            return updatedData;
          }
        } else {
          if (!dislikeTypeData.includes(type)) {
            updatedData = {
              ...d,
              disLikedType: [...data.disLikedType, { name: type, count: 1 }],
              disLikes: data.disLikes + 1,
              isDisLiked: {
                isLike: true,
                likeType: type,
              },
            };
          } else {
            updatedData = {
              ...d,
              disLikedType: data.disLikedType.map((d) => {
                if (d.name === type) {
                  d.count++;
                }
                return d;
              }),
              disLikes: data.disLikes + 1,
              isDisLiked: {
                isLike: true,
                likeType: type,
              },
            };
          }
          return updatedData;
        }
        return data;
      }
      return data;
    });

    setCommentedPost(tempSocialData[0]);
    // disLikePost(d?.id, type);
    setIsDisLikeHovered(false);
    return updatedData ?? d;
  }
};

const detailsLikeRemoveClickHandler = async (
  id,
  d,
  commentedPost,
  setCommentedPost,
  removePostLike,
  setIsLikeHovered
) => {
  let updatedData = null;
  if (d?.isLiked !== null) {
    if (d?.isLiked?.isLike) {
      let tempSocialData = commentedPost.map((data) => {
        if (data?.id === d?.id) {
          if (data?.likedType?.length > 0) {
            data?.likedType?.map((item, index) => {
              if (item?.name === data?.isLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.likedType?.splice(index, 1);
                } else {
                  data.likedType[index].count -= 1;
                }
              }
            });
          }
          updatedData = {
            ...data,
            likes: data.likes - 1,
            isLiked: {
              isLike: false,
              likeType: data?.isLiked?.likeType,
            },
          };
          return updatedData;
        }
        return data;
      });
      setCommentedPost(tempSocialData[0]);
      setIsLikeHovered(false);
      //   await removePostLike(d?.id);
    }
  }
  return updatedData ?? d;
};

const detailsDisLikeRemoveClickHandler = async (
  id,
  d,
  commentedPost,
  setCommentedPost,
  removePostDisLike,
  setIsDisLikeHovered
) => {
  let updatedData = null;
  if (d?.isDisLiked !== null) {
    if (d?.isDisLiked?.isLike) {
      let tempSocialData = commentedPost.map((data) => {
        if (data?.id === d?.id) {
          if (data?.disLikedType?.length > 0) {
            data?.disLikedType?.map((item, index) => {
              if (item?.name === data?.isDisLiked?.likeType) {
                if (item?.count === 1) {
                  return data?.disLikedType?.splice(index, 1);
                } else {
                  data.disLikedType[index].count -= 1;
                }
              }
            });
          }
          updatedData = {
            ...data,
            disLikes: data.disLikes - 1,
            isDisLiked: {
              isLike: false,
              likeType: data?.isDisLiked?.likeType,
            },
          };
          return updatedData;
        }
        return data;
      });
      setCommentedPost(tempSocialData[0]);
      setIsDisLikeHovered(false);
      //   await removePostDisLike(d?.id);
    }
  }
  return updatedData ?? d;
};

export {
  likePostClickHandler,
  likeRemoveClickHandler,
  disLikePostClickHandler,
  disLikeRemoveClickHandler,
  likeCommentClickHandler,
  disLikeCommentClickHandler,
  commentLikeRemoveClickHandler,
  commentDisLikeRemoveClickHandler,
  detailsLikePostClickHandler,
  detailsLikeRemoveClickHandler,
  detaildDisLikePostClickHandler,
  detailsDisLikeRemoveClickHandler,
};
