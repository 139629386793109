import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CandidateDetails,
  AddressDetails,
  PersonalDetails,
  Identification,
  BankFinance,
  EducationDetails,
  ExperienceDetails,
} from "./CandidateFormComp/index";
import { format } from "date-fns";
import * as Yup from "yup";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../../Components/FormikMultiStepForm/FormikMultiStepForm";

const CreateCandidate = () => {
  const [detailsform, setDetailsform] = useState(false);
  const { state } = useLocation();
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Candidate/Details/${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Candidate`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Candidate/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      // //console.log("error",error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Candidate/Edit", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: "Error in updating Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getExperienceListInitialValue = (data) => {
    let returnArray = [
      {
        occupation: null,
        companyName: null,
        summary: null,
        duration: null,
        currentlyWorkHere: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            occupation: item.occupation,
            companyName: item.company,
            summary: item.summary,
            duration: item.duration,
            currentlyWorkHere: item.currentlyWorkingHere,
          });
        });
      }
    return returnArray;
  };
  const getEducationListInitialValue = (data) => {
    let returnArray = [
      {
        employeeEducations: null,
        degree: null,
        college: null,
        yearofPassingFrom: null,
        yearofPassingTo: null,
        grade: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            employeeEducations: item.educationLevel,
            degree: item.degree,
            college: item.college,
            yearofPassingFrom: item.yearofPassingFromYear,
            yearofPassingTo: item.yearofPassingToYear,
            grade: item.grade,
          });
        });
      }
    return returnArray;
  };
  const getDependentListInitialValue = (data) => {
    let returnArray = [
      {
        name: null,
        permanentAddress: null,
        dob: null,
        relationship: null,
        gender: null,
        martialStatus: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            name: item.name,
            permanentAddress: item.address,
            dob: item.dob ? new Date(item.dob) : null,
            relationship: item.relationship,
            gender: item.gender,
            martialStatus: item.martialStatus,
          });
        });
      }
    return returnArray;
  };
  const initialValues = {
    currentPermanentAddressSame: true,
    ManPowerIndentID: data ? detailsform?.manPowerIndentID : null,
    Name: data ? detailsform?.name : "",
    Email: data ? detailsform?.email : "",
    InitialName: data ? detailsform?.initialName : null,
    PersonalPhoneNumber: data ? detailsform?.personalPhoneNumber ?? "" : "",
    MiddleName: data ? detailsform?.middleName : null,
    AlternateNumber: data ? detailsform?.alternateNumber : null,
    LastName: data ? detailsform?.lastName : "",
    Dob: data ? (detailsform?.dob ? new Date(detailsform?.dob) : null) : null,
    Photo: data ? `ProfileImageUploads/${detailsform?.photo}` : null,
    Gender: data ? detailsform?.gender : null,
    JoiningDoj: data
      ? detailsform?.joiningDOJ
        ? new Date(detailsform?.joiningDOJ)
        : null
      : null,
    PermanentAddress: data ? detailsform?.permanentAddress : null,
    PermanentCOname: data ? detailsform?.permanentCOname : null,
    permanentAddressLine2: data ? detailsform?.permanentAddressLine2 : null,
    permanentAddressPincode: data ? detailsform?.permanentAddressPinCode : null,
    permanentAddressState: data ? detailsform?.permanentAddressState : null,
    permanentAddressCountry: data
      ? detailsform?.permanentAddressCountry
      : "India",
    permanentCity: data ? detailsform?.permanentCity : null,
    currentConame: data ? detailsform?.currentCOname : null,
    currentAddress: data ? detailsform?.currentAddress : null,
    currentAddressLine2: data ? detailsform?.currentAddressLine2 : null,
    currentPinCode: data ? detailsform?.currentPinCode : null,
    currentState: data ? detailsform?.currentState : null,
    currentAddressCountry: data ? detailsform?.currentAddressCountry : "India",
    currentCity: data ? detailsform?.currentCity : null,
    birthCountry: data ? detailsform?.birthCountry : null,
    religion: data ? detailsform?.religion : null,
    state: data ? detailsform?.state : null,
    nationality: data ? detailsform?.nationality : null,
    language: data ? detailsform?.language : null,
    dom: data ? (detailsform?.dom ? new Date(detailsform?.dom) : null) : null,
    maritalStatus: data ? detailsform?.maritalStatus : null,
    highestQualification: data ? detailsform?.highestQualification : null,
    previousExperience: data ? detailsform?.previousExperience : null,
    currentSalary: data ? detailsform?.currentSalary : null,
    groupHealthInsurance: data ? detailsform?.groupHealthInsurance : null,
    additionalInfo: data ? detailsform?.additionalInfo : null,
    skillSet: data ? detailsform?.skillSet : null,
    offerLetter: data ? detailsform?.offerLetter : null,
    spouseName: data ? detailsform?.spouseName : null,
    spouseMobileNumber: data ? detailsform?.spouseMobileNumber : null,
    spouseWorking: data ? detailsform?.spouseWorking : null,
    occupation: data ? detailsform?.occupation : null,
    companyName: data ? detailsform?.companyName : null,
    officeAddress: data ? detailsform?.officeAddress : null,
    OfficePhoneNumber: null,
    noOfchildrens: data ? detailsform?.noOfchildrens : null,
    emergencyContactName: data ? detailsform?.emergencyContactName : null,
    contactPersonRelationship: data
      ? detailsform?.contactPersonRelationship
      : null,
    emergencyContactNumber: data ? detailsform?.emergencyContactNumber : null,
    contactPersonPhoneNumber: data
      ? detailsform?.contactPersonPhoneNumber
      : null,
    contactPersonOfficeNumber: data
      ? detailsform?.contactPersonOfficeNumber
      : null,
    contactPersonAddress: data ? detailsform?.contactPersonAddress : null,
    panNumber: data ? detailsform?.panNumber : null,
    uannumber: data ? detailsform?.uanNumber : null,
    aadhaarNumber: data ? detailsform?.aadhaarNumber ?? "" : "",
    pfnumber: data ? detailsform?.pfNumber : null,
    passportNumber: data ? detailsform?.passportNumber : null,
    passportIssueDate: data
      ? detailsform?.passportIssueDate
        ? new Date(detailsform?.passportIssueDate)
        : null
      : null,
    passportExpiryDate: data
      ? detailsform?.passportExpiryDate
        ? new Date(detailsform?.passportExpiryDate)
        : null
      : null,
    bankAccountNumber: data ? detailsform?.bankAccountNumber : null,
    ifsccode: data ? detailsform?.ifscCode : null,
    branchName: data ? detailsform?.branchName : null,
    accountHolderName: data ? detailsform?.accountHolderName : null,
    typeofAccount: data ? detailsform?.typeofAccount : null,
    dependentList: getDependentListInitialValue(detailsform?.dependentslist),
    employeeEducations: getEducationListInitialValue(
      detailsform?.educationslist
    ),
    employeeExperiences: getExperienceListInitialValue(
      detailsform?.experienceslist
    ),
  };
  const onSubmit = async (values) => {
    // //console.log("values",values)
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    if (values?.Name) formData.append("name", values?.Name);
    if (values?.ManPowerIndentID)
      formData.append("ManPowerIndentID", values?.ManPowerIndentID);
    if (values?.Email) formData.append("email", values?.Email);
    if (values?.InitialName)
      formData.append("InitialName", values?.InitialName);
    if (values?.PersonalPhoneNumber)
      formData.append("PersonalPhoneNumber", values?.PersonalPhoneNumber);
    if (values?.PersonalPhoneNumber)
      formData.append("PhoneNumber", values?.PersonalPhoneNumber);
    if (values?.MiddleName) formData.append("MiddleName", values?.MiddleName);
    if (values?.AlternateNumber)
      formData.append("AlternateNumber", values?.AlternateNumber);
    if (values?.LastName) formData.append("lastName", values?.LastName);
    if (values?.Dob)
      formData.append(
        "Dob",
        values?.Dob ? `${format(values?.Dob, "MM/dd/yyyy")}` : null
      );
    if (values?.Gender) formData.append("Gender", values?.Gender);
    if (values?.JoiningDoj) {
      formData.append(
        "JoiningDoj",
        `${format(values?.JoiningDoj, "MM/dd/yyyy")}`
      );
    }
    if (typeof values?.Photo === "object")
      formData.append("UploadPhoto", values?.Photo);
    if (values?.PermanentCOname)
      formData.append("PermanentCOname", values?.PermanentCOname);
    if (values?.permanentCity)
      formData.append("permanentCity", values?.permanentCity);
    if (values?.PermanentAddress)
      formData.append("PermanentAddress", values?.PermanentAddress);
    if (values?.permanentAddressLine2)
      formData.append("permanentAddressLine2", values?.permanentAddressLine2);
    if (values?.permanentAddressPincode)
      formData.append(
        "permanentAddressPincode",
        values?.permanentAddressPincode
      );
    if (values?.permanentAddressState)
      formData.append("permanentAddressState", values?.permanentAddressState);
    if (values?.permanentAddressCountry)
      formData.append(
        "permanentAddressCountry",
        values?.permanentAddressCountry
      );
    if (!data && values?.currentPermanentAddressSame) {
      if (values?.PermanentCOname)
        formData.append("currentConame", values?.PermanentCOname);
      if (values?.permanentCity)
        formData.append("currentCity", values?.permanentCity);
      if (values?.PermanentAddress)
        formData.append("currentAddress", values?.PermanentAddress);
      if (values?.permanentAddressLine2)
        formData.append("currentAddressLine2", values?.permanentAddressLine2);
      if (values?.permanentAddressPincode)
        formData.append("currentPinCode", values?.permanentAddressPincode);
      if (values?.permanentAddressState)
        formData.append("currentState", values?.permanentAddressState);
      if (values?.permanentAddressCountry)
        formData.append(
          "currentAddressCountry",
          values?.permanentAddressCountry
        );
    } else {
      if (values?.currentConame)
        formData.append("currentConame", values?.currentConame);
      if (values?.currentCity)
        formData.append("currentCity", values?.currentCity);
      if (values?.currentAddress)
        formData.append("currentAddress", values?.currentAddress);
      if (values?.currentAddressLine2)
        formData.append("currentAddressLine2", values?.currentAddressLine2);
      if (values?.currentPinCode)
        formData.append("currentPinCode", values?.currentPinCode);
      if (values?.currentState)
        formData.append("currentState", values?.currentState);
      if (values?.currentAddressCountry)
        formData.append("currentAddressCountry", values?.currentAddressCountry);
    }
    if (values?.birthCountry)
      formData.append("birthCountry", values?.birthCountry);
    if (values?.religion) formData.append("religion", values?.religion);
    if (values?.state) formData.append("state", values?.state);
    if (values?.nationality)
      formData.append("nationality", values?.nationality);
    if (values?.language) formData.append("language", values?.language);
    if (values?.dom)
      formData.append("dom", `${format(values?.dom, "MM/dd/yyyy")}`);
    if (values?.maritalStatus)
      formData.append("maritalStatus", values?.maritalStatus);
    if (values?.highestQualification)
      formData.append("highestQualification", values?.highestQualification);
    if (values?.previousExperience)
      formData.append("previousExperience", values?.previousExperience);
    if (values?.currentSalary)
      formData.append("currentSalary", values?.currentSalary);
    if (values?.groupHealthInsurance)
      formData.append("groupHealthInsurance", values?.groupHealthInsurance);
    if (values?.additionalInfo)
      formData.append("additionalInfo", values?.additionalInfo);
    if (values?.skillSet) formData.append("skillSet", values?.skillSet);
    if (typeof values?.offerLetter === "object")
      formData.append("OfferLetterUpload", values?.offerLetter);
    if (values?.spouseName) formData.append("spouseName", values?.spouseName);
    if (values?.spouseMobileNumber)
      formData.append("spouseMobileNumber", values?.spouseMobileNumber);
    if (values?.spouseWorking)
      formData.append("spouseWorking", values?.spouseWorking);
    if (values?.occupation) formData.append("occupation", values?.occupation);
    if (values?.companyName)
      formData.append("companyName", values?.companyName);
    if (values?.officeAddress)
      formData.append("officeAddress", values?.officeAddress);
    if (values?.noOfchildrens)
      formData.append("noOfchildrens", values?.noOfchildrens);
    if (values?.emergencyContactName)
      formData.append("emergencyContactName", values?.emergencyContactName);
    if (values?.contactPersonRelationship)
      formData.append(
        "contactPersonRelationship",
        values?.contactPersonRelationship
      );
    if (values?.emergencyContactNumber)
      formData.append("emergencyContactNumber", values?.emergencyContactNumber);
    if (values?.contactPersonPhoneNumber)
      formData.append(
        "contactPersonPhoneNumber",
        values?.contactPersonPhoneNumber
      );
    if (values?.contactPersonOfficeNumber)
      formData.append(
        "contactPersonOfficeNumber",
        values?.contactPersonOfficeNumber
      );
    if (values?.contactPersonAddress)
      formData.append("contactPersonAddress", values?.contactPersonAddress);
    if (values?.dependentList.length > 0) {
      values?.dependentList.forEach(function (object, index) {
        if (object.name)
          formData.append(`Dependentslist[${index}][Name]`, object.name);
        if (object.permanentAddress)
          formData.append(
            `Dependentslist[${index}][Address]`,
            object.permanentAddress
          );
        if (object.dob)
          formData.append(
            `Dependentslist[${index}][Dob]`,
            object.dob ? `${format(object.dob, "MM/dd/yyyy")}` : ""
          );
        if (object.relationship)
          formData.append(
            `Dependentslist[${index}][Relationship]`,
            object.relationship
          );
        if (object.gender)
          formData.append(`Dependentslist[${index}][Gender]`, object.gender);
        if (object.martialStatus)
          formData.append(
            `Dependentslist[${index}][MartialStatus]`,
            object.martialStatus
          );
      });
    }
    if (values?.panNumber) formData.append("panNumber", values?.panNumber);
    if (values?.uannumber) formData.append("uannumber", values?.uannumber);
    if (values?.aadhaarNumber)
      formData.append("aadhaarNumber", values?.aadhaarNumber);
    if (values?.pfnumber) formData.append("pfnumber", values?.pfnumber);
    if (values?.passportNumber)
      formData.append("passportNumber", values?.passportNumber);
    if (values?.passportIssueDate)
      formData.append(
        "passportIssueDate",
        `${format(values?.passportIssueDate, "MM/dd/yyyy")}`
      );
    if (values?.passportExpiryDate)
      formData.append(
        "passportExpiryDate",
        `${format(values?.passportExpiryDate, "MM/dd/yyyy")}`
      );
    if (values?.bankAccountNumber)
      formData.append("bankAccountNumber", values?.bankAccountNumber);
    if (values?.ifsccode) formData.append("ifsccode", values?.ifsccode);
    if (values?.branchName) formData.append("branchName", values?.branchName);
    if (values?.accountHolderName)
      formData.append("accountHolderName", values?.accountHolderName);
    if (values?.typeofAccount)
      formData.append("typeofAccount", values?.typeofAccount);
    if (values?.employeeEducations.length > 0) {
      values?.employeeEducations.forEach(function (object, index) {
        if (object.employeeEducations)
          formData.append(
            `Educationslist[${index}][EducationLevel]`,
            object.employeeEducations
          );
        if (object.degree)
          formData.append(`Educationslist[${index}][Degree]`, object.degree);
        if (object.college)
          formData.append(`Educationslist[${index}][College]`, object.college);
        if (object.yearofPassingFrom)
          formData.append(
            `Educationslist[${index}][YearofPassingFromYear]`,
            object.yearofPassingFrom
          );
        if (object.yearofPassingTo)
          formData.append(
            `Educationslist[${index}][YearofPassingToYear]`,
            object.yearofPassingTo
          );
        if (object.grade)
          formData.append(`Educationslist[${index}][Grade]`, object.grade);
      });
    }
    if (values?.employeeExperiences.length > 0) {
      values?.employeeExperiences.forEach(function (object, index) {
        if (object.occupation)
          formData.append(
            `Experienceslist[${index}][Occupation]`,
            object.occupation
          );
        if (object.companyName)
          formData.append(
            `Experienceslist[${index}][Company]`,
            object.companyName
          );
        if (object.summary)
          formData.append(`Experienceslist[${index}][Summary]`, object.summary);
        if (object.duration)
          formData.append(
            `Experienceslist[${index}][Duration]`,
            object.duration
          );
        if (object.currentlyWorkHere)
          formData.append(
            `Experienceslist[${index}][CurrentlyWorkingHere]`,
            object.currentlyWorkHere
          );
      });
    }
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const titleOptions = [
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const maritalOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Divorced", value: "Divorced" },
    { label: "N/A", value: "N/A" },
  ];
  const educationOptions = [
    { label: "Phd", value: "Phd" },
    { label: "Masters", value: "Masters" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "12th", value: "12th" },
    { label: "10th", value: "10th" },
    {
      label: "Special Course",
      value: "Special Course",
    },
  ];
  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const relationshipOptions = [
    { label: "Husband", value: "Husband" },
    { label: "Wife", value: "Wife" },
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Sibling", value: "Sibling" },
    { label: "Guardian", value: "Guardian" },
    { label: "Son", value: "Son" },
    { label: "Daughter", value: "Daughter" },
    { label: "Others", value: "Others" },
  ];
  const typeofAccountOptions = [
    { label: "Savings", value: "Savings" },
    { label: "Current", value: "Current" },
  ];
  const [stateOptions, setStateOptions] = useState([]);
  const getStateOptions = async () => {
    try {
      if (stateOptions.length > 0) return;
      const result = await getRequest("Payroll/GetStateLists");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item, value: item });
      });
      setStateOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [manpowerIndentOptions, setManpowerIndentOptions] = useState([]);
  const getmanpowerIndentOptions = async () => {
    try {
      if (manpowerIndentOptions.length > 0) return;
      const result = await getRequest("ManpowerIndents/GetManpowerIndents");
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push({ label: item.requestNumber, value: item.id });
        return null;
      });
      setManpowerIndentOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    getStateOptions();
    getmanpowerIndentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formValues, setFormValues] = useState({});
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  // //console.log("formValuess",formValues)
  const SUPPORTED_FORMATS_DOC = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  return (
    <FormikMultiStepForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      returnPage={returnPage}
      heading={data ? "Edit Candidate" : "Create Candidate"}
      submitText={data ? "Update Candidate" : "Create Candidate"}
      chooseFormValues={chooseFormValues}
      loading={loading}
    >
      <FormikMultiStepFormStep
        label="Candidate"
        validationSchema={Yup.object({
          Name: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .required("Please enter first name"),
          MiddleName: Yup.string()
            .max(149, "Middle Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          LastName: Yup.string()
            .max(149, "Last Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          Email: Yup.string()
            .email()
            .max(149, "Email must be less than 150 characters")
            .required("Please enter the Email"),
          PersonalPhoneNumber: Yup.string()
            .min(5, "Mobile Number should be equal to 10 characters")
            .max(15, "Mobile Number should be equal to 10 characters")
            .notRequired()
            .nullable(),
        })}
      >
        <CandidateDetails
          titleOptions={titleOptions}
          genderOptions={genderOptions}
          manpowerIndentOptions={manpowerIndentOptions}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Personal"
        validationSchema={Yup.object({
          birthCountry: Yup.string()
            .max(149, "birthCountry must be less than 150 characters")
            .notRequired()
            .nullable(),
          religion: Yup.string()
            .max(149, "Religion must be less than 150 characters")
            .nullable()
            .notRequired(),
          nationality: Yup.string()
            .max(149, "Nationality must be less than 150 characters")
            .nullable()
            .notRequired(),
          language: Yup.string()
            .max(149, "Language must be less than 150 characters")
            .nullable()
            .notRequired(),
          highestQualification: Yup.string()
            .max(149, "Highest Qualification must be less than 150 characters")
            .nullable()
            .notRequired(),
          previousExperience: Yup.string()
            .max(149, "Experience must be less than 150 characters")
            .nullable()
            .notRequired(),
          currentSalary: Yup.string()
            .max(5, "Enter correct salary in LPA")
            .nullable()
            .notRequired(),
          additionalInfo: Yup.string()
            .max(499, "Info must be less than 500 characters")
            .nullable()
            .notRequired(),
          skillSet: Yup.string()
            .max(499, "Skill Set must be less than 500 characters")
            .nullable()
            .notRequired(),
          spouseName: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          spouseMobileNumber: Yup.string()
            .min(5, "Mobile Number should be equal to 10 characters")
            .max(15, "Mobile Number should be equal to 10 characters")
            .nullable()
            .notRequired(),
          occupation: Yup.string()
            .max(149, "Occupation must be less than 150 characters")
            .nullable()
            .notRequired(),
          companyName: Yup.string()
            .max(149, "Company Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          PersonalPhoneNumber: Yup.string()
            .min(5, "Phone Number should be equal to 10 characters")
            .max(15, "Phone Number should be equal to 10 characters")
            .nullable()
            .notRequired(),
          officeAddress: Yup.string()
            .max(499, "Address must be less than 500 characters")
            .nullable()
            .notRequired(),
          noOfchildrens: Yup.string()
            .max(2, "Number of Children should be less than 2 characters")
            .nullable()
            .notRequired(),
          emergencyContactName: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          contactPersonRelationship: Yup.string()
            .max(149, "Relationship must be less than 150 characters")
            .nullable()
            .notRequired(),
          emergencyContactNumber: Yup.string()
            .min(5, "Mobile Number should be equal to 10 characters")
            .max(15, "Mobile Number should be equal to 10 characters")
            .nullable()
            .notRequired(),
          contactPersonPhoneNumber: Yup.string()
            .min(5, "Phone Number should be equal to 10 characters")
            .max(15, "Phone Number should be equal to 10 characters")
            .nullable()
            .notRequired(),
          contactPersonOfficeNumber: Yup.string()
            .min(5, "Number should be equal to 10 characters")
            .max(15, "Number should be equal to 10 characters")
            .nullable()
            .notRequired(),
          contactPersonAddress: Yup.string()
            .max(499, "Address must be less than 500 characters")
            .nullable()
            .notRequired(),
          dependentList: Yup.array().of(
            Yup.object().shape({
              name: Yup.string()
                .max(149, "Name must be less than 150 characters")
                .nullable()
                .notRequired(),
              permanentAddress: Yup.string()
                .max(499, "Address must be less than 500 characters")
                .nullable()
                .notRequired(),
              dob: Yup.date()
                .max(new Date(), "Birth date cannot be a future date")
                .nullable()
                .notRequired(),
              relationship: Yup.string()
                .max(149, "Relationship must be less than 150 characters")
                .nullable()
                .notRequired(),
            })
          ),
          offerLetter: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "Fichier taille",
              "File Size must be less than 2 mb",
              (value) =>
                !value ||
                (value && value.size <= 2 * 2048 * 2048) ||
                typeof value !== "object"
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS_DOC.includes(value.type)) ||
                typeof value !== "object"
            ),
          Photo: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "Fichier taille",
              "File Size must be less than 2 mb",
              (value) =>
                !value ||
                (value && value.size <= 2 * 2048 * 2048) ||
                typeof value !== "object"
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS.includes(value.type)) ||
                typeof value !== "object"
            ),
        })}
      >
        <PersonalDetails
          genderOptions={genderOptions}
          maritalOptions={maritalOptions}
          yesNoOptions={yesNoOptions}
          stateOptions={stateOptions}
          formValues={formValues}
          dispatch={dispatch}
          relationshipOptions={relationshipOptions}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Education"
        validationSchema={Yup.object({
          employeeEducations: Yup.array().of(
            Yup.object().shape({
              degree: Yup.string()

                .max(149, "Degree must be less than 150 characters")
                .nullable()
                .notRequired(),
              college: Yup.string()

                .max(149, "College must be less than 150 characters")
                .nullable()
                .notRequired(),
              yearofPassingFrom: Yup.string()

                .max(4, "Year must be less than 5 characters")
                .nullable()
                .notRequired(),
              yearofPassingTo: Yup.string()

                .max(4, "Year must be less than 5 characters")
                .nullable()
                .notRequired(),
              grade: Yup.string()
                .min(1)
                .max(6, "Division/Grade/Percent must be less than 6 characters")
                .nullable()
                .notRequired(),
            })
          ),
        })}
      >
        <EducationDetails
          educationOptions={educationOptions}
          dispatch={dispatch}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Experience"
        validationSchema={Yup.object({
          employeeEducations: Yup.array().of(
            Yup.object().shape({
              occupation: Yup.string()

                .max(149, "Occupation must be less than 150 characters")
                .nullable()
                .notRequired(),
              companyName: Yup.string()

                .max(149, "Company Name must be less than 150 characters")
                .nullable()
                .notRequired(),
              summary: Yup.string()

                .max(299, "Summary must be less than 300 characters")
                .nullable()
                .notRequired(),
              duration: Yup.string()
                .max(2, "Duration must be less than 3 characters")
                .nullable()
                .notRequired(),
            })
          ),
        })}
      >
        <ExperienceDetails yesNoOptions={yesNoOptions} dispatch={dispatch} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Address"
        validationSchema={Yup.object({
          PermanentCOname: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          permanentCity: Yup.string()
            .max(149, "City must be less than 150 characters")
            .nullable()
            .notRequired(),
          PermanentAddress: Yup.string()
            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          permanentAddressLine2: Yup.string()
            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          permanentAddressPincode: Yup.string()
            .min(6, "Pin/Zip must be less than 6 characters")
            .max(6, "Pin/Zip must be less than 6 characters")
            .nullable()
            .notRequired(),
          permanentAddressCountry: Yup.string()
            .max(149, "Country must be less than 300 characters")
            .nullable()
            .notRequired(),
          currentConame: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          currentCity: Yup.string()

            .max(149, "City must be less than 150 characters")
            .nullable()
            .notRequired(),
          currentAddress: Yup.string()
            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          currentAddressLine2: Yup.string()
            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          currentPinCode: Yup.string()
            .min(6, "Pin/Zip must be less than 6 characters")
            .max(6, "Pin/Zip must be less than 6 characters")
            .nullable()
            .notRequired(),
          currentAddressCountry: Yup.string()
            .max(149, "Country must be less than 300 characters")
            .nullable()
            .notRequired(),
        })}
      >
        <AddressDetails
          stateOptions={stateOptions}
          edit={data ? true : false}
          formValues={formValues}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Identification"
        // validationSchema={Yup.object({
        //   aadhaarNumber: Yup.string()
        //     .min(12, "Aadhar Number should be equal to 12 characters")
        //     .max(12, "Aadhar Number should be equal to 12 characters")
        //     .notRequired(),
        //   uannumber: Yup.string()
        //     .min(12, "UAN Number should be equal to 12 characters")
        //     .max(12, "UAN Number should be equal to 12 characters")
        //     .nullable()
        //     .notRequired(),
        //   panNumber: Yup.string()
        //     .min(5, "Pan Number should be equal to 10 characters")
        //     .max(10, "Pan Number should be equal to 10 characters")
        //     .matches(/^([A-Z,a-z]){5}([0-9]){4}([A-Z,a-z]){1}$/, {
        //       message: "Please Enter valid PAN",
        //     })
        //     .nullable()
        //     .notRequired(),
        //   passportNumber: Yup.string()
        //     .min(8, "Passport Number should be equal to 8 characters")
        //     .max(8, "Passport Number should be equal to 8 characters")
        //     .matches(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/, {
        //       message: "Please Enter valid Passport Number",
        //     })
        //     .nullable()
        //     .notRequired(),
        //   pfnumber: Yup.string()
        //     .min(22, "PF Number should be equal to 22 characters")
        //     .max(22, "PF Number should be equal to 22 characters")
        //     .nullable()
        //     .notRequired(),
        // })}
      >
        <Identification />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Payment"
        validationSchema={Yup.object({
          bankAccountNumber: Yup.string()
            .max(20, "Account Number must be less than 20 characters")
            .nullable()
            .notRequired(),
          ifsccode: Yup.string()
            .min(11, "IFSC must be equal to 11 characters")
            .max(11, "IFSC must be equal to 11 characters")
            .nullable()
            .notRequired(),
          branchName: Yup.string()
            .max(149, "Bank Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          accountHolderName: Yup.string()

            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
        })}
      >
        <BankFinance typeofAccountOptions={typeofAccountOptions} />
      </FormikMultiStepFormStep>
    </FormikMultiStepForm>
  );
};
export { CreateCandidate };
