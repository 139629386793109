import React from "react";
import { CreateHotelRequest } from "./CreateHotelRequest";
import { useEffect } from "react";

const CreateHotelBookingComp = ({
  formValues,
  editTrue,
  popAnswer,
  pushEmployee,
  isBoth,
  setInitialValues,
}) => {
  useEffect(() => {
    document.title = `PeopleSol - Hotel Booking Request`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div
      // className="moduleMainContainer"
      style={{ alignItems: "center", background: "var(--form-background)" }}
    >
      <CreateHotelRequest
        isBoth={isBoth}
        popAnswer={popAnswer}
        pushEmployee={pushEmployee}
        formValues={formValues}
        editTrue={editTrue}
        setInitialValues={setInitialValues}
      />
    </div>
  );
};

export { CreateHotelBookingComp };
