import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AllGoals } from "./Components/Goals/AllGoals";
import { MyGoals } from "./Components/Goals/MyGoals";

const AppraisalTab = () => {
  const [tabIndex, setTabIndex] = useState("manpowerplanning");
  const handleTabChange = (event, newTabIndex) => {
    navigate(`appraisal/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "appraisal" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "pms"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);
  useEffect(() => {
    document.title = `PeopleSol -  Appraisal `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("pms/appraisal")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "appraisal");
      if (splitArray?.length > submoduleIndex + 1) {
        if (
          featureMenu
            ?.slice()
            ?.filter((x) => x.isFeatureActive)
            ?.some(
              (x) =>
                x.featureName
                  ?.replaceAll(" ", "")
                  ?.replaceAll("&", "")
                  ?.toLowerCase() === splitArray[submoduleIndex + 1]
            )
        ) {
          setTabIndex(splitArray[submoduleIndex + 1]);
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `appraisal/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      } else {
        setTabIndex(
          featureMenu
            ?.slice()
            ?.filter((x) => x.isFeatureActive)
            ?.sort(
              (a, b) => parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
            )[0]
            .featureName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
        navigate(
          `appraisal/${featureMenu
            ?.slice()
            ?.filter((x) => x.isFeatureActive)
            ?.sort(
              (a, b) => parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
            )[0]
            .featureName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "facilities" && (
        <>
          <AllGoals title={"All Goals"} />
        </>
      )}
      {tabIndex === "loanadvance" && (
        <>
          <MyGoals title={"MyGoals"} />
        </>
      )}
    </div>
  );
};

export { AppraisalTab };
