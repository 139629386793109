import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Notifications } from "../../DetailsComp/Notifications";
import { DetailData } from "../../../../HRMSMaster/Components/DetailData";
import { format } from "date-fns";
import { Timeline } from "../../DetailsComp/TimeLine";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
import UploadImageDetail from "../../../../../Components/UploadDetail/UploadImageDetail";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";

const DetailsCandidate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDetails = () => {
    navigate(-1);
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [fullData, setFullData] = useState(false);
  const getDetailsHrHelpDesk = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Candidate/Details/${selectedId}`);
      setData(result?.data?.data);
      setFullData(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Candidate`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetailsHrHelpDesk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const [subTabIndex, setSubTabIndex] = useState(0);
  const handleSubTabChange = (event, newTabIndex) => {
    setSubTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Candidate Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <div className="tabularMasterDetailsPageBody">
                      <div className="tabContainer">
                        <Tabs
                          value={subTabIndex}
                          onChange={handleSubTabChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          TabIndicatorProps={{
                            style: { background: "var(--secondary)" },
                          }}
                        >
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 0 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Candidate
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 1 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Personal
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 2 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Education
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 3 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Experience
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 4 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Address
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 5 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Identification
                              </span>
                            }
                          />
                          <Tab
                            label={
                              <span
                                className={`tabHeading ${
                                  subTabIndex === 6 &&
                                  "tabActive tabActiveSecondary"
                                }`}
                              >
                                Payment
                              </span>
                            }
                          />
                        </Tabs>
                        <div className="tabContainerLine"></div>
                      </div>
                      {data && (
                        <>
                          {subTabIndex === 0 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Candidate Profile
                              </div>
                              <DetailData
                                detailTitle="Candidate ID"
                                detailData={
                                  data?.candidateOnboardings?.[0]?.referenceNo
                                }
                              />
                              <DetailData
                                detailTitle="Status"
                                detailData={
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        fullData?.summary?.candidateStatusLabel
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        fullData?.summary?.candidateStatusLabel
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {fullData?.summary?.candidateStatusLabel}
                                  </div>
                                }
                              />
                              <DetailData
                                detailTitle="Title"
                                detailData={data?.initialName}
                              />
                              <DetailData
                                detailTitle="First Name"
                                detailData={data?.name}
                              />

                              {/* <DetailData
                                detailTitle="Middle Name"
                                detailData={data?.middleName}
                              /> */}
                              <DetailData
                                detailTitle="Last Name"
                                detailData={data?.lastName}
                              />
                              <DetailData
                                detailTitle="Email"
                                detailData={data?.email}
                              />
                              {/* <DetailData
                                detailTitle="Blood Group"
                                detailData={data?.bloodGroup}
                              /> */}
                              <DetailData
                                detailTitle="Mobile Number"
                                detailData={data?.phoneNumber}
                              />

                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Organisation Details
                              </div>
                              <DetailData
                                detailTitle="Requisition Number"
                                detailData={
                                  data?.manPowerIndentID
                                    ? `MI-${data?.manPowerIndentID}`
                                    : "-"
                                }
                              />
                              <DetailData
                                detailTitle="Date of Joining"
                                detailData={
                                  data?.joiningDOJ
                                    ? format(
                                        new Date(data?.joiningDOJ),
                                        "dd/MM/yyyy"
                                      )
                                    : "-"
                                }
                              />
                              {data?.candidateOnboardings?.[0]?.documents
                                ?.length > 0 && (
                                <>
                                  <div
                                    className="headingForm"
                                    style={{ width: "100%", margin: "0" }}
                                  >
                                    Documents
                                  </div>
                                  {data?.candidateOnboardings?.[0]?.documents?.map(
                                    (item, index) => {
                                      return (
                                        <DetailData
                                          key={index}
                                          detailTitle={item?.document?.name}
                                          detailData={
                                            item?.userFile ? (
                                              <UploadDetail
                                                filepath={`CandidateOnboardingsDocuments/${item?.userFile}`}
                                              />
                                            ) : (
                                              "-"
                                            )
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </>
                              )}
                              {data?.candidateOnboardings?.[0]?.forms?.length >
                                0 && (
                                <>
                                  <div
                                    className="headingForm"
                                    style={{ width: "100%", margin: "0" }}
                                  >
                                    Forms
                                  </div>
                                  {data?.candidateOnboardings?.[0]?.forms?.map(
                                    (item, index) => {
                                      return (
                                        <DetailData
                                          key={index}
                                          detailTitle={item?.form?.title}
                                          detailData={
                                            item?.file ? (
                                              <UploadDetail
                                                filepath={`CandidateOnboardingsDocuments/${item?.file}`}
                                              />
                                            ) : (
                                              "-"
                                            )
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                          )}
                          {subTabIndex === 4 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Permanent Address
                              </div>
                              {/* <DetailData
                                detailTitle="C/o Name "
                                detailData={data?.permanentConame}
                              /> */}
                              <DetailData
                                detailTitle="Address"
                                detailData={data?.permanentAddress}
                              />
                              <DetailData
                                detailTitle="Address Line 2"
                                detailData={data?.permanentAddressLine2}
                              />
                              <DetailData
                                detailTitle="City"
                                detailData={data?.permanentCity}
                              />
                              {/* <DetailData
                                detailTitle="District "
                                detailData={data?.permanentAddressDistrict}
                              />
                              <DetailData
                                detailTitle="House Number"
                                detailData={data?.permanentHouseNumber}
                              />
                              <DetailData
                                detailTitle="Identification of an apartment"
                                detailData={
                                  data?.permanentIdentificationOfAnApartment
                                }
                              /> */}
                              <DetailData
                                detailTitle="State"
                                detailData={data?.permanentAddressState}
                              />
                              <DetailData
                                detailTitle="Pincode/Zip Code "
                                detailData={data?.permanentAddressPinCode}
                              />
                              <DetailData
                                detailTitle="Country"
                                detailData={data?.permanentAddressCountry}
                              />
                              {/* <DetailData
                                detailTitle="Phone Number"
                                detailData={data?.permanentTelephoneNumber}
                              /> */}

                              {/* <DetailData
                                detailTitle="C/o Name "
                                detailData={data?.currentConame}
                              /> */}
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Current Address
                              </div>
                              <DetailData
                                detailTitle="Address"
                                detailData={data?.currentAddress}
                              />
                              <DetailData
                                detailTitle="Address Line 2"
                                detailData={data?.currentAddressLine2}
                              />
                              <DetailData
                                detailTitle="City"
                                detailData={data?.currentCity}
                              />
                              {/* <DetailData
                                detailTitle="District "
                                detailData={data?.currentAddressDistrict}
                              />
                              <DetailData
                                detailTitle="House Number"
                                detailData={data?.currentHouseNumber}
                              />
                              <DetailData
                                detailTitle="Identification of an apartment"
                                detailData={
                                  data?.currentIdentificationOfAnApartment
                                }
                              /> */}
                              <DetailData
                                detailTitle="State"
                                detailData={data?.currentState}
                              />
                              <DetailData
                                detailTitle="Pincode/Zip Code "
                                detailData={data?.currentPinCode}
                              />
                              <DetailData
                                detailTitle="Country"
                                detailData={data?.currentAddressCountry}
                              />
                              {/* <DetailData
                                detailTitle="Phone Number"
                                detailData={data?.currentTelephoneNumber}
                              /> */}
                            </div>
                          )}

                          {subTabIndex === 5 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <DetailData
                                detailTitle="Aadhaar Number"
                                detailData={data?.aadhaarNumber}
                              />
                              <DetailData
                                detailTitle="PAN Number"
                                detailData={data?.panNumber}
                              />
                              <DetailData
                                detailTitle="UAN Number"
                                detailData={data?.uanNumber}
                              />

                              {/* <DetailData
                                detailTitle="Provident Fund Number"
                                detailData={data?.pfNumber}
                              /> */}
                              <DetailData
                                detailTitle="Passport Number"
                                detailData={data?.passportNumber}
                              />
                              <DetailData
                                detailTitle="Passport Issue Date"
                                detailData={
                                  data?.passportIssueDate
                                    ? format(
                                        new Date(data?.passportIssueDate),
                                        "dd/MM/yyyy"
                                      )
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Passport Expiry Date"
                                detailData={
                                  data?.passportExpiryDate
                                    ? format(
                                        new Date(data?.passportExpiryDate),
                                        "dd/MM/yyyy"
                                      )
                                    : null
                                }
                              />
                            </div>
                          )}

                          {subTabIndex === 6 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <DetailData
                                detailTitle="Account Holder Name"
                                detailData={data?.accountHolderName}
                              />
                              <DetailData
                                detailTitle="Account Number"
                                detailData={data?.bankAccountNumber}
                              />
                              {/* <DetailData detailTitle="Bank Details" detailData={data?.shift}/> */}
                              <DetailData
                                detailTitle="IFSC Code"
                                detailData={data?.ifscCode}
                              />
                              <DetailData
                                detailTitle="Bank Name"
                                detailData={data?.branchName}
                              />

                              <DetailData
                                detailTitle="Type Of Account"
                                detailData={data?.typeofAccount}
                              />
                            </div>
                          )}

                          {subTabIndex === 1 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: "0" }}
                              >
                                Personal Details
                              </div>
                              <DetailData
                                detailTitle="Date of Birth"
                                detailData={
                                  data?.dob
                                    ? format(new Date(data?.dob), "dd/MM/yyyy")
                                    : null
                                }
                              />
                              <DetailData
                                detailTitle="Gender"
                                detailData={data?.gender ?? "-"}
                              />
                              <DetailData
                                detailTitle="Religion"
                                detailData={data?.religion ?? "-"}
                              />
                              <DetailData
                                detailTitle="Nationality"
                                detailData={data?.nationality ?? "-"}
                              />
                              <DetailData
                                detailTitle="Language"
                                detailData={data?.language ?? "-"}
                              />
                              <DetailData
                                detailTitle="Marital Status"
                                detailData={data?.maritalStatus ?? "-"}
                              />
                              {data?.maritalStatus === "Married" && (
                                <DetailData
                                  detailTitle="Date of Marriage"
                                  detailData={
                                    data?.dom
                                      ? format(
                                          new Date(data?.dom),
                                          "dd/MM/yyyy"
                                        )
                                      : "-"
                                  }
                                />
                              )}
                              <DetailData
                                detailTitle="Overall Experience"
                                detailData={
                                  data?.previousExperience
                                    ? `${data?.previousExperience} Years`
                                    : "-"
                                }
                              />
                              <DetailData
                                detailTitle="Current Salary"
                                detailData={
                                  data?.currentSalary
                                    ? `${data?.currentSalary} Lakhs`
                                    : "-"
                                }
                              />
                              <DetailData
                                detailTitle="Offer Letter"
                                detailData={
                                  data?.offerLetter ? (
                                    <UploadDetail
                                      filepath={`OfferLetter/${data?.offerLetter}`}
                                    />
                                  ) : (
                                    "-"
                                  )
                                }
                              />
                              <DetailData
                                detailTitle="Profile Photo"
                                detailData={
                                  data?.photo ? (
                                    <UploadImageDetail
                                      filepath={`ProfileImageUploads/${data?.photo}`}
                                    />
                                  ) : (
                                    "-"
                                  )
                                }
                              />
                              {/* <DetailData
                                detailTitle="Source of Hire"
                                detailData={data?.sourceOfHigher}
                              /> */}

                              {data?.maritalStatus === "Married" && (
                                <>
                                  <div
                                    className="headingForm"
                                    style={{ width: "100%", margin: 0 }}
                                  >
                                    Spouse Details
                                  </div>
                                  <DetailData
                                    detailTitle="Spouse Name"
                                    detailData={data?.spouseName}
                                  />
                                  <DetailData
                                    detailTitle="Spouse Mobile Number"
                                    detailData={data?.spouseMobileNumber}
                                  />
                                  <DetailData
                                    detailTitle="No. of Children"
                                    detailData={data?.noOfchildrens}
                                  />
                                </>
                              )}
                              {/* <DetailData
                                detailTitle="Date of Birth"
                                detailData={data?.spouseDob}
                              /> */}
                              <div
                                className="headingForm"
                                style={{ width: "100%", margin: 0 }}
                              >
                                Emergency Contact Details
                              </div>
                              <DetailData
                                detailTitle="Person Name"
                                detailData={data?.emergencyContactName}
                              />
                              <DetailData
                                detailTitle="Relationship"
                                detailData={data?.contactPersonRelationship}
                              />
                              <DetailData
                                detailTitle="Emergency Mobile Number"
                                detailData={data?.emergencyContactNumber}
                              />
                              {/* <DetailData
                                detailTitle="Office Phone Number"
                                detailData={data?.officePhoneNumber}
                              />

                              <DetailData
                                detailTitle="Birth Country"
                                detailData={data?.birthCountry}
                              />
                              <DetailData
                                detailTitle="State"
                                detailData={data?.state}
                              />
                              <DetailData
                                detailTitle="Highest Qualification"
                                detailData={data?.highestQualification}
                              />

                              <DetailData
                                detailTitle="Group Health Insurance"
                                detailData={data?.groupHealthInsurance}
                              />
                              <DetailData
                                detailTitle="Additional Information"
                                detailData={data?.additionalInformation}
                              />
                              <DetailData
                                detailTitle="Skillset"
                                detailData={data?.skillSet}
                              /> */}
                              {/* <DetailData
                                detailTitle="Occupation"
                                detailData={data?.occupation}
                              />
                              <DetailData
                                detailTitle="Company Name"
                                detailData={data?.companyName}
                              />
                              <DetailData
                                detailTitle="Spouse Working?"
                                detailData={data?.spouseWorking}
                              /> */}
                              <div style={{ width: "100%" }}>
                                <div
                                  className="headingForm"
                                  style={{ width: "100%", margin: 0 }}
                                >
                                  Dependent Details
                                </div>
                                <div style={{ width: "100%", padding: "12px" }} className="repeatedSectionContainer">
                                  <table className="tabless tabless-bordered">
                                    <thead
                                      style={{
                                        backgroundColor: "var(--primary-hover)",
                                      }}
                                    >
                                      <tr>
                                        <td>S. No.</td>
                                        <td>Name</td>
                                        <td>Date of Birth</td>
                                        <td>Relationship</td>
                                        <td>Gender</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data?.dependentslist?.length > 0
                                        ? data?.dependentslist?.map(
                                            (item, index) => {
                                              return (
                                                <tr className="trStyle">
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    {item.dob
                                                      ? format(
                                                          new Date(item.dob),
                                                          "dd/MM/yyyy"
                                                        )
                                                      : ""}
                                                  </td>
                                                  <td>{item.relationship}</td>
                                                  <td>{item.gender}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        : "No Data Found"}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}

                          {subTabIndex === 2 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div style={{ width: "100%", padding: "12px" }} className="repeatedSectionContainer">
                                <table className="tabless tabless-bordered">
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td>S. No.</td>
                                      <td>Education Level</td>
                                      <td>Specialization</td>
                                      <td>College/School</td>
                                      <td>Year of Passing</td>
                                      <td>Grade/%</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.educationslist?.length > 0
                                      ? data?.educationslist?.map(
                                          (item, index) => {
                                            return (
                                              <tr className="trStyle">
                                                <td>{index + 1}</td>
                                                <td>{item.educationLevel}</td>
                                                <td>{item.degree}</td>
                                                <td>{item.college}</td>
                                                <td>
                                                  {item.yearofPassingToYear}
                                                </td>
                                                <td>{item.grade}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : "No Data Found"}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {subTabIndex === 3 && (
                            <div className="tabularMasterDetailsPageTabBodyContainer">
                              <div style={{ width: "100%", padding: "12px" }} className="repeatedSectionContainer">
                                <table className="tabless tabless-bordered">
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td>S. No.</td>
                                      <td>Job Role</td>
                                      <td>Job Position</td>
                                      <td>Company</td>
                                      <td>Duration (in Years)</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.experienceslist?.length > 0
                                      ? data?.experienceslist?.map(
                                          (item, index) => {
                                            return (
                                              <tr className="trStyle">
                                                <td>{index + 1}</td>
                                                <td>{item.occupation}</td>
                                                <td>{item.summary}</td>
                                                <td>{item.company}</td>
                                                <td>{item.duration}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : "No Data Found"}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <Timeline
                        id={selectedId}
                        module={"Candidate Onboarding"}
                        requestNumber={
                          data?.candidateOnboardings
                            ? data?.candidateOnboardings[0]?.referenceNo
                            : null
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={selectedId}
                      module={"Candidate Onboarding"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsCandidate };
