import React, { useState, useEffect } from "react";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { showToast, isLoading } from "../../../Features";
import { useDispatch } from "react-redux";

import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectFormikMulti } from "../../../Components/FormComponent/SelectFormikMulti";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";

export const CreateAnnouncementsComp = ({ data }) => {
  const [categoryValuesArray, setCategoryValuesArray] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [typeValuesArray, setTypeValuesArray] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Announcement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const getAreaData = async () => {
    try {
      const result = await getRequest("Area/GetAreas");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setCategoryList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDepartmentData = async () => {
    try {
      const result = await getRequest("Department/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setTypeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getDepartmentData();
    getAreaData();
  }, []);

  useEffect(() => {
    if (typeList.length >= 0) {
      data?._departmentIds?.map((item) => {
        let tempVal = typeList?.filter((options) => options?.value == item);
        if (tempVal.length > 0) {
          setTypeValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }

    if (categoryList.length >= 0) {
      data?._locationIds?.map((item) => {
        let tempVal = categoryList?.filter((options) => options?.value == item);
        if (tempVal.length > 0) {
          setCategoryValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
  }, [typeList, categoryList]);

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="description"
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Location(s)"} />
        <SelectFormikMulti
          multi
          placeholder={"Select Location(s)"}
          name="_locationIds"
          options={categoryList}
          values={categoryValuesArray}
        />
      </div>
      <div>
        <LabelCustom labelName={"Department(s)"} />
        <SelectFormikMulti
          multi
          placeholder={"Select Department(s)"}
          name="_departmentIds"
          options={typeList}
          values={typeValuesArray}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload"} />
        <FileUploadFormik name={`upload`} />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG
        </span>
      </div>
    </div>
  );
};
