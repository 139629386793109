import React, {useState} from "react";
import { Checkbox } from "@mui/material";
import { LabelCustom, YesNoFormik } from "../../../../Components/FormComponent/Index";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";
const Restrictions = (props) => {
  const leaveOption = [
    {label:"Count as Leave",value:"Count as Leave"},
    {label:"Don't count as Leave", value:"Don't count as Leave"}
  ];
  const allowOption = [
    {label:"Allow",value:"Allow"},
    {label:"Don't Allow", value:"Don't Allow"}
  ];
  const dayOption = [
    {label:"Full Day",value:"Full Day"},
    {label:"Half Day", value:"Half Day"}
  ];


const [isChecked, setIsChecked] = useState(false);
const [isClicked, setIsClicked] = useState(false);
useEffect(() => {
  document.title = `PeopleSol - Restrictions `;
  return () => {
    document.title = 'PeopleSol';
  };
}, []);
  return (
    <>
      <div className="formcustom">
      <div>
      <div className="headingForm">Weekends Between Leave Period :</div>
        <RadioFormik   
            name="weekends"
             
            options={leaveOption}

            />
      </div>
      <div>
      <div className="headingForm">Holidays Between Leave Period :</div>
        <RadioFormik   
            name="holidays"
             
            options={leaveOption}

            />
      </div>
      <div>
      <div className="headingForm">While Applying Leave, Exceed Leave Balance </div>
        <RadioFormik   
            name="exceedLeavebalance"
             
            options={allowOption}

            />
      </div>
      <div>
      <div className="headingForm">Duration(s) Allowed :</div>
        <RadioFormik   
            name="duratiuonAllowed"
             
            options={dayOption}

            />
      </div>
      
      <div>
      <div className="headingForm">Allow Requests For :</div>

      <div>
   <Checkbox onClick={() => setIsChecked((prev) => !prev)}/>
      <label>Past Dates</label>
    {isChecked && 
   <div>
        <div style={{display:'flex',alignItems:'center', paddingLeft:'10px'}}>
        Past <InputCustomFormik
          name="pastReqDays"
          step=".001"
          max={999}
        /> Days
        </div>
        </div>
}
      </div>

    <div>
      <Checkbox onClick={() => setIsClicked((prev) => !prev)} />
      <label>Future Dates</label>
      {isClicked && 
      <div>
        <LabelCustom labelName={"Future dates"} />
        <div style={{display:'flex',alignItems:'center', paddingLeft:'10px'}}>
        Next <InputCustomFormik
          name="nextReqDays"
          step=".001"
          max={999}
        /> Days
        </div>
        <div style={{display:'flex',alignItems:'center', paddingLeft:'10px'}}>
        To be Applied<InputCustomFormik
          name="advanceReqDays"
          step=".001"
          max={999}
        /> Days in Advance
        </div>
      </div>
     }
      </div>
      </div>
      <div>
      <div className="headingForm">Show Leave Type When Balance is 0?</div>
        <YesNoFormik   
           name="isZero"
            />
      </div>
      <div>
        <LabelCustom s labelName={"Minimum leave that can be availed per application"} />
        <InputCustomFormik
        placeholder={"Enter Minimum leave"}
          name="minAppLeave"
          step=".001"
          max={999}
        />
      </div>
      <div>
        <LabelCustom s labelName={"Maximum leave that can be availed per application"} />
        <InputCustomFormik
         placeholder={"Enter Maximum leave"}
          name="maxAppLeave"
          step=".001"
          max={999}
        />
      </div>
    
      </div>
    </>
  );
};

export { Restrictions };
