import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import "./OfficeLocation.css";
import { useLocation, useNavigate } from "react-router";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { getRequest, postRequest } from "../../../Services/axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { useSelector } from "react-redux";

function CreateOfficeLocation() {
  let defaultPath = [
    { lat: 23.247258087626, lng: 77.39655501468 },
    { lat: 23.247520915488, lng: 77.396420820203 },
    { lat: 23.24745135867, lng: 77.39624228469 },
    { lat: 23.247197829934, lng: 77.396352250331 },
  ];
  const [path, setPath] = useState(defaultPath);
  const [employeeList, setEmployeeList] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectingEmplList, setSelectingEmpList] = useState([]);
  const [removingEmplList, setRemovingEmpList] = useState([]);
  const [selectedEmplList, setSelectedEmpList] = useState([]);
  const [selectedAllEmplList, setSelectedAllEmpList] = useState([]);
  const [isMapScriptLoading, setIsMapScriptLoading] = useState(true);
  const [searchAddressLocation, setSearchAddressLocation] = useState({
    lat: 23.2473593,
    lng: 77.3963774,
  });
  const [officeAddress, setOfficeAddress] = useState("");
  const [address, setAddress] = useState("");
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [eventtData, setEventtData] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [allSelectedEmpCheckbox, setAllSelectedEmpCheckbox] =useState([]);
  // const [allAvailableEmpCheckbox, setAllAvailableEmpCheckbox] =useState([]);
  
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedId = location?.state?.id;


  function getShortFormatPath(allpath){
    let newPath=allpath.map((d)=>{
      return {lat:d?.latitude, lng:d?.longitude};
    });
    setPath(newPath);
  }
  useEffect(() => {
    if(selectedId){
      document.title = `PeopleSol - Update Office Location `;
      return () => {
        document.title = 'PeopleSol';
      };
    }
    if(!selectedId || selectedId ===""){
      document.title = `PeopleSol - Create Office Location `;
      return () => {
        document.title = 'PeopleSol';
      };
    }
  }, []);
  function selectAllAvailableEmployee(e){
    if(e.currentTarget.checked){
      let allSelected=employeeList.map((d,i)=>{
        return  d?.id;
      });
      setSelectingEmpList(allSelected);
      return;
    }
    setSelectingEmpList([]);
  }
  function selectAllSelectedEmployee(e){
    if(e.currentTarget.checked){
      let allSelected=selectedEmplList.map((d,i)=>{
        return  d?.id;
      });
      setRemovingEmpList(allSelected);
      return;
    }
    setRemovingEmpList([]);
  }

  function getPolygonCentroid(polygonCoordinates) {
    let cx = 0;
    let cy = 0;
  
    const numVertices = polygonCoordinates.length;
  
    for (const vertex of polygonCoordinates) {
      cx += vertex.lat;
      cy += vertex.lng;
    }
  
    const centroidX = cx / numVertices;
    const centroidY = cy / numVertices;
  
    return {lat: centroidX, lng: centroidY};
  }

  const getDetailsData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`OfficeLocation/Details/${selectedId}`);
      setEventtData(result?.data);
      setOfficeAddress(result?.data?.name);
      getShortFormatPath(result?.data?.points);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId && allEmployeeList?.length > 0 && eventtData) {
      let tempList = [];
      eventtData?.locationEmployees?.forEach((d) => {
        tempList.push(d?.employeeId);
      });
      let tempSelectedList = allEmployeeList.filter((d) =>
        tempList.includes(d?.id)
      );
      setSelectedEmpList(JSON.parse(JSON.stringify(tempSelectedList)));
      setSelectedAllEmpList(JSON.parse(JSON.stringify(tempSelectedList)));
      setSelectingEmpList(tempList);
      // setSelectedAllEmpList(tempList);
    }
  }, [selectedId, allEmployeeList, eventtData]);

  useEffect(() => {
    if (selectedId) {
      getDetailsData();
    }
  }, []);

  let listData = [];
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      result.data.map((item) => {
        return listData.push(item);
      });
      setEmployeeList(listData);
      setAllEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function getName(name) {
    if (name) {
      let index = name?.indexOf("(");
      let newName = index > -1 ? name.substring(0, index) : name;
      return newName;
    }
    return null;
  }


  function removeEmpList() {
    let newSelectedeEmp = selectedAllEmplList.filter((d, i) => {
      return !removingEmplList.includes(d?.id);
    });
    setSelectedEmpList(JSON.parse(JSON.stringify(newSelectedeEmp)));
    setSelectedAllEmpList(JSON.parse(JSON.stringify(newSelectedeEmp)));
    setRemovingEmpList([]);
  }

  const searchEmployeeFromList = (e) => {
    if (e.target.value === "") {
      setEmployeeList(allEmployeeList);
    } else {
      let tempEmpList = allEmployeeList.filter((item) =>
        item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setEmployeeList(JSON.parse(JSON.stringify(tempEmpList)));
    }
  };
  const searchSelectedEmployeeFromList = (e) => {
    if (e.target.value === "") {
      setSelectedEmpList(selectedAllEmplList);
    } else {
      let tempEmpList = selectedAllEmplList.filter((item) =>
        item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSelectedEmpList(JSON.parse(JSON.stringify(tempEmpList)));
    }
  };
  function getFullFormatPath(points){
    let newPoints=points.map((d,i)=>{
      return {latitude:d?.lat, longitude:d?.lng}
    });
    return newPoints;
  }
  useEffect(() => {
    getEmployeeData();
  }, []);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const updateOfficeLocation = async () => {
    let mapEmployee = [];
    for (let i = 0; i < selectedEmplList.length; i++) {
      mapEmployee[i] = {
        OfficeId: selectedId ?? 0,
        EmployeeId: selectedEmplList[i]?.id,
      };
    }
    let payload = {
      Id: selectedId,
      Name: address,
      CreatedBy: eventtData?.createdBy,
      UpdatedBy: LoggedInUser?.id,
      points: getFullFormatPath(path),
      LocationEmployees: mapEmployee,
    };

    const result = await postRequest("OfficeLocation/Edit", payload);
     if( result?.data){
      dispatch(
        showToast({
          text: "Office Location has been created successfully!",
          severity: "success",
        })
      );
      setSelectedEmpList([]);
      setSelectedAllEmpList([]);
      setSelectingEmpList([]);
      setPath(defaultPath);
      navigate("/hrmsmaster/timeattendance/officelocation");
    }
  };

  const createOfficeLocation = () => {
    if (address === "" || address === null) {
      dispatch(
        showToast({
          text: "Please select your office location ",
          severity: "error",
        })
      );
      return;
    }
    if (officeAddress === "" || officeAddress === null) {
      dispatch(
        showToast({
          text: "Please enter your office location",
          severity: "error",
        })
      );
      return;
    }
    if (selectedEmplList.length <= 0) {
      dispatch(
        showToast({
          text: "Please select employee to create office location",
          severity: "error",
        })
      );
      return;
    }
    let newPath=getFullFormatPath(path)
    let mapEmployee = [];
    for (let i = 0; i < selectedEmplList.length; i++) {
      mapEmployee[i] = {
        OfficeId: selectedId ?? 0,
        EmployeeId: selectedEmplList[i]?.id,
      };
    }
    let payload = {
      Id: 0,
      Name: officeAddress,
      CreatedBy: LoggedInUser?.id,
      UpdatedBy: LoggedInUser?.id,
      points: newPath,
      LocationEmployees: mapEmployee,
    };

    postRequest("OfficeLocation/Create", payload)
      .then((response) => {
        dispatch(
          showToast({
            text: "Office Location has been created successfully!",
            severity: "success",
          })
        );
        setSelectedEmpList([]);
        setSelectingEmpList([]);
        setSelectedAllEmpList([]);
        setPath(defaultPath);
        navigate("/hrmsmaster/timeattendance/officelocation");
      })
      .catch((error) => {
        dispatch(
          showToast({
            text: "Something went wrong, Please try again! " + error.response,
            severity: "error",
          })
        );
      });
  };

  useEffect(() => {
    if (!document.getElementById("google-maps-api-script")) {
      const script = document.createElement("script");

      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_.GOOGLE_MAP_API_KEY}&libraries=places`;
      script.async = true;
      script.id = "google-maps-api-script";
      script.onload = () => {
        setIsMapScriptLoading(false);
      };
      document.body.appendChild(script);
    } else {
      setIsMapScriptLoading(false);
    }
  }, []);

  return (
    <>
      {!selectedId && (
        <div className="officeLocationCreateBtn">
          <Popup
            popupHeading={"Create Office Location"}
            firstClassName="officeLocationPopupBox1"
            secondClassName="officeLocationPopupBox2"
            bodyClassName="officeLocationBodyPopupBox"
            contentClassName="officeLocationContentHolder"
            content={
              <div className="officeLocationPopupContainer">
                <div className="officeLocationContainer">
                  <div className="searchAndMapContainer">
                    <div className="inputOfficeLocationContainer">
                      <div className="inputOfficeLocationWrapper">
                      <h5>Office Location<sup>*</sup></h5>
                      <div className="inputOfficeLocation">
                        <input type="text" placeholder="Enter your office location "  onChange={(e)=>{setOfficeAddress(e?.target?.value)}} value={officeAddress}/>
                      </div>
                      </div>
                    </div>
                    <div className="googleSearchContainer">
                      <span>Search Location<sup>*</sup></span>
                      <div className="inputSearchContainer">
                        {!isMapScriptLoading && (
                          <PlacesAutocomplete
                            className="searchInputBoxContainer"
                            value={address}
                            onChange={(address) => {
                              setAddress(address);
                            }}
                            onSelect={(address) => {
                              setAddress(address);
                              geocodeByAddress(address)
                                .then((results) => getLatLng(results[0]))
                                .then((latLng) => {
                                  setSearchAddressLocation(latLng);
                                  setPath(
                                    JSON.parse(
                                      JSON.stringify([
                                        {
                                          lat: latLng.lat - 0.0001012,
                                          lng: latLng.lng + 0.0001776,
                                        },
                                        {
                                          lat: latLng.lat + 0.0001616,
                                          lng: latLng.lng + 0.0000434,
                                        },
                                        {
                                          lat: latLng.lat + 0.000092,
                                          lng: latLng.lng - 0.0001351,
                                        },
                                        {
                                          lat: latLng.lat - 0.0001614,
                                          lng: latLng.lng - 0.0000251,
                                        },
                                      ])
                                    )
                                  );
                                })
                                .catch((error) =>{});
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="ofcSearchWrapper">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Location",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#f4fdff",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        )}
                      </div>
                    </div>
                    <div
                      className="officeLocationMapContainer"
                      style={{ height: "500px" }}
                    >
                      {!isMapScriptLoading && (
                        <GoogleMap
                          mapContainerClassName="App-map"
                          center={searchAddressLocation}
                          zoom={20}
                          version="weekly"
                          
                        >
                          <Polygon
                            editable
                            draggable
                            path={path}
                            onMouseUp={onEdit}
                            onDragEnd={onEdit}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                          />
                        </GoogleMap>
                      )}
                    </div>
                  </div>
                  <div className="officeLocationEmployeeContainer">
                    <div className="officeLocationEmployeeWrapper">
                      <div className="officeLocationEmployeeHeading">
                        <h3>Map Employees</h3>
                      </div>
                      <div className="officeLocationEmployeeBox">
                        <div className="officeLocationEmployeeList">
                          <div className="officeLocationEmployeeListBox">
                            <div className="empSearchContainer availableSearch">
                              <input
                                type="text"
                                placeholder="Search Available"
                                size={20}
                                onChange={searchEmployeeFromList}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <circle
                                  cx="8.42358"
                                  cy="8.27942"
                                  r="6.75"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                />
                                <line
                                  x1="13.2342"
                                  y1="13.5294"
                                  x2="18.1736"
                                  y2="18.4688"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="availableEmployeeList">
                              <div className="availableEmpBody">
                                <div className="availableEmpHeader">
                                    <div className="selectAllCheckbox">
                                      <input type="checkbox" className="selectInput large" onClick={selectAllAvailableEmployee} checked={employeeList.length === selectingEmplList.length}/>
                                    </div>
                                    <h4>Available ({employeeList?.length})</h4>
                                </div>
                                <ul>
                                  {employeeList?.map((d, i) => {
                                    return (
                                      <li>
                                      <span>
                                        <input
                                          className="selectInput large"
                                          type="checkbox"
                                          checked={selectingEmplList?.includes(
                                            d?.id
                                          )}
                                          onClick={(e) => {
                                            if (e.currentTarget.checked) {
                                              setSelectingEmpList([
                                                ...selectingEmplList,
                                                d.id,
                                              ]);
                                              // setSelectedAllEmpList([...selectingEmplList, d.id]);
                                            } else {
                                              let tempSelectingEmpList =
                                                JSON.parse(
                                                  JSON.stringify(
                                                    selectingEmplList
                                                  )
                                                );
                                              tempSelectingEmpList.splice(tempSelectingEmpList.indexOf(d?.id), 1);
                                              setSelectingEmpList(
                                                JSON.parse(JSON.stringify(tempSelectingEmpList))
                                              );
                                              // setSelectedAllEmpList(JSON.stringify(tempSelectingEmpList))
                                            }
                                          }}
                                        />
                                      </span>
                                        <span className="getEmployeeName">
                                        {getName(d?.name)}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <button
                                disabled={selectingEmplList <= 0}
                                onClick={() => {
                                  let tempList = allEmployeeList.filter(
                                    (empItem) =>
                                      selectingEmplList?.includes(empItem?.id)
                                  );
                                  setSelectedEmpList(
                                    JSON.parse(JSON.stringify(tempList))
                                  );
                                  setSelectedAllEmpList(
                                    JSON.parse(JSON.stringify(tempList))
                                  );
                                }}
                              >
                                Add Employee
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="7"
                                  viewBox="0 0 13 7"
                                  fill="none"
                                >
                                  <path
                                    d="M1.05225 3.13487C0.835758 3.13487 0.66026 3.31037 0.66026 3.52686C0.66026 3.74334 0.835758 3.91884 1.05225 3.91884V3.13487ZM12.3294 3.80403C12.4825 3.65095 12.4825 3.40276 12.3294 3.24968L9.83484 0.755092C9.68176 0.602012 9.43356 0.602012 9.28048 0.755092C9.1274 0.908172 9.1274 1.15636 9.28048 1.30944L11.4979 3.52686L9.28048 5.74427C9.1274 5.89735 9.1274 6.14554 9.28048 6.29862C9.43356 6.4517 9.68176 6.4517 9.83484 6.29862L12.3294 3.80403ZM1.05225 3.91884H12.0522V3.13487H1.05225V3.91884Z"
                                    fill="white"
                                  />
                                </svg>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="officeLocationEmployeeListBox">
                            <div className="empSearchContainer selectedSearch">
                              <input
                                type="text"
                                placeholder="Search Selected"
                                size={20}
                                onChange={searchSelectedEmployeeFromList}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <circle
                                  cx="8.42358"
                                  cy="8.27942"
                                  r="6.75"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                />
                                <line
                                  x1="13.2342"
                                  y1="13.5294"
                                  x2="18.1736"
                                  y2="18.4688"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="selectedEmplList">
                              <div className="selectedEmpBody">
                                <div className="selectedEmpHeader">
                                  <div className="selectAllCheckbox">
                                    <input type="checkbox" className="selectInput large" onChange={selectAllSelectedEmployee} checked={selectedEmplList.length !== 0 && selectedEmplList.length === removingEmplList.length}/>
                                  </div>
                                  <h4>Selected ({selectedEmplList?.length})</h4>
                                </div>
                                <ul>
                                  {selectedEmplList?.length > 0 &&
                                    selectedEmplList?.map((d, i) => {
                                      return (
                                        <li>
                                          <span>
                                          <input
                                            className="selectInput large"
                                            type="checkbox"
                                            checked={removingEmplList?.includes(
                                              d?.id
                                            )}
                                            onClick={(e) => {
                                              if (e.currentTarget.checked) {
                                                setRemovingEmpList([
                                                  ...removingEmplList,
                                                  d?.id,
                                                ]);
                                              } else {
                                                let tempremovingEmpList =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      removingEmplList
                                                    )
                                                  );
                                                tempremovingEmpList.splice(tempremovingEmpList.indexOf(d?.id), 1);
                                                setRemovingEmpList(
                                                  tempremovingEmpList
                                                );
                                              }
                                            }}
                                          />
                                          </span>{" "}
                                          <span>
                                          {getName(d?.name)}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                              <button
                                disabled={selectedEmplList <= 0}
                                onClick={removeEmpList}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="7"
                                  viewBox="0 0 13 7"
                                  fill="none"
                                >
                                  <path
                                    d="M12.1694 3.13487C12.3859 3.13487 12.5614 3.31037 12.5614 3.52686C12.5614 3.74334 12.3859 3.91884 12.1694 3.91884V3.13487ZM0.892257 3.80403C0.739177 3.65095 0.739177 3.40276 0.892257 3.24968L3.38684 0.755092C3.53992 0.602012 3.78812 0.602012 3.9412 0.755092C4.09428 0.908172 4.09428 1.15636 3.9412 1.30944L1.72379 3.52686L3.9412 5.74427C4.09428 5.89735 4.09428 6.14554 3.9412 6.29862C3.78812 6.4517 3.53992 6.4517 3.38684 6.29862L0.892257 3.80403ZM12.1694 3.91884H1.16943V3.13487H12.1694V3.91884Z"
                                    fill="white"
                                  />
                                </svg>
                                Remove Empolyee
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="officeLocationFooterBtn">
                      <button
                        className="officeLocationCancelBtn"
                        onClick={() => {
                          navigate("/hrmsmaster/timeattendance/officelocation");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="officeLocationCreateBtn"
                        onClick={createOfficeLocation}
                      >
                        Create Office Location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
            handleClose={() => {
              navigate("/hrmsmaster/timeattendance/officelocation");
            }}
          />
        </div>
      )}
      {selectedId && (
        <div className="officeLocationCreateBtn">
          <Popup
            popupHeading={"Update Office Location"}
            firstClassName="officeLocationPopupBox1"
            secondClassName="officeLocationPopupBox2"
            bodyClassName="officeLocationBodyPopupBox"
            contentClassName="officeLocationContentHolder"
            content={
              <>
                {eventtData && (
                  <div className="officeLocationPopupContainer">
                    <div className="officeLocationContainer">
                        <div className="searchAndMapContainer">
                          <div className="inputOfficeLocationContainer">
                          <div className="inputOfficeLocationWrapper">
                          <h5>Office Location<sup>*</sup></h5>
                          <div className="inputOfficeLocation">
                            <input type="text" placeholder="Enter your office location "  onChange={(e)=>{setOfficeAddress(e?.target?.value)}} value={officeAddress}/>
                          </div>
                          </div>
                          </div>
                        <div className="googleSearchContainer">
                          <span>Search Location<sup>*</sup></span>
                          <div className="inputSearchContainer">
                            {!isMapScriptLoading && (
                              <PlacesAutocomplete
                                className="searchInputBoxContainer"
                                value={address}
                                onChange={(address) => {
                                  setAddress(address);
                                }}
                                onSelect={(address) => {
                                  setAddress(address);
                                  geocodeByAddress(address)
                                    .then((results) => getLatLng(results[0]))
                                    .then((latLng) => {
                                      setSearchAddressLocation(latLng);
                                      setPath(
                                        JSON.parse(
                                          JSON.stringify([
                                            {
                                              lat: latLng.lat + 0.0170413,
                                              lng: latLng.lng - 0.0230026,
                                            },
                                            {
                                              lat: latLng.lat - 0.0206145,
                                              lng: latLng.lng - 0.0067792,
                                            },
                                            {
                                              lat: latLng.lat - 0.0072378,
                                              lng: latLng.lng + 0.0207739,
                                            },
                                            {
                                              lat: latLng.lat + 0.0210682,
                                              lng: latLng.lng + 0.0064416,
                                            },
                                          ])
                                        )
                                      );
                                    })
                                    .catch((error) =>{});
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div className="ofcSearchWrapper">
                                    <input
                                      {...getInputProps({
                                        placeholder: "Select Location",
                                        className: "location-search-input",
                                      })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          </div>
                        </div>
                        <div
                          className="officeLocationMapContainer"
                          style={{ height: "550px" }}
                        >
                          {!isMapScriptLoading && (
                            <GoogleMap
                              mapContainerClassName="App-map"
                              center={getPolygonCentroid(path)}
                              zoom={19}
                              version="weekly"
                              
                            >
                              <Polygon
                                editable
                                draggable
                                path={path}
                                onMouseUp={onEdit}
                                onDragEnd={onEdit}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              />
                            </GoogleMap>
                          )}
                        </div>
                      </div>
                      <div className="officeLocationEmployeeContainer">
                        <div className="officeLocationEmployeeWrapper">
                          <div className="officeLocationEmployeeHeading">
                            <h3>Map Employees</h3>
                          </div>
                          <div className="officeLocationEmployeeBox">
                        <div className="officeLocationEmployeeList">
                          <div className="officeLocationEmployeeListBox">
                            <div className="empSearchContainer availableSearch">
                              <input
                                type="text"
                                placeholder="Search Available"
                                size={20}
                                onChange={searchEmployeeFromList}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <circle
                                  cx="8.42358"
                                  cy="8.27942"
                                  r="6.75"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                />
                                <line
                                  x1="13.2342"
                                  y1="13.5294"
                                  x2="18.1736"
                                  y2="18.4688"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="availableEmployeeList">
                              <div className="availableEmpBody">
                                <div className="availableEmpHeader">
                                    <div className="selectAllCheckbox">
                                      <input type="checkbox" className="selectInput large" onClick={selectAllAvailableEmployee} checked={employeeList.length === selectingEmplList.length}/>
                                    </div>
                                    <h4>Available ({employeeList?.length})</h4>
                                </div>
                                <ul>
                                  {employeeList?.map((d, i) => {
                                    return (
                                      <li>
                                      <span>
                                        <input
                                          className="selectInput large"
                                          type="checkbox"
                                          checked={selectingEmplList?.includes(
                                            d?.id
                                          )}
                                          onClick={(e) => {
                                            if (e.currentTarget.checked) {
                                              setSelectingEmpList([
                                                ...selectingEmplList,
                                                d.id,
                                              ]);
                                              // setSelectedAllEmpList([...selectingEmplList, d.id]);
                                            } else {
                                              let tempSelectingEmpList =
                                                JSON.parse(
                                                  JSON.stringify(
                                                    selectingEmplList
                                                  )
                                                );
                                              tempSelectingEmpList.splice(tempSelectingEmpList.indexOf(d?.id), 1);
                                              setSelectingEmpList(
                                                JSON.parse(JSON.stringify(tempSelectingEmpList))
                                              );
                                              // setSelectedAllEmpList(JSON.stringify(tempSelectingEmpList))
                                            }
                                          }}
                                        />
                                      </span>
                                        <span className="getEmployeeName">
                                        {getName(d?.name)}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <button
                                disabled={selectingEmplList <= 0}
                                onClick={() => {
                                  let tempList = allEmployeeList.filter(
                                    (empItem) =>
                                      selectingEmplList?.includes(empItem?.id)
                                  );
                                  setSelectedEmpList(
                                    JSON.parse(JSON.stringify(tempList))
                                  );
                                  setSelectedAllEmpList(
                                    JSON.parse(JSON.stringify(tempList))
                                  );
                                }}
                              >
                                Add Employee
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="7"
                                  viewBox="0 0 13 7"
                                  fill="none"
                                >
                                  <path
                                    d="M1.05225 3.13487C0.835758 3.13487 0.66026 3.31037 0.66026 3.52686C0.66026 3.74334 0.835758 3.91884 1.05225 3.91884V3.13487ZM12.3294 3.80403C12.4825 3.65095 12.4825 3.40276 12.3294 3.24968L9.83484 0.755092C9.68176 0.602012 9.43356 0.602012 9.28048 0.755092C9.1274 0.908172 9.1274 1.15636 9.28048 1.30944L11.4979 3.52686L9.28048 5.74427C9.1274 5.89735 9.1274 6.14554 9.28048 6.29862C9.43356 6.4517 9.68176 6.4517 9.83484 6.29862L12.3294 3.80403ZM1.05225 3.91884H12.0522V3.13487H1.05225V3.91884Z"
                                    fill="white"
                                  />
                                </svg>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="officeLocationEmployeeListBox">
                            <div className="empSearchContainer selectedSearch">
                              <input
                                type="text"
                                placeholder="Search Selected"
                                size={20}
                                onChange={searchSelectedEmployeeFromList}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                              >
                                <circle
                                  cx="8.42358"
                                  cy="8.27942"
                                  r="6.75"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                />
                                <line
                                  x1="13.2342"
                                  y1="13.5294"
                                  x2="18.1736"
                                  y2="18.4688"
                                  stroke="#1296B0"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <div className="selectedEmplList">
                              <div className="selectedEmpBody">
                                <div className="selectedEmpHeader">
                                  <div className="selectAllCheckbox">
                                    <input type="checkbox" className="selectInput large" onChange={selectAllSelectedEmployee} checked={selectedEmplList.length !== 0 && selectedEmplList.length === removingEmplList.length}/>
                                  </div>
                                  <h4>Selected ({selectedEmplList?.length})</h4>
                                </div>
                                <ul>
                                  {selectedEmplList?.length > 0 &&
                                    selectedEmplList?.map((d, i) => {
                                      return (
                                        <li>
                                          <span>
                                          <input
                                            className="selectInput large"
                                            type="checkbox"
                                            checked={removingEmplList?.includes(
                                              d?.id
                                            )}
                                            onClick={(e) => {
                                              if (e.currentTarget.checked) {
                                                setRemovingEmpList([
                                                  ...removingEmplList,
                                                  d?.id,
                                                ]);
                                              } else {
                                                let tempremovingEmpList =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      removingEmplList
                                                    )
                                                  );

                                                tempremovingEmpList.splice(tempremovingEmpList.indexOf(d?.id), 1);
                                                setRemovingEmpList(
                                                  tempremovingEmpList
                                                );
                                              }
                                            }}
                                          />
                                          </span>{" "}
                                          <span>
                                          {getName(d?.name)}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                              <button
                                disabled={selectedEmplList <= 0}
                                onClick={removeEmpList}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="7"
                                  viewBox="0 0 13 7"
                                  fill="none"
                                >
                                  <path
                                    d="M12.1694 3.13487C12.3859 3.13487 12.5614 3.31037 12.5614 3.52686C12.5614 3.74334 12.3859 3.91884 12.1694 3.91884V3.13487ZM0.892257 3.80403C0.739177 3.65095 0.739177 3.40276 0.892257 3.24968L3.38684 0.755092C3.53992 0.602012 3.78812 0.602012 3.9412 0.755092C4.09428 0.908172 4.09428 1.15636 3.9412 1.30944L1.72379 3.52686L3.9412 5.74427C4.09428 5.89735 4.09428 6.14554 3.9412 6.29862C3.78812 6.4517 3.53992 6.4517 3.38684 6.29862L0.892257 3.80403ZM12.1694 3.91884H1.16943V3.13487H12.1694V3.91884Z"
                                    fill="white"
                                  />
                                </svg>
                                Remove Empolyee
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                        </div>
                        <div className="officeLocationFooterBtn">
                          <button
                            className="officeLocationCancelBtn"
                            onClick={() => {
                              navigate(
                                "/hrmsmaster/timeattendance/officelocation"
                              );
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="officeLocationCreateBtn"
                            onClick={updateOfficeLocation}
                          >
                            Update Office Location
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            }
            handleClose={() => {
              navigate("/hrmsmaster/timeattendance/officelocation");
            }}
          />
        </div>
      )}
    </>
  );
}

export default CreateOfficeLocation;
