import * as signalR from "@microsoft/signalr";
import { showToast } from "../Features";
import { useDispatch } from "react-redux";

const signalRService = (() => {
  // This is an Immediately Invoked Function Expression (IIFE)
  let connection = null;

  const startConnection = async (dispatch, userId) => {
    console.log("c0nnection function called");

    connection = new signalR.HubConnectionBuilder()
      .withUrl("https://chat.aesssolutions.com/conversationsHub", {
        headers: {
          X_AppUser: `${userId}`,
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    try {
      await connection.start();
      dispatch(showToast({ text: "Chat Connected", severity: "success" }));
    } catch (err) {
      dispatch(
        showToast({
          text: err ?? "Error in fetching Data",
          severity: "error",
        })
      );
      console.log(err);
      setTimeout(() => startConnection(userId), 5000);
    }
  };

  const isConnected = () => {
    //check if connected
    return (
      connection && connection.state === signalR.HubConnectionState.Connected
    );
  };
  const stopConnection = async (dispatch) => {
    // Remove the events

    if (connection) {
      connection.off("UpdateConversations");
      connection.off("UpdateMessages");
    }

    // Stop the connection
    if (connection) {
      await connection.stop();
      dispatch(showToast({ text: "Chat Disconnected", severity: "success" }));
      console.log("SignalR Disconnected.");
    }
  };

  // invoke functions

  const invokeGetConversations = async (dispatch, userId) => {
    if (isConnected()) {
      try {
        const result = await connection.invoke("GetConversations", userId, "");
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
        return result;
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke '" +
          "GetConversations" +
          "': the connection is not active."
      );
    }
  };

  const invokeGetMessages = async (dispatch, contactId, userId) => {
    if (isConnected()) {
      try {
        return await connection.invoke("GetMessages", contactId, userId, "");
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke '" + "GetMessages" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeSendMessages = async (dispatch, userId, contactId, data) => {
    if (isConnected()) {
      try {
        return await connection.invoke("SendMessage", userId, contactId, data);
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke '" + "SendMessage" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeGetContacts = async (dispatch, userId) => {
    if (isConnected()) {
      try {
        return await connection.invoke("GetContacts", userId, "");
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke '" + "GetContacts" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeToggleStarMsg = async (
    dispatch,
    userId,
    conversationId,
    msgId
  ) => {
    if (isConnected()) {
      try {
        return await connection.invoke(
          "ToggleMessageBookmarkStatus",
          userId,
          conversationId,
          msgId
        );
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke " +
          "Message Bookmark Status" +
          ": the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeForwardMessages = async (
    dispatch,
    conversationId,
    msgIdList,
    userIdList
  ) => {
    if (isConnected()) {
      try {
        return await connection.invoke(
          "ForwardMessages",
          conversationId,
          msgIdList,
          userIdList
        );
        // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
      } catch (err) {
        dispatch(
          showToast({
            text: err ?? "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        showToast({ text: "The connection is not active", severity: "error" })
      );
      console.error(
        "Cannot invoke " + "ForwardMessages" + ": the connection is not active."
      );
    }
  };

  // Delete selected msg and clear chat
  const usedeleteSelectedMsg = async (
    dispatch,
    deleteReqType,
    conversationId,
    userId,
    msgId,
    starMsgStatus = false
  ) => {
    try {
      if (deleteReqType === "deleteForEveryone") {
        if (msgId?.length === 1) {
          await connection.invoke(
            "DeleteMessage",
            conversationId,
            userId,
            msgId[0]
          );
        }
        //delete the multi message only for me
        if (msgId?.length > 1) {
          await connection.invoke(
            "DeleteMessages",
            conversationId,
            userId,
            msgId
          );
        }
      }
      if (deleteReqType === "deleteForMe") {
        if (msgId?.length === 1) {
          await connection.invoke(
            "DeleteMessageForMe",
            conversationId,
            userId,
            msgId[0]
          );
        }
        //delete the multi message only for me
        if (msgId?.length > 1) {
          await connection.invoke(
            "DeleteForMeMessages",
            conversationId,
            userId,
            msgId
          );
        }
      }
      if (deleteReqType === "clearChat") {
        await connection.invoke(
          "ClearMessages",
          conversationId,
          userId,
          starMsgStatus
        );
      }
      // dispatch(showToast({ text: "Chat Connected", severity: "success" }));
    } catch (err) {
      dispatch(
        showToast({
          text: err ?? "Error in fetching Data",
          severity: "error",
        })
      );
    }
  };

  //on functions

  const onUpdateConversations = (callback) => {
    connection.on("UpdateConversations", callback);
  };

  const onUpdateMessages = (callback) => {
    connection.on("UpdateMessages", callback);
  };
  const onNewMessages = (callback) => {
    connection.on("NewMessage", callback);
  };
  const onMessageResponse = (callback) => {
    connection.on("MessageResponse", callback);
  };

  const onUpdateContacts = (callback) => {
    connection.on("UpdateContacts", callback);
  };

  //return functions
  return {
    startConnection,
    stopConnection,
    invokeGetConversations,
    invokeGetMessages,
    isConnected,
    invokeSendMessages,
    usedeleteSelectedMsg,
    invokeForwardMessages,
    invokeGetContacts,
    onUpdateConversations,
    onUpdateMessages,
    onNewMessages,
    onMessageResponse,
    onUpdateContacts,
    invokeToggleStarMsg,
  };
})();

export default signalRService;
