/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";

import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { CreateInductionComp } from "./FormComp/CreateInductionComp";

const CreateInduction = (props) => {
  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`InductionPlans/Details/${data}`);
      // // //console.log('result?.data',result?.data)
      setDetailsform(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Induction`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    // //console.log('induction data', data)
    if (data) {
      getFormDetails();
    }
  }, []);
  // // //console.log("InductionPlans id",data)
  // // //console.log("Policies Details",detailsform)

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("InductionPlans/Create", values);
      dispatch(
        showToast({
          text: "Induction plan has been created successfully",
          severity: "success",
        })
      );
      returnPage();
    } catch (error) {
      // // //console.log(error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("InductionPlans/Edit", values);
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }

      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValue = {
    EmployeeIds: "",
    InductionDate: "",
    SecondInductionDate: "",
    // Attachment: null,
    Upload: null,
  };

  const SUPPORTED_FORMATS_DOC = ["application/pdf", "application/doc"];

  const validationSchema = Yup.object({
    EmployeeIds: Yup.array().required("Please select Employees"),
    InductionDate: Yup.string().required("Please select Induction Date"),
    SecondInductionDate: Yup.string().required(
      "Please select Second Induction Date"
    ),
    Upload: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "Fichier taille",
        "File Size must be less than 1 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          (value && SUPPORTED_FORMATS_DOC.includes(value.type)) ||
          typeof value !== "object"
      ),
  });
  const arr1 = [];

  const onSubmit = (values) => {
    const formData = new FormData();

    // eslint-disable-next-line no-lone-blocks
    {
      data && formData.append("id", data ? data : 0);
    }
    formData.append("Upload", values?.Upload?.name ? values?.Upload : null);
    data
      ? formData.append("EmployeeIds[0]", values?.EmployeeIds)
      : values?.EmployeeIds?.map((item, index) => {
          return formData.append(`EmployeeIds[${index}]`, item);
        });
    formData.append(
      "InductionDate",
      `${format(values?.InductionDate, "yyyy-MM-dd")}`
    );
    formData.append(
      "SecondInductionDate",
      `${format(values?.SecondInductionDate, "yyyy-MM-dd")}`
    );
    data ? Edit(formData) : createPageData(formData);
  };
  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  // // //console.log('detailsform',detailsform)

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Update Induction Plan" : props.title}
      ButtonLabel={data ? "Update" : "Create"}
      onSubmit={onSubmit}
      validationSchema={data ? "" : validationSchema}
      chooseFormValues={chooseFormValues}
      initialvalue={
        JSON.stringify(detailsform) !== "{}"
          ? {
              ...initialValue,

              EmployeeIds: detailsform?.summary?.employeeId,
              InductionDate: detailsform?.summary?.inductionDate
                ? new Date(detailsform?.summary?.inductionDate)
                : "",
              SecondInductionDate: detailsform?.summary?.secondInductionDate
                ? new Date(detailsform?.summary?.secondInductionDate)
                : "",
              Upload: detailsform?.summary?.attachment
                ? detailsform?.summary?.attachment
                : "",
            }
          : initialValue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateInductionComp
          formValues={formValues}
          data={detailsform}
          editID={data}
        />
      }
    />
  );
};

export { CreateInduction };
