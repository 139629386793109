/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import StarRatings from "react-star-ratings";
import { DetailsKeyComp } from "../../../../Components/FormComponent/PopupComponent/DetailsKeyComp";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { AddAssignee } from "../../../HRMS/Components/HRDeskComponents/Form/AddAssignee";
import { UpdateStatus } from "../../../HRMS/Components/HRDeskComponents/Form/UpdateStatus";
import UpdateA from "../../../../Assets/UpdateA.svg";
import UpdateAs from "../../../../Assets/UpdateAs.svg";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { FeedBack } from "../../../HRMS/Components/HRDeskComponents/Form/Feedback";
import { TimelineHrDesk } from "../../../HRMS/Components/HRDeskComponents/Timeline/TimelineHrDesk";
import { useSelector } from "react-redux";
import { ProblemForm } from "./Form/ProblemForm";
import { AssignVendor } from "./Form/AssignVendor";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { getBadgeCount } from "../../../../Features/badgeUtils";

const DetailsItDeskMyTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [isOpen, setIsOpen] = useState(false);
  const [extra, setExtra] = useState("");
  const togglePopup = (type, color, aid) => {
    setTypeAction(type);
    setColorAction(color);
    setExtra(aid);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const PostFeedback = async (value) => {
    try {
      setLoading(true);
      await postRequest("ItHelpdesks/TicketFeedback", value);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      getDetailsITHelpDesk();
      setLoading(false);
      setIsOpen(false);
    }
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const getDetailsITHelpDesk = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `ItHelpdesks/ticketdetails?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details IT Desk My Ticket`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const PostReopen = async (id) => {
    try {
      setLoading(true);
      await postRequest("ItHelpdesks/Reopen", {
        TicketID: id,
        Status: "ReOpen",
      });
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      setIsOpen(false);
      getDetailsITHelpDesk();
      setLoading(false);
    }
  };

  const GetRatingText = (ratingValue) => {
    if (ratingValue >= 4) {
      return "Above Expectations";
    } else if (ratingValue >= 3 && ratingValue < 4) {
      return "Satisfactory";
    } else if (ratingValue >= 2 && ratingValue < 3) {
      return "Meets Expectation";
    } else {
      return "Needs Improvement";
    }
  };

  const PostAddAssignee = async (value) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await postRequest("ItHelpdesks/Update", value);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data,
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetailsITHelpDesk();
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      setLoading(true);
      if (type === "Approve" || type === "Cancel" || type === "Release") {
        await postRequest(`ItHelpdesks/${type}?Id=${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(`ItHelpdesks/Close?Id=${id}&Remarks=${Remark}`);
      } else {
        await postRequest(`ItHelpdesks/${type}?Id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      setIsOpen(false);
      getDetailsITHelpDesk();
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetailsITHelpDesk();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>ITDesk Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div
                        className="detailsEReq"
                        style={{ flexDirection: "column" }}
                      >
                        <div className="headingForm">Request Details</div>
                        <div className="detailInner">
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.employeeTicket?.ticketNumber}
                          />
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?.status?.replace(/<[^>]+>/g, "")
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?.status?.replace(/<[^>]+>/g, "")
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?.status?.replace(/<[^>]+>/g, "")}
                              </div>
                            }
                          />
                          <Details
                            detailTitle={"Service Category"}
                            Details={data?.employeeTicket?.type?.category?.name}
                          />
                          <Details
                            detailTitle={"Service Type"}
                            Details={data?.employeeTicket?.type?.name}
                          />
                          <Details
                            detailTitle={"Request Category"}
                            Details={
                              data?.employeeTicket?.requestCategory?.name
                            }
                          />
                          <Details
                            detailTitle={"Request Type"}
                            Details={data?.employeeTicket?.requestType?.name}
                          />
                          <Details
                            detailTitle={"Request Assigned To"}
                            Details={data?.assignee}
                          />
                          <Details
                            detailTitle={"Request Description"}
                            Details={data?.employeeTicket.description}
                          />
                          <Details
                            detailTitle={"Request Priority"}
                            Details={data?.employeeTicket?.priority}
                          />
                          {data?.employeeTicket?.attachment === null ? (
                            <Details
                              detailTitle={"Supported Document"}
                              Details={"-"}
                            />
                          ) : (
                            <Details
                              detailTitle={"Supported Document"}
                              Details={
                                <UploadDetail
                                  filepath={`IThelpdeskTickets/${data?.employeeTicket?.attachment}`}
                                />
                              }
                            />
                          )}
                          <Details
                            detailTitle={"Created On"}
                            Details={format(
                              new Date(data?.employeeTicket?.createdOn),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={format(
                              new Date(data?.employeeTicket?.lastUpdatedOn),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                          {/* <Details
                          detailTitle={"Team Name"}
                          Details={
                            data?.teamName
                          }
                        /> */}
                        </div>
                        <div className="headingForm">Requester Details</div>
                        <div className="detailInner">
                          <Details
                            detailTitle={"Requester Name"}
                            Details={`${data?.employeeTicket?.employee?.name} ${data?.employeeTicket?.employee?.lastName}`}
                          />
                          <Details
                            detailTitle={"Requester Designation"}
                            Details={
                              data?.employeeTicket?.employee?.designation
                            }
                          />
                          <Details
                            detailTitle={"Requester Department"}
                            Details={data?.employeeTicket?.employee?.department}
                          />
                          <Details
                            detailTitle={"Requester Email"}
                            Details={data?.employeeTicket?.employee?.email}
                          />
                          <Details
                            detailTitle={"Requester's Manager"}
                            Details={data?.reportingManager}
                          />
                          <Details
                            detailTitle={"Contact Number"}
                            Details={
                              data?.employeeTicket?.employee
                                ?.personalPhoneNumber
                            }
                          />
                          <Details
                            detailTitle={"Location"}
                            Details={data?.employeeTicket?.area?.name}
                          />
                        </div>
                        {data?.itsmTicketFeedback != null && (
                          <>
                            <div className="headingForm">Feedback</div>
                            <div className="detailInner">
                              <DetailsKeyComp
                                detailTitle={"Was your query resolved on time?"}
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback?.queryResolvedOnTime
                                )}
                              />
                              <DetailsKeyComp
                                detailTitle={
                                  "Are you satisfied with the response?"
                                }
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback
                                    ?.satisfiedWithResponse
                                )}
                              />
                              <DetailsKeyComp
                                detailTitle={
                                  "Are you satisfied with the result?"
                                }
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback?.satisfiedWithResult
                                )}
                              />
                              <DetailsKeyComp
                                detailTitle={
                                  "How was your interaction experience with Engineer?"
                                }
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback
                                    ?.interactionExperienceWithEngineer
                                )}
                              />
                              <DetailsKeyComp
                                detailTitle={
                                  "How was your experience using the Tool?"
                                }
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback?.experienceUsingTool
                                )}
                              />
                              <DetailsKeyComp
                                detailTitle={"Remarks"}
                                Details={GetRatingText(
                                  data?.itsmTicketFeedback?.remark
                                )}
                              />
                              <Details
                                detailTitle={"Final Ops Rating"}
                                Details={
                                  <StarRatings
                                    starEmptyColor={"#BFBFBF"}
                                    starRatedColor={"var(--primary)"}
                                    rating={data?.itsmTicketFeedback?.rating}
                                    starDimension="30px"
                                    starSpacing="5px"
                                  />
                                }
                              />

                              <Details
                                detailTitle={"ITSM Rating"}
                                Details={
                                  <StarRatings
                                    starEmptyColor={"#BFBFBF"}
                                    starRatedColor={"var(--primary)"}
                                    rating={
                                      data?.itsmTicketFeedback
                                        .experienceUsingTool
                                    }
                                    starDimension="30px"
                                    starSpacing="5px"
                                  />
                                }
                              />
                            </div>
                          </>
                        )}
                        {data?.employeeTicket?.status !== "Closed" &&
                          data?.employeeTicket?.status !== "Resolved" &&
                          data?.employeeTicket?.status !== "Rejected" &&
                          data?.employeeTicket?.status !== "Cancelled" &&
                          data?.employeeTicket?.status !==
                            "New Implementation" &&
                          data?.approvalStatus === true &&
                          data?.employeeTicket?.ticketCurrentyAssignedToId ===
                            LoggedInUser?.id && (
                            <div
                              className="createFootDetails"
                              style={{ background: "white !important" }}
                            >
                              <div
                                className="button secondaryButton"
                                onClick={() =>
                                  togglePopup(
                                    "Update Status",
                                    data?.employeeTicket?.status
                                  )
                                }
                                style={{
                                  background: "#5BC4BF",
                                  color: "white",
                                }}
                              >
                                <div className="flexyD">
                                  <span>Update Status</span>{" "}
                                  <img src={UpdateA} alt="Update Status" />
                                </div>
                              </div>
                              <div
                                className="button secondaryButton"
                                onClick={() =>
                                  togglePopup(
                                    "Update Assignee",
                                    data?.employeeTicket?.typeId
                                  )
                                }
                                style={{
                                  background: "#5BC4BF",
                                  color: "white",
                                }}
                              >
                                <div className="flexyD">
                                  <span> Update Assignee</span>{" "}
                                  <img src={UpdateAs} alt="Update Assignee" />
                                </div>
                              </div>
                            </div>
                          )}
                        {data?.employeeTicket?.status === "Resolved" &&
                          data?.employee?.id === LoggedInUser?.id && (
                            <div
                              className="createFootDetails"
                              style={{ background: "white !important" }}
                            >
                              <div
                                className="button secondaryButton"
                                onClick={() => PostReopen(selectedId)}
                                style={{
                                  background: "rgb(71 143 109 / 97%)",
                                  color: "white",
                                }}
                              >
                                Reopen
                              </div>
                              <div
                                className="button secondaryButton"
                                onClick={() => togglePopup("Resolved")}
                                style={{
                                  background: "#746AB0",
                                  color: "white",
                                }}
                              >
                                Close
                              </div>
                            </div>
                          )}
                        {isOpen && (
                          <Popup
                            popupHeading={
                              typeAction === "Resolved"
                                ? "Ticket Feedback"
                                : typeAction
                            }
                            content={
                              typeAction === "Resolved" ? (
                                <FeedBack
                                  data={PostFeedback}
                                  type={typeAction}
                                  handleClose={togglePopup}
                                  id={selectedId}
                                />
                              ) : typeAction === "Update Assignee" ? (
                                <AddAssignee
                                  type={typeAction}
                                  id={selectedId}
                                  typeId={colorAction}
                                  data={PostAddAssignee}
                                />
                              ) : typeAction === "Problem" ? (
                                <ProblemForm
                                  loading={loading}
                                  setLoading={setLoading}
                                  data={getDetailsITHelpDesk}
                                  type={typeAction}
                                  id={0}
                                  color={colorAction}
                                  handleClose={togglePopup}
                                />
                              ) : typeAction === "Assign To Vendor" ? (
                                <AssignVendor
                                  loading={loading}
                                  setLoading={setLoading}
                                  data={getDetailsITHelpDesk}
                                  type={typeAction}
                                  id={selectedId}
                                  eid={extra}
                                  handleClose={togglePopup}
                                />
                              ) : (
                                <UpdateStatus
                                  data={PostAddAssignee}
                                  prestatus={colorAction}
                                  id={selectedId}
                                />
                              )
                            }
                            handleClose={togglePopup}
                          />
                        )}
                        {data?.actionlist?.length > 0 ? (
                          <div
                            className="createFootDetails"
                            style={{ background: "white !important" }}
                          >
                            {data?.actionlist?.map((item) => (
                              <div>
                                <div
                                  className="button secondaryButton"
                                  onClick={() => {
                                    item.type !== "Approve" &&
                                    item.type !== "Release" &&
                                    item.type !== "Cancel"
                                      ? togglePopup(item?.type, item?.color)
                                      : PostApRejHol(item?.type, item?.id);
                                  }}
                                  style={{
                                    background: item?.color,
                                    color: "white",
                                  }}
                                >
                                  {item?.type}
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {data?.actionlist?.length > 0 &&
                          data?.actionlist?.map(
                            (item) =>
                              isOpen && (
                                <Popup
                                  popupHeading={item?.type}
                                  content={
                                    <>
                                      <Remark
                                        data={PostApRejHol}
                                        type={typeAction}
                                        id={item?.id}
                                        color={colorAction}
                                        handleClose={togglePopup}
                                      />
                                    </>
                                  }
                                  handleClose={togglePopup}
                                />
                              )
                          )}
                      </div>
                    </>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimelineHrDesk
                        id={selectedId}
                        url={"ItHelpdesks"}
                        // module={"ItHelpdesks"}
                        // requestNumber={data?.ticketTypes?.id}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications id={selectedId} module={"ITSM"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsItDeskMyTicket };
