import { Form, Formik } from "formik";
import React from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import * as Yup from "yup";
import dateFormat from "dateformat";
import { useState } from "react";
import { postRequestForm } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Details } from "../../../../HRMS/Components/DetailsComp/Details";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { ReferBack } from "./ReferBack";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useEffect } from "react";

const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const OwnerApproval = ({ data, getDetails }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(0);
  const dispatch = useDispatch();

  const togglePopup = (id) => {
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "ITsmChangeRequest/Approvebyowner",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Owner Approval`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values, val) => {
    const formData = new FormData();
    formData.append("DescriptionByOwner", values?.DescriptionByOwner);
    formData.append("priority", values?.priority);
    formData.append(
      "RiskAssociatedWithProposeChange",
      values?.RiskAssociatedWithProposeChange
    );
    formData.append(
      "ImpactAssociatedWithProposeChange",
      values?.ImpactAssociatedWithProposeChange
    );
    formData.append("OverallImpact", values?.OverallImpact);
    formData.append("BusinessImpact", values?.BusinessImpact);
    formData.append("TechnologyImpact", values?.TechnologyImpact);
    formData.append("CostImpact", values?.CostImpact);
    formData.append("DescriptionOfImpact", values?.DescriptionOfImpact);
    formData.append(
      "ResourcerForChangeImplementation",
      values?.ResourcerForChangeImplementation
    );
    formData.append("Testplandescription", values?.Testplandescription);
    formData.append("RollBackPlanDescription", values?.RollBackPlanDescription);
    if (typeof values?.Testplan == "object")
      formData.append("Testplan", values?.Testplan);
    formData.append("RollBackplan", values?.RollBackplan);
    formData.append("EstimatedCost", values?.EstimatedCost);
    formData.append(
      "EstimatedTimetoComplete",
      dateFormat(values?.EstimatedTimetoComplete, "yyyy-mm-dd")
    );
    formData.append(
      "DowntimeHours",
      dateFormat(values?.DowntimeHours, "h:MM TT")
    );
    formData.append("RemarksByCo", values?.RemarksByCo);
    formData.append("ChangeType", data?.itsmChangeRequest?.changeType);
    formData.append(
      "DescriptionOfchange",
      data?.itsmChangeRequest?.descriptionOfchange
    );
    formData.append("Reason", data?.itsmChangeRequest?.reason);
    if (val !== "NO") {
      formData.append("isApprovedByCo", true);
    }
    formData.append("id", data?.itsmChangeRequest?.id);
    postFormData(formData);
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];
  const validationSchema = Yup.object({
    DescriptionByOwner: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    RiskAssociatedWithProposeChange: Yup.string().required(
      " Please select Risk Associated"
    ),
    ImpactAssociatedWithProposeChange: Yup.string().required(
      " Please select Impact Associated"
    ),
    OverallImpact: Yup.string().required(" Please select Overall Impact "),
    BusinessImpact: Yup.string().required(" Please select Business Impact "),
    TechnologyImpact: Yup.string().required(
      " Please select  Technology Impact "
    ),
    CostImpact: Yup.string().required(" Please select  Cost Impact "),
    DescriptionOfImpact: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    ResourcerForChangeImplementation: Yup.string().required(
      " Please Enter  Resource Required"
    ),
    Testplandescription: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    Testplan: Yup.mixed()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          typeof value !== "object" ||
          (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .required("Please Attach File"),
    RollBackplan: Yup.mixed()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          typeof value !== "object" ||
          (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .required("Please Attach File"),
    RollBackPlanDescription: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    EstimatedCost: Yup.number()
      .min(1)
      .max(9999999999999)
      .required("Please Enter Cost"),
    EstimatedTimetoComplete: Yup.string().required(" Please Select Date"),
    DowntimeHours: Yup.string().required(" Please Select Time"),
    RemarksByCo: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
  });
  const initial = {
    DescriptionByOwner:
      data?.itsmChangeRequest?.descriptionByOwner !== null
        ? data?.itsmChangeRequest?.descriptionByOwner
        : "",
    priority: data?.itsmChangeRequest?.priority,
    RiskAssociatedWithProposeChange:
      data?.itsmChangeRequest?.riskAssociatedWithProposeChange !== null
        ? data?.itsmChangeRequest?.riskAssociatedWithProposeChange
        : "",
    ImpactAssociatedWithProposeChange:
      data?.itsmChangeRequest?.impactAssociatedWithProposeChange !== null
        ? data?.itsmChangeRequest?.impactAssociatedWithProposeChange
        : "",
    OverallImpact:
      data?.itsmChangeRequest?.overallImpact !== null
        ? data?.itsmChangeRequest?.overallImpact
        : "",
    BusinessImpact:
      data?.itsmChangeRequest?.businessImpact !== null
        ? data?.itsmChangeRequest?.businessImpact
        : "",
    TechnologyImpact:
      data?.itsmChangeRequest?.technologyImpact !== null
        ? data?.itsmChangeRequest?.technologyImpact
        : "",
    CostImpact:
      data?.itsmChangeRequest?.costImpact !== null
        ? data?.itsmChangeRequest?.costImpact
        : "",
    DescriptionOfImpact:
      data?.itsmChangeRequest?.descriptionOfImpact !== null
        ? data?.itsmChangeRequest?.descriptionOfImpact
        : "",
    ResourcerForChangeImplementation:
      data?.itsmChangeRequest?.resourcerForChangeImplementation !== null
        ? data?.itsmChangeRequest?.resourcerForChangeImplementation
        : "",
    Testplandescription:
      data?.itsmChangeRequest?.testplandescription !== null
        ? data?.itsmChangeRequest?.testplandescription
        : "",
    Testplan:
      data?.itsmChangeRequest?.testplanDocument !== null
        ? `ChangeRequest/${data?.itsmChangeRequest?.testplanDocument}`
        : "",
    RollBackPlanDescription:
      data?.itsmChangeRequest?.rollBackPlanDescription !== null
        ? data?.itsmChangeRequest?.rollBackPlanDescription
        : "",
    RollBackplan:
      data?.itsmChangeRequest?.rollBackplanDocument !== null
        ? `ChangeRequest/${data?.itsmChangeRequest?.rollBackplanDocument}`
        : "",
    EstimatedCost:
      data?.itsmChangeRequest?.estimatedCost !== null
        ? data?.itsmChangeRequest?.estimatedCost
        : "",
    EstimatedTimetoComplete:
      data?.itsmChangeRequest?.estimatedTimetoComplete !== null
        ? new Date(data?.itsmChangeRequest?.estimatedTimetoComplete)
        : "",
    DowntimeHours:
      data?.itsmChangeRequest?.downtimeHours !== null
        ? new Date(`12/12/12 ${data?.itsmChangeRequest?.downtimeHours}`)
        : "",
    RemarksByCo:
      data?.itsmChangeRequest?.remarksByCo !== null
        ? data?.itsmChangeRequest?.remarksByCo
        : "",
  };
  if (loading) return <Loader />;
  return (
    <div style={{ backgroundColor: "var(--form-background)", padding: "12px" }}>
      <div className="headingForm">Change Owner Details</div>
      <div className="tabularMasterDetailsPageTabBodyContainer tabsIn">
        <Details
          detailTitle={"Change Owner Name"}
          Details={
            data?.itsmChangeRequest?.ticketCategory?.changeOwnerEmployee
              ?.nameWithPersonNumber
          }
        />
        {data?.itsmChangeRequest?.isApprovedByCo && (
          <Details
            detailTitle={"Change Owner Approving Date"}
            Details={format(
              new Date(data?.itsmChangeRequest?.changeOwnerApprovingDate),
              "dd/MM/yyyy hh:mm "
            )}
          />
        )}
        {data?.itsmChangeRequest?.implementerStatus == null &&
        (data?.itsmChangeRequest?.directorDecision === "Approved" ||
          data?.itsmChangeRequest?.directorDecision ===
            "Approved with Conditions") &&
        data?.itsmChangeRequest?.status !== "Cancelled" &&
        data?.itsmChangeRequest?.status !== "Rejected" ? (
          <>
            <Details
              detailTitle={"Implementation assign to"}
              Details={data?.teamName}
            />
          </>
        ) : (
          (data?.itsmChangeRequest?.directorDecision == "Approved" ||
            data?.itsmChangeRequest?.directorDecision ===
              "Approved with Conditions") && (
            <>
              <Details
                detailTitle={"Implementation assign to"}
                Details={data?.teamName}
              />
              <Details
                detailTitle={"Implementation Timeline"}
                Details={data?.itsmChangeRequest?.implementationTimeline}
              />
            </>
          )
        )}
      </div>

      <Formik
        enableReinitialize
        initialValues={initial}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, resetForm }) => {
          return (
            <Form>
              <div className="headingForm">Business Analysis</div>
              <div className="formgrid">
                <div>
                  <LabelCustom
                    labelName={"Business Impact Type"}
                    mandatory={true}
                  />
                  <SelectForm
                    isDisabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="BusinessImpact"
                    options={option}
                    placeholder="Please Select Impact"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Business Impact Description"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="DescriptionOfImpact"
                    placeholder="Please Enter Description"
                    maxLength="500"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Overall Impact Type"}
                    mandatory={true}
                  />
                  <SelectForm
                    isDisabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="OverallImpact"
                    options={option}
                    values={initial.OverallImpact}
                    placeholder="Please Select Impact"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Overall Impact Description"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="ImpactAssociatedWithProposeChange"
                    maxLength="500"
                    placeholder="Please Enter Description"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Risk associated with propose change"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="RiskAssociatedWithProposeChange"
                    maxLength="500"
                    placeholder="Please Enter Description"
                  />
                </div>
              </div>
              <div className="headingForm">Schedule & Cost Analysis</div>
              <div className="formgrid">
                <div>
                  <LabelCustom
                    labelName={"Cost Impact Type"}
                    mandatory={true}
                  />
                  <SelectForm
                    isDisabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="CostImpact"
                    options={option}
                    placeholder="Please Select Impact"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Cost Impact Descripition  "}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="DescriptionByOwner"
                    maxLength="500"
                    placeholder="Please Enter Description"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Estimated Cost (In Rupees)"}
                    mandatory={true}
                  />
                  <InputCustomFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    min={1}
                    max={9999999999999}
                    name="EstimatedCost"
                    type="number"
                    step="0.01"
                    placeholder="Please Enter Cost"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Estimated Completion Date "}
                    mandatory={true}
                  />
                  <DatePickerFormiks
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="EstimatedTimetoComplete"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="headingForm">Implementation Analysis</div>
              <div className="formgrid">
                <div>
                  <LabelCustom
                    labelName={"Technology Impact Type"}
                    mandatory={true}
                  />
                  <SelectForm
                    isDisabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="TechnologyImpact"
                    options={option}
                    placeholder="Please Select Impact"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Technology Impact Description"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="RemarksByCo"
                    maxLength="500"
                    placeholder="Please Enter Description"
                  />
                </div>

                <div>
                  <LabelCustom
                    labelName={"Test Plan Document"}
                    mandatory={true}
                  />
                  <FileUploadFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="Testplan"
                  />
                  <div
                    style={{
                      color: "var(--primary)",
                      fontSize: "14px",
                      marginLeft: "13px",
                    }}
                  >
                    Supporting document must be in pdf, jpeg, png format and
                    within 2mb{" "}
                  </div>
                </div>
                <div>
                  <LabelCustom
                    labelName={"Test Plan Description"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    maxLength="500"
                    name="Testplandescription"
                    placeholder="Please Enter Description"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Roll-back Plan Document"}
                    mandatory={true}
                  />
                  <FileUploadFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    name="RollBackplan"
                  />
                  <div
                    style={{
                      color: "var(--primary)",
                      fontSize: "14px",
                      marginLeft: "13px",
                    }}
                  >
                    Supporting document must be in pdf,jpeg,png format
                  </div>
                </div>
                <div>
                  <LabelCustom
                    labelName={"Roll-back Plan Description"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="RollBackPlanDescription"
                    placeholder="Please enter Description"
                    maxLength="500"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Resource Required For Implementation"}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    style={{ height: "100px" }}
                    name="ResourcerForChangeImplementation"
                    placeholder="Please Enter Description"
                    maxLength="500"
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Downtime Required For Implementation"}
                    mandatory={true}
                  />
                  <TimeCustomFormik
                    disabled={
                      data?.itsmChangeRequest?.isApprovedByCo ? true : false
                    }
                    inputFormat="HH:mm"
                    ampm={false}
                    name="DowntimeHours"
                  />
                </div>
              </div>

              {data?.itsmChangeRequest?.isApprovedByCo === null && (
                <div className="createFootDetails" style={{ margin: "0" }}>
                  <div>
                    <button
                      className="button secondaryButton"
                      style={{ background: "var(--secondary)", color: "white" }}
                      type="button"
                      onClick={() => togglePopup(data?.itsmChangeRequest?.id)}
                    >
                      Refer Back
                    </button>
                  </div>
                  <div>
                    <button
                      className="button secondaryButton"
                      style={{ background: "var(--secondary)", color: "white" }}
                      type="button"
                      onClick={() => onSubmit(values, "NO")}
                    >
                      Save As Draft
                    </button>
                  </div>
                  <div>
                    <button
                      className="deletebtn"
                      type="reset"
                      onClick={() => resetForm({ values: "" })}
                    >
                      Reset
                    </button>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Approve
                    </button>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      {isOpen && (
        <Popup
          popupHeading={"Refer Back"}
          content={
            <>
              <ReferBack
                ChangeType={data?.itsmChangeRequest?.changeType}
                Reason={data?.itsmChangeRequest?.reason}
                Priority={data?.itsmChangeRequest?.priority}
                DescriptionOfchange={
                  data?.itsmChangeRequest?.descriptionOfchange
                }
                getDetails={getDetails}
                type={"Requester"}
                id={selId}
                handleClose={togglePopup}
              />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export default OwnerApproval;
