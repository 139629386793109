import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
const travellingOptions = [
  { label: "One Way Trip", value: "One Way Trip" },
  { label: "Round Trip", value: "Round Trip" },
  { label: "Multilocation Trip", value: "Multilocation Trip" },
];
const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const FlightVendorComp = ({
  data,
  formValues,
  travelCategoryOption,
  pushEmployee,
  popAnswer,
  employeeList,
  setFormValues,
}) => {
  useEffect(() => {
    document.title = `PeopleSol - Group Flight`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <div
        style={
          formValues?.TravelingType
            ? {
                borderBottom: "1px solid #E6E6E6",
                marginBottom: "20px",
                paddingBottom: " 20px",
              }
            : {}
        }
        className="formcustom threeColumn"
      >
        <div>
          <LabelCustom labelName={"Travel Type"} />
          <SelectForm name="TravelingType" options={travellingOptions} />
        </div>
        {formValues?.TravelingType && (
          <div>
            <LabelCustom labelName={"Travel Category"} mandatory={true} />
            <SelectForm name="CategoryID" options={travelCategoryOption} />
          </div>
        )}
        {formValues?.TravelingType &&
          (formValues?.TravelingType === "Round Trip" ||
            formValues?.TravelingType === "Multilocation Trip") && (
            <div>
              <LabelCustom
                labelName={"Budget (In Thousands)"}
                mandatory={true}
              />
              <InputCustomFormik
                type="number"
                name="Budget"
                placeholder={"Enter Budget"}
              />
            </div>
          )}
        {formValues?.TravelingType &&
          formValues?.TravelingType === "Round Trip" && (
            <div>
              <LabelCustom labelName={"Booking Note"} />
              <TextAreaFormik
                name="Bookingnote"
                placeholder={" Enter Booking Note"}
                maxLength={500}
              />
            </div>
          )}
      </div>
      {formValues?.TravelingType && (
        <div className="formcustom threeColumn">
          {formValues?.TravelingType !== "Multilocation Trip" && (
            <>
              <div>
                <LabelCustom labelName={"Travelling Date"} mandatory={true} />
                <DatePickerFormiks name={"FromDate"} minDate={new Date()} />
              </div>
              <div>
                <LabelCustom labelName={"From Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="PickupLocation"
                  type={"text"}
                  placeholder={" Enter from Location"}
                />
              </div>

              <div>
                <LabelCustom labelName={"To Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="DropLocation"
                  type={"text"}
                  placeholder={" Enter to Location"}
                />
              </div>
              {formValues?.TravelingType === "Round Trip" && (
                <>
                  <div>
                    <LabelCustom labelName={"Return Date"} mandatory={true} />
                    <DatePickerFormiks
                      name={"ToDate"}
                      minDate={new Date(formValues?.FromDate)}
                    />
                  </div>
                  <div>
                    <LabelCustom labelName={"From Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="PickupLocationR"
                      type={"text"}
                      placeholder={" Enter From Location"}
                    />
                  </div>

                  <div>
                    <LabelCustom labelName={"To Location"} mandatory={true} />
                    <InputCustomFormik
                      maxLength={50}
                      name="DropLocationR"
                      type={"text"}
                      placeholder={" Enter To Location"}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {formValues?.TravelingType === "One Way Trip" && (
            <div>
              <LabelCustom
                labelName={"Budget (In Thousands)"}
                mandatory={true}
              />
              <InputCustomFormik
                type="number"
                name="Budget"
                placeholder={"Enter Budget"}
              />
            </div>
          )}
          {formValues?.TravelingType === "One Way Trip" && (
            <div>
              <LabelCustom labelName={"Booking Note"} />
              <TextAreaFormik
                name="Bookingnote"
                placeholder={" Enter Booking Note"}
                maxLength={500}
              />
            </div>
          )}
        </div>
      )}
      {formValues?.TravelingType &&
        formValues?.TravelingType !== "Multilocation Trip" && (
          <div>
            <label className="repeatedHeading">
              Please specify the employee's details
            </label>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <FieldArray name="groupItemsNotMulti">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { groupItemsNotMulti } = values;
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No"} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Vendor Name"}
                              mandatory={true}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Phone No."}
                              mandatory={true}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Email"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Age"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Gender"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {groupItemsNotMulti?.map((groupItemss, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}

                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={320}
                              type="text"
                              name={`groupItemsNotMulti[${index}].Name`}
                              placeholder={"Enter the Name"}
                            />
                          </StyledTableCell>
                          {/* 3rd column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={10}
                              type="number"
                              step="0.01"
                              name={`groupItemsNotMulti[${index}].PhoneNumber`}
                              placeholder={" Enter Phone No."}
                            />
                          </StyledTableCell>
                          {/* 4th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={320}
                              type="text"
                              name={`groupItemsNotMulti[${index}].EmailId`}
                              placeholder={"Enter the Email"}
                            />
                          </StyledTableCell>
                          {/* 5th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={3}
                              type="number"
                              step="0.01"
                              name={`groupItemsNotMulti[${index}].Age`}
                              placeholder={"Enter the Age"}
                            />
                          </StyledTableCell>
                          {/* 6th column */}
                          <StyledTableCell>
                            <SelectForm
                              name={`groupItemsNotMulti[${index}].Gender`}
                              options={genderOptions}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                groupItemsNotMulti.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  Name: "",
                                  PhoneNumber: "",
                                  EmailId: "",
                                  Age: "",
                                  Gender: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  );
                }}
              </FieldArray>
            </div>
          </div>
        )}
      {formValues?.TravelingType &&
        formValues?.TravelingType === "Multilocation Trip" && (
          <div>
            <FieldArray name="groupItems">
              {(fieldArrayProps) => {
                const { remove, insert, form } = fieldArrayProps;
                const { values } = form;
                const { groupItems } = values;
                return (
                  <Table style={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Journey"} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {groupItems?.map((Questions, index) => (
                      <TableBody key={index}>
                        <StyledTableCell sx={{ width: "96px" }} align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell sx={{ maxWidth: "920px" }}>
                          <div>
                            <div
                              style={{ borderBottom: "1px solid #E6E6E6" }}
                              className="formcustom fourColumn"
                            >
                              <div>
                                <LabelCustom
                                  labelName={"Travelling Date"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`groupItems[${index}].FromDate`}
                                  minDate={new Date()}
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"Form Location"}
                                  mandatory={true}
                                />

                                <InputCustomFormik
                                  maxLength={50}
                                  name={`groupItems[${index}].PickupLocation`}
                                  type="text"
                                  placeholder={" Enter From Location"}
                                />
                              </div>
                              <div>
                                <LabelCustom
                                  labelName={"To Location"}
                                  mandatory={true}
                                />

                                <InputCustomFormik
                                  maxLength={50}
                                  name={`groupItems[${index}].DropLocation`}
                                  type={"text"}
                                  placeholder={" Enter To Location"}
                                />
                              </div>
                              <div>
                                <LabelCustom labelName={"Booking Note"} />

                                <TextAreaFormik
                                  name={`groupItems[${index}].Bookingnote`}
                                  placeholder={" Enter Comments"}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                            <div
                              style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                              {Questions?.EmployeeDetails?.map(
                                (item, indexW) => (
                                  <div
                                    style={{
                                      overflow: "auto",
                                      width: "100%",
                                      height: "197px",
                                    }}
                                  >
                                    <div
                                      className="formcustom sixColumn"
                                      style={{
                                        minWidth: "1152px",
                                        padding: "18px 0px",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "67px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <LabelCustom labelName={"S. No."} />

                                        <div style={{ padding: "21px 8px" }}>
                                          {indexW + 1}
                                        </div>
                                      </div>
                                      <div style={{ width: "264px" }}>
                                        <LabelCustom
                                          labelName={"Vendor Name"}
                                          mandatory={true}
                                        />
                                        <InputCustomFormik
                                          maxLength={320}
                                          type="text"
                                          name={`groupItems[${index}].EmployeeDetails[${indexW}].Name`}
                                          placeholder={"Enter the Name"}
                                        />
                                      </div>
                                      <div style={{ width: "264px" }}>
                                        <LabelCustom
                                          labelName={"Phone No."}
                                          mandatory={true}
                                        />

                                        <InputCustomFormik
                                          maxLength={10}
                                          type="number"
                                          step="0.01"
                                          name={`groupItems[${index}].EmployeeDetails[${indexW}].PhoneNumber`}
                                          placeholder={" Enter Phone No."}
                                        />
                                      </div>
                                      <div style={{ width: "264px" }}>
                                        <LabelCustom
                                          labelName={"Email"}
                                          mandatory={true}
                                        />
                                        <InputCustomFormik
                                          maxLength={320}
                                          type="text"
                                          name={`groupItems[${index}].EmployeeDetails[${indexW}].EmailId`}
                                          placeholder={"Enter the Email"}
                                        />
                                      </div>
                                      <div style={{ width: "264px" }}>
                                        <LabelCustom
                                          labelName={"Age"}
                                          mandatory={true}
                                        />
                                        <InputCustomFormik
                                          maxLength={3}
                                          type="number"
                                          step="0.01"
                                          name={`groupItems[${index}].EmployeeDetails[${indexW}].Age`}
                                          placeholder={"Enter the Age"}
                                        />
                                      </div>
                                      <div style={{ width: "264px" }}>
                                        <LabelCustom
                                          labelName={"Gender"}
                                          mandatory={true}
                                        />
                                        <SelectForm
                                          name={`groupItems[${index}].EmployeeDetails[${indexW}].Gender`}
                                          options={genderOptions}
                                        />
                                      </div>
                                    </div>
                                    {indexW ===
                                    Questions?.EmployeeDetails?.length - 1 ? (
                                      <div
                                        className="surveyAddBtnContainer"
                                        style={{
                                          position: "sticky",
                                          left: "86%",
                                          /* right: 30px; */
                                          bottom: "21px",
                                          width: "131px",
                                          // position: "absolute",
                                          // right: "12px",
                                          // bottom: "21px",
                                        }}
                                        onClick={() => pushEmployee(index)}
                                      >
                                        <button
                                          type="button"
                                          className="add-survey-btn"
                                        >
                                          +
                                        </button>{" "}
                                        Add Vendor
                                      </div>
                                    ) : (
                                      <div
                                        className="surveyAddBtnContainer"
                                        style={{
                                          position: "sticky",
                                          left: "83.5%",
                                          bottom: "21px",
                                          width: "140px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "red",
                                        }}
                                        onClick={() => popAnswer(index, indexW)}
                                      >
                                        <img
                                          // onClick={() => {
                                          //   popAnswer(answer, answerIndex, index);
                                          // }}
                                          style={{
                                            marginRight: "5px",
                                            cursor: "pointer",
                                          }}
                                          src={SurveyAnswersDeleteIcon}
                                          alt="SurveyAnswersDeleteIcon"
                                        />
                                        Remove Vendor
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "100px", textAlign: "center" }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              groupItems.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() => {
                              insert(index + 1, {
                                TravelingType: "",
                                FromDate: null,
                                PickupLocation: "",
                                DropLocation: "",
                                Bookingnote: "",
                                EmployeeDetails: [
                                  {
                                    Name: null,
                                    Age: null,
                                    Gender: "",
                                    PhoneNumber: "",
                                    EmailId: "",
                                  },
                                ],
                              });
                            }}
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                );
              }}
            </FieldArray>
          </div>
        )}
    </>
  );
};
