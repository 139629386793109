import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import ClockIcon from "../../Assets/ClockIcon.svg";
import CalendarIcon from "../../Assets/CalendarIcon.svg";
import { Tabs, Tab } from "@mui/material";
import { HomeDashboard, HomeEngage } from "./Components";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import PremiumContentPageSocial from "./PremiumContentPageSocial";
 
const Home = () => {
  const [tabIndex, setTabIndex] = useState(0);
 
  const navigate = useNavigate(); // Initialize navigate from react-router-dom
  const location = useLocation();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState([]);
  const [isDashboardActive, setIsDashboardActive] = useState(false);
  const [isSocialActive, setIsSocialActive] = useState(false);
 
  useEffect(() => {
    if (subMenuList) {
      subMenuList.forEach((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "home"
        ) {
          setSubmoduleMenu(menu);
          setIsDashboardActive(menu?.[0]?.[0]?.isSubMenuActive);
          setIsSocialActive(menu?.[1]?.[0]?.isSubMenuActive);
        }
      });
    }
  }, [location.pathname, subMenuList]);
 
 
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("dashboard")) {
      document.title = `PeopleSol - Home`;
      return () => {
        document.title = "PeopleSol";
      };
    }
  });
 
  const [currentTime, setCurrentTime] = useState(format(new Date(), "p"));
  useEffect(() => {
    const timerId = setInterval(() => setCurrentTime(format(new Date(), "p")));
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
 
  // Extracting values from submoduleMenu
  // let isDashboardActive = submoduleMenu?.[0]?.[0]?.isSubMenuActive;
  // let isSocialActive = submoduleMenu?.[1]?.[0]?.isSubMenuActive;
  const dashboardFeatureDisplayName =
    submoduleMenu?.[0]?.[0]?.subMenuDisplayName;
  const socialFeatureDisplayName = submoduleMenu?.[1]?.[0]?.subMenuDisplayName;
 
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
 
    // Update the route path based on the selected tab
    if (newTabIndex === 0) {
      navigate("/home/dashboard");
    } else if (newTabIndex === 1) {
      navigate("/home/social/social");
    }
  };
 
 
  useEffect(() => {
    if (location.pathname.includes("social")) {
      setIsSocialActive(true);
      setIsDashboardActive(false);
      setTabIndex(1);
    } else {
      setIsSocialActive(false);
      setIsDashboardActive(true);
      setTabIndex(0);
    }
  }, [location.pathname]);
  return (
    <div className="homeContainer">
      <div className="dateTimeHolder">
        <img src={CalendarIcon} alt="CalendarIcon" />
        <label>{format(new Date(), "dd MMM yyyy, EEEE")}</label>
        <img src={ClockIcon} id="clock" alt="ClockIcon" />
        <label>{currentTime}</label>
      </div>
 
      {dashboardFeatureDisplayName === "Dashboard" &&
      socialFeatureDisplayName === "Social" ? (
        <>
          <div className="tabContainer">
            {!isSocialActive && (
              <svg
                style={{ position: "absolute", top: "-8px", left: "83%" }}
                width="43"
                height="43"
                viewBox="0 0 43 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_581_501)">
                  <rect
                    x="11.5605"
                    y="9.53223"
                    width="20"
                    height="20"
                    rx="2.4"
                    fill="white"
                  />
                </g>
                <path
                  d="M16.7109 24.9493L16.6416 24.6067H26.5337L26.4637 24.9493H16.7109Z"
                  fill="#FFC329"
                  stroke="#FFC329"
                  strokeWidth="0.82271"
                />
                <path
                  d="M27.8367 18.0709L26.666 22.4896H16.5334L15.3105 18.0926L17.8453 19.475C18.3214 19.7346 18.9176 19.5706 19.1939 19.1039L21.5913 15.0542L23.8817 19.0867L24.4711 18.7519L23.8817 19.0867C24.1522 19.5629 24.7537 19.7356 25.2357 19.4754L27.8367 18.0709Z"
                  fill="#FFC329"
                  stroke="#FFC329"
                  strokeWidth="1.35571"
                />
                <defs>
                  <filter
                    id="filter0_d_581_501"
                    x="0.591077"
                    y="0.75665"
                    width="41.9389"
                    height="41.9389"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2.19389" />
                    <feGaussianBlur stdDeviation="5.48474" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_581_501"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_581_501"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            )}
 
            <Tabs
              value={tabIndex}
              onChange={handleTabChange} // Use the handleTabChange function
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    My Dashboard
                  </span>
                }
              />
 
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    <div style={{ position: "relative" }}>
                      <div>Social </div>
                    </div>
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
 
          {isDashboardActive && tabIndex === 0 && <HomeDashboard />}
          {!isSocialActive && tabIndex === 1 && (
            <>
              <PremiumContentPageSocial />
            </>
          )}
          {isSocialActive && tabIndex === 1 && <HomeEngage />}
        </>
      ) : dashboardFeatureDisplayName ? (
        // Only dashboard tab is active
        <> {isDashboardActive && <HomeDashboard />}</>
      ) : socialFeatureDisplayName ? (
        // Only social tab is active
        <>
          <div className="tabContainer">
            <Tabs
              value={1} // Lock the tab to the social
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              {isSocialActive && (
                <Tab
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Social{" "}
                    </span>
                  }
                />
              )}
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {isSocialActive && <HomeEngage />}
        </>
      ) : (
        // Neither dashboard nor social tab is active
        <></>
      )}
    </div>
  );
};
 
export { Home };