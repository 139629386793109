import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateHotelBookingComp } from "./FormComp/CreateHotelBookingComp";
import { useSelector } from "react-redux";
import { calculateAge } from "../../../Utilities/calculateAge";
const CreateHotelBooking = (props) => {
  const { state } = useLocation();
  console.log("object state", state);
  const [detailsform, setDetailsform] = useState(false);
  const data = state?.data ?? state?.id;
  const isBoth = state?.isBoth ?? null;
  const selectedTravelRequestID =
    state?.selectedTravelRequestID &&
    (state?.selectedTravelRequestID[0]?.value ?? null);
  console.log("object selectedTravelRequestID", selectedTravelRequestID);
  // const isBothData = state?.data ?? null;
  const [travelValues, setTravelValues] = useState(state?.values ?? null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [employeeList, setEmployeeList] = useState([]);
  const [travelDetailsform, setTravelDetailsform] = useState(false);
  console.log("object travelDetailsform", travelDetailsform);
  useEffect(() => {
    document.title = `PeopleSol - Create Hotel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // useEffect(() => {
  //   if (travelValues) {
  //     setInitialValues(() => {
  //       const obj = { ...formValues, Type: travelValues?.Type };
  //       return obj;
  //     });
  //   }
  // }, [travelValues]);

  const getFormDetails = async (id) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );
      if (data) {
        setDetailsform(result.data.data);
      }
      if (selectedTravelRequestID) {
        setTravelDetailsform(result.data.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getEmployeeData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) getFormDetails(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedTravelRequestID) getFormDetails(selectedTravelRequestID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTravelRequestID]);
  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const TravelingDetailsfunc = () => {
    return detailsform
      ? detailsform?.hoteldetail?.map((item) => ({
          id: item?.id ?? null,
          HotelCategory: item?.hotelCategory?.toString() ?? null,
          CheckInTime: detailsform?.hoteldetail[0]?.checkInTime
            ? new Date(detailsform?.hoteldetail[0]?.checkInTime)
            : null,
          CheckOutTime: detailsform?.hoteldetail[0]?.checkOutTime
            ? new Date(detailsform?.hoteldetail[0]?.checkOutTime)
            : null,
          Location: detailsform?.hoteldetail[0]?.location ?? "",
          PreferHotel: detailsform?.hoteldetail[0]?.preferHotel ?? "",
          Adults: detailsform?.hotelemployee[0]?.adults ?? null,
          Children: detailsform?.hotelemployee[0]?.children ?? "",
          Budget: item?.Budget ?? "",
          Bookingnote: detailsform?.hoteldetail[0]?.bookingNote ?? "",
        }))
      : [
          {
            HotelCategory: null,
            CheckInTime: null,
            CheckOutTime: null,
            Location: "",
            PreferHotel: "",
            Adults: "",
            Children: "",
            Budget: "",
            Bookingnote: "",
          },
        ];
  };
  const pushEmployee = (index) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails =
        formValues?.Type === "Vendor" || "Guest"
          ? [
              ...newGroupItems[index].EmployeeDetails,
              {
                Name: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ]
          : [
              ...newGroupItems[index].EmployeeDetails,
              {
                EmployeeId: null,
                Age: null,
                Gender: "",
                PhoneNumber: "",
                EmailId: "",
              },
            ];
      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const popAnswer = (index, indexW) => {
    setInitialValues((prev) => {
      const newGroupItems = [...formValues.groupItems];
      const newEmployeeDetails = newGroupItems[index].EmployeeDetails?.filter(
        (item, ind) => ind !== indexW
      );

      newGroupItems[index] = {
        ...newGroupItems[index],
        EmployeeDetails: newEmployeeDetails,
      };
      return {
        ...formValues,
        groupItems: newGroupItems,
      };
    });
  };
  const groupItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.hotelemployee
          ?.filter(
            (item, index) =>
              item?.hotelDetailId === detailsform?.hoteldetail[0]?.id
          )
          ?.map((items, indexs) => {
            return returnArray.push({
              id: items?.id ?? null,
              EmployeeId: items?.employeeId ?? null,
              Adults: items?.adults ?? null,
              Children: items?.children ?? null,
              PhoneNumber: items?.phoneNumber ?? "",
              EmailId: items?.emailId ?? "",
            });
          })
      : (returnArray = [
          {
            EmployeeId: null,
            Adults: null,
            Children: null,
            PhoneNumber: "",
            EmailId: "",
          },
        ]);
    return returnArray;
  };

  const groupItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.hoteldetail?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,
            HotelCategory: item?.hotelCategory?.toString() ?? null,
            CheckInTime: item?.checkInTime ? new Date(item?.checkInTime) : null,
            CheckOutTime: item?.checkOutTime
              ? new Date(item?.checkOutTime)
              : null,
            Location: item?.location ?? "",
            PreferHotel: item?.preferHotel ?? "",
            Budget: item?.Budget ?? "",
            Bookingnote: item?.bookingNote ?? "",

            EmployeeDetails: detailsform?.hotelemployee
              ?.filter((items) => items?.hotelDetailId === item?.id)
              ?.map((it) => ({
                id: it?.id ?? null,
                EmployeeId: it?.employeeId ?? null,
                Adults: it?.adults ?? null,
                Children: it?.children ?? null,
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              })),
          });
        })
      : (returnArray = [
          {
            HotelCategory: null,
            CheckInTime: null,
            CheckOutTime: null,
            Location: "",
            PreferHotel: "",
            Bookingnote: "",
            Budget: "",

            EmployeeDetails: [
              {
                EmployeeId: null,
                Adults: null,
                Children: null,
                PhoneNumber: "",
                EmailId: "",
              },
            ],
          },
        ]);
    return returnArray;
  };
  const vendorItemsDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.hotelemployee
          ?.filter(
            (item, index) =>
              item?.hotelDetailId === detailsform?.hoteldetail[0]?.id
          )
          ?.map((items, indexs) => {
            return returnArray.push({
              id: items?.id ?? null,
              Name: items?.name ?? "",
              Adults: items?.adults ?? null,
              Children: items?.children ?? null,
              PhoneNumber: items?.phoneNumber ?? "",
              EmailId: items?.emailId ?? "",
            });
          })
      : (returnArray = [
          {
            Name: "",
            Adults: null,
            Children: null,
            PhoneNumber: "",
            EmailId: "",
          },
        ]);
    return returnArray;
  };
  const vendorItemsMultiDetailsfunc = () => {
    let returnArray = [];
    data
      ? detailsform?.hoteldetail?.map((item, index) => {
          return returnArray.push({
            id: item?.id ?? null,

            HotelCategory: item?.hotelCategory?.toString() ?? null,
            CheckInTime: item?.checkInTime ? new Date(item?.checkInTime) : null,
            CheckOutTime: item?.checkOutTime
              ? new Date(item?.checkOutTime)
              : null,
            Location: item?.location ?? "",
            PreferHotel: item?.preferHotel ?? "",
            Bookingnote: item?.bookingNote ?? "",
            Budget: item?.Budget ?? "",
            EmployeeDetails: detailsform?.hotelemployee
              ?.filter((items) => items?.hotelDetailId === item?.id)
              ?.map((it) => ({
                id: it?.id ?? null,

                Name: it?.name ?? "",
                Adults: it?.adults ?? null,
                Children: it?.children ?? null,
                PhoneNumber: it?.phoneNumber ?? "",
                EmailId: it?.emailId ?? "",
              })),
          });
        })
      : (returnArray = [
          {
            HotelCategory: null,
            CheckInTime: null,
            CheckOutTime: null,
            Location: "",
            PreferHotel: "",
            Bookingnote: "",
            Budget: "",
            EmployeeDetails: [
              {
                Name: "",
                Adults: null,
                Children: null,
                PhoneNumber: "",
                EmailId: "",
              },
            ],
          },
        ]);

    return returnArray;
  };
  // const travellingOptions = [
  //   { label: "One Way Trip", value: "One Way Trip" },
  //   { label: "Round Trip", value: "Round Trip" },
  //   { label: "Multilocation Trip", value: "Multilocation Trip" },
  // ];
  const [initialValues, setInitialValues] = useState({
    Type: selectedTravelRequestID
      ? travelDetailsform
        ? travelDetailsform?._request?.type
        : travelValues?.Type ?? "Self"
      : travelValues?.Type ?? "Self",
    TravellingType:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?.travellingdetails[0]?.travelingType
        : travelValues?.TravelingType === "One Way Trip" ||
          travelValues?.TravelingType === "Round Trip"
        ? "One Way Trip"
        : "Multilocation Trip",
    HotelCategory: null,
    CheckInTime: null,
    CheckOutTime: null,
    Location: "",
    PreferHotel: "",
    Adults: "",
    Children: "",
    Budget: "",
    BookingNote: "",
  });
  const tripInitialValuesSelf = {
    Type:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?._request?.type
        : detailsform
        ? detailsform?._request?.type
        : formValues?.Type,
    TravellingType:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?.travellingdetails[0]?.travelingType
        : detailsform
        ? detailsform?._request?.isLinkedTravelHotel
          ? travelValues?.TravelingType
            ? travelValues?.TravelingType === "One Way Trip" ||
              travelValues?.TravelingType === "Round Trip"
              ? "One Way Trip"
              : "Multilocation Trip"
            : detailsform?.hoteldetail[0]?.travellingType
          : detailsform?.hoteldetail[0]?.travellingType
        : formValues?.TravellingType ?? "",
    HotelCategory: detailsform
      ? detailsform?.hoteldetail[0]?.hotelCategory?.toString()
      : formValues?.HotelCategory,
    CheckInTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkInTime
        ? new Date(detailsform?.hoteldetail[0]?.checkInTime)
        : null
      : formValues?.CheckInTime,
    CheckOutTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkOutTime
        ? new Date(detailsform?.hoteldetail[0]?.checkOutTime)
        : null
      : formValues?.CheckOutTime,
    Location: detailsform
      ? detailsform?.hoteldetail[0]?.location
      : formValues?.Location,
    PreferHotel: detailsform
      ? detailsform?.hoteldetail[0]?.preferHotel
      : formValues?.PreferHotel,
    Adults: detailsform ? detailsform?.hotelemployee[0]?.adults : null,
    Children: detailsform ? detailsform?.hotelemployee[0]?.children : "",
    Budget: detailsform ? detailsform?._request?.budget : "",
    BookingNote: detailsform ? detailsform?.hoteldetail[0]?.bookingNote : "",
    hotelbookingdetails: TravelingDetailsfunc(),
  };

  const tripInitialValuesGroup = {
    Type:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?._request?.type
        : detailsform
        ? detailsform?._request?.type
        : formValues?.Type,
    TravellingType:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?.travellingdetails[0]?.travelingType
        : detailsform
        ? detailsform?._request?.isLinkedTravelHotel
          ? travelValues?.TravelingType
            ? travelValues?.TravelingType === "One Way Trip" ||
              travelValues?.TravelingType === "Round Trip"
              ? "One Way Trip"
              : "Multilocation Trip"
            : detailsform?.hoteldetail[0]?.travellingType
          : detailsform?.hoteldetail[0]?.travellingType
        : formValues?.TravellingType,
    HotelCategory: detailsform
      ? detailsform?.hoteldetail[0]?.hotelCategory?.toString()
      : formValues?.HotelCategory,
    CheckInTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkInTime
        ? new Date(detailsform?.hoteldetail[0]?.checkInTime)
        : null
      : formValues?.CheckInTime,
    CheckOutTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkOutTime
        ? new Date(detailsform?.hoteldetail[0]?.checkOutTime)
        : null
      : formValues?.CheckOutTime,
    Location: detailsform
      ? detailsform?.hoteldetail[0]?.location
      : formValues?.Location,
    PreferHotel: detailsform
      ? detailsform?.hoteldetail[0]?.preferHotel
      : formValues?.PreferHotel,
    Adults: "",
    Children: "",
    Budget: detailsform ? detailsform?._request?.budget : "",
    BookingNote: detailsform ? detailsform?.hoteldetail[0]?.bookingNote : "",
    groupItemsNotMulti: groupItemsDetailsfunc(),
    groupItems: groupItemsMultiDetailsfunc(),
  };
  const tripInitialValuesVendor = {
    Type:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?._request?.type
        : detailsform
        ? detailsform?._request?.type
        : formValues?.Type,
    TravellingType:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?.travellingdetails[0]?.travelingType
        : detailsform
        ? detailsform?._request?.isLinkedTravelHotel
          ? travelValues?.TravelingType
            ? travelValues?.TravelingType === "One Way Trip" ||
              travelValues?.TravelingType === "Round Trip"
              ? "One Way Trip"
              : "Multilocation Trip"
            : detailsform?.hoteldetail[0]?.travellingType
          : detailsform?.hoteldetail[0]?.travellingType
        : formValues?.TravellingType,
    HotelCategory: detailsform
      ? detailsform?.hoteldetail[0]?.hotelCategory?.toString()
      : formValues?.HotelCategory,
    CheckInTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkInTime
        ? new Date(detailsform?.hoteldetail[0]?.checkInTime)
        : null
      : formValues?.CheckInTime,
    CheckOutTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkOutTime
        ? new Date(detailsform?.hoteldetail[0]?.checkOutTime)
        : null
      : formValues?.CheckOutTime,
    Location: detailsform
      ? detailsform?.hoteldetail[0]?.location
      : formValues?.Location,
    PreferHotel: detailsform
      ? detailsform?.hoteldetail[0]?.preferHotel
      : formValues?.PreferHotel,
    Adults: "",
    Children: "",
    Budget: detailsform ? detailsform?._request?.budget : "",
    BookingNote: detailsform ? detailsform?.hoteldetail[0]?.bookingNote : "",
    groupItemsNotMulti: vendorItemsDetailsfunc(),
    groupItems: vendorItemsMultiDetailsfunc(),
  };

  const tripInitialValuesCandidate = {
    Type:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?._request?.type
        : detailsform
        ? detailsform?._request?.type
        : formValues?.Type,
    TravellingType:
      selectedTravelRequestID && travelDetailsform
        ? travelDetailsform?.travellingdetails[0]?.travelingType
        : detailsform
        ? detailsform?._request?.isLinkedTravelHotel
          ? travelValues?.TravelingType
            ? travelValues?.TravelingType === "One Way Trip" ||
              travelValues?.TravelingType === "Round Trip"
              ? "One Way Trip"
              : "Multilocation Trip"
            : detailsform?.hoteldetail[0]?.travellingType
          : detailsform?.hoteldetail[0]?.travellingType
        : formValues?.TravellingType,
    HotelCategory: detailsform
      ? detailsform?.hoteldetail[0]?.hotelCategory?.toString()
      : formValues?.HotelCategory,
    CheckInTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkInTime
        ? new Date(detailsform?.hoteldetail[0]?.checkInTime)
        : null
      : formValues?.CheckInTime,
    CheckOutTime: detailsform
      ? detailsform?.hoteldetail[0]?.checkOutTime
        ? new Date(detailsform?.hoteldetail[0]?.checkOutTime)
        : null
      : formValues?.CheckOutTime,
    CandidateId: detailsform
      ? detailsform?.hotelemployee[0]?.candidateId
      : formValues?.CandidateId,
    Location: detailsform
      ? detailsform?.hoteldetail[0]?.location
      : formValues?.Location,
    PreferHotel: detailsform
      ? detailsform?.hoteldetail[0]?.preferHotel
      : formValues?.PreferHotel,
    Adults: detailsform ? detailsform?.hotelemployee[0]?.adults : null,
    Children: detailsform ? detailsform?.hotelemployee[0]?.children : "",
    Budget: detailsform ? detailsform?._request?.budget : "",
    BookingNote: detailsform ? detailsform?.hoteldetail[0]?.bookingNote : "",
    hotelbookingdetails: TravelingDetailsfunc(),
  };
  const getValidationSchema = () => {
    if (formValues && !isBoth) {
      if (formValues.Type === "Self") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightSelfOneWaySchema;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightSelfMultiTripSchema;
      } else if (formValues.Type === "Group") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightGroupOneWaySchema;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightGroupMultiTripSchema;
      } else if (formValues.Type === "Vendor" || formValues.Type === "Guest") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightVendorOneWaySchema;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightVendorMultiTripSchema;
      } else {
        if (formValues?.TravellingType === "One Way Trip")
          return flightCandidateOneWaySchema;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightCandidateMultiTripSchema;
      }
    }
    if (formValues && isBoth) {
      if (formValues.Type === "Self") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightSelfOneWaySchemaBoth;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightSelfMultiTripSchemaBoth;
      } else if (formValues.Type === "Group") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightGroupOneWaySchemaBoth;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightGroupMultiTripSchemaBoth;
      } else if (formValues.Type === "Vendor" || formValues.Type === "Guest") {
        if (formValues?.TravellingType === "One Way Trip")
          return flightVendorOneWaySchemaBoth;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightVendorMultiTripSchemaBoth;
      } else {
        if (formValues?.TravellingType === "One Way Trip")
          return flightCandidateOneWaySchemaBoth;
        else if (formValues?.TravellingType === "Multilocation Trip")
          return flightCandidateMultiTripSchemaBoth;
      }
    }
    return Yup.object({
      TravellingType: Yup.string().required("Please Select Travel Type"),
    });
  };
  const flightSelfOneWaySchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = formValues?.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    // CheckOutTime: Yup.string().required(" Please Select Date and Time"),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightSelfOneWaySchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = formValues?.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    // CheckOutTime: Yup.string().required(" Please Select Date and Time"),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightSelfMultiTripSchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        // Budget: Yup.string().required("Please Enter the Budget"),
        // Bookingnote: Yup.string().required("Please Enter the Note"),
      })
    ),
  });
  const flightSelfMultiTripSchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    // Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),

        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        Budget: Yup.string().required("Please Enter the Budget"),
        // Bookingnote: Yup.string().required("Please Enter the Note"),
      })
    ),
  });
  const flightGroupOneWaySchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightGroupOneWaySchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        EmployeeId: Yup.string().required("Please Select Employee"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightGroupMultiTripSchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        // Budget: Yup.string().required("Please Enter the Budget"),

        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            EmployeeId: Yup.string().required("Please Select Employee"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightGroupMultiTripSchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    // Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Budget: Yup.string().required("Please Enter the Budget"),

        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            EmployeeId: Yup.string().required("Please Select Employee"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightVendorOneWaySchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightVendorOneWaySchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
    groupItemsNotMulti: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required("Please Enter Name"),
        PhoneNumber: Yup.string().required("Please Enter Phone Number"),
        EmailId: Yup.string().required("Please Enter Email"),
        // Bookingnote: Yup.string().required("Please Enter Note"),
      })
    ),
  });
  const flightVendorMultiTripSchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            Name: Yup.string().required("Please Enter Name"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightVendorMultiTripSchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    // Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    groupItems: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        Budget: Yup.string().required("Please Enter the Budget"),

        EmployeeDetails: Yup.array().of(
          Yup.object().shape({
            Name: Yup.string().required("Please Enter Name"),
            PhoneNumber: Yup.string().required("Please Enter Phone Number"),
            EmailId: Yup.string().required("Please Enter Email"),
          })
        ),
      })
    ),
  });
  const flightCandidateOneWaySchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightCandidateOneWaySchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    HotelCategory: Yup.string().required(" Please Select Hotel Category"),
    CheckInTime: Yup.string().required(" Please Select Date and Time"),
    CheckOutTime: Yup.string()
      .required("Please Select Date and Time")
      .test(
        "is-greater",
        "Check-Out Date and Time must be greater than Check-In Date and Time",
        function (value) {
          const startDate = this.parent.CheckInTime;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),
    Location: Yup.string().required("Please Enter the Location."),
    PreferHotel: Yup.string().required(" Please Enter the Preferred Location."),
    Budget: Yup.string().required("Please Enter the Budget"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    // Gender: Yup.string().required("Please Select the Gender"),
    // Age: Yup.string().required("Please Enter Age"),
    // Bookingnote: Yup.string()
    //   .max(499, "Comments must be less than 500 characters")
    //   .nullable()
    //   .notRequired(),
  });
  const flightCandidateMultiTripSchema = Yup.object({
    TravellingType: Yup.string().required(" Please Select Travel Type"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        // Budget: Yup.string().required("Please Enter the Budget"),
        // Bookingnote: Yup.string().required("Please Enter the Note"),
      })
    ),
  });
  const flightCandidateMultiTripSchemaBoth = Yup.object({
    // TravellingType: Yup.string().required(" Please Select Travel Type"),
    CandidateId: Yup.string().required(" Please Select Candidate"),
    // Budget: Yup.string().required("Please Enter Budget"),
    // Gender: Yup.string().required("Please Select Gender"),
    // Age: Yup.string()
    //   .max(2, "Age must be less than 3 characters")
    //   .nullable()
    //   .notRequired(),

    hotelbookingdetails: Yup.array().of(
      Yup.object().shape({
        HotelCategory: Yup.string().required(" Please Select Hotel Category"),
        CheckInTime: Yup.string().required(" Please Select Date and Time"),
        CheckOutTime: Yup.string()
          .required("Please Select Date and Time")
          .test(
            "is-greater",
            "Check-Out Date and Time must be greater than Check-In Date and Time",
            function (value) {
              const startDate = this.parent.CheckInTime;
              const endDate = value;

              if (startDate && endDate) {
                return new Date(endDate) > new Date(startDate);
              }

              return true; // Let other validations pass if Date or EndDate is not defined
            }
          ),
        Location: Yup.string().required("Please Enter the Location."),
        PreferHotel: Yup.string().required(
          " Please Enter the Preferred Location."
        ),
        Budget: Yup.string().required("Please Enter the Budget"),
        // Bookingnote: Yup.string().required("Please Enter the Note"),
      })
    ),
  });
  console.log("first formValues", formValues);
  console.log("first initialValues", initialValues);

  useEffect(() => {
    if (formValues?.Type === "Self") {
      setInitialValues(tripInitialValuesSelf);
    } else if (formValues?.Type === "Group") {
      setInitialValues(tripInitialValuesGroup);
    } else if (formValues?.Type === "Vendor") {
      setInitialValues(tripInitialValuesVendor);
    } else if (formValues?.Type === "Guest") {
      setInitialValues(tripInitialValuesVendor);
    } else if (formValues?.Type === "Candidate") {
      setInitialValues(tripInitialValuesCandidate);
    }
  }, [
    formValues?.Type,
    formValues?.TravellingType,
    detailsform,
    travelDetailsform,
  ]);

  const onSubmitBoth = (values) => {
    const payload = {
      id: data ? detailsform?._request?.id : 0,
    };
    if (values) {
      if (values.Type === "Self") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = [
            {
              id: travelValues?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                },
              ],
            },
          ];
          const TravelingDetailsObjTR = [
            {
              id: detailsform?.travellingdetails[0]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                },
              ],
            },
            {
              id: detailsform?.travellingdetails[1]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              ToDate: travelValues.ToDate,
              PickupLocation: travelValues?.PickupLocationR,
              DropLocation: travelValues?.DropLocationR,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                },
              ],
            },
          ];
          if (travelValues.TravelingType === "One Way Trip")
            payload["TravelingDetails"] = TravelingDetailsObjT;
          else if (travelValues.TravelingType === "Round Trip")
            payload["TravelingDetails"] = TravelingDetailsObjTR;

          const TravelingDetailsObj = [
            {
              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              HotelCategory: Number(values.HotelCategory),
              BookingNote: values?.BookingNote,
              PreferHotel: values?.PreferHotel,
              Budget: values?.Budget,
              HotelEmployees: [
                {
                  Adults: Number(values?.Adults),
                  Children: Number(values?.Children),
                },
              ],
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues?.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = travelValues?.TravelingDetails?.map(
            (item) => ({
              id: item?.id ?? 0,
              FromDate: item?.FromDate,
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelingType: travelValues.TravelingType,
              CategoryID: travelValues.CategoryID,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                },
              ],
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObjT;
          const TravelingDetailsObj = values?.hotelbookingdetails?.map(
            (item) => ({
              TravellingType: values.TravellingType,
              CheckInTime: item?.CheckInTime,
              CheckOutTime: item?.CheckOutTime,
              Location: item?.Location,
              HotelCategory: Number(item?.HotelCategory),
              PreferHotel: item?.PreferHotel,
              Budget: item?.Budget,
              BookingNote: item?.Bookingnote,
              HotelEmployees: [
                {
                  Adults: Number(item?.Adults),
                  Children: Number(item?.Children),
                },
              ],
            })
          );

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Group") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = [
            {
              id: travelValues?.id ?? 0,

              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,

                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          const TravelingDetailsObjTR = [
            {
              id: detailsform?.travellingdetails[0]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
            {
              id: detailsform?.travellingdetails[1]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              ToDate: travelValues.ToDate,
              PickupLocation: travelValues?.PickupLocationR,
              DropLocation: travelValues?.DropLocationR,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          if (travelValues.TravelingType === "One Way Trip")
            payload["TravelingDetails"] = TravelingDetailsObjT;
          else if (travelValues.TravelingType === "Round Trip")
            payload["TravelingDetails"] = TravelingDetailsObjTR;
          const TravelingDetailsObj = [
            {
              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              PreferHotel: values.PreferHotel,
              BookingNote: values?.BookingNote,
              HotelCategory: Number(values?.HotelCategory),
              Budget: values?.Budget,
              HotelEmployees: values?.groupItemsNotMulti?.map((items) => ({
                Name: employeeList?.filter(
                  (item) => item?.value === items?.EmployeeId
                )[0]?.label,
                EmployeeId: items?.EmployeeId,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Adults: Number(items?.Adults),
                Children: Number(items?.Children),
              })),
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues?.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = travelValues?.groupItems?.map(
            (item) => ({
              id: item?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              CategoryID: travelValues.CategoryID,
              FromDate: item?.FromDate,
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelHospitalityEmployees: item?.EmployeeDetails?.map(
                (items) => ({
                  id: items?.id ?? 0,

                  Name: employeeList?.filter(
                    (item) => item?.value === items?.EmployeeId
                  )[0]?.label,
                  EmployeeId: items?.EmployeeId,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObjT;

          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            TravellingType: values.TravellingType,
            CheckInTime: item.CheckInTime,
            CheckOutTime: item?.CheckOutTime,
            Location: item?.Location,
            PreferHotel: item.PreferHotel,
            BookingNote: item?.Bookingnote,
            Budget: item?.Budget,

            HotelCategory: Number(item?.HotelCategory),
            HotelEmployees: item?.EmployeeDetails?.map((items) => ({
              Name: employeeList?.filter(
                (item) => item?.value === items?.EmployeeId
              )[0]?.label,
              EmployeeId: items?.EmployeeId,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Adults: Number(items?.Adults),
              Children: Number(items?.Children),
            })),
          }));

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Vendor" || values.Type === "Guest") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues?.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = [
            {
              id: travelValues?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,

                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          const TravelingDetailsObjTR = [
            {
              id: detailsform?.travellingdetails[0]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
            {
              id: detailsform?.travellingdetails[1]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              ToDate: travelValues.ToDate,
              PickupLocation: travelValues?.PickupLocationR,
              DropLocation: travelValues?.DropLocationR,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: travelValues?.groupItemsNotMulti?.map(
                (items) => ({
                  id: items?.id ?? 0,
                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            },
          ];
          if (travelValues.TravelingType === "One Way Trip")
            payload["TravelingDetails"] = TravelingDetailsObjT;
          else if (travelValues.TravelingType === "Round Trip")
            payload["TravelingDetails"] = TravelingDetailsObjTR;
          const TravelingDetailsObj = [
            {
              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              PreferHotel: values.PreferHotel,
              BookingNote: values?.BookingNote,
              Budget: values?.Budget,
              HotelCategory: Number(values?.HotelCategory),
              HotelEmployees: values?.groupItemsNotMulti?.map((items) => ({
                Name: items?.Name,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Adults: Number(items?.Adults),
                Children: Number(items?.Children),
              })),
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues?.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;

          const TravelingDetailsObjT = travelValues?.groupItems?.map(
            (item) => ({
              id: item?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              CategoryID: travelValues.CategoryID,
              FromDate: item?.FromDate,
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelHospitalityEmployees: item?.EmployeeDetails?.map(
                (items) => ({
                  id: items?.id ?? 0,

                  Name: items?.Name,
                  PhoneNumber: items?.PhoneNumber?.toString(),
                  EmailId: items?.EmailId,
                  Age: Number(items?.Age),
                  Gender: items?.Gender,
                })
              ),
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObjT;

          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            TravellingType: values.TravellingType,
            CheckInTime: item.CheckInTime,
            CheckOutTime: item?.CheckOutTime,
            Location: item?.Location,
            PreferHotel: item.PreferHotel,
            BookingNote: item?.Bookingnote,
            Budget: item?.Budget,
            HotelCategory: Number(item?.HotelCategory),
            HotelEmployees: item?.EmployeeDetails?.map((items) => ({
              Name: items?.Name,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Adults: Number(items?.Adults),
              Children: Number(items?.Children),
            })),
          }));

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Candidate") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = [
            {
              id: travelValues?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                  CandidateId: travelValues.CandidateId,
                },
              ],
            },
          ];
          const TravelingDetailsObjTR = [
            {
              id: detailsform?.travellingdetails[0]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              CategoryID: travelValues.CategoryID,
              FromDate: travelValues.FromDate,
              PickupLocation: travelValues?.PickupLocation,
              DropLocation: travelValues?.DropLocation,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                  CandidateId: travelValues.CandidateId,
                },
              ],
            },
            {
              id: detailsform?.travellingdetails[1]?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              ToDate: travelValues.ToDate,
              PickupLocation: travelValues?.PickupLocationR,
              DropLocation: travelValues?.DropLocationR,
              CategoryID: travelValues.CategoryID,
              Bookingnote: travelValues?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: travelValues?.Age,
                  Gender: travelValues?.Gender,
                  CandidateId: travelValues.CandidateId,
                },
              ],
            },
          ];
          if (travelValues.TravelingType === "One Way Trip")
            payload["TravelingDetails"] = TravelingDetailsObjT;
          else if (travelValues.TravelingType === "Round Trip")
            payload["TravelingDetails"] = TravelingDetailsObjTR;
          const TravelingDetailsObj = [
            {
              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              HotelCategory: Number(values.HotelCategory),
              BookingNote: values?.BookingNote,
              Budget: values?.Budget,
              PreferHotel: values?.PreferHotel,
              HotelEmployees: [
                {
                  Adults: Number(values?.Adults),
                  Children: Number(values?.Children),
                  CandidateId: Number(values.CandidateId),
                },
              ],
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = travelValues.Type;
          payload["Mode"] = travelValues.Mode;
          payload["Budget"] = Number(travelValues.Budget);
          payload["IsLinkedTravelHotel"] = true;
          const TravelingDetailsObjT = travelValues?.TravelingDetails?.map(
            (item) => ({
              id: item?.id ?? 0,
              TravelingType: travelValues.TravelingType,
              CategoryID: travelValues.CategoryID,
              FromDate: item?.FromDate,
              PickupLocation: item?.PickupLocation,
              DropLocation: item?.DropLocation,
              Bookingnote: item?.Bookingnote,
              TravelHospitalityEmployees: [
                {
                  Age: Number(travelValues?.Age),
                  Gender: travelValues?.Gender,
                  CandidateId: travelValues.CandidateId,
                },
              ],
            })
          );

          payload["TravelingDetails"] = TravelingDetailsObjT;
          const TravelingDetailsObj = values?.hotelbookingdetails?.map(
            (item) => ({
              TravellingType: values.TravellingType,
              CheckInTime: item?.CheckInTime,
              CheckOutTime: item?.CheckOutTime,
              Location: item?.Location,
              HotelCategory: Number(item?.HotelCategory),
              PreferHotel: item?.PreferHotel,
              Budget: item?.Budget,
              BookingNote: item?.Bookingnote,
              HotelEmployees: [
                {
                  Adults: Number(item?.Adults),
                  Children: Number(item?.Children),
                  CandidateId: Number(values.CandidateId),
                },
              ],
            })
          );

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      }
    }
    if (data) {
      if (travelValues?.Mode === "Train")
        Edit(payload, "TravelHospitalityRequests/Edit");
      else
        Edit(
          payload,
          `TravelHospitalityRequests/Edit${travelValues?.Mode}Request`
        );
    } else {
      if (travelValues?.Mode === "Train")
        create(payload, "TravelHospitalityRequests/Create");
      else
        create(
          payload,
          `TravelHospitalityRequests/Create${travelValues?.Mode}Request`
        );
    }
  };
  const onSubmit = (values) => {
    const payload = {
      id: data ? detailsform?._request?.id : 0,
    };
    if (values) {
      if (values.Type === "Self") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              HotelCategory: Number(values.HotelCategory),
              BookingNote: values?.BookingNote,
              PreferHotel: values?.PreferHotel,
              HotelEmployees: [
                {
                  Adults: Number(values?.Adults),
                  Children: Number(values?.Children),
                },
              ],
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);

          const TravelingDetailsObj = values?.hotelbookingdetails?.map(
            (item) => ({
              id: item?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: item?.CheckInTime,
              CheckOutTime: item?.CheckOutTime,
              Location: item?.Location,
              HotelCategory: Number(item?.HotelCategory),
              PreferHotel: item?.PreferHotel,
              BookingNote: item?.Bookingnote,
              HotelEmployees: [
                {
                  Adults: Number(item?.Adults),
                  Children: Number(item?.Children),
                },
              ],
            })
          );

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Group") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              PreferHotel: values.PreferHotel,
              BookingNote: values?.BookingNote,

              HotelCategory: Number(values?.HotelCategory),
              HotelEmployees: values?.groupItemsNotMulti?.map((items) => ({
                id: items?.id ?? 0,

                Name: employeeList?.filter(
                  (item) => item?.value === items?.EmployeeId
                )[0]?.label,
                EmployeeId: items?.EmployeeId,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Adults: Number(items?.Adults),
                Children: Number(items?.Children),
              })),
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            id: item?.id ?? 0,

            TravellingType: values.TravellingType,
            CheckInTime: item.CheckInTime,
            CheckOutTime: item?.CheckOutTime,
            Location: item?.Location,
            PreferHotel: item.PreferHotel,
            BookingNote: item?.Bookingnote,
            HotelCategory: Number(values?.HotelCategory),
            HotelEmployees: item?.EmployeeDetails?.map((items) => ({
              id: items?.id ?? 0,

              Name: employeeList?.filter(
                (item) => item?.value === items?.EmployeeId
              )[0]?.label,
              EmployeeId: items?.EmployeeId,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Adults: Number(items?.Adults),
              Children: Number(items?.Children),
            })),
          }));

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Vendor" || values.Type === "Guest") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              PreferHotel: values.PreferHotel,
              BookingNote: values?.BookingNote,
              // Budget: values?.Budget,

              HotelCategory: Number(values?.HotelCategory),
              HotelEmployees: values?.groupItemsNotMulti?.map((items) => ({
                id: items?.id ?? 0,

                Name: items?.Name,
                PhoneNumber: items?.PhoneNumber?.toString(),
                EmailId: items?.EmailId,
                Adults: Number(items?.Adults),
                Children: Number(items?.Children),
              })),
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);

          const TravelingDetailsObj = values?.groupItems?.map((item) => ({
            id: item?.id ?? 0,

            TravellingType: values.TravellingType,
            CheckInTime: item.CheckInTime,
            CheckOutTime: item?.CheckOutTime,
            Location: item?.Location,
            PreferHotel: item.PreferHotel,
            BookingNote: item?.Bookingnote,
            HotelCategory: Number(values?.HotelCategory),
            HotelEmployees: item?.EmployeeDetails?.map((items) => ({
              id: items?.id ?? 0,

              Name: items?.Name,
              PhoneNumber: items?.PhoneNumber?.toString(),
              EmailId: items?.EmailId,
              Adults: Number(items?.Adults),
              Children: Number(items?.Children),
            })),
          }));

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      } else if (values.Type === "Candidate") {
        if (values.TravellingType === "One Way Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = [
            {
              id: values?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: values.CheckInTime,
              CheckOutTime: values?.CheckOutTime,
              Location: values?.Location,
              HotelCategory: Number(values.HotelCategory),
              BookingNote: values?.BookingNote,
              PreferHotel: values?.PreferHotel,
              HotelEmployees: [
                {
                  Adults: Number(values?.Adults),
                  Children: Number(values?.Children),
                  CandidateId: Number(values.CandidateId),
                },
              ],
            },
          ];
          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
        if (values.TravellingType === "Multilocation Trip") {
          payload["Type"] = values.Type;
          payload["Mode"] = "Hotel";
          payload["Budget"] = Number(values.Budget);
          payload["IsLinkedTravelHotel"] = selectedTravelRequestID
            ? true
            : false;
          selectedTravelRequestID &&
            (payload["TravelRequestIDLinked"] = selectedTravelRequestID);
          const TravelingDetailsObj = values?.hotelbookingdetails?.map(
            (item) => ({
              id: item?.id ?? 0,

              TravellingType: values.TravellingType,
              CheckInTime: item?.CheckInTime,
              CheckOutTime: item?.CheckOutTime,
              Location: item?.Location,
              HotelCategory: Number(item?.HotelCategory),
              PreferHotel: item?.PreferHotel,
              BookingNote: item?.Bookingnote,
              HotelEmployees: [
                {
                  Adults: Number(item?.Adults),
                  Children: Number(item?.Children),
                  CandidateId: Number(values.CandidateId),
                },
              ],
            })
          );

          payload["hotelbookingdetails"] = TravelingDetailsObj;
        }
      }
    }
    if (data) Edit(payload, "TravelHospitalityRequests/EditHotelRequest");
    else create(payload, "TravelHospitalityRequests/CreateHotelRequest");
  };

  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Hotel Booking" : "Create Hotel Booking"}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Edit Hotel Booking" : "Create Hotel Booking"}
      onSubmit={(val) => {
        isBoth ? onSubmitBoth(val) : onSubmit(val);
      }}
      initialvalue={initialValues}
      returnPage={returnPage}
      validationSchema={getValidationSchema}
      styles={{ maxWidth: "90%", width: "90%" }}
      propsDiv={
        <CreateHotelBookingComp
          isBoth={
            data
              ? isBoth || detailsform?._request?.isLinkedTravelHotel
              : selectedTravelRequestID
              ? true
              : false || isBoth
          }
          setLoading={setLoading}
          popAnswer={popAnswer}
          pushEmployee={pushEmployee}
          formValues={formValues}
          editTrue={data ? true : false}
          setInitialValues={setInitialValues}
        />
      }
    />
  );
};
export { CreateHotelBooking };
