import React, { useEffect, useState } from "react";
import { CreateFlightRequest } from "./CreateFlightRequest";
import { CreateTrainRequest } from "./CreateTrainRequest";
import { CreateBusRequest } from "./CreateBusRequest";
import { CreateCabRequest } from "./CreateCabRequest";
import TabIconBg from "../../../../Assets/TabIconBg.svg";
import FlightIcon from "../../../../Assets/FlightIcon.svg";
import TrainIcon from "../../../../Assets/TrainIcon.svg";
import BusIcon from "../../../../Assets/BusIcon.svg";
import CabIcon from "../../../../Assets/CabIcon.svg";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";

const CreateTravelBookingComp = ({
  formValues,
  editTrue,
  loading,
  setLoading,
  formik,
  employeeList,
  pushEmployee,
  popAnswer,
  setFormValues,
  setInitialValues,
  LoggedInUser,
}) => {
  useEffect(() => {
    document.title = `PeopleSol - Travel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [travelActive, setTravelActive] = useState(
    formValues?.Mode ?? "Flight"
  );
  const dispatch = useDispatch();
  const clickHandler = (item, props) => {
    if (editTrue)
      return dispatch(
        showToast({
          text: "You cannot edit an existing travel booking type and mode of travel. Please create a new travel booking",
          severity: "error",
        })
      );
    setTravelActive(item);
    props?.form?.setFieldValue("Mode", item);
    props?.form?.setFieldValue("Type", "Self");
  };
  useEffect(() => {
    if (formValues)
      if (formValues.Mode)
        setTravelActive((prev) => {
          if (prev === formValues?.Mode) return prev;
          else return formValues?.Mode;
        });
  }, [formValues]);

  const tabIconField = (props) => {
    return (
      <div className="tabIconContainer cursor">
        <div
          className={`flightIconBg tabIconBox ${travelActive
            .toString()
            .toLowerCase()}ActiveFlightStyle`}
          onClick={() => clickHandler("Flight", props)}
        >
          <div className="tabIconText">
            <div className="horizontalLine"></div>
            <span style={{ color: "white" }}>Flight</span>
            <div style={{ marginTop: "18px" }}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="17"
                  height="17"
                  rx="8.5"
                  transform="matrix(-1 0 0 1 17 0)"
                  fill="white"
                />
                <rect
                  width="9.33057"
                  height="9.33057"
                  rx="4.66528"
                  transform="matrix(-1 0 0 1 13.0713 3.96777)"
                  fill="#6E6CC6"
                />
              </svg>
            </div>
          </div>
          <img
            style={{ mixBlendMode: "lighten" }}
            src={TabIconBg}
            alt={TabIconBg}
          />
          <div className="tabIconImage">
            <img src={FlightIcon} alt={FlightIcon} style={{ height: "40px" }} />
            {travelActive !== "Flight" && (
              <p>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="17"
                    height="17"
                    rx="8.5"
                    transform="matrix(-1 0 0 1 18.0063 1.13281)"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_i_991_1050"
                      x="0.231934"
                      y="0.358398"
                      width="18.5488"
                      height="18.5488"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    ></filter>
                  </defs>
                </svg>
              </p>
            )}
          </div>
        </div>

        <div
          className={`trainIconBg tabIconBox ${travelActive
            .toString()
            .toLowerCase()}ActiveTrainStyle`}
          onClick={() => clickHandler("Train", props)}
        >
          <div className="tabIconText">
            <div className="horizontalLine"></div>
            <span style={{ color: "white" }}>Train</span>
            <div style={{ marginTop: "18px" }}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="17"
                  height="17"
                  rx="8.5"
                  transform="matrix(-1 0 0 1 17 0)"
                  fill="white"
                />
                <rect
                  width="9.33057"
                  height="9.33057"
                  rx="4.66528"
                  transform="matrix(-1 0 0 1 13.0713 3.96777)"
                  fill="#FC5638"
                />
              </svg>
            </div>
          </div>
          <img
            style={{ mixBlendMode: "lighten" }}
            src={TabIconBg}
            alt={TabIconBg}
          />
          <div className="tabIconImage">
            <img src={TrainIcon} alt={TrainIcon} style={{ height: "40px" }} />
            {travelActive !== "Train" && (
              <p>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="17"
                    height="17"
                    rx="8.5"
                    transform="matrix(-1 0 0 1 18.0063 1.13281)"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_i_991_1050"
                      x="0.231934"
                      y="0.358398"
                      width="18.5488"
                      height="18.5488"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    ></filter>
                  </defs>
                </svg>
              </p>
            )}
          </div>
        </div>

        <div
          className={`busIconBg tabIconBox ${travelActive
            .toString()
            .toLowerCase()}ActiveBusStyle`}
          onClick={() => clickHandler("Bus", props)}
        >
          <div className="tabIconText">
            <div className="horizontalLine"></div>
            <span style={{ color: "white" }}>Bus</span>
            <div style={{ marginTop: "18px" }}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="17"
                  height="17"
                  rx="8.5"
                  transform="matrix(-1 0 0 1 17 0)"
                  fill="white"
                />
                <rect
                  width="9.33057"
                  height="9.33057"
                  rx="4.66528"
                  transform="matrix(-1 0 0 1 13.0713 3.96777)"
                  fill="#D951B2"
                />
              </svg>
            </div>
          </div>
          <img
            style={{ mixBlendMode: "lighten" }}
            src={TabIconBg}
            alt={TabIconBg}
          />
          <div className="tabIconImage">
            <img src={BusIcon} alt={BusIcon} style={{ height: "40px" }} />
            {travelActive !== "Bus" && (
              <p>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="17"
                    height="17"
                    rx="8.5"
                    transform="matrix(-1 0 0 1 18.0063 1.13281)"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_i_991_1050"
                      x="0.231934"
                      y="0.358398"
                      width="18.5488"
                      height="18.5488"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    ></filter>
                  </defs>
                </svg>
              </p>
            )}
          </div>
        </div>

        <div
          className={`cabIconBg tabIconBox ${travelActive
            .toString()
            .toLowerCase()}ActiveCabStyle`}
          onClick={() => clickHandler("Cab", props)}
        >
          <div className="tabIconText">
            <div className="horizontalLine"></div>
            <span style={{ color: "white" }}>Cab</span>
            <div style={{ marginTop: "18px" }}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="17"
                  height="17"
                  rx="8.5"
                  transform="matrix(-1 0 0 1 17 0)"
                  fill="white"
                />
                <rect
                  width="9.33057"
                  height="9.33057"
                  rx="4.66528"
                  transform="matrix(-1 0 0 1 13.0713 3.96777)"
                  fill="#3DBAB4  "
                />
              </svg>
            </div>
          </div>
          <img
            style={{ mixBlendMode: "lighten" }}
            src={TabIconBg}
            alt={TabIconBg}
          />
          <div className="tabIconImage">
            <img src={CabIcon} alt={CabIcon} style={{ height: "40px" }} />
            {travelActive !== "Cab" && (
              <p>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="17"
                    height="17"
                    rx="8.5"
                    transform="matrix(-1 0 0 1 18.0063 1.13281)"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_i_991_1050"
                      x="0.231934"
                      y="0.358398"
                      width="18.5488"
                      height="18.5488"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    ></filter>
                  </defs>
                </svg>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };
  if (loading) return <Loader />;
  else
    return (
      <div
        className="moduleMainContainer"
        style={{
          alignItems: "center",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <p style={{ fontWeight: 500, marginBottom: "6px" }}>
          Select Travel Mode
        </p>
        <Field name="Mode" component={tabIconField} />
        <p
          style={{
            marginTop: "18px",
            fontWeight: 500,
            fontSize: "15px",
            color: "var(--primary)",
          }}
        >
          {travelActive}
        </p>
        {travelActive === "Flight" && (
          <>
            <CreateFlightRequest
              LoggedInUser={LoggedInUser}
              popAnswer={popAnswer}
              pushEmployee={pushEmployee}
              form={formik}
              formValues={formValues}
              setInitialValues={setInitialValues}
              setFormValues={setFormValues}
              editTrue={editTrue}
              employeeList={employeeList}
            />
          </>
        )}
        {travelActive === "Train" && (
          <>
            <CreateTrainRequest
              LoggedInUser={LoggedInUser}
              popAnswer={popAnswer}
              pushEmployee={pushEmployee}
              form={formik}
              formValues={formValues}
              setInitialValues={setInitialValues}
              setFormValues={setFormValues}
              editTrue={editTrue}
              employeeList={employeeList}
            />
          </>
        )}
        {travelActive === "Bus" && (
          <>
            <CreateBusRequest
              LoggedInUser={LoggedInUser}
              popAnswer={popAnswer}
              pushEmployee={pushEmployee}
              form={formik}
              formValues={formValues}
              setInitialValues={setInitialValues}
              setFormValues={setFormValues}
              editTrue={editTrue}
              employeeList={employeeList}
            />
          </>
        )}
        {travelActive === "Cab" && (
          <>
            <CreateCabRequest
              LoggedInUser={LoggedInUser}
              popAnswer={popAnswer}
              pushEmployee={pushEmployee}
              form={formik}
              formValues={formValues}
              setInitialValues={setInitialValues}
              setFormValues={setFormValues}
              editTrue={editTrue}
              employeeList={employeeList}
            />
          </>
        )}
      </div>
    );
};
export { CreateTravelBookingComp };
