import React, { useEffect, useRef, useState } from "react";
import "./Chat.css";

import { useSelector } from "react-redux";
import { IconButton, Popover } from "@mui/material";
import ContactList from "./Components/ContactList";
import signalRService from "../../Services/signalRService";
import MyProfileDetails from "./Components/MyProfileDetails/MyProfileDetails";
import SendImageChat from "../../Assets/SendImageChat.svg";
import ScheduleMessageChat from "../../Assets/ScheduleMessageChat.svg";
import BeforeActiveThirdImg from "../../Assets/BeforeActiveThirdImg.svg";
import BeforeActiveSecondImg from "../../Assets/BeforeActiveSecondImg.svg";
import BeforeActiveFirstImg from "../../Assets/BeforeActiveFirstImg.svg";
import { format } from "date-fns";
import MySettingDetails from "./Components/Settings/MySettingDetails";
import ChatInterfaceIntro from "./Components/ChatInterfaceIntro";
import StarredMessages from "./Components/StarredMessages/StarredMessages";
import BlockLists from "./Components/BlockLists/BlockLists";
import EmpProfiledetails from "./Components/EmpProfileDetails/EmpProfileDetails";
import ChatInterface from "./ChatInterface";
import InputSectionChat from "./InputSectionChat";
import NewGroup from "./Components/NewGroup/NewGroup";
import { Popup } from "../../Components/FormComponent/PopupComponent/Popup";
import ForwardInputChat from "./ForwardInputChat";
import ForwardPopup from "./ForwardPopup";
import GroupInfo from "./Components/GroupInfo/GroupInfo";
import ForwardViewAll from "./ForwardViewAll";
import DocumentPreviewContainer from "./Components/UserAttchments/DocumentPreviewContainer";
import { confirmAlert } from "react-confirm-alert";
import CompanyBroadcast from "./CompanyBroadcast";
import { Community } from "./Components/Community/Community";
import TabbedInterface from "./TabbedInterface";
import { StatusUpdate } from "./Components/Status/StatusUpdate";
import MediaViewerChat from "./MediaViewerChat";
import { useDispatch } from "react-redux";
import {
  addNewActiveMessage,
  setActiveMessageList,
  setContactList,
  setConversationList,
} from "../../Features/ChatSlice";

export const ChatApp = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const chatList = useSelector((state) => state.chat.conversationList);
  const contactsList = useSelector((state) => state.chat.contactList);

  const [activeChat, SetActiveChat] = useState(0);
  const [settingsActive, setSettingsActive] = useState(false);
  const [starredMessage, setStarredMessage] = useState(false);
  const [blocklist, setBlocklist] = useState(false);
  const [groupInfo, setGroupInfo] = useState(false);
  const [activeTab, setActiveTab] = useState("chat");
  const [mySelfChat, setMySelfChat] = useState(false);
  const [empProfileDetails, setEmpProfileDetails] = useState(false);
  const [myProfileDetails, setMyProfileDetails] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [companyBroadcast, setCompanyBroadcast] = useState(false);
  const [community, setCommunity] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaCaption, setMediaCaption] = useState("");
  const dispatch = useDispatch();

  const handleDocsSelect = (files, type) => {
    if (!files) {
      setSelectedDocs([]);
      setAttachmentSelected(false);
    } else {
      setSelectedDocs(files);
      setSelectedFileType(type);
      setAttachmentSelected(true);
    }
  };
  const ViewFullscreen = (array, caption, ind) => {
    setMediaArray(array);
    setMediaIndex(ind);
    setMediaCaption(caption);
  };
  const [replyData, setReplyData] = useState({
    replyId: null,
    replyContent: "",
    data: null,
  });
  const [forwardData, setForwardData] = useState({
    isActive: false,
    msgId: [],
    userId: [],
    conversationId: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const togglePop = () => {
    setCompanyBroadcast((prev) => !prev);
  };
  const toggleNewGroup = () => {
    setNewGroup((prev) => !prev);
    setAnchorEl(false);
  };
  const inputFile = useRef(null);

  const open = Boolean(anchorEl);
  const rightOpen = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;
  const _id = rightOpen ? "simple-popover" : undefined;
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRightMoreClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchor(null);
  };

  useEffect(() => {
    const initChat = async () => {
      await signalRService.startConnection(dispatch, LoggedInUser.personNumber);
      signalRService.onUpdateConversations((data) => {
        dispatch(setConversationList(data));
      });
      signalRService.onUpdateMessages((messages) => {
        const sortedMessages = messages?.sort(
          (a, b) =>
            new Date(a?.messages[0]?.time) - new Date(b?.messages[0]?.time)
        );
        dispatch(setActiveMessageList(sortedMessages)); // Dispatching the entire list of messages
      });
      signalRService.onMessageResponse((msg) => {
        const newMessages = { messages: [] };
        newMessages?.messages?.push(msg);
        dispatch(addNewActiveMessage(newMessages));
      });
      signalRService.onNewMessages((msg) => {
        const newMessages = { messages: [] };
        newMessages?.messages?.push(msg);
        dispatch(addNewActiveMessage(newMessages));
      });
      signalRService.onUpdateContacts((data) => {
        dispatch(setContactList(data));
      });
      const initialConversations = await signalRService.invokeGetConversations(
        dispatch,
        LoggedInUser.personNumber
      );
      const initialContacts = await signalRService.invokeGetContacts(
        dispatch,
        LoggedInUser.personNumber
      );
    };
    initChat();

    return () => {
      dispatch(setActiveMessageList([]));
      dispatch(setConversationList([]));
      setTimeout(() => {
        signalRService.stopConnection(dispatch);
      }, 3000);
    };
  }, [LoggedInUser.personNumber]);

  // useEffect(() => {
  //   setSendPayload({
  //     From: {
  //       Id: LoggedInUser?.id,
  //       MyIBID: activeChat?.participatingContacts?.filter(
  //         (item) => item.personNumber === LoggedInUser?.personNumber
  //       )[0]?.myIBID,
  //       Name: LoggedInUser?.name,
  //       PersonNumber: LoggedInUser?.personNumber,
  //     },
  //     Time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
  //     MessageType: 0,
  //     Caption: "",
  //     Content: "",
  //     MessageStatus: 0,
  //     ReplyingToMessageId: null,
  //     ReplyingToMessage: null,
  //     ConversationId: activeChat?.id,
  //     HasBookmarked: false,
  //     IsMediaCompressed: false,
  //   });
  // }, [activeChat]);

  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const element = scrollRef.current;
      element.scrollTop = element.scrollHeight;
    }
  };

  function createNewGroupChat(items) {
    // let tempList = [...chatList];
    // let newData = tempList.splice(0, 0, items);
    // setChatList([...tempList]);
    // SetActiveChat((tempList[0] = items));
    // SetActiveChat(tempList[0].msgs ?? []);
  }
  // function searchChatters(e) {
  //   if (e.target.value !== "") {
  //     let tempChatters = JSON.parse(JSON.stringify(allChatList));
  //     tempChatters = tempChatters.filter((d, i) =>
  //       d.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
  //     );
  //     setChatList(tempChatters);
  //   } else {
  //     setChatList(allChatList);
  //   }
  // }
  function showStatus(onlineStatus) {
    if (onlineStatus === 1) {
      return "greenDot";
    } else if (onlineStatus === 2) {
      return "redDot";
    } else if (onlineStatus === 3) {
      return "yellowDot";
    } else if (onlineStatus === 4) {
      return "offlineDot";
    } else {
      return "offlineDot";
    }
  }
  const handleAttachmentClick = () => {
    setShowOptions((prev) => !prev);
  };
  const deleteMessage = (conversationId, userId, msgId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-chat-Delete">
            <h1 className="deleteMessageFont">Delete Message?</h1>
            <div>
              <button
                className="button cancelButton chatDeleteButton"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  signalRService?.usedeleteSelectedMsg(
                    dispatch,
                    "deleteForMe",
                    conversationId,
                    userId,
                    msgId
                  );
                  onClose();
                  handleContactClick(conversationId);
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for me
              </button>
              <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  signalRService?.usedeleteSelectedMsg(
                    dispatch,
                    "deleteForEveryone",
                    conversationId,
                    userId,
                    msgId
                  );
                  onClose();
                  handleContactClick(conversationId);
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for everyone
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const sendMessageText = async (messageInput, replyID) => {
    let currentUser = activeChat?.participatingContacts?.filter(
      (item) => item.personNumber === LoggedInUser?.personNumber
    );
    let chatMsgObj = {
      From: {
        Id: 0,
        MyIBID: 0,
        Name: "",
        PersonNumber: "",
      },
      Time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      MessageType: 0,
      Caption: "",
      Content: "",
      MessageStatus: 0,
      ReplyingToMessageId: null,
      ReplyingToMessage: null,
      ConversationId: activeChat?.id,
      HasBookmarked: false,
      IsMediaCompressed: false,
    };
    chatMsgObj.From.Id = currentUser[0].id;
    chatMsgObj.From.MyIBID = currentUser[0].myIBID;
    chatMsgObj.From.Name = currentUser[0].name;
    chatMsgObj.From.PersonNumber = currentUser[0].personNumber;
    chatMsgObj.Content = messageInput;
    if (replyID) {
      chatMsgObj.ReplyingToMessageId = replyID;
    }
    await signalRService.invokeSendMessages(
      dispatch,
      LoggedInUser.personNumber,
      activeChat?.id,
      chatMsgObj
    );
    // handleContactClick(activeChat?.id);
  };
  const sendMediaMessage = async (fileInput, captionInput, type) => {
    let currentUser = activeChat?.participatingContacts?.filter(
      (item) => item.personNumber === LoggedInUser?.personNumber
    );
    let chatMsgObj = {
      From: {
        Id: 0,
        MyIBID: 0,
        Name: "",
        PersonNumber: "",
      },
      Time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      MessageType: 6,
      Caption: "",
      Content: "",
      MessageStatus: 0,
      ReplyingToMessageId: null,
      ReplyingToMessage: null,
      ConversationId: activeChat?.id,
      HasBookmarked: false,
      IsMediaCompressed: false,
    };
    chatMsgObj.From.Id = currentUser[0].id;
    chatMsgObj.From.MyIBID = currentUser[0].myIBID;
    chatMsgObj.From.Name = currentUser[0].name;
    chatMsgObj.From.PersonNumber = currentUser[0].personNumber;
    chatMsgObj.Content = fileInput.map((item) => item.url)?.join(",");
    // chatMsgObj.Caption = captionInput?.join(",");
    chatMsgObj.Caption = captionInput;
    if (type === "document") {
      chatMsgObj.MessageType = 4;
    } else {
      chatMsgObj.MessageType = 6;
    }

    await signalRService.invokeSendMessages(
      dispatch,
      LoggedInUser.personNumber,
      activeChat?.id,
      chatMsgObj
    );
    // handleContactClick(activeChat?.id);
  };
  const getMessagesList = async (contactId) => {
    dispatch(setActiveMessageList([]));
    await signalRService.invokeGetMessages(
      dispatch,
      contactId,
      LoggedInUser.personNumber
    );
  };

  const handleContactClick = (contactId) => {
    getMessagesList(contactId);
  };

  const handleOptionSelect = async (option) => {
    if (option === "Settings") {
      setSettingsActive(true);
    } else if (option === "starredMessage") {
      setStarredMessage(true);
    } else if (option === "blocklist") {
      setBlocklist(true);
    } else if (option === "New Group") {
      setNewGroup(true);
    } else if (option === "Company Broadcast") {
      setCompanyBroadcast(true);
    } else if (option === "Community") {
      setCommunity(true);
    }
    handleClose();
  };
  return (
    <>
      <div className="chatContainerBg">
        <div className="chatContainerBg1"></div>
        <div className="chatContainerBg2"></div>
        <div className="chatContainerBg3"></div>
        <div className="chatContainerBg4"></div>
      </div>
      <div className="chatContainer">
        <div className="chatMainContainer">
          {myProfileDetails && (
            <MyProfileDetails setMyProfileDetails={setMyProfileDetails} />
          )}

          {settingsActive && (
            <MySettingDetails
              setAnchorEl={setAnchorEl}
              setSettingsActive={setSettingsActive}
            />
          )}
          {starredMessage && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setStarredMessage(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Starred message</span>
                </div>
                <StarredMessages setStarredMessages={setStarredMessage} />
              </div>
            </div>
          )}
          {blocklist && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setBlocklist(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Block List</span>
                </div>
              </div>
              <BlockLists setBlockContactList={setBlocklist} />
            </div>
          )}
          {community && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setCommunity(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Community</span>
                </div>
              </div>
              <Community setCommunity={setCommunity} />
            </div>
          )}

          {!myProfileDetails &&
            !settingsActive &&
            !starredMessage &&
            !blocklist &&
            !community && (
              <div className="chatContactListContainer">
                <div className="chatHeaderContainer">
                  <div className="selfChatHeader">
                    <div
                      className="chatListHeader"
                      onClick={() => {
                        setMyProfileDetails(true);
                      }}
                    >
                      <div className="activeImage">
                        <img
                          src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                          alt="Profile"
                        />
                        <div className="greenDot"></div>
                      </div>
                      <div className="allChatListDisplayContainer">
                        <label className="postMainName">
                          {LoggedInUser?.name &&
                            (LoggedInUser?.name?.replace(/\s\(\d+\)$/, "")
                              .length > 20
                              ? LoggedInUser?.name
                                  .replace(/\s\(\d+\)$/, "")
                                  .substring(0, 20) + "..."
                              : LoggedInUser?.name.replace(/\s\(\d+\)$/, ""))}
                        </label>
                        <label>Active</label>
                      </div>
                    </div>
                    <div className="selfChatHeaderActionsContainer">
                      <div className="newChatAndGroupContainer">
                        <IconButton
                          id={id}
                          aria-describedby={id}
                          onClick={(event) => handleMoreClick(event)}
                          title="more"
                        >
                          <svg
                            width="4"
                            height="18"
                            viewBox="0 0 4 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="1.72626"
                              cy="2.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="9.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="16.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                          </svg>
                        </IconButton>
                      </div>
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div
                        className="dropdowncol"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        <div
                          className="taskCont"
                          onClick={() =>
                            handleOptionSelect("Company Broadcast")
                          }
                        >
                          Company Broadcast{" "}
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("New Group")}
                        >
                          New Group
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Community")}
                        >
                          Community
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("starredMessage")}
                        >
                          Starred Message
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("blocklist")}
                        >
                          Block List
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Settings")}
                        >
                          Settings
                        </div>
                      </div>
                    </Popover>
                    <Popover
                      id={_id}
                      open={rightOpen}
                      anchorEl={anchor}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div
                        className="dropdowncol"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        <div
                          className="taskCont"
                          onClick={() =>
                            handleOptionSelect("Company Broadcast")
                          }
                        >
                          View contact
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("New Group")}
                        >
                          Media, links and docs
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Hold Salary")}
                        >
                          Clear Chat
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => {
                            setAnchor(false);
                            confirmAlert({
                              title: "Block User?",
                              message:
                                "Blocked contacts will no longer be able to call you or send you messages.",
                              buttons: [
                                {
                                  label: "Cancel",
                                  onClick: () => {},
                                },
                                {
                                  label: "Block",
                                  onClick: () => {},
                                },
                              ],
                              overlayClassName: "customConfirmAlertContainer",
                            });
                          }}
                        >
                          Block
                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
                <TabbedInterface
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                {activeTab === "chat" && (
                  <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                    <div className="chatListSearchContainer"></div>

                    <div
                      className="mySelfChatContainer"
                      onClick={() => {
                        setMySelfChat(true);
                        // setActiveAttachment(mySelfActiveChat);
                      }}
                    >
                      <div
                        className="chatListContentContainerActive mySelfChatBox active"
                        style={{
                          backgroundColor: "#F0F0FF",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div className="activeImage">
                            <img
                              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                              alt="Profile"
                            />
                            <div className={"greenDot"}></div>
                          </div>
                          <div className="allChatListDisplayContainer">
                            <label
                              className="chatListName"
                              style={{ color: "#8A88ED" }}
                            >
                              My Self
                            </label>
                            <label className="chatListlastMsg">
                              Hello,{" "}
                              {LoggedInUser?.name &&
                                LoggedInUser?.name
                                  ?.replace(/\s\(\d+\)$/, "")
                                  ?.split(" ")[0]}{" "}
                              how are you
                            </label>
                          </div>
                        </div>
                        <div
                          style={{ color: "#8A88ED", paddingRight: " 14px" }}
                        >
                          {">>"}
                        </div>
                      </div>
                    </div>
                    <div className="chatListContainer">
                      {/* Show Chat Contact List Here */}
                      <ContactList
                        LoggedInUser={LoggedInUser}
                        chatList={chatList}
                        showStatus={showStatus}
                        SetActiveChat={SetActiveChat}
                        handleContactClick={handleContactClick}
                      />
                    </div>
                  </div>
                )}
                {activeTab === "updates" && (
                  <div style={{ width: "100%" }}>
                    <StatusUpdate />
                  </div>
                )}
                {activeTab === "calls" && (
                  <div className="tab">
                    <h2>Recent Calls</h2>
                    <ul>
                      <li>Call 1</li>
                      <li>Call 2</li>
                      <li>Call 3</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          {Object.keys(activeChat)?.length > 0 ? (
            <div className="chatConversationContainer">
              <>
                <div
                  className="chatHeaderContainer"
                  style={{ position: "relative" }}
                >
                  <div
                    className="newChatAndGroupContainer"
                    style={{ position: "absolute", top: "14px", right: "5px" }}
                  >
                    <IconButton
                      id={id}
                      aria-describedby={id}
                      onClick={(event) => handleRightMoreClick(event)}
                      title="more"
                    >
                      <svg
                        width="4"
                        height="18"
                        viewBox="0 0 4 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="1.72626"
                          cy="2.4375"
                          r="1.5"
                          fill="#1296B0"
                        />
                        <circle
                          cx="1.72626"
                          cy="9.4375"
                          r="1.5"
                          fill="#1296B0"
                        />
                        <circle
                          cx="1.72626"
                          cy="16.4375"
                          r="1.5"
                          fill="#1296B0"
                        />
                      </svg>
                    </IconButton>
                  </div>
                  <div className="openedChatHeader">
                    <div
                      className="selfChatHeader"
                      onClick={() => {
                        activeChat?.type === undefined ||
                        activeChat?.type === "Contact"
                          ? setEmpProfileDetails(true)
                          : setGroupInfo(true);
                      }}
                    >
                      <div className="activeImage">
                        <div
                          className={`activeImageContainer ${
                            !activeChat.icon ? "customProfilePic" : ""
                          }`}
                          style={
                            activeChat.icon
                              ? {
                                  backgroundImage: `url(${activeChat.icon})`,
                                }
                              : {}
                          }
                        >
                          {!activeChat?.icon
                            ? activeChat?.title
                                ?.split(" ")
                                ?.slice(0, 2)
                                ?.map((item) => {
                                  return item?.charAt(0)?.toUpperCase();
                                })
                                .join("")
                            : null}
                        </div>
                        <div
                        // className={showStatus(activeChat.onlineStatus)}
                        ></div>
                      </div>
                      <div
                        className="allChatListDisplayContainer"
                        style={{ justifyContent: "start" }}
                      >
                        <label className="postMainName">
                          {activeChat?.title}
                        </label>
                        {activeChat?.type === "Contact" && (
                          <label>
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 1 && "Active"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 2 && "Busy"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 3 && "Away"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 4 && "Offline"}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 65px)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {attachmentSelected ? (
                    <DocumentPreviewContainer
                      handleDocsSelect={handleDocsSelect}
                      selectedFiles={selectedDocs}
                      selectedFileType={selectedFileType}
                      attachmentSelected={attachmentSelected}
                      sendMediaMessage={sendMediaMessage}
                    />
                  ) : (
                    <>
                      <div
                        ref={scrollRef}
                        className="imagePreviewContainer"
                        style={{
                          flex: " 1",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <ChatInterface
                          deleteMessage={deleteMessage}
                          togglePopup={togglePopup}
                          replyData={replyData}
                          forwardData={forwardData}
                          setForwardData={setForwardData}
                          LoggedInUser={LoggedInUser}
                          setReplyData={setReplyData}
                          handleContactClick={handleContactClick}
                          ViewFullscreen={ViewFullscreen}
                          scrollToBottom={scrollToBottom}
                          activeChatId={activeChat?.id}
                        />
                      </div>
                      {forwardData?.isActive ? (
                        <ForwardInputChat
                          LoggedInUser={LoggedInUser}
                          deleteMessage={deleteMessage}
                          isOpen={isOpen}
                          togglePopup={togglePopup}
                          forwardData={forwardData}
                          setForwardData={setForwardData}
                        />
                      ) : (
                        <InputSectionChat
                          setReplyData={setReplyData}
                          LoggedInUser={LoggedInUser}
                          handleDocsSelect={handleDocsSelect}
                          replyData={replyData}
                          handleAttachmentClick={handleAttachmentClick}
                          showOptions={showOptions}
                          inputFile={inputFile}
                          setShowOptions={setShowOptions}
                          sendMessageText={sendMessageText}
                          ScheduleMessageChat={ScheduleMessageChat}
                          SendImageChat={SendImageChat}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          ) : (
            <ChatInterfaceIntro
              LoggedInUser={LoggedInUser}
              BeforeActiveFirstImg={BeforeActiveFirstImg}
              BeforeActiveSecondImg={BeforeActiveSecondImg}
              BeforeActiveThirdImg={BeforeActiveThirdImg}
            />
          )}
          {empProfileDetails && (
            <EmpProfiledetails
              setEmpProfileDetails={setEmpProfileDetails}
              // setActiveAttachment={setActiveAttachment}
              activeChat={activeChat}
            />
          )}
          {groupInfo && (
            <GroupInfo
              setGroupInfo={setGroupInfo}
              // setActiveAttachment={setActiveAttachment}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <Popup
          contentClassName="forwardPopup-chat"
          popupHeading={
            viewAll
              ? "(" + `${forwardData?.userId?.length}` + ")"
              : "Forward message to"
          }
          needPrevious={viewAll}
          handlePrevious={() => setViewAll((prev) => !prev)}
          bodyClassName="overflowStop"
          createMyHead="createHeadForward"
          content={
            !viewAll ? (
              <ForwardPopup
                handleClose={togglePopup}
                LoggedInUser={LoggedInUser}
                contactsList={contactsList}
                chatList={chatList}
                setForwardData={setForwardData}
                forwardData={forwardData}
                setViewAll={setViewAll}
                viewAll={viewAll}
              />
            ) : (
              <>
                <ForwardViewAll
                  LoggedInUser={LoggedInUser}
                  contactsList={contactsList}
                  chatList={chatList}
                  setForwardData={setForwardData}
                  forwardData={forwardData}
                  setViewAll={setViewAll}
                  viewAll={viewAll}
                  showStatus={showStatus}
                />
              </>
            )
          }
          handleClose={togglePopup}
        />
      )}
      {newGroup && (
        <Popup
          firstClassName="newChatPopup1"
          secondClassName="newChatPopup2"
          bodyClassName="newChatPopupBodyContainer"
          popupHeading={"Add Group Member"}
          content={
            <>
              <NewGroup
                setNewGroup={setNewGroup}
                createNewGroupChat={createNewGroupChat}
                chatList={chatList}
                handleClose={handleClose}
              />
            </>
          }
          handleClose={toggleNewGroup}
        />
      )}
      {companyBroadcast && (
        <Popup
          color="var(--primary)"
          popupHeading={"Select Contact"}
          contentClassName="newPad"
          content={
            <>
              <CompanyBroadcast setCompanyBroadcast={setCompanyBroadcast} />
            </>
          }
          handleClose={togglePop}
        />
      )}
      {mediaArray?.length > 0 && (
        <MediaViewerChat
          mediaArray={mediaArray}
          setMediaArray={setMediaArray}
          mediaCaption={mediaCaption}
          mediaIndex={mediaIndex}
          setMediaCaption={setMediaCaption}
          setMediaIndex={setMediaIndex}
        />
      )}
    </>
  );
};
