import React, { useState, useEffect } from "react";
import onboardingpic from "../../Assets/Onboardingpic.svg";
import TraveltravelbookingPic from "../../Assets/TraveltravelbookingPic.svg";
import TravelhotelbookingPic from "../../Assets/TravelhotelbookingPic.svg";
import TraveltraveladvancePic from "../../Assets/TraveltraveladvancePic.svg";
import TravelreimbursementPic from "../../Assets/TravelreimbursementPic.svg";
import TravelreportkpiPic from "../../Assets/TravelreportkpiPic.svg";
import "./Travel.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TravelBooking } from "./Components/TravelBooking";
import { HotelBooking } from "./Components/HotelBooking";
import { TravelAdvance } from "./Components/TravelAdvance";
import { Reimbursement } from "./Components/Reimbursement";
import { ReportsandKPI } from "./Components/ReportsandKPI";
import { CreateTravelBooking } from "./Components/CreateTravelBooking";
import { CreateHotelBooking } from "./Components/CreateHotelBooking";
import { CreateTravelAdvance } from "./Components/CreateTravelAdvance";
import { TravelAdvanceDetails } from "./Components/DetailsComp/TravelAdvanceDetails";
import { TravelBookingDetails } from "./Components/TravelBookingComp/TravelBookingDetails";
import { CreateReimbursement } from "./Components/CreateReimbursement";
import { DetailsHotelBooking } from "./Components/DetailsComp/DetailsHotelBooking";
import { useSelector } from "react-redux";
import { ReimbursmentDetails } from "./Components/ReimbursementComp/ReimbursmentDetails";

const Travel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "travel"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("travel")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "travel");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        }
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (
          splitArray[submoduleIndex + 3] === "details" ||
          splitArray[submoduleIndex + 3] === "feedback"
        ) {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  const [activeSubmodule, setActiveSubmodule] = useState("onboarding");
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    

    if (subMenuName === "travelbooking") return TraveltravelbookingPic;
    else if (subMenuName === "hotelbooking") return TravelhotelbookingPic;
    else if (subMenuName === "traveladvance") return TraveltraveladvancePic;
    else if (subMenuName === "reimbursement") return TravelreimbursementPic;
    else if (subMenuName === "reportkpi") return TravelreportkpiPic;
    
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "travelbooking" && (
        <>
          {createActive && (
            <CreateTravelBooking title={"Create Travel Booking"} />
          )}
          {detailsActive && <TravelBookingDetails />}
        </>
      )}
      {activeSubmodule === "hotelbooking" && (
        <>
          {createActive && (
            <CreateHotelBooking title={"Create Hotel Booking"} />
          )}
          {detailsActive && <DetailsHotelBooking />}
        </>
      )}
      {activeSubmodule === "traveladvance" && (
        <>
          {createActive && (
            <CreateTravelAdvance title={"Create Travel Advance"} />
          )}
          {detailsActive && <TravelAdvanceDetails />}
        </>
      )}
      {activeSubmodule === "reimbursement" && (
        <>
          {createActive && (
            <CreateReimbursement title={"Create Reimbursement"} />
          )}
          {detailsActive && <ReimbursmentDetails />}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/travel/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>

      <div className="homeEngageMainContainer">
        {activeSubmodule === "travelbooking" ? (
          <TravelBooking title="Travel Booking" />
        ) : null}
        {activeSubmodule === "hotelbooking" ? (
          <HotelBooking title="Hotel Booking" />
        ) : null}
        {activeSubmodule === "traveladvance" ? (
          <TravelAdvance title="Travel Advance" />
        ) : null}
        {activeSubmodule === "reimbursement" ? (
          <Reimbursement title="Reimbursement" />
        ) : null}
        {activeSubmodule === "reportskpi" ? (
          <ReportsandKPI title="Reports and KPI" />
        ) : null}
      </div>
    </div>
  );
};

export { Travel };
