import React, { useState } from "react";
import { FieldArray } from "formik";
import { TableBody, TableHead, TableRow, Table } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { showToast } from "../../../../../../../Features";
import { Loader } from "../../../../../../../Components/FormComponent/Loader";
import { LabelCustom } from "../../../../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../../../../Components/FormComponent/InputCustomFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../../../Components/FormComponent/Repeated";
import { DatePickerFormiks } from "../../../../../../../Components/FormComponent/DatePickerFormiks";
import { TextAreaFormik } from "../../../../../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../../../../../Components/FormComponent/FileUploadFormik";
import { CheckboxFormiks } from "../../../../../../../Components/FormComponent/CheckboxFormiks";
import SurveyAnswersDeleteIcon from "../../../../../../../Assets/SurveyAnswersDeleteIcon.svg";

const CreateSurveyComp = ({ loading, setInitialValues, type,formValues}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Survey`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);


  const pushAnswer = (index, surveyArray) => {
    setInitialValues((prev) => {
      return {
        ...formValues, // Copy previous state
        SurveyQuestion: formValues.SurveyQuestion.map((question, i) => {
          if (i === index) {
            // If this is the question we're updating
            const lastAnswer = question.Answers[question.Answers.length - 1];
            const newAnswerId = lastAnswer ? lastAnswer.AnswerId + 1 : 1; // Handle if there are no answers yet
            return {
              ...question,
              Answers: [
                ...question.Answers,
                { AnswerId: newAnswerId, AnswerText: "" },
              ],
            };
          }
          return question; // Return unchanged questions
        }),
      };
    });
  };

  // const popAnswer = (array, index) => {
  //   const newArray = [...array];
  //   newArray[index].pop({ AnswerText: "" });
  //   setArrayAnswer({ itemArray: newArray });
  // };

  const popAnswer = (answer,answerIndex, questionIndex) => {
    setInitialValues((prev) => {
      return {
        ...formValues,
        SurveyQuestion: formValues.SurveyQuestion.map((question, index) => {
          if (index === questionIndex) {
            // If this is the question we're updating
            return {
              ...question,
              Answers: question.Answers.filter((_, ind) => ind !== answerIndex),
            };
          }
          return question; // Return unchanged questions
        }),
      };
    });
  };
  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="formcustom threeColumn createSurveyComp">
          <div>
            <LabelCustom
              labelName={type === "survey" ? "Survey Title" : "Poll Title"}
              mandatory={true}
            />
            <InputCustomFormik
              name={"SurveyTitle"}
              placeholder={"Enter title"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Upload Image"} />
            <FileUploadFormik name={"Upload"} placeholder={"Select image"} />
          </div>
          <div>
            <LabelCustom
              labelName={
                type === "survey" ? "Survey Descriptions" : "Poll Description"
              }
              mandatory={true}
            />
            <TextAreaFormik
              name={"Description"}
              placeholder={"Enter description"}
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                type === "survey" ? "Survey Start Date" : "Poll Start Date"
              }
              mandatory={true}
            />
            <DatePickerFormiks
              name={"StartOn"}
              placeholder={"Select"}
              minDate={new Date()}
            />
          </div>
          <div>
            <LabelCustom
              labelName={
                type === "survey" ? "Survey End Date" : "Poll End Date"
              }
              mandatory={true}
            />
            <DatePickerFormiks
              name={"EndOn"}
              placeholder={"Select"}
              minDate={new Date()}
            />
          </div>
        </div>
        <div>
          {/* <label className="repeatedHeading">Expenses</label> */}
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: "12px",
            }}
            className="hideSurveyScrollBar"
          >
            <FieldArray name="SurveyQuestion">
              {(fieldArrayProps) => {
                const { remove, insert, form } = fieldArrayProps;
                const { values } = form;
                const { SurveyQuestion } = values;
                return (
                  <Table style={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"SI. No."} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <LabelCustom
                            labelName={"Questions"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {SurveyQuestion?.map((Questions, index) => (
                      <TableBody key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell sx={{ maxWidth: "600px" }}>
                          <TextAreaFormik
                            mandatory={true}
                            name={`SurveyQuestion[${index}].QuestionText`}
                            placeholder={"Enter Questions"}
                          />
                          <div className="surveycreateform">
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              {Questions?.Answers?.map(
                                (answer, answerIndex) => (
                                  <div
                                    key={answerIndex}
                                    style={{ padding: "0px" }}
                                  >
                                    <LabelCustom
                                      mandatory={true}
                                      labelName={`Answer Option #${
                                        answerIndex + 1
                                      }`}
                                    />
                                    <div style={{ display: "flex" }}>
                                      <InputCustomFormik
                                        type="text"
                                        step="0.01"
                                        name={`SurveyQuestion[${index}].Answers[${answerIndex}].AnswerText`}
                                        placeholder={"Enter"}
                                        style={{ width: "240px" }}
                                      />
                                      {/* {answerIndex > 3 && (
                                      )} */}
                                      <img
                                        onClick={() => {
                                          popAnswer(answer, answerIndex, index);
                                        }}
                                        style={{
                                          marginRight: "20px",
                                          cursor: "pointer",
                                        }}
                                        src={SurveyAnswersDeleteIcon}
                                        alt="SurveyAnswersDeleteIcon"
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="surveyCheckbox-addBtn">
                            <td className="surveyCheckCell">
                              <CheckboxFormiks
                                labelName={"Allow Multiple Answer"}
                                name={`SurveyQuestion[${index}].AllowMultipleAnswer`}
                              />
                            </td>
                            <td
                              className="surveyAddBtnContainer"
                              onClick={() => pushAnswer(index, SurveyQuestion)}
                            >
                              <button type="button" className="add-survey-btn">
                                +
                              </button>{" "}
                              Add Answer
                            </td>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              SurveyQuestion.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() => {
                              insert(index + 1, {
                                QuestionText: "",
                                Answers: [
                                  { AnswerId: 1, AnswerText: "" },
                                  { AnswerId: 2, AnswerText: "" },
                                  { AnswerId: 3, AnswerText: "" },
                                  { AnswerId: 4, AnswerText: "" },
                                ],
                                AllowMultipleAnswer: false,
                              });
                              // setArrayAnswer((prevArrayAnswers) => {
                              //   const newArray = [
                              //     ...prevArrayAnswers.itemArray,
                              //   ];
                              //   newArray.splice(index + 1, 0, [
                              //     { AnswerText: "" },
                              //     { AnswerText: "" },
                              //     { AnswerText: "" },
                              //     { AnswerText: "" },
                              //   ]);
                              //   return { itemArray: newArray };
                              // });
                            }}
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                );
              }}
            </FieldArray>
          </div>
        </div>
      </>
    );
};

export default CreateSurveyComp;
