import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequestForm } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";

export const Relieving = ({ id, data, empId, type, color, handleClose }) => {
  const dispatch = useDispatch();
  const [detailsform, setDetailsform] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = Yup.object({
    ExperienceUpload: Yup.mixed().nullable().required("Please Attach File"),
    RelievingLetterUpload: Yup.mixed()
      .nullable()
      .required("Please Attach File"),
  });
  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Resignations/DueClearance?ID=${id}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequestForm("Resignations/SendRelievingLetter", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Relieving`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const initial = {
    RequestNumber: `RS/${id}`,
    Id: id,
    EmployeeId: empId,
    Email: detailsform.employees?.email,
    ExperienceUpload: null,
    RelievingLetterUpload: null,
  };
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("RequestNumber", `RS/${id}`);
    formData.append("Id", id);
    formData.append("EmployeeId", empId);
    formData.append("Email", values?.Email);
    formData.append("ExperienceUpload", values?.ExperienceUpload);
    formData.append("RelievingLetterUpload", values?.RelievingLetterUpload);
    createPageData(formData);
  };
  if (loading) return <Loader />;
  return (
    <Formik
      initialValues={initial}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize={true}
    >
      <Form>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom labelName={"Personal Email"} />
            <InputCustomFormik name={"Email"} type="email" />
          </div>
          <div>
            <LabelCustom labelName={"Experience Letter "} mandatory="true" />
            <FileUploadFormik name={"ExperienceUpload"} />
          </div>
          <div>
            <LabelCustom labelName={"Relieving Letter "} mandatory="true" />
            <FileUploadFormik name={"RelievingLetterUpload"} />
          </div>
        </div>
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ background: "rgb(255, 128, 0)", color: "white" }}
          >
            {type}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
