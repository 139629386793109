import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { CabSelfComp } from "./CabSelfComp";
import { CabGroupComp } from "./CabGroupComp";
import { CabVendorComp } from "./CabVendorComp";
import { CabGuestComp } from "./CabGuestComp";
import { CabCandidateComp } from "./CabCandidateComp";
import { Field } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { useSelector } from "react-redux";
import Nodata from "../../../../Assets/Nodata.svg";
import { getRequest, postRequest } from "../../../../Services/axios";

const CreateCabRequest = ({
  formValues,
  editTrue,
  pushEmployee,
  popAnswer,
  employeeList,
  setFormValues,
  setInitialValues,
}) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState();
  const [travelCategoryOption, setTravelCategoryOption] = useState(null);

  const getFormDetails = async () => {
    try {
      setIsLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=Cab`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      setTravelCategoryOption(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getFormDetails();
  }, []);
  const getInitialTab = () => {
    switch (formValues?.Type) {
      case "Self":
        return 0;
      case "Group":
        return 1;
      case "Vendor":
        return 2;
      case "Guest":
        return 3;
      case "Candidate":
        return 4;
      default:
        return 0;
    }
  };
  const [tabIndex, setTabIndex] = useState(getInitialTab);
  useEffect(() => {
    document.title = `PeopleSol - Cab Request`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  useEffect(() => {
    if (formValues)
      if (formValues.Type)
        setTabIndex((prev) => {
          if (prev === getInitialTab()) return prev;
          else return getInitialTab();
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);
  const dispatch = useDispatch();
  const handleTabChange = (props, newTabIndex) => {
    if (editTrue)
      return dispatch(
        showToast({
          text: "You cannot edit an existing travel booking type and mode of travel. Please create a new travel booking",
          severity: "error",
        })
      );
    setTabIndex(newTabIndex);
    switch (newTabIndex) {
      case 0:
        return props?.form?.setFieldValue("Type", "Self");
      case 1:
        return props?.form?.setFieldValue("Type", "Group");
      case 2:
        return props?.form?.setFieldValue("Type", "Vendor");
      case 3:
        return props?.form?.setFieldValue("Type", "Guest");
      case 4:
        return props?.form?.setFieldValue("Type", "Candidate");
      default:
        return props?.form?.setFieldValue("Type", "Self");
    }
  };
  const TypeTabField = (props) => {
    return (
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={(event, newTabIndex) => handleTabChange(props, newTabIndex)}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Self
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Group
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Vendor
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Guest
              </span>
            }
          />
          {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.teamnames?.includes("HR Team")) && (
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                  Candidate
                </span>
              }
            />
          )}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
    );
  };
  return (
    <div
      className="moduleMainContainer"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Field name="Type" component={TypeTabField} />
      {tabIndex === 0 && (
        <CabSelfComp
          editTrue={editTrue}
          LoggedInUser={LoggedInUser}
          pushEmployee={pushEmployee}
          formValues={formValues}
          travelCategoryOption={travelCategoryOption}
          setFormValues={setFormValues}
          setInitialValues={setInitialValues}
        />
      )}
      {tabIndex === 1 && (
        <CabGroupComp
          editTrue={editTrue}
          LoggedInUser={LoggedInUser}
          popAnswer={popAnswer}
          pushEmployee={pushEmployee}
          formValues={formValues}
          travelCategoryOption={travelCategoryOption}
          setFormValues={setFormValues}
          employeeList={employeeList}
        />
      )}
      {tabIndex === 2 && (
        <CabVendorComp
          editTrue={editTrue}
          LoggedInUser={LoggedInUser}
          popAnswer={popAnswer}
          pushEmployee={pushEmployee}
          formValues={formValues}
          travelCategoryOption={travelCategoryOption}
          employeeList={employeeList}
          setFormValues={setFormValues}
        />
      )}
      {tabIndex === 3 && (
        <CabGuestComp
          editTrue={editTrue}
          LoggedInUser={LoggedInUser}
          popAnswer={popAnswer}
          pushEmployee={pushEmployee}
          formValues={formValues}
          travelCategoryOption={travelCategoryOption}
          employeeList={employeeList}
          setFormValues={setFormValues}
        />
      )}
      {
        tabIndex === 4 && (
          // (LoggedInUser?.isHRHead ||
          //   LoggedInUser?.isHRDirector ||
          //   LoggedInUser?.teamnames?.includes("HR Team")) ? (
          <CabCandidateComp
            editTrue={editTrue}
            LoggedInUser={LoggedInUser}
            popAnswer={popAnswer}
            pushEmployee={pushEmployee}
            formValues={formValues}
            travelCategoryOption={travelCategoryOption}
            employeeList={employeeList}
            setFormValues={setFormValues}
          />
        )
        // )
        // : (
        //   <div className="noDatastyle">
        //     <img
        //       src={Nodata}
        //       alt="No data"
        //       style={{ height: "150px", width: "250px" }}
        //     />
        //     <h4>{"You Don't have required permissions to create candidate"}</h4>
        //   </div>
        // )
      }
    </div>
  );
};

export { CreateCabRequest };
