import CloseIcon from "../../../Assets/CloseIcon.svg";
import React from "react";

import { IconButton } from "@mui/material";
const PopupForQuestion = ({
  detailsVideo,
  pauseIndex,
  handleClose,
  content,
  popupHeading,
  clickFullscreen,
}) => {
  return (
    <div
      className={
        clickFullscreen ? "popup-questionboxFull" : "popup-questionbox"
      }
    >
      <div className="questionbox">
        {/* <div className="createHead">
          <label>{popupHeading}</label>
          <IconButton onClick={handleClose} title="Close">
            <img className="close-icon" src={CloseIcon} alt="close" />
          </IconButton>
        </div> */}
        {/* <div className="horizontalLine createLine"></div> */}

        <div style={{ height: "100%" }}>
          <div className="popupContentHolderQuestion"> {content}</div>
        </div>
      </div>
    </div>
  );
};

export { PopupForQuestion };
