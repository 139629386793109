import React from "react";
import "./HeaderComponent.css";
import topLineIcon from "../../../../../Assets/topLineIcon.png"
import bottomLineIcon from "../../../../../Assets/bottomLineIcon.png"

function HeaderComponent(props) {
  return (
    <div className="headerComponentContainer">
      <div className="headerComponentWrapper">
        <div className="topCornerIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="30"
            viewBox="0 0 28 30"
            fill="none"
          >
            <g opacity="0.6">
              <path
                d="M17.1936 -22.599C29.6744 -12.0254 31.2206 6.66393 20.647 19.1448C10.0735 31.6257 -8.61584 33.1718 -21.0967 22.5983C-33.5776 12.0247 -35.1237 -6.66461 -24.5502 -19.1455C-13.9766 -31.6263 4.7127 -33.1725 17.1936 -22.599Z"
                fill="#5991C2"
              />
              <path
                d="M17.1936 -22.599C29.6744 -12.0254 31.2206 6.66393 20.647 19.1448C10.0735 31.6257 -8.61584 33.1718 -21.0967 22.5983C-33.5776 12.0247 -35.1237 -6.66461 -24.5502 -19.1455C-13.9766 -31.6263 4.7127 -33.1725 17.1936 -22.599Z"
                fill="#77A2C7"
              />
            </g>
          </svg>
        </div>
        <div className="topLineIcon">
          <img src={topLineIcon} alt="" />
        </div>
        <div className="bottomLineIcon">
          <img src={bottomLineIcon} alt="" />
        </div>
        <div className="dotIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
          >
            <g opacity="0.6">
              <path
                d="M6.55217 0.263834C9.63688 0.263834 12.1375 2.76449 12.1375 5.8492C12.1375 8.93392 9.63688 11.4346 6.55217 11.4346C3.46745 11.4346 0.966797 8.93392 0.966797 5.8492C0.966797 2.76449 3.46745 0.263834 6.55217 0.263834Z"
                fill="#C13699"
              />
              <path
                d="M6.55217 0.263834C9.63688 0.263834 12.1375 2.76449 12.1375 5.8492C12.1375 8.93392 9.63688 11.4346 6.55217 11.4346C3.46745 11.4346 0.966797 8.93392 0.966797 5.8492C0.966797 2.76449 3.46745 0.263834 6.55217 0.263834Z"
                fill="#DA5DB6"
              />
            </g>
          </svg>
        </div>
        <div className="bottomCornerIcon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="33"
            viewBox="0 0 43 33"
            fill="none"
          >
            <path
              opacity="0.6"
              d="M10.5486 52.6068C-1.93225 42.0332 -3.47842 23.3439 7.09515 10.863C17.6687 -1.61785 36.358 -3.16402 48.8389 7.40955C61.3198 17.9831 62.8659 36.6724 52.2924 49.1533C41.7188 61.6342 23.0295 63.1803 10.5486 52.6068Z"
              fill="#5BC4BF"
            />
          </svg>
        </div>
        <div className="propsMidIcon">
          <img src={props?.icon} alt="" />
        </div>
        <div className="propsMidTitle">
          <h3>{props?.title}</h3>
        </div>
      </div>
    </div>
  );
}

export default HeaderComponent;
