import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { YesNoFormik } from "../../../../Components/FormComponent/YesNoFormik";
export const CreateRewardComp = ({ edit }) => {
  const dispatch = useDispatch();
  const option = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const opt = [{ label: "select", value: null }];
  const [questionSetList, setQuestionSetList] = useState([]);
  const [systemRoleList, setSystemRoleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const getBandListFilter = async () => {
    try {
      const result = await getRequest("Awards/GetAwardCycles");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setQuestionSetList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Reward `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getSystemRoles = async () => {
    try {
      const result = await getRequest("SystemRoles/GetSystemRoles");
      let listData = [];
      result?.data?.results.map((item) => {
        return listData.push({ label: item.text, value: item.text });
      });
      setSystemRoleList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getlocations = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLocationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getBandListFilter();
    getSystemRoles();
    getlocations();
  }, []);

  return (
    <div>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Title"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="title"
            type={"text"}
            placeholder={"Enter title"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Award Cycle"} mandatory={true} />
          <SelectForm name={"cycleId"} options={questionSetList} />
        </div>
        <div>
          <LabelCustom labelName={"Award For"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="awardFor"
            type={"text"}
            placeholder={"Enter award for"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Location"} />
          <SelectForm name={"locationId"} options={locationList} />
        </div>
        <div>
          <LabelCustom labelName={"No. of Winners"} mandatory={true} />
          <InputCustomFormik
            name="noOfWinners"
            step=".001"
            placeholder={"Enter no. of winners"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Eligibility Criteria"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="eligibilityCriteria"
            type={"text"}
            placeholder={"Enter eligibility criteria"}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Nominations can be Submitted By"}
            mandatory={true}
          />
          <SelectForm
            name={"nominationsCanBeSubmittedBy"}
            options={systemRoleList}
          />
        </div>
        {edit ? (
          <div>
            <LabelCustom labelName={"Is Active"} mandatory={true} />
            <YesNoFormik name={"isActive"} />
          </div>
        ) : null}
      </div>

      <div style={{ display: "grid" }}>
        <LabelCustom labelName={"Details"} />
        <FieldArray name="requestlist">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { requestlist } = values;

            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Sl. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Question"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Is Mandatory"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {requestlist?.map((requestlist, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextAreaFormik
                          maxLength={300}
                          type="text"
                          name={`requestlist[${index}].question`}
                          placeholder={"Enter the question"}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <SelectForm
                          values={
                            requestlist
                              ? option.filter(
                                  (options) =>
                                    options.value === requestlist.isMandatory
                                )
                              : opt
                          }
                          name={`requestlist[${index}].isMandatory`}
                          options={option}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ textAlign: "center" }}>
                        {index > 0 && (
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => remove(index)}
                          >
                            -
                          </button>
                        )}
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({ question: "", isMandatory: "" })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
