/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Popover, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import "./PayrollPrepare.css";
import { useDeferredValue } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { ArrearForm } from "./ArrearForm";
import { TaxDeduction } from "./TaxDeduction";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
function PayrollPrepare() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnPage = () => {
    navigate("/payroll/preparepayroll/ongoing");
  };
  const [typeAction, setTypeAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);
  const togglePopup = (id, type) => {
    setTypeAction(type);
    setIsOpen((prev) => !prev);
  };
  const holdSalary = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await getRequest(`PreparePayroll/HoldSalary/${selectedEmployeeId}`);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  let detailUrl = "/payroll/employee/details/revisesalary";
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const handleOptionSelect = async (option) => {
    if (option === "Revise Salary") {
      try {
        dispatch(isLoading(true));
        setLoading(true);
        const result = await getRequest(
          `EmployeeSalaryDetails/Details/${selectedEmployeeId}`
        );
        navigate(`${detailUrl}`, {
          state: { id: result?.data?.data },
        });
      } catch (error) {
        dispatch(
          showToast({
            text: error?.response?.data ?? "Error in fetching Data",
            severity: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    } else if (option === "Add Arrear")
      togglePopup(selectedEmployeeId, "Add Arrear");
    else holdSalary();
    // Perform actions based on the selected option, like calling an API
    // For example: callApi(option);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreClick = (event, employeeId, actionId) => {

    setAnchorEl(event.currentTarget);
    setSelectedEmployeeId(employeeId);
    setSelId(actionId)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [status, setStatus] = useState('');
  const [Deduction, setDeduction] = useState(null);
  const [employeeList, setEmployeeList] = useState(null);
  const [tabIndex, setTabIndex] = useState("employee");
  const [count, setCount] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - Prpare Payroll  `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const { state, pathname } = useLocation();

  const editId = state?.id;
  useEffect(() => {
    if (editId) {
      if (pathname.includes('prepare')) getFormDetails();
    }
  }, []);
  console.log("set", Deduction);
  useEffect(() => {
    if (detailsform?.monthPayout) {
      getEmployeeList(detailsform);
    }
  }, [reqData]);
  const getEmployeeList = async (detail) => {
    const employeeResult = await postRequest(
      `PreparePayroll/EmployeeSummaryList?Month=${detail?.monthPayout}&PayAreaId=${detail?.payAreaId}`,
      reqData
    );
    if (employeeResult) {
      setEmployeeList(employeeResult?.data?.data);
      setCount(employeeResult.data.summary.allCount);
      calculateTotalPages(employeeResult.data.summary.allCount);
    }
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PreparePayroll/Details/${editId}`);
      if (result) {
        setDetailsform(result?.data?.data);
        setStatus(result?.data?.summary?.statusLable)
        setDeduction(result?.data?.data?.summary);
        if (result?.data?.data?.monthPayout) {
          const createResult = await getRequest(
            `PreparePayroll/Create/${result?.data?.data?.monthPayout}/${result?.data?.data.payAreaId}`
          );
          if (createResult) {
            getEmployeeList(result?.data?.data);
          }
        } else {
          dispatch(
            showToast({
              text: "Some error occured. Please try again later.",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const sendPayrollApprove = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/SendForApproval/${editId}`
      );
      if (result) {
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const CheckAttendanceClickHandler = (payId) => {
    navigate("/payroll/preparepayroll/ongoing/checkattendance", {
      state: { id: payId, month: detailsform?.monthPayout },
    });
  };
  const CheckSalaryClickHandler = (payId) => {
    navigate("/payroll/preparepayroll/ongoing/checksalary", {
      state: { id: payId, month: detailsform?.monthPayout },
    });
  };
  const salaryStructureClickHandler = (payId, id) => {
    navigate("/payroll/preparepayroll/ongoing/prepare/empsalary", {
      state: { id: editId, month: detailsform?.monthPayout, empId: id },
    });
  };


  const DeductionComponent = ({ deduction }) => {
    let className = "";
    console.log("ded", deduction);
    let content = null;

    if (deduction.type === "EPF Employee Contribution") {
      className = "epfSummary";
      content = (
        <>
          <p style={{ fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "ESI Employee Contribution") {
      className = "esicSummary";
      content = (
        <>
          <p style={{ fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "ESI Employer Contribution") {
      className = `insuranceSummary`;
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "TDS") {
      className = `tdsSummary`;
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "Gratuity") {
      className = `gratuitySummary`;
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "Insurance") {
      className = `insuranceSummary`;
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else if (deduction.type === "Loan and Advance") {
      className = "loanSummary";
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    } else {
      className = "tdsSummary";
      content = (
        <>
          <p style={{ fontSize: '12px', fontWeight: "500", lineHeight: "14px" }}>
            {deduction.type}
          </p>
          <p style={{ fontSize: '17px', fontWeight: 500, color: "var(--primary-text)" }}>₹{deduction.amount}</p>
        </>
      );
    }
    return (
      <div
        className={`payrollSummaryContainer preparePayrollSummary ${className}`}
      >
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
            stroke="#8A88ED"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
            stroke="#8A88ED"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div>{content}</div>
      </div>
    );
  };
  const DeductionsList = ({ deductions }) => {
    return (
      <>
        {deductions.map((deduction, index) => (
          <DeductionComponent key={index} deduction={deduction} />
        ))}
      </>
    );
  };

  if (loading) return <Loader />;
  else
    return (
      <div className="moduleMainContainer">
        <div className="tableHeaderContainer" style={{ padding: "0 12px" }}>
          <IconButton onClick={returnPage}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </IconButton>
          <h6>Prepare Payroll, {detailsform?.monthPayout}</h6>
          {status !== "Pending For Approval" ? (
            <button
              className="button primaryButton"
              style={{ marginLeft: "auto" }}
              onClick={sendPayrollApprove}
            >
              Send For Approval
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="preparePayrollMainContainer" style={{ overflow: "scroll" }}>
          <div
            className="homeDashboardContainer"
            style={{ padding: "12px", width: "100%" }}
          >
            <div
              style={{
                width: "40%",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "calc(50% - 6px)" }}>
                <div
                  className="horizontalLine"
                  style={{
                    backgroundColor: "#B69FB3",
                    width: "20%",
                    marginBottom: "6px",
                  }}
                />
                <h5>{detailsform?.payArea}</h5>
              </div>
              <div style={{ width: "calc(50% - 6px)" }}>
                <div
                  style={{
                    backgroundColor: "var(--primary-hover)",
                    color: "var(--primary)",
                    padding: "6px 12px",
                    width: "fit-content",
                    borderRadius: "6px",
                  }}
                >
                  {detailsform?.status}
                </div>
              </div>
              <div style={{ width: "calc(50% - 6px)" }}>
                <p>{`Payout ${detailsform?.monthPayout}`}</p>
                <h2 style={{ color: "#B69FB3" }}>{detailsform?.amount}</h2>
              </div>
              <div style={{ width: "calc(50% - 6px)" }}>
                <p>No. of Employee</p>
                <h2 style={{ color: "#5BC4BF" }}>
                  {detailsform?.noOfEmployees}
                </h2>
              </div>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                alignItems: "center",
              }}
            >
              {Deduction?.length ? <DeductionsList deductions={Deduction} /> : <></>}
            </div>
          </div>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            <Tab
              value={"employee"}
              label={
                <span
                  className={`tabHeading ${tabIndex === "employee" && "tabActive"
                    }`}
                >
                  Employee Summary
                </span>
              }
            />
            <Tab
              value={"tax"}
              label={
                <span
                  className={`tabHeading ${tabIndex === "tax" && "tabActive"}`}
                >
                  Taxes & Deductions
                </span>
              }
            />
            <Tab
              value={"timeline"}
              label={
                <span
                  className={`tabHeading ${tabIndex === "timeline" && "tabActive"
                    }`}
                >
                  Timeline
                </span>
              }
            />
          </Tabs>
          {tabIndex === "employee" && (
            <div className="preparePayrollTableContainer" style={{ height: "400px" }}>
              <div
                className="tableHeaderContainer"
                style={{ padding: "0 0 0 12px" }}
              >
                <button
                  className="button primaryButton"
                  onClick={() =>
                    CheckAttendanceClickHandler(detailsform?.payAreaId)
                  }
                >
                  Check Attendance
                </button>
                <button
                  onClick={() =>
                    CheckSalaryClickHandler(detailsform?.payAreaId)
                  }
                  className="button secondaryButton"
                  style={{ border: "1px solid var(--primary)" }}
                >
                  Check Salary
                </button>
                <div className="search-box" title="Search">
                  <input
                    className="search-text"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchParam(e.target.value)}
                  />
                  <a className="search-btn" title="Search">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                        fill="#1296B0"
                      />
                    </svg>
                  </a>
                </div>

              </div>
              <table className="attendance-table">
                <colgroup>
                  <col />
                  <col style={{ width: "100%" }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr className="tableHeaderRow" style={{ zIndex: "9" }}>
                    <th>Actions</th>
                    <th style={{ textAlign: "start" }}>Employee Name</th>
                    <th>Account Number</th>
                    <th>Bank Name</th>
                    <th>Bonus Added</th>
                    <th>Pay Days</th>
                    <th>Total Earning</th>
                    <th>Total Deduction</th>
                    <th>Total Reimbursement</th>
                    <th>Total Net Payable</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList?.map((item) => (
                    <tr key={item.item.id}>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() =>
                            salaryStructureClickHandler(
                              detailsform?.payAreaId,
                              item.item.id
                            )
                          }
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.15"
                              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
                              fill="#0DB53C"
                            />
                            <line
                              x1="14.7295"
                              y1="7.43701"
                              x2="7.72949"
                              y2="7.43701"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                            <line
                              x1="5.72949"
                              y1="7.43701"
                              x2="5.61462"
                              y2="7.43701"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                            <line
                              x1="14.7295"
                              y1="11.3437"
                              x2="7.72949"
                              y2="11.3437"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                            <line
                              x1="5.72949"
                              y1="11.3437"
                              x2="5.61462"
                              y2="11.3438"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                            <line
                              x1="14.7295"
                              y1="15.2505"
                              x2="7.72949"
                              y2="15.2505"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                            <line
                              x1="5.72949"
                              y1="15.2505"
                              x2="5.61462"
                              y2="15.2505"
                              stroke="#0DB53C"
                              strokeLinecap="round"
                            />
                          </svg>
                        </IconButton>
                        <IconButton
                          onClick={(event) =>
                            handleMoreClick(event, item.item.id, item.item.actionId)
                          }
                          title="more"
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 3 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="1.52099"
                              cy="2.0957"
                              r="1.22363"
                              fill="#D9D9D9"
                            />
                            <circle
                              cx="1.52099"
                              cy="7.87207"
                              r="1.22363"
                              fill="#D9D9D9"
                            />
                            <circle
                              cx="1.52099"
                              cy="13.6484"
                              r="1.22363"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </IconButton>
                      </td>
                      <td style={{ textAlign: "start", cursor: "pointer" }}>
                        {item.item.employee}
                      </td>
                      <td>{item.item.accountNumber}</td>
                      <td>{item.item.bankName}</td>
                      <td>{item.item.bonus}</td>
                      <td>{item.item.payDays}</td>
                      <td>{item.item.totalEarning + item.item.bonus}</td>
                      <td>{item.item.totalDeduction}</td>
                      <td>{item.item.totalReimbursement}</td>
                      <td>{item.item.totalNetPayable}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="horizontalLine"></div>
              <div className="tableFooterContainer">
                <label>Total Record Count: </label>
                <span>{count}</span>
                <SelectCustom
                  searchable={false}
                  options={noOfRecords}
                  dropdownPosition="top"
                  values={[recordsSelected]}
                  onChange={(e) => paginationChanged(e)}
                />
                <label>Records Per Page</label>
                {currentPage > 1 ? (
                  <IconButton
                    onClick={() => pageChangedPrev()}
                    color="primary"
                    className="pageChangerIcon pageChangerIconActive"
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                        stroke="#BFBFBF"
                        strokeLinecap="round"
                      />
                    </svg>
                  </IconButton>
                ) : (
                  <IconButton
                    disabled
                    color="primary"
                    className="pageChangerIcon"
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                        stroke="#BFBFBF"
                        strokeLinecap="round"
                      />
                    </svg>
                  </IconButton>
                )}
                {currentPage} - {totalPages}
                {totalPages > currentPage ? (
                  <IconButton
                    onClick={() => pageChangedNext()}
                    color="primary"
                    className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                        stroke="#BFBFBF"
                        strokeLinecap="round"
                      />
                    </svg>
                  </IconButton>
                ) : (
                  <IconButton
                    disabled
                    color="primary"
                    className={`pageChangerIcon pageChangerIconNext`}
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                        stroke="#BFBFBF"
                        strokeLinecap="round"
                      />
                    </svg>
                  </IconButton>
                )}
              </div>
            </div>
          )}
          {tabIndex === "tax" && (
            <TaxDeduction Id={editId} PayMonth={detailsform?.monthPayout} />
          )}
          {tabIndex === "timeline" && (
            <>
              <div style={{ display: "flex", width: "100%" }}>
                <TimeLineData
                  id={editId}
                  url={"PreparePayroll"}
                  module={"Payroll"}
                  requestNumber={editId}
                />
              </div>
            </>
          )}
        </div>
        {/* Popover to display additional fields */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div
            className="dropdownPrp"
            style={{
              border: "1px solid rgb(216, 240, 242)",
              fontFamily: "Poppins",
            }}
            key={selectedEmployeeId}
          >
            <div
              className="taskDDContent"
              style={{ width: "9.5rem", paddingLeft: "16px", color: "black" }}
              onClick={() => handleOptionSelect("Revise Salary")}
            >
              Revise Salary
            </div>
            <div
              className="taskDDContent"
              style={{ width: "9.5rem", paddingLeft: "16px", color: "black" }}
              onClick={() => handleOptionSelect("Add Arrear")}
            >
              Add Arrear
            </div>
            <div
              className="taskDDContent"
              style={{ width: "9.5rem", paddingLeft: "16px", color: "black" }}
              onClick={() => handleOptionSelect("Hold Salary")}
            >
              Hold Salary
            </div>
          </div>
        </Popover>
        {isOpen && (
          <Popup
            popupHeading={typeAction}
            content={
              <>
                <ArrearForm
                  loading={loading}
                  type={typeAction}
                  month={detailsform?.monthPayout}
                  id={selId}
                  PayrollEmployeeId={selectedEmployeeId}
                  color={"red"}
                  handleClose={togglePopup}
                />
              </>
            }
            handleClose={togglePopup}
          />
        )}
      </div>
    );
}

export default PayrollPrepare;
