import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { useDispatch } from "react-redux";
import { UpdateTaskStatus } from "../../../../../Components/FormComponent/PopupComponent/UpdateTaskStatus";
import { IconButton, Tooltip } from "@mui/material";
import Nodata from "../../../../../Assets/Nodata.svg";
import ApproveIcon from "../../../../../Assets/ApproveIcon.svg";
import { showToast } from "../../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import * as Yup from "yup";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { camelToCapital } from "../../../../../Utilities/camelToCapital";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { DetailSubTask } from "./DetailSubTask";
import { useSelector } from "react-redux";
import { UpdateConfirmStatus } from "../../../../../Components/FormComponent/PopupComponent/UpdateConfirmStatus";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const AllTasksComp = ({
  data,
  taskList,
  getTasksData,
  headerAction,
  sortType,
  selectedTaskOption,
  sortParam,
  onlyTable,
  loading,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [taskActiveId, setTaskActiveId] = useState(0);
  const [details, setDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsOpen(false);
    setSubAddTaskVisible(false);
    setSubTaskDetailsActive(false);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [addSubTaskVisible, setSubAddTaskVisible] = useState(false);
  const [subTaskDetailsActive, setSubTaskDetailsActive] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [idActive, setIdActive] = useState(0);
  const [tbodyData, setTbodyData] = useState(false);
  const [detailed, setDetailed] = useState(false);
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const [isSubTaskVisible, setSubTaskVisible] = useState(false);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 100,
      iDisplayStart: 0,
      sSearch: "",
      sorting: null,
      sSortDir_0: null,
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol - All Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`Tasks/Details/${id}`);
      setDetails(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getDetailed = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`Tasks/Details/${id}`);
      setDetailed(result?.data?._projecttasks);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getSubTasksData = useCallback(async () => {
    try {
      const result = await postRequest("Tasks/List", reqData);
      if (result.data.count > 0) {
        setTbodyData(JSON.parse(result.data.data));
      } else {
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
    }
  }, [reqData]);

  const handleTaskHover = useCallback((taskId, isTaskHovered) => {
    setReqData((prevReqData) => ({
      ...prevReqData,
      TaskId: taskId,
      dataTableRequest: {
        ...prevReqData.dataTableRequest,
      },
    }));
    setTimeout(() => {
      setHoveredTaskId(taskId);
      if (!isTaskHovered) {
        setSubTaskVisible(true); // Show the subtask component after a delay
      } else {
        setSubTaskVisible((prev) => !prev);
      }
    }, 300);
  }, []);

  useEffect(() => {
    getSubTasksData();
  }, [getSubTasksData]);
  const RemoveNumberInsideBracket = (text) => {
    let updatedText;
    if (text) updatedText = text.replace(/\(\d+\)/, ""); // Removes the number inside parentheses
    return updatedText;
  };

  const addSubTaskClickHandler = () => {
    setSubTaskDetailsActive(false);
    setSubAddTaskVisible((prev) => !prev);
    togglePopup();
  };
  const navigate = useNavigate();

  const filteredHeading = [
    "Actions",
    "Name",
    "AssignTo",
    " Deadline",
    " Status ",
    " Project",
    "Priority",
    "CompletedOn",
  ];
  const validationSchema = Yup.object({
    Title: Yup.string().required("Please Enter Title"),
    EmployeeId: Yup.string().required("Please Enter Employee Name"),
  });
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("RequestNumber", 0);
    if (selectedTaskId) formData.append("TaskId", values?.TaskId);
    if (detailed) formData.append("Id", detailed?.id);
    formData.append("Title", values?.Title);
    if (values?.StartsOn)
      formData.append("StartsOn", `${format(values?.StartsOn, "MM/dd/yyyy")}`);
    if (values?.EndsOn)
      formData.append("EndsOn", `${format(values?.EndsOn, "MM/dd/yyyy")}`);
    formData.append("ProjectId", values?.ProjectId);
    if (typeof values.upload === "object")
      formData.append("upload", values?.upload);
    formData.append("Description", values?.Description);
    formData.append("EstimatedHours", values?.EstimatedHours);
    formData.append("Status", "Yet to Start");
    formData.append("Priority", values?.Priority);
    formData.append("EmployeeId", values?.EmployeeId);

    detailed ? EditPageData(formData) : createPageData(formData);
  };
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("Tasks/Edit", values);
      // eslint-disable-next-line no-unused-vars
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      getSubTasksData();
    }
  };
  const createPageData = async (values) => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm("Tasks/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getSubTasksData();
      handleClose();
      setSelectedTaskId(null);
    }
  };

  if (loading) return <Loader />;
  else
    return (
      <div className="taskMainContainer">
        {taskList?.length ? (
          <div className="addTask">
            <div
              className="allTaskContainer"
              // onMouseLeave={() => handleTaskHover(null)}
            >
              <div className="headertask">
                {filteredHeading.map((heading) => {
                  return (
                    <div
                      style={{
                        flex: "1",
                        marginLeft: heading === "AssignTo" && "10px",
                      }}
                      className="tableHeaderCell"
                    >
                      {camelToCapital(
                        heading === "Title"
                          ? "Name"
                          : heading === "Employee"
                          ? "AssignTo"
                          : heading === "Percentage"
                          ? "Progress"
                          : heading === "ProjectName"
                          ? "Project"
                          : heading === "EndsOn"
                          ? "Deadline"
                          : heading
                      )}
                      {heading !== "Actions" && (
                        <>
                          <IconButton
                            onClick={() =>
                              headerAction(
                                heading === "Name"
                                  ? "Title"
                                  : heading === "AssignTo"
                                  ? "Employee"
                                  : heading === "Progress"
                                  ? "Percentage"
                                  : heading === "Project"
                                  ? "ProjectName"
                                  : heading === "Deadline"
                                  ? "EndsOn"
                                  : heading
                              )
                            }
                          >
                            <svg
                              className={`${
                                sortParam === heading
                                  ? "headingSort headingSortActive"
                                  : "headingSort"
                              }`}
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className={`${
                                  sortType === "ascending" && "sortTypeActive"
                                }`}
                                d="M2.99759 0.734329L5.28394 4.39843L2.99759 4.39843L0.711235 4.39843L2.99759 0.734329Z"
                                fill="#BFBFBF"
                              />
                              <path
                                className={`${
                                  sortType === "descending" && "sortTypeActive"
                                }`}
                                d="M2.99759 10.7343L5.28394 7.07023L2.99759 7.07023L0.711235 7.07023L2.99759 10.7343Z"
                                fill="#BFBFBF"
                              />
                            </svg>
                          </IconButton>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {taskList &&
                taskList?.map((Item) => {
                  const isTaskHovered = Item.Id === hoveredTaskId;
                  const apiDateObject = new Date(Item?.EndsOn);
                  // Step 2: Calculate the difference in milliseconds
                  // Create a new date object for the day before
                  const today = new Date();
                  const currentDate = new Date();
                  const completedDate = new Date(Item?.Date);
                  currentDate.setDate(today.getDate() - 1);
                  const differenceInMillisecondsCompleted =
                    apiDateObject - completedDate;
                  const differenceInMilliseconds = apiDateObject - currentDate;
                  const differenceInCompletedDays = Math.floor(
                    differenceInMillisecondsCompleted / (1000 * 60 * 60 * 24)
                  );
       
                  // Step 3: Convert milliseconds to days
                  const differenceInDays = Math.floor(
                    differenceInMilliseconds / (1000 * 60 * 60 * 24)
                  );
                  return (
                    <div key={Item.Id} className="allTaskContInside">
                      <div
                        className="taskComponent cursor"
                        style={{
                          backgroundColor:
                            isTaskHovered && "var(--primary-hover)",
                          borderRadius: isTaskHovered && "8px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "1px",
                            height: "50%",
                            bottom: 0,
                            left: "14px",
                            zIndex: 1,
                            backgroundColor: "var(--grey)",
                          }}
                          className="verticalLine"
                        />
                        <div style={{ display: "flex", zIndex: 2 }}>
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="6.48877"
                              cy="7.24365"
                              r="6.47607"
                              fill={
                                Item.Status === "Yet to Start"
                                  ? "#F7941D"
                                  : Item.Status === "In Progress"
                                  ? "var(--primary)"
                                  : Item.Status === "Completed" ||
                                    Item.Status === "Confirmed"
                                  ? "#0DB53C"
                                  : Item.Status === "Hold"
                                  ? "#8A88ED"
                                  : "red"
                              }
                            />
                          </svg>
                        </div>
                        {selectedTaskOption === "Pending Confirmation" ? (
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              paddingLeft: "15px",
                              gap: "3px",
                            }}
                          >
                            <Tooltip arrow title={"Details"} placement="top">
                              <IconButton
                                style={{ padding: "0" }}
                                onClick={async () => {
                                  try {
                                    setLoading(true);
                                    const result = await getRequest(
                                      `Tasks/Details/${Item.Id}`
                                    );
                                    setDetails(result?.data);
                                  } catch (error) {
                                    dispatch(
                                      showToast({
                                        text:
                                          error?.response?.data ??
                                          "Error in fetching Data",
                                        severity: "error",
                                      })
                                    );
                                  } finally {
                                    setLoading(false);
                                  }
                                  setHoveredTaskId(Item.Id);
                                  setIdActive(Item.Id);
                                  navigate(
                                    `/hrms/projecttask/task/details/${Item.Id}`,
                                    {
                                      state: { selectedTaskOption },
                                    }
                                  );
                                }}
                              >
                                <svg
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.15"
                                    d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                    fill="#0DB53C"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="6.83691"
                                    x2="8.52344"
                                    y2="6.83691"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="6.83691"
                                    x2="6.40857"
                                    y2="6.83691"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="10.7437"
                                    x2="8.52344"
                                    y2="10.7437"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="10.7437"
                                    x2="6.40857"
                                    y2="10.7437"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="14.6504"
                                    x2="8.52344"
                                    y2="14.6504"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="14.6504"
                                    x2="6.40857"
                                    y2="14.6504"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </IconButton>
                            </Tooltip>
                            {LoggedInUser?.id === Item?.EmployeeId ||
                            LoggedInUser?.id === Item?.CreatedBy ? (
                              <Tooltip arrow title="Edit Task" placement="top">
                                <IconButton
                                  onClick={async () =>
                                    navigate(
                                      `/hrms/projecttask/task/create/${Item.Id}`
                                    )
                                  }
                                  style={{ padding: "0" }}
                                >
                                  <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.15"
                                      d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                      fill="#FB7158"
                                    />
                                    <line
                                      x1="11.3414"
                                      y1="14.7392"
                                      x2="15.3043"
                                      y2="14.7392"
                                      stroke="#FB7158"
                                      stroke-width="0.8"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M11.2721 7.61396L6.22461 12.6277V14.6297H8.25845L13.2489 9.60742M11.2721 7.61396L12.5653 6.3294C13.1116 5.78667 13.9941 5.78815 14.5387 6.33271V6.33271C15.0833 6.87734 15.0847 7.75992 14.5418 8.30628L13.2489 9.60742M11.2721 7.61396L13.2489 9.60742"
                                      stroke="#FB7158"
                                      stroke-width="0.8"
                                    />
                                  </svg>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            <Tooltip
                              arrow
                              title="Confirm Completion"
                              placement="top"
                              onClick={async () => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className="homeScreenPopup">
                                        <UpdateConfirmStatus
                                          selectedTaskOption={
                                            selectedTaskOption
                                          }
                                          id={Item.Id}
                                          onClose={onClose}
                                          dispatch={dispatch}
                                          getTasksData={getTasksData}
                                        />
                                      </div>
                                    );
                                  },
                                });
                              }}
                            >
                              <img src={ApproveIcon} alt="confirm" />
                            </Tooltip>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              paddingLeft: "15px",
                              gap: "3px",
                            }}
                          >
                            <Tooltip arrow title={"Details"} placement="top">
                              <IconButton
                                style={{ padding: "0" }}
                                onClick={async () => {
                                  try {
                                    setLoading(true);
                                    const result = await getRequest(
                                      `Tasks/Details/${Item.Id}`
                                    );
                                    setDetails(result?.data);
                                  } catch (error) {
                                    dispatch(
                                      showToast({
                                        text:
                                          error?.response?.data ??
                                          "Error in fetching Data",
                                        severity: "error",
                                      })
                                    );
                                  } finally {
                                    setLoading(false);
                                  }
                                  setHoveredTaskId(Item.Id);
                                  setIdActive(Item.Id);
                                  navigate(
                                    `/hrms/projecttask/task/details/${Item.Id}`,
                                    {
                                      state: { selectedTaskOption },
                                    }
                                  );
                                }}
                              >
                                <svg
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.15"
                                    d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                    fill="#0DB53C"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="6.83691"
                                    x2="8.52344"
                                    y2="6.83691"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="6.83691"
                                    x2="6.40857"
                                    y2="6.83691"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="10.7437"
                                    x2="8.52344"
                                    y2="10.7437"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="10.7437"
                                    x2="6.40857"
                                    y2="10.7437"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="15.5234"
                                    y1="14.6504"
                                    x2="8.52344"
                                    y2="14.6504"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="6.52344"
                                    y1="14.6504"
                                    x2="6.40857"
                                    y2="14.6504"
                                    stroke="#0DB53C"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </IconButton>
                            </Tooltip>

                            {Item.Status === "Cancelled" ||
                            Item.Status === "Confirmed" ? (
                              <></>
                            ) : (
                              <>
                                {(LoggedInUser?.id === Item?.EmployeeId ||
                                  LoggedInUser?.id === Item?.CreatedBy) && (
                                  <Tooltip
                                    arrow
                                    title="Edit Task"
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={async () =>
                                        navigate(
                                          `/hrms/projecttask/task/create/${Item.Id}`
                                        )
                                      }
                                      style={{ padding: "0" }}
                                    >
                                      <svg
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.15"
                                          d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                          fill="#FB7158"
                                        />
                                        <line
                                          x1="11.3414"
                                          y1="14.7392"
                                          x2="15.3043"
                                          y2="14.7392"
                                          stroke="#FB7158"
                                          stroke-width="0.8"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M11.2721 7.61396L6.22461 12.6277V14.6297H8.25845L13.2489 9.60742M11.2721 7.61396L12.5653 6.3294C13.1116 5.78667 13.9941 5.78815 14.5387 6.33271V6.33271C15.0833 6.87734 15.0847 7.75992 14.5418 8.30628L13.2489 9.60742M11.2721 7.61396L13.2489 9.60742"
                                          stroke="#FB7158"
                                          stroke-width="0.8"
                                        />
                                      </svg>
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {Item.Status === "Cancelled" ||
                                Item.Status === "Confirmed" ? (
                                  <></>
                                ) : (
                                  <>
                                    {(LoggedInUser?.id === Item?.EmployeeId ||
                                      LoggedInUser?.id === Item?.CreatedBy) && (
                                      <IconButton
                                        onClick={async () => {
                                          confirmAlert({
                                            customUI: ({ onClose }) => {
                                              return (
                                                <div className="homeScreenPopup">
                                                  <UpdateTaskStatus
                                                    id={Item.Id}
                                                    onClose={onClose}
                                                    dispatch={dispatch}
                                                    getTasksData={
                                                      getTasksData
                                                    }
                                                  />
                                                </div>
                                              );
                                            },
                                          });
                                        }}
                                        title="Update Status"
                                        style={{ padding: "0" }}
                                      >
                                        <svg
                                          width="21"
                                          height="21"
                                          viewBox="0 0 21 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.964844"
                                            y="0.243652"
                                            width="20"
                                            height="20"
                                            rx="4"
                                            fill="#D8F0F2"
                                          />
                                          <path
                                            d="M15.2207 5.36865V9.79635"
                                            stroke="#1296B0"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M13.1113 7.58252H17.3295"
                                            stroke="#1296B0"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M15.1612 11.9777C14.832 12.825 14.2685 13.561 13.5366 14.0999C12.8047 14.6389 11.9346 14.9584 11.0278 15.0213"
                                            stroke="#1296B0"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M8.5461 14.5235C7.6422 14.0695 6.90524 13.341 6.44083 12.4424C5.97643 11.5438 5.80842 10.5212 5.9609 9.52127"
                                            stroke="#1296B0"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M6.85412 7.38279C7.39693 6.65375 8.13597 6.0942 8.98492 5.7695C9.83387 5.44481 10.7577 5.36836 11.6485 5.5491"
                                            stroke="#1296B0"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </IconButton>
                                    )}
                                  </>
                                )}
                                <Tooltip
                                  arrow
                                  title="Add Sub Task"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={async () => {
                                      await getDetails(Item?.Id);
                                      addSubTaskClickHandler();
                                      setSelectedTaskId(Item.Id);
                                    }}
                                    style={{ padding: "0" }}
                                  >
                                    <svg
                                      width="21"
                                      height="21"
                                      viewBox="0 0 21 21"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.964844"
                                        y="0.243652"
                                        width="20"
                                        height="20"
                                        rx="4"
                                        fill="#F4EAF3"
                                      />
                                      <rect
                                        x="4.46484"
                                        y="5.24365"
                                        width="13"
                                        height="3"
                                        rx="1.5"
                                        fill="#F4EAF3"
                                        stroke="#B69FB3"
                                      />
                                      <rect
                                        x="9.34375"
                                        y="12.2437"
                                        width="8.12109"
                                        height="3"
                                        rx="1.5"
                                        fill="#F4EAF3"
                                        stroke="#B69FB3"
                                      />
                                      <path
                                        d="M6.15723 8.37158V11.8125C6.15723 12.9171 7.05266 13.8125 8.15723 13.8125H9.62223"
                                        stroke="#B69FB3"
                                      />
                                    </svg>
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </div>
                        )}
                        <label
                          className={`taskText ${
                            taskActiveId === 3 && "completeTaskText"
                          }`}
                          style={{
                            flex: 1,
                            flexShrink: 0,
                            paddingRight: "10px",
                          }}
                          onClick={() => {
                            setTaskActiveId(1);
                          }}
                        >
                          {Item?.Title?.length > 60
                            ? `${Item.Title?.slice(0, 60)}...`
                            : Item?.Title}
                        </label>
                        <label style={{ flex: 1, flexShrink: 0 }}>
                          {!Item.Employee
                            ? RemoveNumberInsideBracket(LoggedInUser.name)
                            : RemoveNumberInsideBracket(Item.Employee)}{" "}
                        </label>
                        <label
                          style={{
                            flex: 1,
                            flexShrink: 0,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {Item?.EndsOn &&
                            format(new Date(Item?.EndsOn), "dd/MM/yyyy")}
                          {differenceInDays < 0 && (
                            <span className={`delayedTextHolder`}>
                              {Item?.EndsOn && Item.Status === "Confirmed"
                                ? ""
                                : Item.Status === "Completed"
                                ? Math.abs(differenceInDays) < 365 &&
                                  `Delayed by ${Math.abs(
                                    differenceInCompletedDays
                                  )} Days`
                                : Math.abs(differenceInDays) < 365 &&
                                  `Delayed by  ${Math.abs(
                                    differenceInDays
                                  )} Days`}
                            </span>
                          )}
                        </label>
                        <label
                          style={{
                            flex: 1,
                            flexShrink: 0,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label>
                            <label
                              className={`progressIconHolder`}
                              style={{
                                flex: 1,
                                flexShrink: 0,
                                color:
                                  Item.Status === "Yet to Start"
                                    ? "#F7941D"
                                    : Item.Status === "In Progress"
                                    ? "var(--primary)"
                                    : Item.Status === "Completed" ||
                                      Item.Status === "Confirmed"
                                    ? "#0DB53C"
                                    : Item.Status === "Hold"
                                    ? "#8A88ED"
                                    : "red",
                                backgroundColor:
                                  Item.Status === "Yet to Start"
                                    ? "#FFF4E5"
                                    : Item.Status === "In Progress"
                                    ? "#D8F0F2"
                                    : Item.Status === "Completed" ||
                                      Item.Status === "Confirmed"
                                    ? "#E7F8EC"
                                    : Item.Status === "Hold"
                                    ? "#F0F0FF"
                                    : "#FCEBEB",
                              }}
                            >
                              {Item.Status}
                            </label>
                            <span className="progressPercentHolder">
                              {Item.Status === "In Progress" &&
                                `(
                    ${Item.Percentage}%)`}
                            </span>
                          </label>
                        </label>
                        <label style={{ flex: 1, flexShrink: 0 }}>
                          {Item.ProjectName}
                        </label>
                        <label style={{ flex: 1, flexShrink: 0 }}>
                          {Item.Priority && (
                            <span
                              className="priorityHolder"
                              style={{
                                border:
                                  Item.Priority === "Medium"
                                    ? "1px solid #0DB53C"
                                    : Item.Priority === "Low"
                                    ? "1px solid #0DB53C"
                                    : "1px solid #FB7158",
                                color:
                                  Item.Priority === "Medium"
                                    ? "#0DB53C"
                                    : Item.Priority === "Low"
                                    ? " #0DB53C"
                                    : " #FB7158",
                              }}
                            >
                              {Item.Priority}
                            </span>
                          )}
                        </label>

                        <label style={{ flex: 1, flexShrink: 0 }}>
                          {Item?.Date &&
                            format(new Date(Item?.Date), "dd/MM/yyyy")}
                        </label>
                        <div style={{ position: "absolute", right: "0px" }}>
                          <IconButton
                            onClick={() =>
                              handleTaskHover(Item.Id, isTaskHovered)
                            }
                            style={{
                              transform:
                                isSubTaskVisible &&
                                isTaskHovered &&
                                "rotate(180deg)",
                            }}
                          >
                            <svg
                              width="14"
                              height="9"
                              viewBox="0 0 14 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                stroke="#1296B0"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      </div>
                      <div>
                        {isTaskHovered && isSubTaskVisible && (
                          <div
                            className={`subTaskContainer ${
                              isTaskHovered && isSubTaskVisible
                                ? "visible"
                                : "hidden"
                            }`}
                          >
                            {tbodyData &&
                              tbodyData.map((item) => {
                                const isTaskHovered = item.Id === hoveredTaskId;
                                const apiDateObject = new Date(item?.EndsOn);
                                // Step 2: Calculate the difference in milliseconds
                                // Get today's date
                                const today = new Date();
                                // Create a new date object for the day before
                                const currentDate = new Date();
                                currentDate.setDate(today.getDate() - 1);
                                const differenceInMilliseconds =
                                  apiDateObject - currentDate;
                                // Step 3: Convert milliseconds to days
                                const differenceInDays = Math.floor(
                                  differenceInMilliseconds /
                                    (1000 * 60 * 60 * 24)
                                );
                                return (
                                  <>
                                    <div
                                      className="taskComponent cursor"
                                      style={{
                                        backgroundColor:
                                          isTaskHovered &&
                                          "var(--primary-hover)",
                                        borderRadius: isTaskHovered && "8px",
                                        paddingLeft: "24px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "1px",
                                          height: "100%",
                                          bottom: 0,
                                          left: "14px",
                                          zIndex: 1,
                                          backgroundColor: "var(--grey)",
                                        }}
                                        className="verticalLine"
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          height: "1px",
                                          width: "10px",
                                          left: "16px",
                                          zIndex: 1,
                                          backgroundColor: "var(--grey)",
                                        }}
                                        className="horizontalLine"
                                      />
                                      <div
                                        style={{ display: "flex", zIndex: 2 }}
                                      >
                                        <svg
                                          width="11"
                                          height="12"
                                          viewBox="0 0 13 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="6.48877"
                                            cy="6.24365"
                                            r="5.47607"
                                            fill={
                                              item.Status === "Yet to Start"
                                                ? "#F7941D"
                                                : item.Status === "In Progress"
                                                ? "var(--primary)"
                                                : item.Status === "Completed"
                                                ? "#0DB53C"
                                                : item.Status === "Hold"
                                                ? "#8A88ED"
                                                : "red"
                                            }
                                          />
                                        </svg>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          paddingLeft: "5px",
                                          gap: "3px",
                                        }}
                                      >
                                        <IconButton
                                          title="Details"
                                          style={{ padding: "0" }}
                                          onClick={async () => {
                                            navigate(
                                              `/hrms/projecttask/task/summary/${item.Id}`,
                                              {
                                                state: { selectedTaskOption },
                                              }
                                            );
                                            // setIdActive(item.Id);
                                            // getDetails(item.Id);
                                            // subTaskDetailsClickHandler();
                                          }}
                                        >
                                          <svg
                                            width="21"
                                            height="21"
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.15"
                                              d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                              fill="#0DB53C"
                                            />
                                            <line
                                              x1="15.5234"
                                              y1="6.83691"
                                              x2="8.52344"
                                              y2="6.83691"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                            <line
                                              x1="6.52344"
                                              y1="6.83691"
                                              x2="6.40857"
                                              y2="6.83691"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                            <line
                                              x1="15.5234"
                                              y1="10.7437"
                                              x2="8.52344"
                                              y2="10.7437"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                            <line
                                              x1="6.52344"
                                              y1="10.7437"
                                              x2="6.40857"
                                              y2="10.7437"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                            <line
                                              x1="15.5234"
                                              y1="14.6504"
                                              x2="8.52344"
                                              y2="14.6504"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                            <line
                                              x1="6.52344"
                                              y1="14.6504"
                                              x2="6.40857"
                                              y2="14.6504"
                                              stroke="#0DB53C"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                        </IconButton>
                                        {item.Status === "Cancelled" ||
                                        item.Status === "Confirmed" ? (
                                          <></>
                                        ) : (
                                          <>
                                            {(LoggedInUser?.name ===
                                              item?.Employee ||
                                              LoggedInUser?.id ===
                                                item?.CreatedBy) && (
                                              <IconButton
                                                onClick={async () => {
                                                  confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        <div className="homeScreenPopup">
                                                          <UpdateTaskStatus
                                                            id={item.Id}
                                                            onClose={onClose}
                                                            dispatch={dispatch}
                                                            getTasksData={
                                                              getSubTasksData
                                                            }
                                                          />
                                                        </div>
                                                      );
                                                    },
                                                  });
                                                }}
                                                title="Update Status"
                                                style={{ padding: "0" }}
                                              >
                                                <svg
                                                  width="21"
                                                  height="21"
                                                  viewBox="0 0 21 21"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    x="0.964844"
                                                    y="0.243652"
                                                    width="20"
                                                    height="20"
                                                    rx="4"
                                                    fill="#D8F0F2"
                                                  />
                                                  <path
                                                    d="M15.2207 5.36865V9.79635"
                                                    stroke="#1296B0"
                                                    stroke-linecap="round"
                                                  />
                                                  <path
                                                    d="M13.1113 7.58252H17.3295"
                                                    stroke="#1296B0"
                                                    stroke-linecap="round"
                                                  />
                                                  <path
                                                    d="M15.1612 11.9777C14.832 12.825 14.2685 13.561 13.5366 14.0999C12.8047 14.6389 11.9346 14.9584 11.0278 15.0213"
                                                    stroke="#1296B0"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M8.5461 14.5235C7.6422 14.0695 6.90524 13.341 6.44083 12.4424C5.97643 11.5438 5.80842 10.5212 5.9609 9.52127"
                                                    stroke="#1296B0"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M6.85412 7.38279C7.39693 6.65375 8.13597 6.0942 8.98492 5.7695C9.83387 5.44481 10.7577 5.36836 11.6485 5.5491"
                                                    stroke="#1296B0"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </IconButton>
                                            )}
                                            <IconButton
                                              title="Edit SubTask"
                                              onClick={async () => {
                                                await getDetailed(item?.Id);
                                                addSubTaskClickHandler();
                                                setSelectedTaskId(Item.Id);
                                              }}
                                              style={{ padding: "0" }}
                                            >
                                              <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  opacity="0.15"
                                                  d="M0.964844 4.24365C0.964844 2.03451 2.7557 0.243652 4.96484 0.243652H16.9648C19.174 0.243652 20.9648 2.03451 20.9648 4.24365V16.2437C20.9648 18.4528 19.174 20.2437 16.9648 20.2437H4.96484C2.7557 20.2437 0.964844 18.4528 0.964844 16.2437V4.24365Z"
                                                  fill="#FB7158"
                                                />
                                                <line
                                                  x1="11.3414"
                                                  y1="14.7392"
                                                  x2="15.3043"
                                                  y2="14.7392"
                                                  stroke="#FB7158"
                                                  stroke-width="0.8"
                                                  stroke-linecap="round"
                                                />
                                                <path
                                                  d="M11.2721 7.61396L6.22461 12.6277V14.6297H8.25845L13.2489 9.60742M11.2721 7.61396L12.5653 6.3294C13.1116 5.78667 13.9941 5.78815 14.5387 6.33271V6.33271C15.0833 6.87734 15.0847 7.75992 14.5418 8.30628L13.2489 9.60742M11.2721 7.61396L13.2489 9.60742"
                                                  stroke="#FB7158"
                                                  stroke-width="0.8"
                                                />
                                              </svg>
                                            </IconButton>
                                          </>
                                        )}
                                      </div>
                                      <label
                                        className={`taskText ${
                                          taskActiveId === 3 &&
                                          "completeTaskText"
                                        }`}
                                        style={{
                                          flex: 1,
                                          flexShrink: 0,
                                          paddingRight: "10px",
                                        }}
                                        onClick={() => {
                                          setTaskActiveId(1);
                                        }}
                                      >
                                        {item.Title}
                                      </label>
                                      <label style={{ flex: 1, flexShrink: 0 }}>
                                        {RemoveNumberInsideBracket(
                                          item.Employee
                                        )}
                                      </label>
                                      <label
                                        style={{
                                          flex: 1,
                                          flexShrink: 0,
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {item?.EndsOn &&
                                          format(
                                            new Date(item?.EndsOn),
                                            "dd/MM/yyyy"
                                          )}
                                        {differenceInDays < 0 && (
                                          <span className={`delayedTextHolder`}>
                                            {" "}
                                            {item?.EndsOn &&
                                              `Delayed by ${Math.abs(
                                                differenceInDays
                                              )} Days`}
                                          </span>
                                        )}
                                      </label>
                                      <label
                                        style={{
                                          flex: 1,
                                          flexShrink: 0,
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <label>
                                          <label
                                            className={`progressIconHolder`}
                                            style={{
                                              flex: 1,
                                              flexShrink: 0,
                                              color:
                                                item.Status === "Yet to Start"
                                                  ? "#F7941D"
                                                  : item.Status ===
                                                    "In Progress"
                                                  ? "var(--primary)"
                                                  : item.Status === "Completed"
                                                  ? "#0DB53C"
                                                  : item.Status === "Hold"
                                                  ? "#8A88ED"
                                                  : "red",
                                              backgroundColor:
                                                item.Status === "Yet to Start"
                                                  ? "#FFF4E5"
                                                  : item.Status ===
                                                    "In Progress"
                                                  ? "#D8F0F2"
                                                  : item.Status === "Completed"
                                                  ? "#E7F8EC"
                                                  : item.Status === "Hold"
                                                  ? "#F0F0FF"
                                                  : "#FCEBEB",
                                            }}
                                          >
                                            {item.Status}
                                          </label>

                                          <span className="progressPercentHolder">
                                            {item.Status === "In Progress" &&
                                              `(
                                ${item.Percentage}%)`}
                                          </span>
                                        </label>
                                      </label>
                                      <label style={{ flex: 1, flexShrink: 0 }}>
                                        {item.ProjectName}
                                      </label>
                                      <label style={{ flex: 1, flexShrink: 0 }}>
                                        {item.Priority && (
                                          <span
                                            className="priorityHolder"
                                            style={{
                                              border:
                                                item.Priority === "Medium"
                                                  ? "1px solid #0DB53C"
                                                  : item.Priority === "Low"
                                                  ? "1px solid #0DB53C"
                                                  : "1px solid #FB7158",
                                              color:
                                                item.Priority === "Medium"
                                                  ? "#0DB53C"
                                                  : item.Priority === "Low"
                                                  ? " #0DB53C"
                                                  : " #FB7158",
                                            }}
                                          >
                                            {item.Priority}
                                          </span>
                                        )}
                                      </label>
                                      <label style={{ flex: 1, flexShrink: 0 }}>
                                        {Item?.Date &&
                                          format(
                                            new Date(Item?.Date),
                                            "dd/MM/yyyy"
                                          )}
                                      </label>
                                    </div>
                                    <div
                                      className="borderDot"
                                      style={{ margin: "0 42px" }}
                                    ></div>
                                  </>
                                );
                              })}
                            {isTaskHovered && isSubTaskVisible && (
                              <div className="addSubTaskContainer">
                                {/* Add the "Add Subtask" button here */}
                                {Item.Status === "Cancelled" ||
                                Item.Status === "Confirmed" ? (
                                  <></>
                                ) : (
                                  <label
                                    className={`createSubTaskButton cursor`}
                                    onClick={async () => {
                                      setDetailed(false);
                                      await getDetails(Item?.Id);
                                      addSubTaskClickHandler();
                                      setSelectedTaskId(Item.Id);
                                    }}
                                  >
                                    <span>
                                      <svg
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.964844"
                                          y="0.243652"
                                          width="20"
                                          height="20"
                                          rx="4"
                                          fill="#F4EAF3"
                                        />
                                        <rect
                                          x="4.46484"
                                          y="5.24365"
                                          width="13"
                                          height="3"
                                          rx="1.5"
                                          fill="#F4EAF3"
                                          stroke="#B69FB3"
                                        />
                                        <rect
                                          x="9.34375"
                                          y="12.2437"
                                          width="8.12109"
                                          height="3"
                                          rx="1.5"
                                          fill="#F4EAF3"
                                          stroke="#B69FB3"
                                        />
                                        <path
                                          d="M6.15723 8.37158V11.8125C6.15723 12.9171 7.05266 13.8125 8.15723 13.8125H9.62223"
                                          stroke="#B69FB3"
                                        />
                                      </svg>
                                    </span>{" "}
                                    <span>Add Sub Task</span>
                                  </label>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div className="noDatastyle">
            <img
              src={Nodata}
              alt="No data"
              style={{ height: "150px", width: "250px" }}
            />
            <h4>{data ?? "No Data Found"}</h4>
          </div>
        )}
        {isOpen && (
          <Popup
            handleClose={handleClose}
            popupHeading={
              addSubTaskVisible
                ? detailed
                  ? "Update Sub Task"
                  : "Add Sub Task"
                : "Details"
            }
            content={
              <>
                {addSubTaskVisible &&
                  (loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Formik
                        initialValues={{
                          ProjectId: detailed
                            ? detailed.projectId
                            : details?._projecttasks?.projectId ?? "",
                          EmployeeId: detailed
                            ? detailed.employeeId
                            : details?._projecttasks?.employeeId,
                          Title: detailed ? detailed?.title : "",
                          Description: detailed ? detailed?.description : "",
                          upload: detailed
                            ? `ProjectsTasks/${detailed?.attachments}`
                            : "",
                          EstimatedHours: detailed
                            ? detailed?.estimatedHours
                            : "",
                          StartsOn: detailed
                            ? detailed?.startsOn && new Date(detailed?.startsOn)
                            : new Date(),
                          EndsOn: detailed
                            ? detailed?.endsOn && new Date(detailed?.endsOn)
                            : "",
                          Priority: detailed ? detailed?.priority : "",
                          TaskId: selectedTaskId,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        <Form style={{ width: "100%", minWidth: "640px" }}>
                          <div className="formcustom">
                            <div>
                              {" "}
                              <LabelCustom
                                mandatory={true}
                                fontWeight={"500"}
                                labelName={"Sub Task Name"}
                              />
                              <InputCustomFormik
                                placeholder={"Enter Sub Task Name"}
                                name="Title"
                              />
                            </div>
                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={" Sub Task Description"}
                              />
                              <TextAreaFormik
                                maxLength={500}
                                placeholder="Please Enter Sub Task Description"
                                style={{ height: "90px" }}
                                name={"Description"}
                              />
                            </div>

                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={"Estimated Hours"}
                              />
                              <InputCustomFormik
                                name="EstimatedHours"
                                placeholder="Enter Estimated No. of Hours"
                                type="number"
                              />
                            </div>
                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={"Sub Task Start Date"}
                                mandatory={false}
                              />
                              <DatePickerFormiks
                                name={"StartsOn"}
                                placeholder={"Select the Sub task start date"}
                              />
                            </div>
                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={"Sub Task End Date"}
                                mandatory={false}
                              />
                              <DatePickerFormiks
                                name={"EndsOn"}
                                placeholder={"Select the Sub task end date"}
                              />
                            </div>
                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={"Sub Task Priority"}
                                mandatory={false}
                              />
                              <SelectForm
                                name={"Priority"}
                                placeholder={"Select Priority"}
                                options={option}
                              />
                            </div>
                            <div>
                              <LabelCustom
                                fontWeight={"500"}
                                labelName={" Task Attachment"}
                              />
                              <FileUploadFormik
                                name={"upload"}
                                placeholder={"Choose file"}
                              />
                            </div>
                          </div>
                          <div
                            className="createFootCust"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <button
                              type="button"
                              className="button secondaryButton"
                              // onClick={() => {setSubTaskActiveId(0);setTaskVisibleId(1)}}
                              onClick={() => {
                                handleClose();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="button primaryButton"
                              type="submit"
                              style={{
                                background: "var(--primary)",
                                color: "white",
                              }}
                            >
                              {detailed ? "Update" : "Create"}
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </>
                  ))}
                {subTaskDetailsActive && (
                  <DetailSubTask
                    details={details}
                    handleClose={handleClose}
                    getTasksData={getSubTasksData}
                    loading={loading}
                    key={idActive}
                  />
                )}
              </>
            }
          />
        )}
      </div>
    );
};

export { AllTasksComp };
