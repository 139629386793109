import CloseIcon from "../../../Assets/CloseIcon.svg";
import React from "react";
import { IconButton } from "@mui/material";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { postRequest } from "../../../Services/axios";
import { useState } from "react";
import { useEffect } from "react";
import { Loader } from "../../../Components/FormComponent/Loader";
const OrganisationPopup = ({
  selIdprop,
  handleClose,
  content,
  selectedRows,
  type,
}) => {
  const [tdetails, setDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const details = async (id) => {
    setLoading(true);
    const result = await postRequest(
      `SkillsEmployeeController/GetEmployeeSkills?employeeId=${id}`
    );
    setDetails(result?.data);
    setLoading(false);
  };
  useEffect(() => {
    details(selIdprop);
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <div style={{ height: "620px", overflowY: "auto" }}>
        {tdetails &&
          tdetails?.map((item, index) => (
            <div key={index} className="mainOrganisationPopup">
              <div className="OrganisationPopupHeading">{item?.skillset}</div>
              <div className="organisationPopupcontent">
                {item?.skills?.map((itemSkill, indexSkill) => (
                  <div key={indexSkill} className="circleOrganisationPopup">
                    <CircularProgressbarWithChildren
                      value={`${(itemSkill?.level / 5) * 100}`}
                      text={`${itemSkill?.level}/5`}
                      strokeWidth={3}
                      styles={buildStyles({
                        textColor: "#1296B0",
                        pathColor: "#1296B0",
                        trailColor: "#D8F0F2",
                        rotation: 0,
                        strokeLinecap: "round",
                      })}
                    ></CircularProgressbarWithChildren>
                    <div className="circleOrganisationname">
                      {itemSkill?.skillName}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    );
  //   if (loading) return "";
  //   else
  //     return (
  //     );
};

export { OrganisationPopup };
