import { FieldArray } from "formik";
import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormError } from "../../../../Components/FormComponent/SelectFormError";
import { getRequest } from "../../../../Services/axios";
import { useEffect } from "react";

const optionRej = [{ label: "Close and Notify", value: "Close and Notify" }];
const optionApp = [{ label: "Go to Next Step", value: "Go to Next Step" }];
const opt = [{ label: "select", value: null }];
const optionSer = [
  {
    label: "Initiate an Approval",
    value: "Initiate an Approval",
  },
  {
    label: "Assign to IT Helpdesk",
    value: "Assign to IT Helpdesk",
  },
  {
    label: "Send an Email",
    value: "Send an Email",
  },
];
const WorkflowStepsFormIt = (props) => {
  const { values } = props;
  const [approver, setApprover] = React.useState([]);
  const [val, setVal] = React.useState([]);

  React.useEffect(() => {
    values?.items?.map((item, index) => {
      let arr = { tab: index, value: item.actionType };
      setVal((prev) => {
        var contains = prev.some((elem) => {
          return JSON.stringify(arr) === JSON.stringify(elem);
        });
        if (!contains) {
          return [...prev, arr];
        } else {
          return prev;
        }
      });
      return null;
    });
  }, [values]);
  const getApprover = async () => {
    try {
      const result = await getRequest("WorkflowRoles/GetRoles");
      let listData = [
        {label:"Reporting Manager",value:"Reporting Manager"},
        {label:"Functional HOD",value:"Functional HOD"}
      ];
      result?.data?.map((item) => {
        return listData?.push({ label: item?.name, value: item?.name });
      });
      setApprover(listData);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getApprover();
  }, []);
  const x = val.find((item) => item.value === "Assign to IT Helpdesk");
  const setValueHandler = (index, value) => {
    const tempindex = val.findIndex((item) => item.tab === index);
    if (tempindex !== -1) {
      let emptarr = val;
      emptarr[tempindex] = { tab: index, value: value };

      setVal(emptarr);
    } else {
      setVal((prev) => [...prev, { tab: index, value: value }]);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Workflow Steps`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      {" "}
      <div>
        <FieldArray name="items">
          {(fieldArrayProps) => {
            const { insert, remove, form } = fieldArrayProps;
            const { values, setFieldValue } = form;
            const { items } = values;

            return (
              <div>
                {items.map((items, index) => {
                  return (
                    <div key={index}>
                      <div className="headingForm"> Action {index + 1}</div>
                      <div
                        style={{
                          border: "0.75px solid #1296B0",
                          padding: "15px 140px 7px 5px",
                          margin: "13px",
                          minHeight: "230px",
                        }}
                      >
                        <div className={"formcustomS"}>
                          <div>
                            <LabelCustom
                              labelName={"Action Name"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              placeholder={"  Enter Action Name "}
                              name={`items[${index}].name`}
                              maxLength={50}
                            />
                          </div>
                          <div>
                            <LabelCustom
                              labelName={
                                "After the workflow is triggered, then "
                              }
                              mandatory={true}
                            />
                            <SelectFormError
                              value={optionSer}
                              name={`items[${index}].actionType`}
                              onChange={(e) => {
                                setFieldValue(
                                  `items[${index}].actionType`,
                                  e[0]?.value
                                );

                                setValueHandler(index, e[0]?.value);
                              }}
                              values={
                                items
                                  ? optionSer.filter(
                                      (options) =>
                                        options?.label === items?.actionType
                                    )
                                  : opt
                              }
                              options={optionSer}
                            />
                          </div>
                        </div>
                        {val.map((item) => {
                          return (
                            <>
                              {item?.tab === index &&
                              item?.value === "Initiate an Approval" ? (
                                <>
                                  <div className="formcustomS">
                                    <div>
                                      <LabelCustom
                                        labelName={"Select Approver"}
                                        mandatory={true}
                                      />
                                      <SelectForm
                                        values={
                                          items
                                            ? approver.filter(
                                                (options) =>
                                                  options?.label ===
                                                  items?.approver
                                              )
                                            : opt
                                        }
                                        name={`items[${index}].approver`}
                                        options={approver}
                                        placeholder={" Select Approver "}
                                      />
                                    </div>
                                    <div>
                                      <LabelCustom
                                        labelName={"Add Viewer(s)"}
                                      />
                                      <InputCustomFormik
                                        maxLength={50}
                                        name={`items[${index}].viewers`}
                                        placeholder={"  Enter Viewer(s)  "}
                                      />
                                    </div>
                                  </div>
                                  <div className="headingForm">OUTCOMES</div>
                                  <div className="formcustomS">
                                    <div>
                                      <LabelCustom
                                        labelName={"If approved, then"}
                                        mandatory={true}
                                      />
                                      <SelectForm
                                        name={`items[${index}].ifApproved`}
                                        options={optionApp}
                                        placeholder={"  Select Action  "}
                                        values={
                                          items
                                            ? optionApp.filter(
                                                (options) =>
                                                  options?.label ===
                                                  items?.ifApproved
                                              )
                                            : opt
                                        }
                                      />
                                    </div>
                                    <div>
                                      <LabelCustom
                                        labelName={"If rejected, then "}
                                        mandatory={true}
                                      />
                                      <SelectForm
                                        name={`items[${index}].ifRejected`}
                                        values={
                                          items
                                            ? optionRej.filter(
                                                (options) =>
                                                  options?.label ===
                                                  items?.ifRejected
                                              )
                                            : opt
                                        }
                                        options={optionRej}
                                        placeholder={"   Select Action   "}
                                      />
                                    </div>
                                    <div>
                                      <LabelCustom
                                        labelName={"TAT (In Hrs)"}
                                        mandatory={true}
                                      />
                                      <InputCustomFormik
                                        type="number"
                                        step="0.01"
                                        name={`items[${index}].tat`}
                                        min={1}
                                        max={999}
                                        placeholder={"   Enter TAT   "}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : item?.tab === index &&
                                item?.value === "Send an Email" ? (
                                <div>
                                  <LabelCustom
                                    labelName={"Email"}
                                    mandatory={true}
                                  />
                                  <InputCustomFormik
                                    placeholder={"  Enter Email "}
                                    type="email"
                                    name={`items[${index}].emailViewers`}
                                  />
                                </div>
                              ) : item?.tab === index &&
                                item?.value === "Assign to IT Helpdesk" ? (
                                ""
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: " 0px 28px 0px 0px",
                          justifyContent: " flex-end",
                        }}
                      >
                        {index > 0 && (
                          <div>
                            <button
                              className="remove-btn"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              -
                            </button>
                          </div>
                        )}

                        {x?.tab === index &&
                        x?.value === "Assign to IT Helpdesk" ? (
                          ""
                        ) : (
                          <div>
                            <button
                              className="add-btn"
                              type="button"
                              onClick={() =>
                                insert(index + 1, {
                                  name: "",
                                  actionType: "",
                                  approver: "",
                                  viewers: "",
                                  ifApproved: "",
                                  ifRejected: "",
                                  tat: null,
                                  emailViewers: "",
                                })
                              }
                            >
                              +
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
};
export { WorkflowStepsFormIt };
