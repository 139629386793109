/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import ApproveIcon from "../../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../../Components/Table/TransactionTable";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { ApprovePopup } from "../../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Loader } from "../../../../Components/FormComponent/Loader";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { AddAssignee } from "../../../HRMS/Components/HRDeskComponents/Form/AddAssignee";
import { UpdateStatus } from "../../../HRMS/Components/HRDeskComponents/Form/UpdateStatus";
import { FeedBack } from "../../../HRMS/Components/HRDeskComponents/Form/Feedback";
const ItdeskApproved = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const handleClose = () => {
    setIsOpen(false);
  };
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [optionLocation, setOptionLocation] = React.useState([]);
  const [optionSer, setOptionSer] = React.useState([]);
  const [optionSerCat, setOptionSerCat] = React.useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedNotiList, setSelectedNotiList] = useState([]);
  const [selectedTeamList, setSelectedTeamList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);
  const [optionTeam, setOptionTeam] = React.useState([]);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const [optionNoti, setOptionNoti] = React.useState([]);
  const [selectedSerList, setSelectedSerList] = useState([]);
  const [selectedSerCatList, setSelectedSerCatList] = useState([]);
  const [selectedLocationList, setSelectedLocationList] = useState([]);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);
  const [extraId, setExtraId] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [typeAll, setTypeAll] = useState("");
  const togglePopup = (type, color, id, extraId) => {
    // //console.log("type", type, color, id, extraId);
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setExtraId(extraId);
    setIsOpen((prev) => !prev);
  };

  useEffect(()=>{
    document.title = `PeopleSol - Approved IT Help Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };
  const clickHandlerFunction = (id, type, color, extraId) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "ReOpen"
    ) {
      togglePopup(type, color, id, extraId);
    } else if (type === "ReOpen") PostReopen(id);
    else {
      PostApRejHol(type, id);
    }
  };
  const PostFeedback = async (value) => {
    try {
      setLoading(true);
      const result = await postRequest("ItHelpdesks/TicketFeedback", value);
      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      getListData();
      setLoading(false);
      setIsOpen(false);
    }
  };
  const PostReopen = async (id) => {
    try {
      setLoading(true);
      await postRequest("ItHelpdesks/Reopen", {
        TicketID: id,
        Status: "ReOpen",
      });
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
      getListData();
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Approve" || type === "Cancel" || type === "Release") {
        const result = await postRequest(`ItHelpdesks/${type}?Id=${id}`);
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else if (type === "Mark as Closed") {
        const result = await postRequest(
          `ItHelpdesks/Close?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else {
        const result = await postRequest(
          `ItHelpdesks/${type}?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);

      if (isOpen) setIsOpen(false);
      getAllListData();
    }
  };

  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    status: "Approved",
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getStaticActions = (data) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (
      data.status !== "Cancelled" &&
      data.status !== "Rejected" &&
      data.status !== "Closed" &&
      data.status !== "Resolved-Pending for close"
    ) {
      returnActionArray.push({
        img: <img src={CancelIcon} alt="Cancel" />,
        action: "Cancel Request",
        clickAction: (id) => {
          clickHandlerFunction(id, "Cancel", "red");
        },
      });
    }
    if (data.status === "Resolved-Pending for close") {
      returnActionArray.push(
        {
          img: (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.15"
                width="20"
                height="20"
                rx="4"
                fill="#77A2C7"
              />
              <path
                d="M2.32161 12.4808C3.18191 15.2606 5.49749 17.402 8.37588 17.9919M2 9.25657C2.20236 7.26596 3.13264 5.4217 4.61063 4.08106C6.08861 2.74042 8.00909 1.99885 10 2C14.1648 2 17.59 5.18384 18 9.25657M11.6161 18C14.4864 17.4101 16.794 15.2929 17.6704 12.5212"
                stroke="#77A2C7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.6037 11.9291C6.76589 12.1994 6.96411 12.4517 7.18937 12.677C7.98853 13.4732 9.0707 13.9204 10.1989 13.9204C11.327 13.9204 12.4092 13.4732 13.2083 12.677C13.8546 12.0248 14.2719 11.1807 14.3977 10.2712M6 9.06379C6.12615 8.18077 6.51359 7.33379 7.18937 6.65801C7.98853 5.86172 9.0707 5.41461 10.1989 5.41461C11.327 5.41461 12.4092 5.86172 13.2083 6.65801C13.4426 6.89228 13.6318 7.14457 13.794 7.40587"
                stroke="#77A2C7"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.4325 14.3348V11.929H8.83827M13.9652 5V7.40578H11.5594"
                stroke="#77A2C7"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          action: "ReOpen",
          clickAction: (id) => clickHandlerFunction(id, "ReOpen"),
        },
        {
          img: (
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.15"
                width="20"
                height="20"
                rx="4"
                fill="#5BC4BF"
              />
              <path
                d="M3.97008 14.1343C4.23069 14.5686 4.54923 14.974 4.9112 15.336C6.19536 16.6156 7.93429 17.334 9.7471 17.334C11.5599 17.334 13.2988 16.6156 14.583 15.336C15.6215 14.2881 16.2921 12.9316 16.4942 11.4702M3 9.53003C3.2027 8.11111 3.82529 6.75011 4.9112 5.6642C6.19536 4.38465 7.93429 3.6662 9.7471 3.6662C11.5599 3.6662 13.2988 4.38465 14.583 5.6642C14.9595 6.04065 15.2635 6.44605 15.5241 6.86594"
                stroke="#5BC4BF"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.69501 18V14.1342H7.56084M15.7993 3V6.86583H11.9334"
                stroke="#5BC4BF"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10.5L9.2 12L12 9"
                stroke="#5BC4BF"
                strokeLinecap="round"
              />
            </svg>
          ),
          action: "Close",
          clickAction: (id) => clickHandlerFunction(id, "Resolved"),
        }
      );
    }
    return returnActionArray;
  };

  const getLocation = async () => {
    try {
      const result = await getRequest("Area/GetAreas");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
        return null;
      });
      setOptionLocation(listData);
    } catch (error) {
    } finally {
    }
  };

  const getEmployee = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFilterNoti = async () => {
    try {
      const result = await getRequest("ItsmWorkflows/GetITServiceCatalog");
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.categoryName, value: item.id });
        return null;
      });
      setOptionNoti(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFilterService = async () => {
    try {
      const result = await getRequest(
        "ItsmRequestCategories/GetTicketCategories"
      );
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
        return null;
      });
      setOptionSerCat(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFilterServiceCat = async () => {
    try {
      const result = await getRequest(
        "ItsmRequestCategories/GetItsmRequestCategories"
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
        return null;
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
        status: "Approved",
      };
      const result = await postRequest(
        "ItHelpdesks/MyApprovalList",
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getCheckStatus = (data) => {
    if (
      data.status !== "Cancelled" &&
      data.status !== "Rejected" &&
      data.status !== "Closed"
    ) {
      return true;
    }
    return false;
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("ItHelpdesks/MyList", reqData);
      if (result.data) {
        let tempData = [];
        result.data.data.map((item) =>
          tempData.push({
            ...item,
            actions: getStaticActions(item),
            checkActive: getCheckStatus(item),
          })
        );
        setOriginalData(tempData);
        const clippedArray = result.data.data.map(
          ({
            employeeName,
            attachment,
            department,

            designation,
            employeeID,
            employeeNames,

            employeePersonNumber,

            hasAllApproved,

            hasAnyRejected,

            isPending,

            lastUpdatedOn,

            locationID,

            officalmail,

            phone,

            priority,

            reportingOfficer,
            reportingOfficerName,
            requestCategory,
            requestCategoryId,
            serviceCategoryID,
            serviceid,
            tatlastCheckedOn,
            teamId,
            statusLabel,
            statusLabel1,
            ticketTypeID,
            assigineeId,
            typeId,
            extention,
            hrHelpdeskTicketTatInformations,
            ...rest
          }) => ({ ...rest })
        );

        const finalArray = clippedArray.map(
          ({
            id,
            ticketNumber,
            status,
            serviceCategory,
            description,
            serviceType,
            assgineName,
            location,

            ...rest
          }) => ({
            id,
            ticketNumber,
            status,
            serviceCategory,
            description,
            serviceType,
            assgineName,
            location,

            ...rest,
          })
        );
        setTbodyData(finalArray);
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.count);
      calculateTotalPages(result.data.summary.count);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostAddAssignee = async (value) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("ItHelpdesks/Update", value);
      // //console.log("result",result?.data?.message)
      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data,
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isOpen) setIsOpen(false);
    }
  };
  const handleApprove = async (selectedRows, typeAll) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        return tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.actions[1]?.id
        );
      });
      const result = await deleteWithPayloadRequest(
        `ItHelpdesks/${typeAll}`,
        tempIds
      );
      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getAllListData();
    }
  };
  const rejectAll = async (typeAll, selectedRows, remarks) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        return tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.actions[1]?.id
        );
      });
      const result = await deleteWithPayloadRequest(
        `ItHelpdesks/${typeAll}?Remarks=${remarks}`,
        tempIds
      );
      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);

      getAllListData();
    }
  };

  const getActions = (actionList) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
            id: action?.id,
          });
        }
        if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Reject", "red"),
            id: action?.id,
          });
        }
        if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
            id: action?.id,
          });
        }
        return null;
      });
    }
    return returnActionArray;
  };
  const getMoreActions = (actionList, item) => {
    // //console.log("action", item?.item.typeId);
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () => {
              clickHandlerFunction(
                action?.id,
                action?.type,
                action?.color,
                item?.item.typeId
              );
            },
          });
        }
        return null;
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAllListCheckStatus = (data) => {
    let returnValue = false;
    data?.actions?.map((item) => {
      if (item.type === "Approve" || item.type === "Reject") returnValue = true;
      return null;
    });
    return returnValue;
  };
  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("ItHelpDesks/MyApprovalList", reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              assignedTo: item?.item?.assgineName,
              requestNumber: item?.item?.ticketNumber,
              status: item?.status.replace(/<[^>]+>/g, ""),
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions),
              moreActions: getMoreActions(item?.actions, item),
            })
          );
          setOriginalData(tempData);
          const finalArray = tempData.map(
            ({
              id,
              requestNumber,
              employeeName,
              status,
              serviceCategory,
              serviceType,
              description,
              area,
              TAT,
              assignedTo,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              employeeName,
              status,
              serviceCategory,
              serviceType,
              description,
              area,
              TAT,
              assignedTo,
              createdOn,
              lastUpdatedOn,
            })
          );

          setTbodyData(finalArray);
          setCount(result.data.summary.approvedCount);
          calculateTotalPages(result.data.summary.approvedCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("ithelpdesk"))
      getAllListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getFormDetails = (id) => {
    navigate(`/itsm/itdeskticket/details/${id}`);
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      if (heading === "requestNumber") heading = "ticketNumber";
      else if (heading === "assignedTo") heading = "assgineName";
if (heading === "location") heading = "area";
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      ticketType: selectedNotiList[0]?.value,
      requestCategory: selectedSerList[0]?.value,
      serviceCategory: selectedSerCatList[0]?.value,
      department: selectedDepartmentList[0]?.label,
      teamId: selectedTeamList[0]?.value,
      employee:selectedEmployeeList[0].label,
      location: selectedLocationList[0]?.value,
      status: selectedStatusList[0]?.value,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      ticketType: null,
      employee: null,
      requestCategory: null,
      serviceCategory: null,
      location: null,
      status: "Approved",
      teamId: null,
      department: null,
    });
    setFilterSearchToogle((prev) => !prev);
    setSelectedNotiList([]);
    setSelectedSerList([]);
    setSelectedSerCatList([]);
    setSelectedLocationList([]);
    setSelectedStatusList([]);
    setSelectedDepartmentList([]);
    setSelectedTeamList([]);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      // eslint-disable-next-line array-callback-return
      tbodyData?.map((item) => {
        if (
          originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
            .actions?.length > 1
        )
          arr.push(item.Id ?? item.id);
      });
      setSelectedRows(arr);
    }
  };
  return  (
    <div className="fullTableContainer ">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton onClick={() => setFilterSearchToogle((prev) => !prev)}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Request Category </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getFilterServiceCat();
                }}
                options={optionSer}
                values={selectedSerList}
                onChange={(value) => {
                  setSelectedSerList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p> Service Type </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getFilterNoti();
                }}
                options={optionNoti}
                values={selectedNotiList}
                onChange={(value) => {
                  setSelectedNotiList(value);
                }}
              />
            </div>
            <>
              <div className="filterContainer">
                <p> Employee </p>
                <SelectCustom
                  onDropdownOpen={() => {
                    getEmployee();
                  }}
                  options={optionEmployee}
                  values={selectedEmployeeList}
                  onChange={(value) => {
                    setSelectedEmployeeList(value);
                  }}
                />
              </div>
            </>

            <div className="filterContainer">
              <p> Service Category </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getFilterService();
                }}
                options={optionSerCat}
                values={selectedSerCatList}
                onChange={(value) => {
                  setSelectedSerCatList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p> Location </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getLocation();
                }}
                options={optionLocation}
                values={selectedLocationList}
                onChange={(value) => {
                  setSelectedLocationList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>

      <div className="tableHeaderContainer">
        {selectedRows.length > 0 && (
          <>
            <>
              <button
                className="button primaryButton"
                onClick={() => togglePopupapprove("ApproveAll")}
              >
                <span className="deletebtnflex">
                  <p>Approve</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button>
              <button
                className="deletebtn"
                onClick={() => togglePopupapprove("RejectAll")}
              >
                <span className="deletebtnflex">
                  <p>Reject</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button>
            </>
          </>
        )}
        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>

        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <TransactionTable
              selectAll={selectAll}
              tableFilterName="ItDeskTeamListFilter"
              tbodyData={tbodyData}
              headerAction={sortUpdated}
              sortParam={sortParam}
              sortType={sortType}
              selectedRows={selectedRows}
              addToSelectedRows={addToSelectedRows}
              originalData={originalData}
              containStatus={true}
              containTat={true}
            />
          </>
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>

      {isOpen &&
        (loading ? (
          ""
        ) : (
          <Popup
            popupHeading={
              typeAction === "Resolved"
                ? "Ticket Feedback"
                : typeAction === "Problem"
                ? "Raise Problem Request"
                : typeAction
            }
            content={
              <>
                {typeAction === "Add Assignee" ||
                typeAction === "Update Assignee" ? (
                  <AddAssignee
                    loading={loading}
                    data={PostAddAssignee}
                    type={typeAction}
                    handleClose={togglePopup}
                    id={selId}
                    typeId={extraId}
                    color={colorAction}
                  />
                ) : typeAction === "Update Status" ? (
                  <UpdateStatus
                    data={PostAddAssignee}
                    type={typeAction}
                    id={selId}
                    prestatus={extraId}
                    assId={colorAction}
                  />
                ) : typeAction === "Resolved" ? (
                  <FeedBack
                    data={PostFeedback}
                    type={typeAction}
                    handleClose={handleClose}
                    id={selId}
                  />
                ) : (
                  <Remark
                    data={PostApRejHol}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                )}
              </>
            }
            handleClose={handleClose}
          />
        ))}
      {isApprovePopup && (
        <ApprovePopup
          popupHeading={typeAll}
          selectedRows={selectedRows}
          type={typeAll}
          content={
            <div style={{ display: "flex" }}>
              {typeAll === "ApproveAll" ? (
                <>
                  <button
                    className="button"
                    onClick={() => {
                      handleApprove(selectedRows, typeAll);
                    }}
                  >
                    Confirm
                  </button>
                  <button className="button">Cancel</button>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <Remark
                    data={rejectAll}
                    type={typeAll}
                    id={selectedRows}
                    color={"red"}
                    handleClose={togglePopupapprove}
                  />
                </div>
              )}
            </div>
          }
          handleClose={togglePopupapprove}
        />
      )}
    </div>
  );
};
export { ItdeskApproved };
