import React from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import  ReactQuill  from  "react-quill";
import  "react-quill/dist/quill.snow.css";
import './quill.css'
import { useEffect } from "react";
export const OnboardingSettingForm = ({ data, setValue }) => {
  // const [val, setVal] = useState("");

  const  modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
        ["direction"]
    ],
};
useEffect(() => {
  document.title = `PeopleSol - Onboarding Settings`;
  return () => {
    document.title = 'PeopleSol';
  };
}, []);
  return (
    <>
      <div>
        <LabelCustom labelName={"Employee Onboarding Content"} />
        <ReactQuill defaultValue={data?.obInductionContent}  modules={modules} theme="snow" placeholder="Content goes here..." onChange={setValue} />
      </div>
      <div
        className="formcustom"
        style={{ gridTemplateColumns: "repeat(2,1fr)" }}
      >
        <div>
          <LabelCustom
            labelName={"Minimum time required to close the Induction Window"}
          />
          <InputCustomFormik
            step=".001"
            name="ObInductionMinTime"
            placeholder={"Enter Name "}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Minimum time required to close the Policies Window"}
          />
          <InputCustomFormik
            step=".001"
            name="ObPoliciesMinTime"
            placeholder={"Enter Name "}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Minimum time required to close the Asset Form Window"}
          />
          <InputCustomFormik
            step=".001"
            name="ObAssetFormMinTime"
            placeholder={"Enter Name "}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Minimum time required to close the Feedback Window"}
          />
          <InputCustomFormik
            step=".001"
            name="ObFeedbackMinTime"
            placeholder={"Enter Name "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Support Email Id"} />
          <InputCustomFormik
            type={"email"}
            name="SupportMail"
            placeholder={"Enter Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Terms & Conditions "} />
          <FileUploadFormik
            name="Upload"
          />
        </div>
      </div>
    </>
  );
};
