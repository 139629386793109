import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { CreateITSMSettingForm } from "./Form/CreateITSMSettingForm";
import { Form, Formik } from "formik";
import { itsetchema } from "./Schema";
import { useState } from "react";
import { useEffect } from "react";

const ITSMSettings = (props) => {
  const [formList, setFormList] = useState({});
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const dispatch = useDispatch();

  const getItSetingDetails = async () => {
    try {
      const result = await postRequest("ItsmSettings/GetDetails");
      setFormList(result.data);
    } catch (error) {}
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("ItsmSettings/Update", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - ITSM Settings`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const initialvalue = {
    itsmIvrserviceType: formList?.itsmIvrserviceType,
    itsmChangeApproverEmployeeId: formList?.itsmChangeApproverEmployeeId,
    itsmProblemManagerEmployeeId: formList?.itsmProblemManagerEmployeeId,
    itsmMaximumTicketThreshold: formList?.itsmMaximumTicketThreshold,
  };

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data?.Id,
    };
    Edit(edit);
    returnPage();
  };
  useEffect(() => {
    getItSetingDetails();
  }, []);

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("settings/itsettings");
  };
  return (
    <div style={{ background: "#FAF9F8" }}>
      <Formik
        enableReinitialize={true}
        initialValues={initialvalue}
        onSubmit={onSubmit}
        validationSchema={itsetchema}
      >
        {(formik, values) => {
          return (
            <Form
              style={{
                padding: " 15px 15px 0 15px ",
                position: "relative",
                height: "100%",
                flexGrow: 1,
              }}
            >
              <CreateITSMSettingForm
                data={formList}
                editID={formList ? true : false}
              />
              <div className="createFootCust">
                <div>
                  <button
                    style={{ padding: " 8px 24px" }}
                    className="btnTime"
                    onClick={getItSetingDetails}
                    type="reset"
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button className="button primaryButton" type="submit">
                    Update
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { ITSMSettings };
