/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import {
  Navigation,
  SideNavigation,
  Toast,
  CandidateNavigation,
  ConsultantNavigation,
} from "./Components";
import { Routepath } from "./Routepath/Routepath";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin, closePopup, isLoading } from "./Features";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Day30Feedback } from "./Pages/Home/Components/HomeDashboard/FeedbackComp/Day30Feedback";
import { Notifications } from "./Pages";
import { getRequest } from "./Services/axios";
import { setEmployeeData } from "./Features/AuthSlice";
import { ServiceFloat } from "./Pages/Notifications/ServiceFloat";
import { setEmployeeId } from "./Features/employeeSlice";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from "./Components/OutsideClickHandler/OutsideClickHandler";
import { PopupLogout } from "./Components/FormComponent/PopupLogout";

function App() {
  const [hideNav, setHideNav] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { menu } = useSelector((state) => state.menu);
  const { search } = useSelector((state) => state.search);
  const { popup } = useSelector((state) => state.popup ?? false);
  const { noti } = useSelector((state) => state.noti ?? false);
  const { service } = useSelector((state) => state.service ?? false);
  const { userLogin, candidateLogin, consultantLogin } = useSelector(
    (state) => state.auth
  );
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  const dispatch = useDispatch();
  dispatch(checkLogin());
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      location.pathname.toString().includes("/candidateonboarding") ||
      location.pathname.toString().includes("/previewcandidateonboarding") ||
      location.pathname.toString().includes("/employeeonboarding") ||
      location.pathname.toString().includes("/onboardingcompleted") ||
      location.pathname.toString().includes("/previewonboardingcompleted") ||
      location.pathname.toString().includes("/adminonboarding") ||
      location.pathname.toString().includes("/resetpassword") ||
      location.pathname.toString().includes("/feedback/form")
    ) {
      setHideNav((prev) => {
        if (!prev) {
          return true;
        } else {
          return prev;
        }
      });
    } else {
      setHideNav((prev) => {
        if (!prev) {
          return prev;
        } else {
          return false;
        }
      });
    }
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") === "emp"
    ) {
      if (LoggedInUser) {
        if (LoggedInUser?.shouldRestPassword)
          return navigate("/resetpassword", { replace: true });
        else if (
          LoggedInUser?.hasOnBoardingCompleted === false ||
          LoggedInUser?.hasOnBoardingCompleted === null
        )
          return navigate("/employeeonboarding", { replace: true });
      }
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   const preventDrag = (e) => {
  //     if (e.target.nodeName === "IMG") {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("dragstart", preventDrag, false);

  //   return () => {
  //     document.removeEventListener("dragstart", preventDrag, false);
  //   };
  // }, []);

  // the above useEffect code is to prevent dragging of images to a different chrome tab .. activate the code when required
  function redirectToLogin() {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    window.location.href = "/login";
  }
  const getEmployeeData = async () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userType") === "emp"
    ) {
      dispatch(isLoading(true));
      try {
        const result = await getRequest("Employees/GetEmployeedata");
        if (result) {
          dispatch(setEmployeeData(result?.data?.data));
          dispatch(setEmployeeId(result?.data?.data?.id));
        }
      } catch (error) {
      } finally {
        dispatch(isLoading(false));
      }
    }
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    getEmployeeData();
  }, []);

  if(!isOnline) return  confirmAlert({
      customUI: () => {
        return (
          <div className="overlay">
            <OutsideClickHandler onOutsideClick={redirectToLogin} >
              <PopupLogout isOnline={isOnline}/>
            </OutsideClickHandler>
          </div>
        );
      },
    });

  else return (
    <main
      className={`App ${
        ((!userLogin && !candidateLogin && !consultantLogin) || hideNav) &&
        "App-hide"
      } ${theme === "light" ? "App-light" : "App-dark"}`}
    >
      {popup === true ? <Day30Feedback /> : null}
      {userLogin && !hideNav && (
        <nav
          className={`nav ${
            (search || popup === true) && "searchActiveContainer"
          }`}
          onClick={() => {
            if (popup === true) dispatch(closePopup());
          }}
        >
          <Navigation />
        </nav>
      )}
      {candidateLogin && (
        <nav className="nav">
          <CandidateNavigation />
        </nav>
      )}
      {consultantLogin && (
        <nav className="nav">
          <ConsultantNavigation />
        </nav>
      )}
      {userLogin && (
        <aside className={`aside ${!menu && "menuHide"}`}>
          <SideNavigation />

          {/* <BottomNav /> */}
        </aside>
      )}
      {userLogin && (
        <aside className={`noti ${!noti && "menuHide"}`}>
          <Notifications />
        </aside>
      )}
      {userLogin && (
        <aside className={`serviceFloat ${!service && "menuHide"}`}>
          <ServiceFloat navigate={navigate} />
        </aside>
      )}
      <section className="body">
        <Routepath />
      </section>
      <Toast />
    </main>
  );
}

export default App;
