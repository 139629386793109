import React, { useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
// import CloseIcon from "../../../../Assets/CloseIcon.svg";

import { TextAreaCustom } from "../../../../Components/FormComponent/TextAreaCustom";
import { IconButton } from "@mui/material";
import VideoThumbnailGenerator from "./VideoThumbnailGenerator";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";

const DocumentPreviewContainer = ({
  selectedFiles,
  handleDocsSelect,
  sendMediaMessage,
  selectedFileType,
}) => {
  const dispatch = useDispatch();

  const [arrSelectedFiles, setarrSelectedFiles] = useState([...selectedFiles]);
  // const [arrMsgFiles, setarrMsgFiles] = useState([]);
  const [arrMsgFilesSingle, setArrMsgFilesSingle] = useState();
  const [active, setActive] = useState({ index: 0, item: selectedFiles[0] });
  const [videoFileData, setVideoFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningLength, setWarningLength] = useState(false);

  useEffect(() => {
    // const msgArray = Array.isArray([...selectedFiles])
    //   ? new Array(selectedFiles.length).fill("")
    //   : [];

    // // console.log("object msgArray", msgArray);
    // setarrMsgFiles(msgArray);
    makeActive(`selectedIndex${0}`, arrSelectedFiles, 0);
  }, []);
  const makeActive = (id, arr, index) => {
    const element = document.getElementById(id);
    if (!element?.classList?.contains("activeSerlectedFile")) {
      removeActive(arr, index);
      element?.classList?.add("activeSerlectedFile");
    }
  };
  const removeActive = (arr, index) => {
    arr?.forEach((_, ind) => {
      if (ind !== index) {
        const element = document.getElementById(`selectedIndex${ind}`);

        if (element?.classList?.contains("activeSerlectedFile")) {
          element?.classList?.remove("activeSerlectedFile");
        }
      }
    });
  };
  // Function to handle input change
  const handleChange = (event) => {
    setArrMsgFilesSingle(event.target.value);
    // setarrMsgFiles((prev) => {
    //   const newArr = [...prev];
    //   newArr[index] = event.target.value;
    //   return newArr;
    // });
  };
  const addImages = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    if (selectedFileType === "document") {
      fileInput.accept =
        "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (selectedFileType === "photo") {
      fileInput.accept =
        "image/jpg,image/jpeg,image/png,image/svg, video/mp4 , video/ogv ,video/webm";
    }
    fileInput.multiple = true; // Enable multiple file selection

    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files;
      // Handle file selection logic here
      console.log("Selected file:", selected);
      setarrSelectedFiles((prev) => [...prev, ...selected]);
      // const arraySelectedMsg = Array.isArray([...selected])
      //   ? new Array(selected.length).fill("")
      //   : [];
      // setarrMsgFiles((prev) => [...prev, ...arraySelectedMsg]);
    });
    fileInput.click();
  };
  const sizeConversion = (size) => {
    if (size / (1024 * 1024) > 1) {
      return `${(size / (1024 * 1024))?.toFixed(2)} MB`;
    } else if (size / 1024 > 1) {
      return `${(size / 1024)?.toFixed(2)} KB`;
    } else {
      return `${size?.toFixed(2)} B`;
    }
  };
  console.log("object active", active);
  console.log("object selectedFiles", selectedFiles);
  console.log("object arrSelectedFiles", arrSelectedFiles);
  // console.log("object arrMsgFiles", arrMsgFiles);
  console.log("object videoFileData", videoFileData);
  console.log("object  videoFileData.flat()", videoFileData.flat());
  useEffect(() => {
    if (videoFileData?.length > 0) {
      if (selectedFileType === "document") {
        videoFileData.flat().forEach((item) => {
          sendMediaMessage([item], arrMsgFilesSingle, selectedFileType);
        });
      } else {
        sendMediaMessage(videoFileData, arrMsgFilesSingle, selectedFileType);
      }
      handleDocsSelect(false);
    }
  }, [videoFileData]);

  const checkSize = () => {
    try {
      if (arrSelectedFiles?.length > 8) {
        setWarningLength(true);
        return false;
      }
      const totalSize = arrSelectedFiles?.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.size,
        0
      );
      if (totalSize >= 104857600) {
        setWarning(true);
        return false;
      } else return true;
    } catch (error) {
    } finally {
    }
  };
  const handleFileUpoad = async () => {
    try {
      if (checkSize()) {
        if (selectedFileType === "document") {
          setLoading(true);
          const results = []; // Array to store responses in order

          // Use for...of loop to handle async operations in sequence
          for (const item of arrSelectedFiles) {
            const formData = new FormData();
            formData.append("files", item);

            // Perform the API request
            const response = await fetch(
              "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
              {
                method: "POST",
                body: formData,
              }
            );

            // Await the JSON response and store it
            const responseData = await response.json();
            results.push(responseData.data); // Add the received data to the results array
          }

          // Update state with all collected data
          setVideoFileData(results);
          setLoading(false);
        } else {
          setLoading(true);
          const formData = new FormData();
          arrSelectedFiles?.forEach((item) => formData.append("files", item));
          const response = await fetch(
            "https://media.solunxt.com/api/v1/Storage/Save?token=5b4cbd69-2366-4708-871c-5300a4dc6f18",
            {
              method: "POST",
              // headers: {
              //   "Content-Type": "application/json",
              // },
              body: formData,
            }
          );
          const responseData = await response.json();
          setVideoFileData(responseData?.data);
        }
      } else return;
    } catch (error) {
      dispatch(
        showToast({
          text: error ?? "Some error occurred in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const handleFileDeletion = async () => {
    try {
      const url = videoFileData?.map((item) =>
        item?.url?.split("Chat/")[1]?.replace(/\//g, "\\")
      );
      console.log("object url", url);
      const urlStrings = url.map((url) => `&fileName=${url}`);
      const response = await fetch(
        `https://media.solunxt.com/api/v1/Storage/Delete?token=5b4cbd69-2366-4708-871c-5300a4dc6f18${urlStrings}`,
        {
          method: "DELETE",
          // headers: {
          //   "Content-Type": "application/json",
          // },
        }
      );
      if (response.ok) {
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "File Already Deleted",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function removeItemAtIndex(array, index) {
    if (array?.length < 2) {
      handleDocsSelect(false);
    } else {
      const newArray = array.filter((_, i) => i !== index);

      setarrSelectedFiles(newArray);
      // setarrMsgFiles((prev) => {
      //   let arr = [...prev];
      //   const newArrayMsg = arr.filter((_, i) => i !== index);
      //   return newArrayMsg;
      // });
      if (index === array?.length - 1) {
        setActive({
          index: index - 1,
          item: arrSelectedFiles[index - 1],
        });
      } else {
        setActive({
          index: index,
          item: arrSelectedFiles[index + 1],
        });
      }
    }
  }
  const renderedFiles = useMemo(
    () =>
      arrSelectedFiles?.map((item, index) => (
        <div key={index} className={"eachImageHoverCover"}>
          <div
            key={index}
            onClick={() => {
              setActive({ index: index, item: item });
              makeActive(`selectedIndex${index}`, arrSelectedFiles, index);
            }}
            id={`selectedIndex${index}`}
            className={
              // active?.index === index
              //   ? "activeSerlectedFile eachImageHover"
              //   :
              "eachImageHover"
            }
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              width: "58px",
              height: "58px",
              borderRadius: "3px",
              border: "0.46px solid #BFBFBF",
              backgroundColor: "#FAFAFA",
              margin: "6px 6px 0px 6px",
              flexShrink: "0", // Prevent shrinking
              flexGrow: "0", // Prevent growing
            }}
          >
            <div
              className=""
              style={
                item?.type?.includes("image")
                  ? {
                      backgroundColor: "black",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundImage: `url(${URL.createObjectURL(item)})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }
                  : item.type.includes("video")
                  ? {
                      backgroundColor: "black",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "2px",
                    }
                  : {
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
              }
            >
              {!item.type.includes("image") && !item.type.includes("video") && (
                <svg
                  width="22"
                  height="24"
                  viewBox="0 0 22 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.13598 1.14551H19.2782C20.3565 1.14551 21.315 2.02551 21.315 3.01551V20.9455C21.315 22.1555 20.2367 23.1455 18.9188 23.1455H3.22382C1.90592 23.1455 0.827637 22.1555 0.827637 20.9455V7.1845"
                    fill="#DAF0F3"
                  />
                  <path
                    d="M8.13598 1.14551H19.2782C20.3565 1.14551 21.315 2.02551 21.315 3.01551V20.9455C21.315 22.1555 20.2367 23.1455 18.9188 23.1455H3.22382C1.90592 23.1455 0.827637 22.1555 0.827637 20.9455V7.1845"
                    stroke="#6ABCCC"
                  />
                  <path
                    d="M8.08473 1.14551V7.33188H0.827637L8.08473 1.14551Z"
                    fill="#DAF0F3"
                    stroke="#6ABCCC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
              {item.type.includes("video") && (
                <video
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                  controls={false}
                  controlsList="nodownload nofullscreen"
                  src={URL.createObjectURL(item)}
                ></video>
              )}
            </div>
          </div>
          <div
            className="eachImageHoverDelete cursor"
            onClick={() => {
              removeItemAtIndex(arrSelectedFiles, index);
            }}
          >
            {/* <img
          style={{ height: "100%" }}
          src={DeleteIcon}
          alt=""
        /> */}
            <p style={{ fontSize: "10px" }}>Remove</p>
          </div>
        </div>
      )),
    [arrSelectedFiles]
  );
  const renderedPreview = useMemo(
    () => (
      <div
        style={{
          width: "100%",
          height: "423px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {active?.item?.type?.includes("image") ? (
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            src={URL.createObjectURL(active?.item)}
            alt=""
          />
        ) : active?.item?.type.includes("video") ? (
          <video
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            controls={true}
            controlsList="nodownload nofullscreen"
            src={URL.createObjectURL(active?.item)}
          ></video>
        ) : (
          <div>
            <svg
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              width="115"
              height="122"
              viewBox="0 0 115 122"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_412_9542)">
                <path
                  d="M44.0469 7.14551H94.6934C99.5947 7.14551 103.951 11.1455 103.951 15.6455V97.1455C103.951 102.646 99.0501 107.146 93.0596 107.146H21.7189C15.7284 107.146 10.8271 102.646 10.8271 97.1455V34.5955"
                  fill="white"
                />
                <path
                  d="M44.0469 7.14551H94.6934C99.5947 7.14551 103.951 11.1455 103.951 15.6455V97.1455C103.951 102.646 99.0501 107.146 93.0596 107.146H21.7189C15.7284 107.146 10.8271 102.646 10.8271 97.1455V34.5955"
                  stroke="#1296B0"
                />
                <path
                  d="M43.8139 7.14551V35.2654H10.8271L43.8139 7.14551Z"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_412_9542"
                  x="0.327148"
                  y="0.645508"
                  width="114.124"
                  height="121"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0705882 0 0 0 0 0.588235 0 0 0 0 0.690196 0 0 0 0.2 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_412_9542"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_412_9542"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <div style={{ color: "#6ABCCC", fontSize: "24px" }}>
              No preview available
            </div>
            <div style={{ color: "#6ABCCC", fontSize: "17px" }}>
              {sizeConversion(active?.item?.size)}
            </div>
          </div>
        )}
      </div>
    ),
    [active]
  );

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div className="document-preview-container">
        <div className="cover-chat-document">
          <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <div className="header">
              <div className="file-name">
                {active?.item && active?.item?.name}
              </div>
              <div className="selected-file-icon">
                <CloseIcon
                  onClick={() => {
                    handleDocsSelect(false);
                  }}
                />
              </div>
            </div>
            <div className="preview">{renderedPreview}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <div style={{ width: "637.46px" }}>
              <input
                placeholder="Type your text here..."
                className="input-textarea-chat input-shorttext"
                // value={
                //   arrMsgFiles[active?.index] ? arrMsgFiles[active?.index] : ""
                // }
                // onChange={(e) => {
                //   handleChange(e, active?.index);
                // }}
                value={arrMsgFilesSingle ? arrMsgFilesSingle : ""}
                onChange={(e) => {
                  // handleChange(e, active?.index);
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: "0.5px solid #BFBFBF",
            height: "107px",
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-start",
            paddingLeft: "17px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              width: "calc(100% - 80px)",
              height: "80%",
              // backgroundColor: "blanchedalmond",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  height: "93px",
                  overflowX: "auto",
                  maxWidth: "calc(100% - 60px)",
                }}
              >
                <div
                  className="custom-scrollbar-chat"
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    overflowX: "auto",
                    overflowY: "hidden",
                    width: "100%",
                  }}
                >
                  {renderedFiles}
                </div>
              </div>
              <div
                onClick={addImages}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "58px",
                  height: "58px",
                  borderRadius: "3px",
                  border: "0.46px solid #BFBFBF",
                  backgroundColor: "#FAFAFA",
                  margin: "6px",
                  flexShrink: "0", // Prevent shrinking
                  flexGrow: "0", // Prevent growing
                  // marginTop: "17px",
                }}
              >
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10.8672"
                    y1="0.645508"
                    x2="10.8672"
                    y2="21.6455"
                    stroke="#BFBFBF"
                    stroke-linecap="round"
                  />
                  <line
                    x1="22.0903"
                    y1="10.4219"
                    x2="1.09033"
                    y2="10.4219"
                    stroke="#BFBFBF"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <IconButton
            style={{ marginLeft: "10px", marginTop: "17px" }}
            onClick={() => {
              handleFileUpoad();
            }}
          >
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.2598" cy="21.8857" r="21" fill="#1296B0" />
              <path
                d="M13.5646 15.4038L25.2656 11.5494C30.4967 9.75982 33.3875 12.6507 31.5979 17.8817L27.7435 29.5827C25.128 37.4293 20.8605 37.4293 18.245 29.5827L17.1437 26.1413L13.5646 24.9023C5.71805 22.2868 5.71805 18.0194 13.5646 15.4038Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.2812 25.4538L22.237 20.498"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IconButton>
        </div>
      </div>

      {loading && (
        <div className="document-preview-container-uploading">
          <div
            style={{
              color: "rgb(51, 51, 51)",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "17px",
              textAlign: "center",
              width: "500px",
              backgroundColor: "white",
            }}
          >
            <p>Sending your media...</p>
            <div style={{ marginTop: "0px" }}>
              {" "}
              <Loader />
            </div>
          </div>
        </div>
      )}
      {warningLength && (
        <div className="document-preview-container-uploading">
          <div
            style={{
              color: "rgb(51, 51, 51)",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "17px",
              textAlign: "center",
              width: "500px",
              backgroundColor: "white",
            }}
          >
            <p style={{ textAlign: "end" }}>
              <CloseIcon
                style={{ color: "red", fontSize: "21px", cursor: "pointer" }}
                onClick={() => setWarningLength(false)}
              />
            </p>
            <p>
              You've exceeded the maximum limit of <b>8</b> media files. Please
              reduce the number of media files or send them separately in
              multiple messages.
            </p>
          </div>
        </div>
      )}
      {warning && (
        <div className="document-preview-container-uploading">
          <div
            style={{
              color: "rgb(51, 51, 51)",
              padding: "20px",
              borderRadius: "5px",
              fontSize: "17px",
              textAlign: "center",
              width: "500px",
              backgroundColor: "white",
            }}
          >
            <p style={{ textAlign: "end" }}>
              <CloseIcon
                style={{ color: "red", fontSize: "21px", cursor: "pointer" }}
                onClick={() => setWarning(false)}
              />
            </p>
            <p>
              The total size of the media files you're trying to send exceeds
              the limit of <b>100MB</b> . <br /> Please reduce the size of your
              media files or send them in separate messages.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentPreviewContainer;
