import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import Nodata from "../../../Assets/Nodata.svg";

const AssesmentDetailsLD = ({ detailsResults }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "New", value: "New" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const guestOptions = [
    { label: "Casual", value: "Casual" },
    { label: "Special", value: "Special" },
  ];
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ overflow: "auto" }}>
      {detailsResults?.distinctFeedbacks?.length > 0 ? (
        <table className="AssesmentLDtable">
          <thead className="AssesmentLDtableHead">
            <tr>
              <th className="slnoColumn">Sl no.</th>
              <th className="questionColumn">Questions</th>
              <th className="answerColumn" style={{ textAlign: "center" }}>
                Answers
              </th>
            </tr>
          </thead>
          <tbody>
            {detailsResults &&
              detailsResults?.distinctFeedbacks?.map((item, index) => (
                <tr key={index}>
                  <td className="slnoColumn"> {index + 1}</td>
                  <td className="questionColumn">{item?.questionText}</td>
                  <td className="answerColumn" style={{ textAlign: "center" }}>
                    {item?.isCorrect ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                      >
                        <path
                          d="M1.00464 7.69043L7.00464 13.6904L21.0046 1.69043"
                          stroke="#0DB53C"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M13.5431 1.12988L1.54272 13.1299"
                          stroke="#F71D1D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.54272 1.12988L13.5431 13.1299"
                          stroke="#F71D1D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </div>
  );
};
export { AssesmentDetailsLD };
