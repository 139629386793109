import "./CandidateLanding.css";
import Addonboard from "../../Assets/Addonboard.svg";
import GirlCandidate from "../../Assets/GirlCandidate.svg";
import WelImg from "../../Assets/WelImg.svg";
import rotTraingle from "../../Assets/rotTraingle.svg";
import CandText from "../../Assets/CandText.svg";
import circleCan from "../../Assets/circleCan.svg";
import CanOuter from "../../Assets/CanOuter.svg";
import AddOrange from "../../Assets/AddOrange.svg";
import CirclePink from "../../Assets/CirclePink.svg";
import CircDouble from "../../Assets/CircDouble.svg";
import circlelilPur from "../../Assets/circlelilPur.svg";
import circleLittle from "../../Assets/circleLittle.svg";
import CircTraipur from "../../Assets/CircTraipur.svg";
import TraingSky from "../../Assets/TraingSky.svg";
import OnboardingSidePanelBackground from "../../Assets/OnboardingSidePanelBackground.svg";
import OnboardingSidePanelImg from "../../Assets/OnboardingSidePanelImg.svg";
import OnboardingSidePanelImgActive from "../../Assets/OnboardingSidePanelImgActive.svg";
import OnboardingSidePanelLine from "../../Assets/OnboardingSidePanelLine.svg";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Tab, Tabs } from "@mui/material";
import { CheckboxFormiks } from "../../Components/FormComponent/CheckboxFormiks";
import { getRequest } from "../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../Features";
import {
  AddressDetails,
  BankFinance,
  CandidateDetails,
  EducationDetails,
  ExperienceDetails,
  Identification,
  PersonalDetails,
} from "../HRMS/Components/OnboardingComponents/CandidateFormComp";
import { DragFormik } from "../../Components/FormComponent/Upload/DragFormik";
import { OpenFile } from "../../Utilities";
import { useNavigate } from "react-router-dom";
import UploadImageDetail from "../../Components/UploadDetail/UploadImageDetail";
const CandidateOnboardingLanding = ({ candidateDetals }) => {
  useEffect(() => {
    document.title = `PeopleSol - Preview Candidate Onboarding`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="outerCont">
      <div className="CirclePink">
        <img src={CirclePink} alt="LoginPerson" />
      </div>
      <div className="canOuter">
        <img src={CanOuter} alt="LoginPerson" />
      </div>
      <div className="outerGr">
        <img src={Addonboard} alt="LoginPerson" />
      </div>
      <div className="circleCan">
        <img src={circleCan} alt="LoginPerson" />
      </div>
      <div className="innerCont">
        <div className="TraingSky">
          <img src={TraingSky} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="CircDouble">
          <img src={CircDouble} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circlelilPur">
          <img src={circlelilPur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circleLittle">
          <img src={circleLittle} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="CircTraipur">
          <img src={CircTraipur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="WelImg">
          <img src={WelImg} alt="LoginPerson" />
        </div>
        <div className="flexIn">
          <div className="flexinText">
            <div className="canName">
              <div className="wel">Welcome</div>
              <div className="canUser">Mr Anuj Yadav</div>
            </div>
            <div className="innerCantext">
              {candidateDetals?.candidateOnboardingWelcomeContent}
            </div>
            <div className="orangeCan">
              <img src={AddOrange} alt="LoginPerson" />
            </div>
            <div className="cheersC">
              {
                candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                  "|"
                )?.[0]
              }
              <div className=" teaes">
                {
                  candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                    "|"
                  )?.[1]
                }
              </div>
            </div>
          </div>
          <div className="girlImg">
            <img
              src={GirlCandidate}
              style={{ width: "258.96px", height: "369.06px" }}
              alt="LoginPerson"
            />
          </div>
        </div>
        <div className="btnContt">
          <button type="submit" className="btnCand">
            Let's Get Started
          </button>
          <UploadImageDetail
            filepath={`Company/${candidateDetals?.companyLogo}`}
          />
        </div>
        <div className="candText">
          <img src={CandText} alt="LoginPerson" />
        </div>
      </div>
      <div className="rotTraingle">
        <img src={rotTraingle} alt="LoginPerson" />
      </div>
    </div>
  );
};
const PreviewCandidateOnboarding = () => {
  const [candidateDetals, setCandidateDetails] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const dispatch = useDispatch();
  const getCandidateDetails = async () => {
    try {
      const result = await getRequest("SubmitCandidate/OnboardingPreview");
      if (result?.data?.data) {
        setCandidateDetails(result?.data?.data);
        let tempArray = [];
        if (result?.data?.data?.states) {
          if (result?.data?.data?.states.length > 0) {
            result?.data?.data?.states?.map((item) => {
              return tempArray.push({ label: item, value: item });
            });
          }
        }
        setStateOptions(tempArray);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    getCandidateDetails();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (candidateDetals) {
      let tempObject = {
        currentPermanentAddressSame: true,
        Name: "Anuj",
        Email: "anuj.yadav@gmail.com",
        InitialName: "Mr.",
        PhoneNumber: "9999988888",
        MiddleName: "K",
        AlternateNumber: "8888899999",
        LastName: "Yadav",
        Dob: new Date(),
        Photo: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.photo
          : null,
        Gender: "Male",
        JoiningDoj: new Date(),
        PermanentAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentAddress
          : null,
        PermanentCOname: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentCOname
          : null,
        permanentAddressLine2: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressLine2
          : null,
        permanentAddressPincode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressPinCode
          : null,
        permanentAddressState: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressState
          : null,
        permanentAddressCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressCountry
          : "India",
        permanentCity: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentCity
          : null,
        currentConame: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentCOname
          : null,
        currentAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentAddress
          : null,
        currentAddressLine2: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentAddressLine2
          : null,
        currentPinCode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentPinCode
          : null,
        currentState: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentState
          : null,
        currentAddressCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.currentAddressCountry
          : "India",
        currentCity: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentCity
          : null,
        birthCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.birthCountry
          : null,
        religion: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.religion
          : null,
        state: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.state
          : null,
        nationality: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.nationality
          : null,
        language: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.language
          : null,
        dom: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.dom
            ? new Date(candidateDetals?.candidateOnboarding?.candidate?.dom)
            : null
          : null,
        maritalStatus: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.maritalStatus
          : null,
        highestQualification: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.highestQualification
          : null,
        previousExperience: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.previousExperience
          : null,
        currentSalary: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentSalary
          : null,
        groupHealthInsurance: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.groupHealthInsurance
          : null,
        additionalInfo: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.additionalInfo
          : null,
        skillSet: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.skillSet
          : null,
        offerLetter: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.offerLetter
          : null,
        spouseName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseName
          : null,
        spouseMobileNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseMobileNumber
          : null,
        spouseWorking: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseWorking
          : null,
        occupation: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.occupation
          : null,
        companyName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.companyName
          : null,
        officePhoneNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.officePhoneNumber
          : null,
        officeAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.officeAddress
          : null,
        noOfchildrens: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.noOfchildrens
          : null,
        emergencyContactName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.emergencyContactName
          : null,
        contactPersonRelationship: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonRelationship
          : null,
        emergencyContactNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.emergencyContactNumber
          : null,
        contactPersonPhoneNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonPhoneNumber
          : null,
        contactPersonOfficeNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonOfficeNumber
          : null,
        contactPersonAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonAddress
          : null,
        panNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.panNumber
          : null,
        uannumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.uanNumber
          : null,
        aadhaarNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.aadhaarNumber
          : null,
        pfnumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.pfNumber
          : null,
        passportNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportNumber
          : null,
        passportIssueDate: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportIssueDate
            ? new Date(
                candidateDetals?.candidateOnboarding?.candidate?.passportIssueDate
              )
            : null
          : null,
        passportExpiryDate: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportExpiryDate
            ? new Date(
                candidateDetals?.candidateOnboarding?.candidate?.passportExpiryDate
              )
            : null
          : null,
        bankAccountNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.bankAccountNumber
          : null,
        ifsccode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.ifscCode
          : null,
        branchName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.branchName
          : null,
        accountHolderName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.accountHolderName
          : null,
        typeofAccount: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.typeofAccount
          : null,
        dependentList: getDependentListInitialValue(
          candidateDetals?.candidateOnboarding?.candidate?.dependentslist
        ),
        employeeEducations: getEducationListInitialValue(
          candidateDetals?.candidateOnboarding?.candidate?.educationslist
        ),
        employeeExperiences: getExperienceListInitialValue(
          candidateDetals?.candidateOnboarding?.candidate?.experienceslist
        ),
        agreeCheck: false,
      };
      candidateDetals?.requiredDocument?.map((item) => {
        let name = `document${item?.id}`;
        return (tempObject[name] = item.isMandatory ? "" : null);
      });
      candidateDetals?.requiredForms?.map((item) => {
        let name = `form${item.id}`;
        return (tempObject[name] = item.isMandatory ? "" : null);
      });
      setInitialValues(tempObject);
    }
  }, [candidateDetals]);

  const navigate = useNavigate();
  const titleOptions = [
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const maritalOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Divorced", value: "Divorced" },
    { label: "N/A", value: "N/A" },
  ];
  const educationOptions = [
    { label: "Phd", value: "Phd" },
    { label: "Masters", value: "Masters" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "12th", value: "12th" },
    { label: "10th", value: "10th" },
    {
      label: "Special Course",
      value: "Special Course",
    },
  ];
  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const relationshipOptions = [
    { label: "Husband", value: "Husband" },
    { label: "Wife", value: "Wife" },
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Sibling", value: "Sibling" },
    { label: "Guardian", value: "Guardian" },
    { label: "Son", value: "Son" },
    { label: "Daughter", value: "Daughter" },
    { label: "Others", value: "Others" },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const getExperienceListInitialValue = (data) => {
    let returnArray = [
      {
        occupation: null,
        companyName: null,
        summary: null,
        duration: null,
        currentlyWorkHere: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            occupation: item.occupation,
            companyName: item.company,
            summary: item.summary,
            duration: item.duration,
            currentlyWorkHere: item.currentlyWorkingHere,
          });
        });
      }
    return returnArray;
  };
  const getEducationListInitialValue = (data) => {
    let returnArray = [
      {
        employeeEducations: null,
        degree: null,
        college: null,
        yearofPassingFrom: null,
        yearofPassingTo: null,
        grade: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            employeeEducations: item.educationLevel,
            degree: item.degree,
            college: item.college,
            yearofPassingFrom: item.yearofPassingFromYear,
            yearofPassingTo: item.yearofPassingToYear,
            grade: item.grade,
          });
        });
      }
    return returnArray;
  };
  const getDependentListInitialValue = (data) => {
    let returnArray = [
      {
        name: null,
        permanentAddress: null,
        dob: null,
        relationship: null,
        gender: null,
        martialStatus: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            name: item.name,
            permanentAddress: item.address,
            dob: item.dob ? new Date(item.dob) : null,
            relationship: item.relationship,
            gender: item.gender,
            martialStatus: item.martialStatus,
          });
        });
      }
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const onSubmit = async (values) => {
    navigate("/previewonboardingcompleted", {
      replace: true,
      state: { logo: candidateDetals?.companyLogo },
    });
  };
  return (
    <FormikStepper
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      chooseFormValues={chooseFormValues}
      candidateDetals={candidateDetals}
    >
      <FormikStep label="Greetings">
        <CandidateOnboardingLanding candidateDetals={candidateDetals} />
      </FormikStep>
      <FormikStep label="Learning">
        <div className="homeCardsHolderContainer settingCardHolder">
          <div
            className="homeDashboardCardHolder"
            style={{ height: "fit-content" }}
          >
            {/* <div className="videoCard">
              <video controls style={{ width: "100%" }}>
                <source src={"https://www.youtube.com/watch?v=TBEJVPTXFHY"} />
                Your browser does not support the video tag.
              </video>
              <label>{"Title"}</label>
            </div> */}
            {candidateDetals?.trainingCourseChapters?.map((videoData) => {
              let embedIndex = 0;
              let videoType = "";
              if (videoData?.videoUrl?.includes("youtube"))
                videoType = "youtube";
              if (videoData?.videoUrl?.includes("=")) {
                embedIndex = videoData?.videoUrl?.lastIndexOf("=");
              }
              return (
                <div className="videoCard" key={videoData?.id}>
                  <iframe
                    title={videoData?.title}
                    width="100%"
                    height="100%"
                    src={
                      videoType === "youtube"
                        ? `https://www.youtube.com/embed/${videoData?.videoUrl?.slice(
                            embedIndex + 1
                          )}`
                        : videoData?.videoUrl
                    }
                    frameborder="1"
                    allowfullscreen={true}
                  ></iframe>
                  <label>{videoData?.title}</label>
                </div>
              );
            })}
          </div>
        </div>
      </FormikStep>
      <FormikStep label="Profile">
        <div className="tabContainer">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#1296B0" } }}
          >
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                  Candidate
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                  Personal
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                  Education
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                  Experience
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                  Address
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 5 && "tabActive"}`}>
                  Identification
                </span>
              }
            />
            <Tab
              label={
                <span className={`tabHeading ${tabIndex === 6 && "tabActive"}`}>
                  Payment
                </span>
              }
            />
          </Tabs>
          <div className="tabContainerLine"></div>
        </div>
        {tabIndex === 0 && (
          <div className="onboardingTabBodyContainer">
            <CandidateDetails titleOptions={titleOptions} onboarding={true} />
          </div>
        )}
        {tabIndex === 4 && (
          <div className="onboardingTabBodyContainer">
            <AddressDetails
              stateOptions={stateOptions}
              formValues={formValues}
            />
          </div>
        )}
        {tabIndex === 5 && (
          <div className="onboardingTabBodyContainer">
            <Identification />
          </div>
        )}
        {tabIndex === 6 && (
          <div className="onboardingTabBodyContainer">
            <BankFinance />
          </div>
        )}
        {tabIndex === 1 && (
          <div className="onboardingTabBodyContainer">
            <PersonalDetails
              genderOptions={genderOptions}
              maritalOptions={maritalOptions}
              relationshipOptions={relationshipOptions}
              formValues={formValues}
              dispatch={dispatch}
            />
          </div>
        )}
        {tabIndex === 2 && (
          <div className="onboardingTabBodyContainer">
            <EducationDetails
              educationOptions={educationOptions}
              dispatch={dispatch}
            />
          </div>
        )}
        {tabIndex === 3 && (
          <div className="onboardingTabBodyContainer">
            <ExperienceDetails
              yesNoOptions={yesNoOptions}
              dispatch={dispatch}
            />
          </div>
        )}
      </FormikStep>
      <FormikStep label="Documents">
        <div className="formcustom threeColumn" style={{ width: "100%" }}>
          {candidateDetals?.requiredDocument?.map((item, index) => {
            return (
              <div className="cardContainer" key={index}>
                <div
                  className="headingContainer"
                  style={{
                    color: "var(--primary)",
                    justifyContent: "flex-start",
                  }}
                >
                  {item?.name}
                  <span style={{ color: "red" }}>
                    {item.isMandatory ? "*" : ""}
                  </span>
                </div>
                <DragFormik name={`document${item?.id}`} />
                <div
                  className="uploadDocumentFooter cursor"
                  onClick={() =>
                    OpenFile(
                      "CandidateOnboardingsDocuments",
                      item?.originalFile
                    )
                  }
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="9.03125" cy="9.75293" r="9" fill="#1296B0" />
                    <path
                      d="M9.53125 5.90686C9.53125 5.63072 9.30739 5.40686 9.03125 5.40686C8.75511 5.40686 8.53125 5.63072 8.53125 5.90686L9.53125 5.90686ZM8.6777 13.9508C8.87296 14.1461 9.18954 14.1461 9.3848 13.9508L12.5668 10.7689C12.762 10.5736 12.762 10.257 12.5668 10.0618C12.3715 9.86649 12.0549 9.86649 11.8597 10.0618L9.03125 12.8902L6.20282 10.0618C6.00756 9.86649 5.69098 9.86649 5.49572 10.0618C5.30045 10.257 5.30045 10.5736 5.49572 10.7689L8.6777 13.9508ZM8.53125 5.90686L8.53125 13.5973L9.53125 13.5973L9.53125 5.90686L8.53125 5.90686Z"
                      fill="white"
                    />
                  </svg>
                  <label className="cursor">
                    Click here to download the document format.
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </FormikStep>
      <FormikStep label="Form">
        <div className="formcustom" style={{ width: "100%" }}>
          {candidateDetals?.requiredForms?.map((item, index) => {
            return (
              <div className="cardContainer" key={index}>
                <div
                  className="headingContainer"
                  style={{
                    color: "var(--primary)",
                    justifyContent: "flex-start",
                  }}
                >
                  {item?.title}
                  <span style={{ color: "red" }}>
                    {item.isMandatory ? "*" : ""}
                  </span>
                </div>
                <DragFormik name={`form${item?.id}`} />
              </div>
            );
          })}
        </div>
      </FormikStep>
      <FormikStep
        label="Finish"
        validationSchema={Yup.object({
          agreeCheck: Yup.bool().oneOf(
            [true],
            "Accept Terms & Conditions to proceed further"
          ),
        })}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "24px",
            flexGrow: "1",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h5 style={{ color: "var(--primary)", marginBottom: "6px" }}>
              Candidature Agreement
            </h5>
            <div className="horizontalLine"></div>
          </div>
          <p style={{ marginTop: "24px" }}>
            {candidateDetals?.candidateOnboardingAgreementContent}
          </p>
          <div>
            <p style={{ marginTop: "48px", fontWeight: 500 }}>
              {
                candidateDetals?.candidateOnboardingAgreementSignature?.split(
                  "|"
                )?.[0]
              }
            </p>
            <p
              style={{
                color: "var(--primary)",
                fontWeight: 500,
              }}
            >
              {
                candidateDetals?.candidateOnboardingAgreementSignature?.split(
                  "|"
                )?.[1]
              }
            </p>
          </div>
          <div
            style={{
              width: "fit-content",
              backgroundColor: "var(--form-background)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              paddingRight: "12px",
            }}
          >
            <CheckboxFormiks
              style={{ justifySelf: "end" }}
              name="agreeCheck"
              labelName=""
            />
            <label
              className="cursor"
              style={{ marginBottom: "3px", textDecoration: "underline" }}
              onClick={() =>
                OpenFile("Settings", candidateDetals?.termsAndCondition)
              }
            >
              I agree with the Terms and Conditions.
            </label>
          </div>
        </div>
      </FormikStep>
    </FormikStepper>
  );
};
export function FormikStep({ children }) {
  return <>{children}</>;
}
export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const headerClicked = async (clickedStep) => {
    if (clickedStep < step) {
      setStep(clickedStep);
    }
  };
  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  const backFunction = () => {
    if (step === 2) {
      if (props.tabIndex === 0) setStep((s) => s - 1);
      else props.setTabIndex((prev) => prev - 1);
    } else setStep((s) => s - 1);
  };
  return (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props?.onSubmit(values, helpers);
        } else if (step === 2) {
          if (props.tabIndex === 6) setStep((s) => s + 1);
          else props.setTabIndex((prev) => prev + 1);
        } else {
          setStep((s) => s + 1);
          helpers?.setTouched({});
        }
      }}
    >
      {({ isSubmitting, values }) => {
        props?.chooseFormValues && props?.chooseFormValues(values);
        return (
          <Form style={{ height: "100%" }}>
            {step === 0 ? (
              <>{currentChild}</>
            ) : (
              <div className="outerCont">
                <div className="CirclePink">
                  <img src={CirclePink} alt="LoginPerson" />
                </div>
                <div className="canOuter">
                  <img src={CanOuter} alt="LoginPerson" />
                </div>
                <div className="outerGr">
                  <img src={Addonboard} alt="LoginPerson" />
                </div>
                <div className="circleCan">
                  <img src={circleCan} alt="LoginPerson" />
                </div>
                <div className="onboardingInnerContainer">
                  <div className="onboardingSidePanel">
                    <img
                      style={{
                        width: "100%",
                        bottom: 0,
                        position: "absolute",
                        right: 0,
                      }}
                      src={OnboardingSidePanelBackground}
                      alt="OnboardingSidePanelBackground"
                    />
                    <div className="onboardingSidePanelContainer">
                      <img
                        style={{
                          height: "100%",
                          position: "absolute",
                          left: "22px",
                          zIndex: 1,
                        }}
                        src={OnboardingSidePanelLine}
                        alt="OnboardingSidePanelLine"
                      />
                      {childrenArray.map((child, index) => (
                        <div
                          className={`onboardingSidePanelTile ${
                            index === step && "onboardingSidePanelTileActive"
                          } ${index < step && "cursor"}`}
                          onClick={() => headerClicked(index)}
                          key={child.props.label}
                        >
                          {index === step ? (
                            <img
                              src={OnboardingSidePanelImgActive}
                              alt="OnboardingSidePanelImg"
                            />
                          ) : (
                            <img
                              src={OnboardingSidePanelImg}
                              alt="OnboardingSidePanelImg"
                            />
                          )}
                          <label>{child.props.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="onboardingMainContainer">
                    <div className="onboardingFormContainer">
                      {currentChild}
                    </div>
                    <div className="onboardingFormButtonContainer">
                      <div style={{ display: "flex", gap: "12px" }}>
                        {step > 0 && (
                          <button
                            type="button"
                            className="button backButton noMargin"
                            onClick={backFunction}
                          >
                            {"< Back"}
                          </button>
                        )}
                        <button
                          className="button primaryButton noMargin"
                          type="submit"
                        >
                          {isLastStep() ? "Complete Onboarding " : "Next >"}
                        </button>
                      </div>
                      <UploadImageDetail
                        filepath={`Company/${props.candidateDetals?.companyLogo}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="rotTraingle">
                  <img src={rotTraingle} alt="LoginPerson" />
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
export { PreviewCandidateOnboarding };
