import { useEffect } from "react";
import { useState } from "react";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest } from "../../../../../Services/axios";
import "../change.css";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const change = [
  { label: "Normal", value: "Normal" },
  { label: "Emergency", value: "Emergency" },
  { label: "Standard", value: "Standard" },
];
const ChangeForm = ({ formValues }) => {
  const [optionSer, setOptionSer] = useState([]);
  const getOptionService = async () => {
    try {
      const result = await getRequest("ServiceCategories/GetServiceCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOptionService();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Change`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="changeForm">
      <div style={{ flex: 1 }}>
        <div className="formcustom ">
          <div>
            <LabelCustom labelName={"Change Required in ? "} mandatory={true} />
            <SelectForm
              name="TicketCategoryId"
              options={optionSer}
              placeholder="Select Change Required"
            />
          </div>
          <div>
            <LabelCustom labelName={"Nature of Change"} mandatory={true} />
            <SelectForm
              name="ChangeType"
              options={change}
              placeholder="Select Nature of Change"
            />
          </div>
          <div>
            <LabelCustom
              labelName={"Change Request Priority"}
              mandatory={true}
            />
            <SelectForm
              name="Priority"
              options={option}
              placeholder="Select Priority"
            />
          </div>
          <div>
            <LabelCustom labelName={"Supporting Document "} />
            <FileUploadFormik name="upload" />
            <div
              style={{
                color: "var(--primary)",
                fontSize: "14px",
                marginLeft: "13px",
              }}
            >
              Supporting document must be in pdf,jpeg,png format
            </div>
          </div>
          <div>
            <LabelCustom
              labelName={"Description of Change "}
              mandatory={true}
            />
            <TextAreaFormik
              maxLength={500}
              style={{ height: "100px" }}
              name="DescriptionOfchange"
              placeholder="Enter Description"
            />
          </div>

          <div>
            <LabelCustom
              labelName={"Reason/Justification for Change "}
              mandatory={true}
            />
            <TextAreaFormik
              maxLength={500}
              style={{ height: "100px" }}
              name="Reason"
              placeholder="Enter Reason "
            />
          </div>
        </div>
      </div>
      {formValues?.ChangeType !== "" && (
        <div
          style={{
            flex: 2,
            marginTop: "32px",
            background: "var(--primary-hover)",
            padding: "10px 20px",
            lineHeight: "25px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              fontSize: "17px",
              fontWeight: "600",
              marginBottom: "10px",
              textDecoration: "underline",
            }}
          >
            Nature of Change{" "}
          </p>
          <div>
            <ul>
              <li>
                <strong>Normal Change Type</strong>
                <p>
                  It follows general approval. For ex: New Server Installation ,
                  New Requirement development, etc.
                </p>
              </li>
              <li>
                <strong>Standard Change Type </strong>
                <p>
                  It follows pre approval. For ex: Server Backup, DB backup,
                  etc.
                </p>
              </li>
              <li>
                <strong>Emergency Change Type</strong>
                <p>It needs immediate action For ex: Server Down , etc.</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export { ChangeForm };
