import "./CandidateLanding.css";
import Addonboard from "../../Assets/Addonboard.svg";
import GirlCandidate from "../../Assets/GirlCandidate.svg";
import WelImg from "../../Assets/WelImg.svg";
import rotTraingle from "../../Assets/rotTraingle.svg";
import CandText from "../../Assets/CandText.svg";
import circleCan from "../../Assets/circleCan.svg";
import CanOuter from "../../Assets/CanOuter.svg";
import AddOrange from "../../Assets/AddOrange.svg";
import CirclePink from "../../Assets/CirclePink.svg";
import CircDouble from "../../Assets/CircDouble.svg";
import circlelilPur from "../../Assets/circlelilPur.svg";
import circleLittle from "../../Assets/circleLittle.svg";
import CircTraipur from "../../Assets/CircTraipur.svg";
import TraingSky from "../../Assets/TraingSky.svg";
import OnboardingSidePanelBackground from "../../Assets/OnboardingSidePanelBackground.svg";
import OnboardingSidePanelImg from "../../Assets/OnboardingSidePanelImg.svg";
import OnboardingSidePanelImgActive from "../../Assets/OnboardingSidePanelImgActive.svg";
import OnboardingSidePanelLine from "../../Assets/OnboardingSidePanelLine.svg";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Tab, Tabs } from "@mui/material";
import { CheckboxFormiks } from "../../Components/FormComponent/CheckboxFormiks";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequestForm } from "../../Services/axios";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../Features";
import { openInNewTab } from "../../Utilities/openInNewTab";
import {
  AddressDetails,
  BankFinance,
  CandidateDetails,
  EducationDetails,
  ExperienceDetails,
  Identification,
  PersonalDetails,
} from "../HRMS/Components/OnboardingComponents/CandidateFormComp";
import { DragFormik } from "../../Components/FormComponent/Upload/DragFormik";
import { format } from "date-fns";
import { OpenFile } from "../../Utilities";
import { Loader } from "../../Components/FormComponent/Loader";
import UploadImageDetail from "../../Components/UploadDetail/UploadImageDetail";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const CandidateOnboardingLanding = ({ candidateDetals, loading }) => {
  useEffect(() => {
    document.title = `PeopleSol - Candidate Onboarding Landing`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="outerCont">
      <div className="CirclePink">
        <img src={CirclePink} alt="LoginPerson" />
      </div>
      <div className="canOuter">
        <img src={CanOuter} alt="LoginPerson" />
      </div>
      <div className="outerGr">
        <img src={Addonboard} alt="LoginPerson" />
      </div>
      <div className="circleCan">
        <img src={circleCan} alt="LoginPerson" />
      </div>
      <div className="innerCont">
        <div className="TraingSky">
          <img src={TraingSky} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="CircDouble">
          <img src={CircDouble} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circlelilPur">
          <img src={circlelilPur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="circleLittle">
          <img src={circleLittle} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="CircTraipur">
          <img src={CircTraipur} style={{ width: "90%" }} alt="LoginPerson" />
        </div>
        <div className="WelImg">
          <img src={WelImg} alt="LoginPerson" />
        </div>
        <div className="flexIn">
          <div className="flexinText">
            <div className="canName">
              <div className="wel">Welcome</div>
              <div className="canUser">
                <p>
                  {loading ? (
                    <Skeleton width={300} />
                  ) : (
                    `${
                      candidateDetals?.candidateOnboarding?.candidate
                        ?.initialName ?? ""
                    } ${
                      candidateDetals?.candidateOnboarding?.candidate?.name
                    } ${
                      candidateDetals?.candidateOnboarding?.candidate
                        ?.lastName ?? ""
                    }`
                  )}
                </p>
              </div>
            </div>
            <div className="innerCantext">
              {loading ? (
                <Skeleton count={10} />
              ) : (
                candidateDetals?.candidateOnboardingWelcomeContent
              )}
            </div>
            <div className="orangeCan">
              <img src={AddOrange} alt="LoginPerson" />
            </div>
            <div className="cheersC">
              {loading ? (
                <Skeleton width={100} />
              ) : (
                candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                  "|"
                )?.[0]
              )}
              <div className=" teaes">
                {loading ? (
                  <Skeleton width={100} />
                ) : (
                  candidateDetals?.candidateOnboardingWelcomeSignature?.split(
                    "|"
                  )?.[1]
                )}
              </div>
            </div>
          </div>
          <div className="girlImg">
            <img
              src={GirlCandidate}
              style={{ width: "258.96px", height: "369.06px" }}
              alt="LoginPerson"
            />
          </div>
        </div>
        {!loading && (
          <div className="btnContt">
            <button type="submit" className="btnCand">
              Let's Get Started
            </button>
            <UploadImageDetail
              filepath={`Company/${candidateDetals?.companyCode}/Logo/${candidateDetals?.companyLogo}`}
            />
          </div>
        )}
        <div className="candText">
          <img src={CandText} alt="LoginPerson" />
        </div>
      </div>
      <div className="rotTraingle">
        <img src={rotTraingle} alt="LoginPerson" />
      </div>
    </div>
  );
};
const OnboardingForm = () => {
  const [candidateDetals, setCandidateDetails] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const SUPPORTED_FORMATS_DOC = ["application/pdf", "application/doc"];
  const getCandidateDetails = async (token) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `SubmitCandidate/CandidateOnboardingToken/${token}`
      );
      if (result?.data?.data) {
        setCandidateDetails(result?.data?.data);
        // //console.log("result?.data?.data?.states",result?.data?.data)
        let tempArray = [];
        if (result?.data?.data?.states) {
          if (result?.data?.data?.states.length > 0) {
            result?.data?.data?.states?.map((item) => {
              return tempArray.push({ label: item, value: item });
            });
          }
        }
        setStateOptions(tempArray);
      } else setLinkExpired(result?.data);
    } catch (error) {
      setLinkExpired(
        "Some error occured. Please try again or contact HR for another link."
      );
      dispatch(
        showToast({
          text: error?.data?.response ?? "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location?.search?.toString()?.split("=")?.[1]) {
      const locationPathname = location?.search?.toString()?.split("=")?.[1];
      getCandidateDetails(locationPathname);
    } else setLinkExpired("Not a valid onboarding link.");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (candidateDetals) {
      let tempObject = {
        currentPermanentAddressSame: false,
        Code: candidateDetals ? candidateDetals?.companyCode : "",
        Name: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.name
          : "",
        Email: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.email
          : "",
        InitialName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.initialName
          : null,
        PersonalPhoneNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.personalPhoneNumber
          : null,
        MiddleName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.middleName
          : null,
        AlternateNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.alternateNumber
          : null,
        LastName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.lastName
          : "",
        Dob: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.dob
            ? new Date(candidateDetals?.candidateOnboarding?.candidate?.dob)
            : null
          : null,
        Photo: candidateDetals
          ? `ProfileImageUploads/${candidateDetals?.candidateOnboarding?.candidate?.photo}`
          : null,
        Gender: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.gender
          : null,
        JoiningDoj: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.joiningDOJ
            ? new Date(
                candidateDetals?.candidateOnboarding?.candidate?.joiningDOJ
              )
            : null
          : null,
        PermanentAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentAddress
          : null,
        PermanentCOname: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentCOname
          : null,
        permanentAddressLine2: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressLine2
          : null,
        permanentAddressPincode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressPinCode
          : null,
        permanentAddressState: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressState
          : null,
        permanentAddressCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.permanentAddressCountry
          : "India",
        permanentCity: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.permanentCity
          : null,
        currentConame: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentCOname
          : null,
        currentAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentAddress
          : null,
        currentAddressLine2: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentAddressLine2
          : null,
        currentPinCode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentPinCode
          : null,
        currentState: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentState
          : null,
        currentAddressCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.currentAddressCountry
          : "India",
        currentCity: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentCity
          : null,
        birthCountry: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.birthCountry
          : null,
        religion: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.religion
          : null,
        state: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.state
          : null,
        nationality: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.nationality
          : null,
        language: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.language
          : null,
        dom: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.dom
            ? new Date(candidateDetals?.candidateOnboarding?.candidate?.dom)
            : null
          : null,
        maritalStatus: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.maritalStatus
          : null,
        highestQualification: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.highestQualification
          : null,
        previousExperience: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.previousExperience
          : null,
        currentSalary: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.currentSalary
          : null,
        groupHealthInsurance: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.groupHealthInsurance
          : null,
        additionalInfo: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.additionalInfo
          : null,
        skillSet: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.skillSet
          : null,
        offerLetter: candidateDetals
          ? `OfferLetter/${candidateDetals?.candidateOnboarding?.candidate?.offerLetter}`
          : null,
        spouseName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseName
          : null,
        spouseMobileNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseMobileNumber
          : null,
        spouseWorking: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.spouseWorking
          : null,
        occupation: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.occupation
          : null,
        companyName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.companyName
          : null,
        officePhoneNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.officePhoneNumber
          : null,
        officeAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.officeAddress
          : null,
        noOfchildrens: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.noOfchildrens
          : null,
        emergencyContactName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.emergencyContactName
          : null,
        contactPersonRelationship: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonRelationship
          : null,
        emergencyContactNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.emergencyContactNumber
          : null,
        contactPersonPhoneNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonPhoneNumber
          : null,
        contactPersonOfficeNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonOfficeNumber
          : null,
        contactPersonAddress: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate
              ?.contactPersonAddress
          : null,
        panNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.panNumber
          : null,
        uannumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.uanNumber
          : null,
        aadhaarNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.aadhaarNumber
          : null,
        pfnumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.pfNumber
          : null,
        passportNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportNumber
          : null,
        passportIssueDate: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportIssueDate
            ? new Date(
                candidateDetals?.candidateOnboarding?.candidate?.passportIssueDate
              )
            : null
          : null,
        passportExpiryDate: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.passportExpiryDate
            ? new Date(
                candidateDetals?.candidateOnboarding?.candidate?.passportExpiryDate
              )
            : null
          : null,
        bankAccountNumber: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.bankAccountNumber
          : null,
        ifsccode: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.ifscCode
          : null,
        branchName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.branchName
          : null,
        accountHolderName: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.accountHolderName
          : null,
        typeofAccount: candidateDetals
          ? candidateDetals?.candidateOnboarding?.candidate?.typeofAccount
          : null,
        dependentList: getDependentListInitialValue(
          candidateDetals?.candidateOnboarding?.candidateDependentslist
        ),
        employeeEducations: getEducationListInitialValue(
          candidateDetals?.candidateOnboarding?.candidateEducationslist
        ),
        employeeExperiences: getExperienceListInitialValue(
          candidateDetals?.candidateOnboarding?.candidateExperienceslist
        ),
        agreeCheck: false,
      };
      candidateDetals?.candidateOnboarding?.documents?.map((item) => {
        let name = `document${item?.document?.id}`;
        return (tempObject[name] = item.isMandatory ? "" : null);
      });
      candidateDetals?.candidateOnboarding?.forms?.map((item) => {
        let name = `form${item?.form?.id}`;
        return (tempObject[name] = item.isMandatory ? "" : null);
      });
      let valSchema = {};
      candidateDetals?.candidateOnboarding?.documents?.map((item) => {
        if (item.isMandatory) {
          let name = `document${item?.document?.id}`;
          valSchema[name] = Yup.string().required("Document required");
        }
        return null;
      });
      setdocumentValidationSchema(Yup.object(valSchema));
      valSchema = {};
      candidateDetals?.candidateOnboarding?.forms?.map((item) => {
        if (item.isMandatory) {
          let name = `form${item?.form?.id}`;
          valSchema[name] = Yup.string().required("Form required");
        }
        return null;
      });
      setFormValidationSchema(Yup.object(valSchema));
      setInitialValues(tempObject);
    }
  }, [candidateDetals]);

  const navigate = useNavigate();
  const titleOptions = [
    { label: "Mr.", value: "Mr." },
    { label: "Mrs.", value: "Mrs." },
    { label: "Ms.", value: "Ms." },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const maritalOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Divorced", value: "Divorced" },
    { label: "N/A", value: "N/A" },
  ];
  const educationOptions = [
    { label: "Phd", value: "Phd" },
    { label: "Masters", value: "Masters" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "12th", value: "12th" },
    { label: "10th", value: "10th" },
    {
      label: "Special Course",
      value: "Special Course",
    },
  ];
  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const relationshipOptions = [
    { label: "Husband", value: "Husband" },
    { label: "Wife", value: "Wife" },
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Sibling", value: "Sibling" },
    { label: "Guardian", value: "Guardian" },
    { label: "Son", value: "Son" },
    { label: "Daughter", value: "Daughter" },
    { label: "Others", value: "Others" },
  ];
  const typeofAccountOptions = [
    { label: "Savings", value: "Savings" },
    { label: "Current", value: "Current" },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const getExperienceListInitialValue = (data) => {
    let returnArray = [
      {
        occupation: null,
        companyName: null,
        summary: null,
        duration: null,
        currentlyWorkHere: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            occupation: item.occupation,
            companyName: item.company,
            summary: item.summary,
            duration: item.duration,
            currentlyWorkHere: item.currentlyWorkingHere,
          });
        });
      }
    return returnArray;
  };
  // //console.log("candidateDe",candidateDetals?.companyCode)
  const getEducationListInitialValue = (data) => {
    let returnArray = [
      {
        employeeEducations: null,
        degree: null,
        college: null,
        yearofPassingFrom: null,
        yearofPassingTo: null,
        grade: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            employeeEducations: item.educationLevel,
            degree: item.degree,
            college: item.college,
            yearofPassingFrom: item.yearofPassingFromYear,
            yearofPassingTo: item.yearofPassingToYear,
            grade: item.grade,
          });
        });
      }
    return returnArray;
  };
  const getDependentListInitialValue = (data) => {
    let returnArray = [
      {
        name: null,
        permanentAddress: null,
        dob: null,
        relationship: null,
        gender: null,
        martialStatus: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            name: item.name,
            permanentAddress: item.address,
            dob: item.dob ? new Date(item.dob) : null,
            relationship: item.relationship,
            gender: item.gender,
            martialStatus: item.martialStatus,
          });
        });
      }
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({});
  const [formValues, setFormValues] = useState({});
  const [documentValidationSchema, setdocumentValidationSchema] = useState("");
  const [formValidationSchema, setFormValidationSchema] = useState("");
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const createPageData = async (values, code) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        `SubmitCandidate/CandidateOnboarding?Code=${code}`,
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Onboarding Successful", severity: "success" })
        );
      navigate(`/onboardingcompleted/${candidateDetals?.companyCode}`, {
        replace: true,
        state: { logo: candidateDetals?.companyLogo },
      });
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append(
      "Id",
      candidateDetals ? candidateDetals?.candidateOnboarding?.id : 0
    );
    formData.append(
      "CandidateId",
      candidateDetals ? candidateDetals?.candidateOnboarding?.candidateId : 0
    );
    if (values?.Name) formData.append("Candidate.name", values?.Name);
    if (values?.ManPowerIndentID)
      formData.append("Candidate.ManPowerIndentID", values?.ManPowerIndentID);
    if (values?.Email) formData.append("Candidate.email", values?.Email);
    if (values?.InitialName)
      formData.append("Candidate.InitialName", values?.InitialName);
    if (values?.PhoneNumber)
      formData.append("Candidate.PhoneNumber", values?.PhoneNumber);
    if (values?.MiddleName)
      formData.append("Candidate.MiddleName", values?.MiddleName);
    if (values?.AlternateNumber)
      formData.append("Candidate.AlternateNumber", values?.AlternateNumber);
    if (values?.LastName)
      formData.append("Candidate.lastName", values?.LastName);
    if (values?.Dob)
      formData.append(
        "Candidate.Dob",
        values?.Dob ? `${format(values?.Dob, "MM/dd/yyyy")}` : null
      );
    if (values?.Gender) formData.append("Candidate.Gender", values?.Gender);
    if (values?.JoiningDoj) {
      formData.append(
        "Candidate.JoiningDoj",
        `${format(values?.JoiningDoj, "MM/dd/yyyy")}`
      );
    }
    if (typeof values?.Photo === "object")
      formData.append("PhotoUpload", values?.Photo);
    if (values?.PermanentCOname)
      formData.append("Candidate.PermanentCOname", values?.PermanentCOname);
    if (values?.permanentCity)
      formData.append("Candidate.permanentCity", values?.permanentCity);
    if (values?.PermanentAddress)
      formData.append("Candidate.PermanentAddress", values?.PermanentAddress);
    if (values?.permanentAddressLine2)
      formData.append(
        "Candidate.permanentAddressLine2",
        values?.permanentAddressLine2
      );
    if (values?.permanentAddressPincode)
      formData.append(
        "Candidate.permanentAddressPincode",
        values?.permanentAddressPincode
      );
    if (values?.permanentAddressState)
      formData.append(
        "Candidate.permanentAddressState",
        values?.permanentAddressState
      );
    if (values?.permanentAddressCountry)
      formData.append(
        "Candidate.permanentAddressCountry",
        values?.permanentAddressCountry
      );
    if (values?.currentPermanentAddressSame) {
      if (values?.PermanentCOname)
        formData.append("Candidate.currentConame", values?.PermanentCOname);
      if (values?.permanentCity)
        formData.append("Candidate.currentCity", values?.permanentCity);
      if (values?.PermanentAddress)
        formData.append("Candidate.currentAddress", values?.PermanentAddress);
      if (values?.permanentAddressLine2)
        formData.append(
          "Candidate.currentAddressLine2",
          values?.permanentAddressLine2
        );
      if (values?.permanentAddressPincode)
        formData.append(
          "Candidate.currentPinCode",
          values?.permanentAddressPincode
        );
      if (values?.permanentAddressState)
        formData.append(
          "Candidate.currentState",
          values?.permanentAddressState
        );
      if (values?.permanentAddressCountry)
        formData.append(
          "Candidate.currentAddressCountry",
          values?.permanentAddressCountry
        );
    } else {
      if (values?.currentConame)
        formData.append("Candidate.currentConame", values?.currentConame);
      if (values?.currentCity)
        formData.append("Candidate.currentCity", values?.currentCity);
      if (values?.currentAddress)
        formData.append("Candidate.currentAddress", values?.currentAddress);
      if (values?.currentAddressLine2)
        formData.append(
          "Candidate.currentAddressLine2",
          values?.currentAddressLine2
        );
      if (values?.currentPinCode)
        formData.append("Candidate.currentPinCode", values?.currentPinCode);
      if (values?.currentState)
        formData.append("Candidate.currentState", values?.currentState);
      if (values?.currentAddressCountry)
        formData.append(
          "Candidate.currentAddressCountry",
          values?.currentAddressCountry
        );
    }
    if (values?.birthCountry)
      formData.append("Candidate.birthCountry", values?.birthCountry);
    if (values?.religion)
      formData.append("Candidate.religion", values?.religion);
    if (values?.state) formData.append("Candidate.state", values?.state);
    if (values?.nationality)
      formData.append("Candidate.nationality", values?.nationality);
    if (values?.language)
      formData.append("Candidate.language", values?.language);
    if (values?.dom)
      formData.append("Candidate.dom", `${format(values?.dom, "MM/dd/yyyy")}`);
    if (values?.maritalStatus)
      formData.append("Candidate.maritalStatus", values?.maritalStatus);
    if (values?.highestQualification)
      formData.append(
        "Candidate.highestQualification",
        values?.highestQualification
      );
    if (values?.previousExperience)
      formData.append(
        "Candidate.previousExperience",
        values?.previousExperience
      );
    if (values?.currentSalary)
      formData.append("Candidate.currentSalary", values?.currentSalary);
    if (values?.groupHealthInsurance)
      formData.append(
        "Candidate.groupHealthInsurance",
        values?.groupHealthInsurance
      );
    if (values?.additionalInfo)
      formData.append("Candidate.additionalInfo", values?.additionalInfo);
    if (values?.skillSet)
      formData.append("Candidate.skillSet", values?.skillSet);
    if (typeof values?.offerLetter === "object")
      formData.append("OfferLetterUpload", values?.offerLetter);
    if (values?.spouseName)
      formData.append("Candidate.spouseName", values?.spouseName);
    if (values?.spouseMobileNumber)
      formData.append(
        "Candidate.spouseMobileNumber",
        values?.spouseMobileNumber
      );
    if (values?.spouseWorking)
      formData.append("Candidate.spouseWorking", values?.spouseWorking);
    if (values?.occupation)
      formData.append("Candidate.occupation", values?.occupation);
    if (values?.companyName)
      formData.append("Candidate.companyName", values?.companyName);
    if (values?.officePhoneNumber)
      formData.append("Candidate.officePhoneNumber", values?.officePhoneNumber);
    if (values?.officeAddress)
      formData.append("Candidate.officeAddress", values?.officeAddress);
    if (values?.noOfchildrens)
      formData.append("Candidate.noOfchildrens", values?.noOfchildrens);
    if (values?.emergencyContactName)
      formData.append(
        "Candidate.emergencyContactName",
        values?.emergencyContactName
      );
    if (values?.contactPersonRelationship)
      formData.append(
        "Candidate.contactPersonRelationship",
        values?.contactPersonRelationship
      );
    if (values?.emergencyContactNumber)
      formData.append(
        "Candidate.emergencyContactNumber",
        values?.emergencyContactNumber
      );
    if (values?.contactPersonPhoneNumber)
      formData.append(
        "Candidate.contactPersonPhoneNumber",
        values?.contactPersonPhoneNumber
      );
    if (values?.PersonalPhoneNumber)
      formData.append(
        "Candidate.PersonalPhoneNumber",
        values?.PersonalPhoneNumber
      );
    if (values?.contactPersonOfficeNumber)
      formData.append(
        "Candidate.contactPersonOfficeNumber",
        values?.contactPersonOfficeNumber
      );
    if (values?.contactPersonAddress)
      formData.append(
        "Candidate.contactPersonAddress",
        values?.contactPersonAddress
      );
    if (values?.dependentList.length > 0) {
      values?.dependentList.forEach(function (object, index) {
        if (object.name)
          formData.append(
            `CandidateDependentslist[${index}].Name`,
            object.name
          );
        if (object.permanentAddress)
          formData.append(
            `CandidateDependentslist[${index}].Address`,
            object.permanentAddress
          );
        if (object.dob)
          formData.append(
            `CandidateDependentslist[${index}].Dob`,
            object.dob ? `${format(object.dob, "MM/dd/yyyy")}` : ""
          );
        if (object.relationship)
          formData.append(
            `CandidateDependentslist[${index}].Relationship`,
            object.relationship
          );
        if (object.gender)
          formData.append(
            `CandidateDependentslist[${index}].Gender`,
            object.gender
          );
        if (object.martialStatus)
          formData.append(
            `CandidateDependentslist[${index}].MartialStatus`,
            object.martialStatus
          );
      });
    }
    if (values?.panNumber)
      formData.append("Candidate.panNumber", values?.panNumber);
    if (values?.uannumber)
      formData.append("Candidate.uannumber", values?.uannumber);
    if (values?.aadhaarNumber)
      formData.append("Candidate.aadhaarNumber", values?.aadhaarNumber);
    if (values?.pfnumber)
      formData.append("Candidate.pfnumber", values?.pfnumber);
    if (values?.passportNumber)
      formData.append("Candidate.passportNumber", values?.passportNumber);
    if (values?.passportIssueDate)
      formData.append(
        "Candidate.passportIssueDate",
        `${format(values?.passportIssueDate, "MM/dd/yyyy")}`
      );
    if (values?.passportExpiryDate)
      formData.append(
        "Candidate.passportExpiryDate",
        `${format(values?.passportExpiryDate, "MM/dd/yyyy")}`
      );
    if (values?.bankAccountNumber)
      formData.append("Candidate.bankAccountNumber", values?.bankAccountNumber);
    if (values?.ifsccode)
      formData.append("Candidate.ifsccode", values?.ifsccode);
    if (values?.branchName)
      formData.append("Candidate.branchName", values?.branchName);
    if (values?.accountHolderName)
      formData.append("Candidate.accountHolderName", values?.accountHolderName);
    if (values?.typeofAccount)
      formData.append("Candidate.typeofAccount", values?.typeofAccount);
    if (values?.employeeEducations.length > 0) {
      values?.employeeEducations.forEach(function (object, index) {
        if (object.employeeEducations)
          formData.append(
            `CandidateEducationslist[${index}].EducationLevel`,
            object.employeeEducations
          );
        if (object.degree)
          formData.append(
            `CandidateEducationslist[${index}].Degree`,
            object.degree
          );
        if (object.college)
          formData.append(
            `CandidateEducationslist[${index}].College`,
            object.college
          );
        if (object.yearofPassingFrom)
          formData.append(
            `CandidateEducationslist[${index}].YearofPassingFromYear`,
            object.yearofPassingFrom
          );
        if (object.yearofPassingTo)
          formData.append(
            `CandidateEducationslist[${index}].YearofPassingToYear`,
            object.yearofPassingTo
          );
        if (object.grade)
          formData.append(
            `CandidateEducationslist[${index}].Grade`,
            object.grade
          );
      });
    }
    if (values?.employeeExperiences.length > 0) {
      values?.employeeExperiences.forEach(function (object, index) {
        if (object.occupation)
          formData.append(
            `CandidateExperienceslist[${index}][Occupation]`,
            object.occupation
          );
        if (object.companyName)
          formData.append(
            `CandidateExperienceslist[${index}][Company]`,
            object.companyName
          );
        if (object.summary)
          formData.append(
            `CandidateExperienceslist[${index}][Summary]`,
            object.summary
          );
        if (object.duration)
          formData.append(
            `CandidateExperienceslist[${index}][Duration]`,
            object.duration
          );
        if (object.currentlyWorkHere)
          formData.append(
            `CandidateExperienceslist[${index}][CurrentlyWorkingHere]`,
            object.currentlyWorkHere
          );
      });
    }
    candidateDetals?.candidateOnboarding?.documents?.map((item, index) => {
      let name = `document${item?.document?.id}`;
      formData.append(`Documents[${index}].DocumentId`, item?.document?.id);
      formData.append(`Documents[${index}].documentfile`, values[name]);
      return null;
    });
    candidateDetals?.candidateOnboarding?.forms?.map((item, index) => {
      let name = `form${item?.form?.id}`;
      formData.append(`Forms[${index}].FormId`, item?.form?.id);
      formData.append(`Forms[${index}].formfile`, values[name]);
      return null;
    });
    createPageData(formData, values?.Code);
  };
  // //console.log("formva",formValues)
  if (linkExpired) {
    return <>{linkExpired}</>;
  } else
    return (
      <FormikStepper
        loading={loading}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        initialValues={initialValues}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
        chooseFormValues={chooseFormValues}
        candidateDetals={candidateDetals}
      >
        <FormikStep label="Greetings">
          <CandidateOnboardingLanding
            candidateDetals={candidateDetals}
            loading={loading}
          />
        </FormikStep>
        <FormikStep label="Learning">
          <div className="homeCardsHolderContainer settingCardHolder">
            <div
              className="homeDashboardCardHolder"
              style={{ height: "fit-content" }}
            >
              {/* <div className="videoCard" key={videoData?.id}>
                  <video controls style={{ width: "100%" }}>
                    <source src={videoData?.youTubeVideoUrl} />
                    Your browser does not support the video tag.
                  </video>
                  <label>{videoData?.title}</label>
                </div> */}
              {candidateDetals?.corporateTraningVideos?.map((videoData) => {
                if (videoData?.type !== "Candidate Onboarding") return null;
                let embedIndex = 0;
                let videoType = "";
                if (videoData?.youTubeVideoUrl?.includes("youtube"))
                  videoType = "youtube";
                if (videoData?.youTubeVideoUrl?.includes("=")) {
                  embedIndex = videoData?.youTubeVideoUrl?.lastIndexOf("=");
                }
                if (videoType === "youtube")
                  return (
                    <div className="videoCard" key={videoData?.id}>
                      <iframe
                        title={videoData?.title}
                        width="100%"
                        height="100%"
                        src={
                          videoType === "youtube"
                            ? `https://www.youtube.com/embed/${videoData?.youTubeVideoUrl?.slice(
                                embedIndex + 1
                              )}`
                            : videoData?.youTubeVideoUrl
                        }
                        frameborder="1"
                        allowfullscreen
                      ></iframe>
                      <label>{videoData?.title}</label>
                    </div>
                  );
                else
                  return (
                    <div className="videoCard" key={videoData?.id}>
                      <video controls style={{ width: "100%" }}>
                        <source src={videoData?.youTubeVideoUrl} />
                        Your browser does not support the video tag.
                      </video>
                      <label>{videoData?.title}</label>
                    </div>
                  );
              })}
            </div>
          </div>
        </FormikStep>
        <FormikStep
          label="Profile"
          validationSchema={Yup.object({
            Name: Yup.string().required("Please enter first name"),
            PhoneNumber: Yup.string()
              .min(5, "Mobile Number should be equal to 10 characters")
              .max(15, "Mobile Number should be equal to 10 characters")
              .notRequired("Please Enter Mobile Number"),
            aadhaarNumber: Yup.string()
              .min(12, "Aadhar Number should be equal to 12 characters")
              .max(12, "Aadhar Number should be equal to 12 characters")
              .notRequired(),
            uannumber: Yup.string()
              .min(12, "UAN Number should be equal to 12 characters")
              .max(12, "UAN Number should be equal to 12 characters")
              .nullable()
              .notRequired(),
            panNumber: Yup.string()
              .min(10, "Pan Number should be equal to 10 characters")
              .max(10, "Pan Number should be equal to 10 characters")
              .matches(/^([A-Z,a-z]){5}([0-9]){4}([A-Z,a-z]){1}$/, {
                message: "Please Enter valid PAN",
              })
              .nullable()
              .notRequired(),
            passportNumber: Yup.string()
              .min(8, "Passport Number should be equal to 8 characters")
              .max(8, "Passport Number should be equal to 8 characters")
              .matches(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/, {
                message: "Please Enter valid Passport Number",
              })
              .nullable()
              .notRequired(),
            pfnumber: Yup.string()
              .min(22, "PF Number should be equal to 22 characters")
              .max(22, "PF Number should be equal to 22 characters")
              .nullable()
              .notRequired(),
            bankAccountNumber: Yup.string()

              .max(20, "Account Number must be less than 20 characters")
              .nullable()
              .notRequired(),
            ifsccode: Yup.string()
              .min(11, "IFSC must be equal to 11 characters")
              .max(11, "IFSC must be equal to 11 characters")
              .nullable()
              .notRequired(),
            branchName: Yup.string()
              .max(149, "Bank Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            accountHolderName: Yup.string()

              .max(149, "Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            offerLetter: Yup.mixed()
              .nullable()
              .notRequired()
              .test(
                "Fichier taille",
                "File Size must be less than 2 mb",
                (value) =>
                  !value ||
                  (value && value.size <= 2 * 2048 * 2048) ||
                  typeof value !== "object"
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS_DOC.includes(value.type)) ||
                  typeof value !== "object"
              ),
            Photo: Yup.mixed()
              .nullable()
              .notRequired()
              .test(
                "Fichier taille",
                "File Size must be less than 2 mb",
                (value) =>
                  !value ||
                  (value && value.size <= 2 * 2048 * 2048) ||
                  typeof value !== "object"
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS.includes(value.type)) ||
                  typeof value !== "object"
              ),
          })}
        >
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Candidate
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Personal
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Education
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 3 && "tabActive"}`}
                  >
                    Experience
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 4 && "tabActive"}`}
                  >
                    Address
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 5 && "tabActive"}`}
                  >
                    Identification
                  </span>
                }
              />
              <Tab
                label={
                  <span
                    className={`tabHeading ${tabIndex === 6 && "tabActive"}`}
                  >
                    Payment
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {tabIndex === 0 && (
            <div className="onboardingTabBodyContainer">
              <CandidateDetails
                titleOptions={titleOptions}
                genderOptions={genderOptions}
                onboarding={true}
              />
            </div>
          )}
          {tabIndex === 4 && (
            <div className="onboardingTabBodyContainer">
              <AddressDetails
                stateOptions={stateOptions}
                formValues={formValues}
              />
            </div>
          )}
          {tabIndex === 5 && (
            <div className="onboardingTabBodyContainer">
              <Identification />
            </div>
          )}
          {tabIndex === 6 && (
            <div className="onboardingTabBodyContainer">
              <BankFinance typeofAccountOptions={typeofAccountOptions} />
            </div>
          )}
          {tabIndex === 1 && (
            <div className="onboardingTabBodyContainer">
              <PersonalDetails
                genderOptions={genderOptions}
                maritalOptions={maritalOptions}
                formValues={formValues}
                relationshipOptions={relationshipOptions}
                dispatch={dispatch}
              />
            </div>
          )}
          {tabIndex === 2 && (
            <div className="onboardingTabBodyContainer">
              <EducationDetails
                educationOptions={educationOptions}
                dispatch={dispatch}
              />
            </div>
          )}
          {tabIndex === 3 && (
            <div className="onboardingTabBodyContainer">
              <ExperienceDetails
                yesNoOptions={yesNoOptions}
                dispatch={dispatch}
              />
            </div>
          )}
        </FormikStep>
        <FormikStep
          label="Documents"
          validationSchema={documentValidationSchema}
        >
          <div className="formcustom threeColumn" style={{ width: "100%" }}>
            {candidateDetals?.candidateOnboarding?.documents?.map(
              (item, index) => {
                return (
                  <div className="cardContainer" key={index}>
                    <div
                      className="headingContainer"
                      style={{
                        color: "var(--primary)",
                        justifyContent: "flex-start",
                      }}
                    >
                      {item?.document?.name}
                      <span style={{ color: "red" }}>
                        {item.isMandatory ? "*" : ""}
                      </span>
                    </div>
                    <DragFormik name={`document${item?.document?.id}`} />
                    <div
                      className="uploadDocumentFooter cursor"
                      onClick={() =>
                        OpenFile(
                          "CandidateOnboardingsDocuments",
                          item?.document?.originalFile
                        )
                      }
                    >
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="9.03125"
                          cy="9.75293"
                          r="9"
                          fill="#1296B0"
                        />
                        <path
                          d="M9.53125 5.90686C9.53125 5.63072 9.30739 5.40686 9.03125 5.40686C8.75511 5.40686 8.53125 5.63072 8.53125 5.90686L9.53125 5.90686ZM8.6777 13.9508C8.87296 14.1461 9.18954 14.1461 9.3848 13.9508L12.5668 10.7689C12.762 10.5736 12.762 10.257 12.5668 10.0618C12.3715 9.86649 12.0549 9.86649 11.8597 10.0618L9.03125 12.8902L6.20282 10.0618C6.00756 9.86649 5.69098 9.86649 5.49572 10.0618C5.30045 10.257 5.30045 10.5736 5.49572 10.7689L8.6777 13.9508ZM8.53125 5.90686L8.53125 13.5973L9.53125 13.5973L9.53125 5.90686L8.53125 5.90686Z"
                          fill="white"
                        />
                      </svg>
                      <label>Click here to download the document format.</label>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </FormikStep>
        <FormikStep label="Form" validationSchema={formValidationSchema}>
          <div className="formcustom threeColumn" style={{ width: "100%" }}>
            {candidateDetals?.candidateOnboarding?.forms?.map((item, index) => {
              return (
                <div className="cardContainer" key={index}>
                  <div
                    className="headingContainer"
                    style={{
                      color: "var(--primary)",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item?.form?.title}
                    <span style={{ color: "red" }}>
                      {item.isMandatory ? "*" : ""}
                    </span>
                  </div>
                  <DragFormik name={`form${item?.form?.id}`} />
                </div>
              );
            })}
          </div>
        </FormikStep>
        <FormikStep
          label="Finish"
          validationSchema={Yup.object({
            agreeCheck: Yup.bool().oneOf(
              [true],
              "Accept Terms & Conditions to proceed further"
            ),
          })}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "24px",
              flexGrow: "1",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h5 style={{ color: "var(--primary)", marginBottom: "6px" }}>
                Candidature Agreement
              </h5>
              <div className="horizontalLine"></div>
            </div>
            <p style={{ marginTop: "24px" }}>
              {candidateDetals?.candidateOnboardingAgreementContent}
            </p>
            <div>
              <p style={{ marginTop: "48px", fontWeight: 500 }}>
                {
                  candidateDetals?.candidateOnboardingAgreementSignature?.split(
                    "|"
                  )?.[0]
                }
              </p>
              <p
                style={{
                  color: "var(--primary)",
                  fontWeight: 500,
                }}
              >
                {
                  candidateDetals?.candidateOnboardingAgreementSignature?.split(
                    "|"
                  )?.[1]
                }
              </p>
            </div>
            <div
              style={{
                width: "340px",
                backgroundColor: "var(--form-background)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                paddingRight: "12px",
                position: "relative",
              }}
            >
              <CheckboxFormiks
                style={{ justifySelf: "end" }}
                name="agreeCheck"
                labelName=""
              />
              <label
                className="cursor"
                style={{
                  marginBottom: "3px",
                  textDecoration: "underline",
                  position: "absolute",
                  left: "56px",
                }}
                onClick={() =>
                  openInNewTab(
                    `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Settings/${candidateDetals?.termsAndCondition}`
                  )
                }
              >
                I agree with the Terms and Conditions.
              </label>
            </div>
          </div>
        </FormikStep>
      </FormikStepper>
    );
};
export function FormikStep({ children }) {
  return <>{children}</>;
}
export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const headerClicked = async (clickedStep) => {
    if (clickedStep < step) {
      setStep(clickedStep);
    }
  };
  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  const backFunction = () => {
    if (step === 2) {
      if (props.tabIndex === 0) setStep((s) => s - 1);
      else props.setTabIndex((prev) => prev - 1);
    } else setStep((s) => s - 1);
  };
  return (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props?.onSubmit(values, helpers);
        } else if (step === 2) {
          if (props.tabIndex === 6) setStep((s) => s + 1);
          else props.setTabIndex((prev) => prev + 1);
        } else {
          setStep((s) => s + 1);
          helpers?.setTouched({});
        }
      }}
    >
      {({ isSubmitting, values }) => {
        props?.chooseFormValues && props?.chooseFormValues(values);
        return (
          <Form style={{ height: "100%" }}>
            {step === 0 ? (
              <>{currentChild}</>
            ) : (
              <div className="outerCont">
                <div className="CirclePink">
                  <img src={CirclePink} alt="LoginPerson" />
                </div>
                <div className="canOuter">
                  <img src={CanOuter} alt="LoginPerson" />
                </div>
                <div className="outerGr">
                  <img src={Addonboard} alt="LoginPerson" />
                </div>
                <div className="circleCan">
                  <img src={circleCan} alt="LoginPerson" />
                </div>
                <div className="onboardingInnerContainer">
                  <div className="onboardingSidePanel">
                    <img
                      style={{
                        width: "100%",
                        bottom: 0,
                        position: "absolute",
                        right: 0,
                      }}
                      src={OnboardingSidePanelBackground}
                      alt="OnboardingSidePanelBackground"
                    />
                    <div className="onboardingSidePanelContainer">
                      <img
                        style={{
                          height: "100%",
                          position: "absolute",
                          left: "22px",
                          zIndex: 1,
                        }}
                        src={OnboardingSidePanelLine}
                        alt="OnboardingSidePanelLine"
                      />
                      {childrenArray.map((child, index) => (
                        <div
                          className={`onboardingSidePanelTile ${
                            index === step && "onboardingSidePanelTileActive"
                          } ${index < step && "cursor"}`}
                          onClick={() => headerClicked(index)}
                          key={child.props.label}
                        >
                          {index === step ? (
                            <img
                              src={OnboardingSidePanelImgActive}
                              alt="OnboardingSidePanelImg"
                            />
                          ) : (
                            <img
                              src={OnboardingSidePanelImg}
                              alt="OnboardingSidePanelImg"
                            />
                          )}
                          <label>{child.props.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="onboardingMainContainer">
                    {props.loading ? (
                      <Loader />
                    ) : (
                      <div className="onboardingFormContainer">
                        {currentChild}
                      </div>
                    )}
                    <div className="onboardingFormButtonContainer">
                      <div style={{ display: "flex", gap: "12px" }}>
                        {step > 0 && (
                          <button
                            type="button"
                            className="button backButton noMargin"
                            onClick={backFunction}
                          >
                            {"< Back"}
                          </button>
                        )}
                        <button
                          className="button primaryButton noMargin"
                          type="submit"
                        >
                          {isLastStep() ? "Complete Onboarding " : "Next >"}
                        </button>
                      </div>
                      <UploadImageDetail
                        filepath={`Company/${props?.candidateDetals?.companyCode}/Logo/${props?.candidateDetals?.companyLogo}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="rotTraingle">
                  <img src={rotTraingle} alt="LoginPerson" />
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
export { OnboardingForm };
