/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateOutdoorComp } from "./FormComp/CreateOutdoorComp";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";

const CreateOutdoor = (props) => {
  const [detailsform, setDetailsform] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const { state } = useLocation();

  const data = state?.id;

  //  // //console.log('data',data)

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("OutdoorDutyReportings/Create", values);
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Outdoor`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("OutdoorDutyReportings/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    }
  };

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`OutdoorDutyReportings/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  // // //console.log('detailsform',detailsform)

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialvalue = {
    location: "",
    fromDate: "",
    toDate: "",
    description: "",
  };

  const validationSchema = Yup.object({
    location: Yup.string()

      .max(149, "Location must be less than 150 characters")
      .required("Please enter the Location"),
    fromDate: Yup.date().required("Please enter  From Date"),
    toDate: Yup.date().required("Please enter To Date"),
    description: Yup.string()

      .max(499, "Description must be less than 500 characters")
      .required("Please enter Description"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      fromDate: dateFormat(values?.fromDate, "yyyy-mm-dd"),
      toDate: dateFormat(values?.toDate, "yyyy-mm-dd"),
    };

    const create = {
      ...values,
      id: 0,

      fromDate: dateFormat(values?.fromDate, "yyyy-mm-dd"),
      toDate: dateFormat(values?.toDate, "yyyy-mm-dd"),
    };

    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Update Outdoor Request" : props.title}
      ButtonLabel={data ? "Update Outdoor Request" : "Create Outdoor Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              location: detailsform?.data?.location,
              fromDate: detailsform?.data?.fromDate
                ? new Date(detailsform?.data?.fromDate)
                : "",
              toDate: detailsform?.data?.toDate
                ? new Date(detailsform?.data?.toDate)
                : "",
              description: detailsform?.data?.description,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateOutdoorComp formValues={formValues} />}
    />
  );
};

export { CreateOutdoor };
