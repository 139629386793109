import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import HomeDashboardBackground from "../../../../Assets/HomeDashboardBackground.svg";
import ProfileAvatar from "../../../../Assets/ProfileAvatar.svg";
import "./HomePayroll.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BoxView } from "../../../../Components";
import { format } from "date-fns";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const HomePayroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deduction, setDeduction] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [payData, setPayData] = useState([]);
  const [paySummary, setPaySummary] = useState([]);
  const [monthlyPayout, setMonthlyPayout] = useState([]);
  const [year, setYear] = useState([]);
  const createBackGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 0.4)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 0)");
    return gradient;
  };
  const renderDeductionSummary = (deductionData) => {
    return Object.entries(deductionData).map(([key, value]) => (
      <div
        key={key}
        style={{
          padding: "16px",
          borderRadius: "6px",
          height: "120px",
          width: "fit-content",
          minWidth: "120px",
          flexGrow: 1,
        }}
        className={`${key}Summary`}
      >
        <svg
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
            stroke="#8A88ED"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
            stroke="#8A88ED"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div style={{ marginTop: "12px" }}>
          <p style={{ fontWeight: "500", lineHeight: "14px" }}>
            {key.toUpperCase()}
          </p>
          <h4 style={{ color: "var(--primary-text)" }}>{value}</h4>
        </div>
      </div>
    ));
  };
  const createLineGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 1)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 1)");
    return gradient;
  };
  console.log("deduction", deduction);
  const getDeduction = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/MyDashboard/Home/DeductionSummary"
      );
      setDeduction(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getDeduction();
  }, []);

  useEffect(() => {
    getmonthlyPayout();
  }, []);
  const getmonthlyPayout = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/MyDashboard/Home/MonthlyPayOut"
      );
      setYear(result?.data?.data[0]?.month);
      setMonthlyPayout(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getProfileData = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/MyDashboard/Home/Banner"
      );

      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getPaySummary = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/MyDashboard/Home/PayDays"
      );
      setPayData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const PaySummary = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/MyDashboard/Home/PaySummary"
      );
      setPaySummary(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const location = useLocation();
  const chartData = {
    labels: paySummary.map((data) => data.month),
    datasets: [
      {
        label: "Pay Summary",
        data: paySummary.map((data) => data.totalNetPayable),
        borderColor: createLineGradient(),
        backgroundColor: createBackGradient(),
        fill: true,
      },
    ],
  };
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("payroll")) {
      getProfileData();
      getPaySummary();
      PaySummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const monthlyPayout = [
  //   {
  //     id: 1,
  //     month: "Sep 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 2,
  //     month: "Aug 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "Jul 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "Jun 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "May 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "Apr 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "Mar 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  //   {
  //     id: 3,
  //     month: "Feb 2022",
  //     monthAmount: "10,00,000",
  //     deduction: "2,00,000",
  //     payDays: "25",
  //     leaveTaken: "03",
  //   },
  // ];
  const getFormDetails = (id) => {
    const selectedRecord = monthlyPayout?.find((item) => item?.Id === id);
    navigate("/hrmsmaster/orgstructure/band/details", {
      state: { selectedRecord },
    });
  };
  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.110596"
            y="0.587402"
            width="18.4572"
            height="18.4572"
            rx="4"
            fill="#D8F0F2"
          />
          <path
            d="M9.92749 4.5127C9.92749 4.23655 9.70363 4.0127 9.42749 4.0127C9.15135 4.0127 8.92749 4.23655 8.92749 4.5127L9.92749 4.5127ZM8.92749 4.5127L8.92749 12.5518L9.92749 12.5518L9.92749 4.5127L8.92749 4.5127Z"
            fill="#1296B0"
          />
          <path
            d="M5.41748 8.9668L9.42752 12.9768L13.4376 8.9668"
            stroke="#1296B0"
            stroke-linecap="round"
          />
          <path
            d="M5.18164 15.1191H13.4969"
            stroke="#1296B0"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Download",
      clickAction: (id) => id,
    },
  ];
  return (
    <div className="fullTableContainer" style={{ overflow: "auto" }}>
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div className="profileImgHolder">
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarContainer">
            {bannerList?.image ? (
              <img
                src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.image}`}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
            ) : (
              <img
                src={ProfileAvatar}
                alt={"Profile"}
                className="imageInsideProgressBar"
              />
            )}
          </div>
        </div>

        <div className="homeGreetingContainer">
          <h3>{bannerList?.name}</h3>
          <h4>{bannerList?.role}</h4>
          <div className="horizontalLinePrimary"></div>
          {/* <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div> */}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            justifyContent: "center",
            gap: "6px",
            padding: "12px",
            flexGrow: 1,
          }}
        >
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Employee Designation</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.designation}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Location</p>
            <p className="dashboardServicesName companyDash">
              {" "}
              {bannerList?.location}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Date of Joining</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.doj &&
                format(new Date(bannerList?.doj), "dd/MM/yyyy hh:mm a")}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">UAN Account</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.accountNumber ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">PAN No</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.panNo === "" ? "-" : bannerList?.panNo}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Account Name</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.accountHolderName ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Bank Name</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.bankName ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">IFSC Code</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.ifscCode ?? "-"}
            </p>
          </div>
          <div className="dashboardServicesType">
            <div
              className="horizontalLine"
              style={{
                width: "30%",
                backgroundColor: "var(--secondary)",
                marginBottom: "3px",
              }}
            ></div>
            <p className="dashboardServicesHeading">Account Number</p>
            <p className="dashboardServicesName companyDash">
              {bannerList?.accountNumber ?? "-"}
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "calc(65% - 24px)",
            boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
            borderRadius: "6px",
            margin: "12px",
          }}
        >
          <div className="headingContainer">
            <h5>Pay Summary {year}</h5>
          </div>
          {console.log("------:", chartData)}
          <div style={{ width: "100%", padding: "12px" }}>
            <Line
              data={chartData}
              width={"100%"}
              height={430} // Adjust the height here
              options={{
                plugins: {
                  legend: false,
                  datalabels: {
                    backgroundColor: "white",
                    borderRadius: 4,
                    color: "#8A88ED",
                    font: {
                      weight: "bold",
                    },
                    formatter: Math.round,
                    fill: true,
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 20000, // Adjust the suggestedMin value as per your requirement
                    suggestedMax: 60000, // Adjust the suggestedMax value as per your requirement
                  },
                },
                elements: {
                  point: {
                    radius: 4,
                  },
                },
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "calc(35% - 24px)",
            margin: "12px",
            height: "calc(100% - 24px)",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "fit-content",
              boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
              borderRadius: "6px",
            }}
          >
            <div className="headingContainer">
              <h5>{year} Pay Days</h5>
            </div>
            <div
              style={{
                display: "flex",
                padding: "12px",
                gap: "12px",
                justifyContent: "space-around",
                height: "fit-content",
                alignItems: "center",
              }}
            >
              <div>
                <h6>Pay Days</h6>
                <p
                  style={{
                    fontSize: "54px",
                    color: "#0DB53C",
                    fontWeight: 500,
                  }}
                >
                  {payData?.payableDays}
                </p>
              </div>
              <div>
                <h6>Leave Taken</h6>
                <p
                  style={{
                    fontSize: "54px",
                    color: "#FB7158",
                    fontWeight: 500,
                  }}
                >
                  {payData?.leaveTaken}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "fit-content",
              boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
              borderRadius: "6px",
              marginTop: "12px",
            }}
          >
            <div className="headingContainer">
              <h5>Deduction Summary {year}</h5>
            </div>
            <div
              style={{
                padding: "12px",
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                overflow: "auto",
                height: "calc(100% - 50px)",
              }}
            >
              <div className="payrollSummaryContainer tdsSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>TDS</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.tds}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer esicSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>ESIC</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction.esic}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer insuranceSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>PT</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction.pt}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer epfSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>EPF</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.epf}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer gratuitySummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Gratuity
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.gratuity}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer loanSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>Loan</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.loan ?? "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 style={{ margin: "12px 0 0 24px" }}>Monthly Pay Out {year}</h5>
      <BoxView
        tbodyData={monthlyPayout}
        actionData={actionData}
        headingTile="month"
        subTiles={["monthAmount", "deduction", "payDays", "leaveTaken"]}
        date="LastUpdatedOn"
        payroll={true}
      />
    </div>
  );
};
export { HomePayroll };
