import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateTravelEligibilityComp } from "../FormComp/CreateTravelEligibilityComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateTravelEligibility = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState({});
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const data = state?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = React.useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryGlobalArray, setCategoryGlobalArray] = useState([]);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "TravelAdvanceEligibilities/Create",
        values
      );
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getDetails = async () => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Details?ID=${data}`
      );
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "TravelAdvanceEligibilities/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const handleModeChange = async (index, value) => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategoryinconfig?mode=${value}`
      );
      const categories = result.data?.results?.map((item) => ({
        label: item.text,
        value: item.id,
      }));
      setCategoryArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[index] = categories;
        return updatedArray;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occurred in fetching categories",
          severity: "error",
        })
      );
    }
  };
  const handleModeChangeEdit = async (index, value) => {
    try {
      // debugger;
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategoryinconfig?mode=${value}`
      );
      const categories = result.data?.results?.map((item) => ({
        label: item.text,
        value: item.id,
      }));

      setCategoryArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[index] = categories;
        return updatedArray;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occurred in fetching categories",
          severity: "error",
        })
      );
    }
  };

  const getInitalTravel = () => {
    let returnArray = [];
    if (data) {
      detail?.traveladvanceeligibilitiesdetails?.map((item, index) => {
        !firstTimeRender && handleModeChangeEdit(index, item?.mode);
        return returnArray.push({
          mode: item?.mode,
          category:
            typeof item?.category === "string"
              ? item?.category.split(",").map((item) => item)
              : item?.category,
        });
      });
      if (
        !firstTimeRender &&
        detail?.traveladvanceeligibilitiesdetails?.length > 0
      ) {
        setFirstTimeRender(true);
      }
    } else
      returnArray = [
        {
          mode: "",
          category: "",
        },
      ];

    return returnArray;
  };

  const initialvalue = {
    bandId: data ? detail?.traveladvanceeligibility?.bandId : "",
    perdayEligibilty: data
      ? detail?.traveladvanceeligibility?.perdayEligibilty
      : "",
    traveladvanceeligibilitiesdetails: getInitalTravel(),
  };
  const validationSchema = Yup.object({
    bandId: Yup.string().required("Please Select Band"),
    perdayEligibilty: Yup.string().required("Please Enter Per Day Eligibilty "),
    traveladvanceeligibilitiesdetails: Yup.array().of(
      Yup.object().shape({
        category: Yup.array()
          .min(1, `Please Select Atleast One Category `)
          .required("Please Select Category"),
        mode: Yup.string().required("Please Select Mode"),
      })
    ),
  });

  const onSubmit = (values) => {
    const newStructure = values?.traveladvanceeligibilitiesdetails?.map(
      (item, index) => ({
        category:
          typeof item?.category === "string"
            ? item?.category
            : item?.category.join(","),
        mode: item?.mode,
      })
    );
    const edit = {
      ...values,
      traveladvanceeligibilitiesdetails: newStructure,

      Id: data,
    };

    const create = {
      ...values,
      traveladvanceeligibilitiesdetails: newStructure,
      id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      styles={{ width: "80%", maxWidth: "80%" }}
      chooseFormValues={chooseFormValues}
      FormHeading={
        data
          ? "Edit Travel Advance Eligibility"
          : "Create Travel Advance Eligibility"
      }
      ButtonLabel={
        data
          ? "Update Travel Advance Eligibility"
          : "Create Travel Advance Eligibility"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateTravelEligibilityComp
          handleModeChange={handleModeChange}
          formValues={formValues}
          data={data}
          categoryArray={categoryArray}
          setCategoryArray={setCategoryArray}
        />
      }
    />
  );
};

export { CreateTravelEligibility };
