// ContactList component
import React, { useEffect, useState } from "react";
import GroupIcon from "../../../Assets/GroupIcon.svg";
import MarriageAnniversary from "../../../Assets/MarriageAnniversary.svg";
import { format, isToday, isYesterday } from "date-fns";
import { useSelector } from "react-redux";
const ContactList = ({
  LoggedInUser,
  chatList,
  showStatus,
  SetActiveChat,
  handleContactClick,
}) => {
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const [chatListNotSelf, setChatListNotSelf] = useState([]);
  function checkAnniversary(d, type) {
    let date = new Date(d);
    let currDate = new Date();
    if (["anniversary", "dob", "workanniversary"].includes(type)) {
      return (
        date.getDate() === currDate.getDate() &&
        date.getMonth() === currDate.getMonth()
      );
    } else if (type === "newjoinee") {
      return date.toLocaleDateString() === currDate.toLocaleDateString();
    }
    return false;
  }
  const formattedDate = (date) => {
    if (isToday(date)) {
      return format(new Date(date), "hh:mm a");
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return format(date, "d/M/yy");
    }
  };
  useEffect(() => {
    let chatListNotSelfvar = chatList?.filter((item) => item?.type !== "Self");
    console.log("object chatListNotSelfvar", chatListNotSelfvar);

    setChatListNotSelf(chatListNotSelfvar);
  }, [chatList]);
  console.log("chatListNotSelf", chatListNotSelf);
  return (
    <div className="conversationListItem">
      {chatListNotSelf.length > 1 &&
        chatListNotSelf.map((contact, index) => (
          <div
            key={contact.id}
            onClick={() => {
              handleContactClick(contact?.id);
              setActiveChatIndex(index);
              SetActiveChat(contact);
            }}
            className={` chatListContentContainer ${
              contact?.participatingContacts?.filter(
                (item) => item?.myIBID !== LoggedInUser?.id
              )[0]?.onlineStatus && "chatListContentContainerActive"
            } ${index === activeChatIndex && "active"} `}
          >
            <div className="chatListLeftcontent">
              <div className="activeImage">
                <div
                  className={`activeImageContainer ${
                    !contact.icon ? "customProfilePic" : ""
                  }`}
                  style={
                    contact.icon
                      ? {
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${contact.icon})`,
                        }
                      : {}
                  }
                >
                  {!contact.icon
                    ? contact.title
                        .split(" ")
                        .slice(0, 2)
                        .map((item) => {
                          return item.charAt(0).toUpperCase();
                        })
                        .join("")
                    : null}
                </div>

                {contact.type === "Contact" && (
                  <div
                    className={showStatus(
                      contact?.participatingContacts?.filter(
                        (item) => item?.myIBID !== LoggedInUser?.id
                      )[0]?.onlineStatus
                    )}
                  ></div>
                )}

                {contact.type === "Group" && (
                  <div className="groupDot">
                    <img src={GroupIcon} alt="group" />
                  </div>
                )}
              </div>
              <div
                className="allChatListDisplayContainer"
                style={{ justifyContent: "start", paddingTop: "6px" }}
              >
                <label className="chatListName">
                  {" "}
                  {contact.title.replace(/\s\(\d+\)$/, "").length > 18
                    ? contact.title.replace(/\s\(\d+\)$/, "").substring(0, 18) +
                      "..."
                    : contact.title.replace(/\s\(\d+\)$/, "")}
                </label>
                <label className="chatListRole" style={{ textWrap: "nowrap" }}>
                  {contact.type === "Contact" &&
                    contact?.participatingContacts?.filter(
                      (item) => item?.myIBID !== LoggedInUser?.id
                    )[0]?.designation}
                </label>

                <label className="chatListlastMsg">
                  {contact?.type === "Contact" &&
                    (contact?.recentMessage !== null &&
                    contact?.recentMessage?.content?.length > 18
                      ? contact?.recentMessage?.content?.substring(0, 18) +
                        "..."
                      : contact?.recentMessage?.content)}
                </label>
              </div>
            </div>
            <div className="chatListRightContent" style={{ height: "100%" }}>
              <div
                className="anniversaryContainer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: " 100%",
                  paddingTop: " 15px",
                }}
              >
                <div className="allAnniversary">
                  {/* Aniversery Contanct */}
                  {checkAnniversary(contact?.anniversary, "anniversary") && (
                    <div>
                      <img
                        src={MarriageAnniversary}
                        alt="Marriage Anniversary"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="TimeUnreadCountContainer"
                style={{
                  justifyContent: "flex-start",
                  height: " 100%",
                  paddingTop: " 15px",
                }}
              >
                <label className="chatTime">
                  {contact.lastActive &&
                    formattedDate(new Date(contact.lastActive))}
                </label>
                <label className="chatUnreadCount">
                  {contact.unreadMessageCount > 0 && contact.unreadMessageCount}
                </label>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContactList;
