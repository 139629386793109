import React, { useState, useEffect } from "react";
import OfficegatepassPic from "../../Assets/OfficegatepassPic.svg";
import OfficemeetingroomPic from "../../Assets/OfficemeetingroomPic.svg";
import OfficecompanyvehiclePic from "../../Assets/OfficecompanyvehiclePic.svg";
import OfficecompanyfoodPic from "../../Assets/OfficecompanyfoodPic.svg";
import OfficecompanyhospitalityPic from "../../Assets/OfficecompanyhospitalityPic.svg";
import OfficevisitorsPic from "../../Assets/OfficevisitorsPic.svg";
import OfficecourierPic from "../../Assets/OfficecourierPic.svg";
import OfficecontactsPic from "../../Assets/OfficecontactsPic.svg";
import OfficekeyPic from "../../Assets/OfficekeyPic.svg";
import OfficehousekeepingPic from "../../Assets/OfficehousekeepingPic.svg";
import GatePassIcon from "../../Assets/GatePassIcon.svg";
import MeetingRoomIcon from "../../Assets/MeetingRoomIcon.svg";
import CompanyCarIcon from "../../Assets/CompanyCarIcon.svg";
import CompanyFoodIcon from "../../Assets/CompanyFoodIcon.svg";
import CompanyHospitalityIcon from "../../Assets/CompanyHospitalityIcon.svg";
import VisitorsIcon from "../../Assets/VisitorsIcon.svg";
import SendCourierIcon from "../../Assets/SendCourierIcon.svg";
import ReceiveCourierIcon from "../../Assets/ReceiveCourierIcon.svg";
import ContactsIcon from "../../Assets/ContactsIcon.svg";
import HouseKeepingIcon from "../../Assets/HouseKeepingIcon.svg";
import ReportsKPIpic from "../../Assets/ReportsKPI.svg";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import "./Office.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyCar } from "./Components/CompanyCar";
import { CreateCompanyCar } from "./CompanyCarComponents/CreateCompanyCar";
import { CreateVisitors } from "./CompanyCarComponents/CreateVisitors";
import { CompanyFood } from "./Components/CompanyFood";
import { CreateCompanyFood } from "./CompanyCarComponents/CreateCompanyFood";
import { DetailsPageVisitors } from "./DetailsComp/DetailsPageVisitors";
import { DetailsPageVehicle } from "./DetailsComp/DetailsPageVehicle";
import { DetailsPageFood } from "./DetailsComp/DetailsPageFood";
import { GatePass } from "./Components/GatePass";
import { MeetingRoom } from "./Components/MeetingRoom";
import { CompanyHospitality } from "./Components/CompanyHospitality";
import { Visitors } from "./Components/Visitors";
import { Courier } from "./Components/Courier";
import { Contacts } from "./Components/Contacts";
import { Key } from "./Components/Key";
import { ReportsKpi } from "./Components/ReportsKpi";
import { CreateGatePass } from "./CompanyCarComponents/CreateGatePass";
import { DetailsPageGatePass } from "./DetailsComp/DetailsPageGatePass";
import { DetailsPageMeetingRoom } from "./DetailsComp/DetailsPageMeetingRoom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { CreateMeetingRoom } from "./CompanyCarComponents/CreateMeetingRoom";
import { CreateCompanyHospitality } from "./CompanyCarComponents/CreateCompanyHospitality";
import { DetailsPageHospitality } from "./DetailsComp/DetailsPageHospitality";
import { CreateSendCourier } from "./CompanyCarComponents/CreateSendCourier";
import { DetailsPageSendCourier } from "./DetailsComp/DetailsPageSendCourier";
import { CreateReceiveCourier } from "./CompanyCarComponents/CreateReceiveCourier";
import { DetailsPageReceiveCourier } from "./DetailsComp/DetailsPageReceiveCourier";
import { CreateHouseKeeping } from "./CompanyCarComponents/CreateHouseKeeping";
import { DetailsPageHouseKeeping } from "./DetailsComp/DetailsPageHouseKeeping";
import { HouseKeeping } from "./Components/HouseKeeping";

const Office = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  const [activeFeature, setActiveFeature] = useState("sendcourier");

  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "office"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("office")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "office");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
          setCreateActive(false);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (
          splitArray[submoduleIndex + 3] === "details" ||
          splitArray[submoduleIndex + 3] === "feedback"
        ) {
          setDetailsActive(true);
          setCreateActive(false);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  const [activeSubmodule, setActiveSubmodule] = useState("gatepass");
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "gatepass") return GatePassIcon;
    else if (subMenuName === "meetingroom") return MeetingRoomIcon;
    else if (subMenuName === "companycar") return CompanyCarIcon;
    else if (subMenuName === "companyfood") return CompanyFoodIcon;
    else if (subMenuName === "companyhospitality")
      return CompanyHospitalityIcon;
    else if (subMenuName === "visitors") return VisitorsIcon;
    else if (subMenuName === "courier") return OfficecourierPic;
    else if (subMenuName === "contacts") return ContactsIcon;
    else if (subMenuName === "key") return OfficekeyPic;
    else if (subMenuName === "housekeeping") return HouseKeepingIcon;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "gatepass" && (
        <>
          {createActive && <CreateGatePass title={"Create Gate Pass"} />}
          {detailsActive && <DetailsPageGatePass />}
        </>
      )}
      {activeSubmodule === "meetingroom" && (
        <>
          {createActive && <CreateMeetingRoom title={"Create meeting room "} />}
          {detailsActive && <DetailsPageMeetingRoom />}
        </>
      )}
      {activeSubmodule === "companycar" && (
        <>
          {createActive && (
            <CreateCompanyCar title={"Create Company Vehicle"} />
          )}
          {detailsActive && <DetailsPageVehicle />}
        </>
      )}
      {activeSubmodule === "companyfood" && (
        <>
          {createActive && (
            <CreateCompanyFood title={"Create Company Vehicle"} />
          )}
          {detailsActive && <DetailsPageFood />}
        </>
      )}
      {activeSubmodule === "companyhospitality" && (
        <>
          {createActive && (
            <CreateCompanyHospitality title={"Create Company Hospitality"} />
          )}
          {detailsActive && <DetailsPageHospitality />}
        </>
      )}
      {activeSubmodule === "visitors" && (
        <>
          {createActive && <CreateVisitors title={"Create Visitors Request"} />}
          {detailsActive && <DetailsPageVisitors />}
        </>
      )}
      {activeSubmodule === "courier" && (
        <>
          {activeFeature === "sendcourier" && (
            <>
              {createActive && (
                <CreateSendCourier title={"Create Send Courier"} />
              )}
              {detailsActive && <DetailsPageSendCourier />}
            </>
          )}
          {activeFeature === "receivecourier" && (
            <>
              {createActive && (
                <CreateReceiveCourier title={"Create Receive Courier"} />
              )}
              {detailsActive && <DetailsPageReceiveCourier />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "contacts" && <> </>}
      {activeSubmodule === "key" && <></>}
      {activeSubmodule === "housekeeping" && (
        <>
          {createActive && (
            <CreateHouseKeeping title={"Create House Keeping"} />
          )}
          {detailsActive && <DetailsPageHouseKeeping />}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/office/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer" style={{ overflow: "hidden" }}>
        {activeSubmodule === "gatepass" ? (
          <GatePass title={"Gate Pass"} />
        ) : null}
        {activeSubmodule === "meetingroom" ? (
          <MeetingRoom title={"Meeting Room"} />
        ) : null}
        {activeSubmodule === "companycar" ? (
          <CompanyCar title={"Company Vehicle"} />
        ) : null}
        {activeSubmodule === "companyfood" ? (
          <CompanyFood title={"Company Food"} />
        ) : null}
        {activeSubmodule === "companyhospitality" ? (
          <CompanyHospitality title={"Company Hospitality"} />
        ) : null}
        {activeSubmodule === "visitors" ? (
          <Visitors title={"Visitors"} />
        ) : null}
        {activeSubmodule === "courier" ? <Courier title={"Courier"} /> : null}
        {activeSubmodule === "contacts" ? (
          <Contacts title={"Contacts"} />
        ) : null}
        {activeSubmodule === "key" ? <Key title={"Key"} /> : null}
        {activeSubmodule === "housekeeping" ? (
          <HouseKeeping title={"House Keeping"} />
        ) : null}
      </div>
    </div>
  );
};

export { Office };
