import React, { useEffect } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { showToast } from "../../../../../Features";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequestForm } from "../../../../../Services/axios";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import { useSelector } from "react-redux";
import { FileUploadFormikMulti } from "../../../../../Components/FormComponent/FileUploadFormikMulti";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
  { label: "Critical", value: "Critical" },
];
export const CreateTasks = () => {
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeList, setEmployeList] = useState([]);
  const [details, setDetails] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [formValues, setFormValues] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);

  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const data = id[xy].substring(n + 1);

  useEffect(() => {
    document.title = `PeopleSol - Create Task`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(`Tasks/Details/${data}`);
      setDetails(result?.data?._projecttasks);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data.length !== 6) getDetails();
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("Tasks/Create", values);
      // eslint-disable-next-line no-unused-vars
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      navigate(-1);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("Tasks/Edit", values);
      // eslint-disable-next-line no-unused-vars
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      navigate(-1);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        `Employees/GetTeamMembers/${LoggedInUser?.id}`
      );
      let listData = [];
      result?.data?.data?.map((item) => {
        return listData?.push({
          label: item?.nameWithPersonNumber,
          value: item?.id,
        });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const projectTeam = async () => {
    try {
      const result = await getRequest(
        `Projects/GetProjectTeam/?projectId=${formValues.ProjectId}`
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  console.log("object formValues", formValues);
  const getProject = async () => {
    try {
      const result = await getRequest("Projects/GetProjects");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setProjectList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getProject();
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formValues.ProjectId) projectTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.ProjectId]);
  const validationSchema = Yup.object({
    Title: Yup.string().required("Please Enter Title"),
    EmployeeId: Yup.string().required("Please Enter Employee Name"),
  });
  const onSubmit = (values) => {


    const formData = new FormData();

    if (data.length !== 6)
      formData.append("RequestNumber", details?.requestNumber);
    else formData.append("RequestNumber", 0);
    if (data.length !== 6) formData.append("Id", data);
    formData.append("Title", values?.Title);
    if (values?.StartsOn)
      formData.append("StartsOn", `${format(values?.StartsOn, "MM/dd/yyyy")}`);
    if (values?.EndsOn)
      formData.append("EndsOn", `${format(values?.EndsOn, "MM/dd/yyyy")}`);
    if (values?.ProjectId) formData.append("ProjectId", values?.ProjectId);
    if (typeof values.upload === "object") {
      values.upload?.forEach((element, index) => {
        formData.append(`upload`, element);
      });
    }
    if (values?.Description)
      formData.append("Description", values?.Description);
    if (values?.EstimatedHours)
      formData.append("EstimatedHours", values?.EstimatedHours);

    formData.append("Status", "Yet to Start");
    if (values?.Priority) formData.append("Priority", values?.Priority);
    if (values?.EmployeeId) formData.append("EmployeeId", values?.EmployeeId);
    if (values?.Incentive) formData.append("Incentive", values?.Incentive);
    if (values?.TargetAchievement)
      formData.append("TargetAchievement", values?.TargetAchievement);

    if (data.length !== 6) EditPageData(formData);
    else createPageData(formData);
  };
  return (
    <div className="createPageContainer side-navigation ">
      <div className="createProjectContainer  requsitionForm">
        <div className="createHeader">
          <label>{data.length !== 6 ? "Update Task" : "Add Task"}</label>
          <IconButton
            className="closeIconHolder"
            onClick={returnPage}
            title="Close"
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </div>
        <div className="horizontalLine createLine"></div>
        <div className="bodyContainer ">
          {loading ? (
            <Loader />
          ) : (
            <Formik
              initialValues={{
                ProjectId: data.length !== 6 ? details?.projectId : "",
                EmployeeId:
                  data.length !== 6 ? details?.employeeId : LoggedInUser.id,
                Title: data.length !== 6 ? details?.title : "",
                Description: data.length !== 6 ? details?.description : "",
                upload:
                  data.length !== 6
                    ? `ProjectsTasks/${details?.attachments}`
                    : [],
                EstimatedHours:
                  data.length !== 6 ? details?.estimatedHours : "",
                StartsOn:
                  data.length !== 6
                    ? details?.startsOn && new Date(details?.startsOn)
                    : new Date(),
                EndsOn:
                  data.length !== 6
                    ? details?.endsOn && new Date(details?.endsOn)
                    : new Date(),
                Priority: data.length !== 6 ? details?.priority : "",
                Incentive: data.length !== 6 ? details?.incentive : "",
                TargetAchievement: 100,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values }) => {
                setFormValues(values);
                return (
                  <Form style={{ width: "100%" }}>
                    <div className="formcustom twoColumn">
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Task Name"}
                          mandatory={true}
                        />
                        <InputCustomFormik
                          placeholder={"Enter Task Name"}
                          name="Title"
                        />
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Project Associated With Task"}
                        />
                        <SelectForm
                          name={"ProjectId"}
                          placeholder={"Select Status"}
                          options={projectList}
                        />
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Assign To"}
                        />
                        <SelectForm
                          name="EmployeeId"
                          options={
                            formValues.ProjectId ? employeList : employeeList
                          }
                          placeholder="Please select Employee"
                        />{" "}
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Task Priority"}
                          mandatory={false}
                        />
                        <SelectForm
                          name={"Priority"}
                          placeholder={"Select Priority"}
                          options={option}
                        />{" "}
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Task Start Date"}
                          mandatory={false}
                        />
                        <DatePickerFormiks
                          name={"StartsOn"}
                          placeholder={"Select the task start date"}
                          maxDate={values.EndsOn}
                          minDate={new Date()}
                        />{" "}
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Task Planned End Date"}
                          mandatory={false}
                        />
                        <DatePickerFormiks
                          name={"EndsOn"}
                          placeholder={"Select the task end date"}
                          minDate={values.StartsOn}
                        />{" "}
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Estimated Hours"}
                        />
                        <InputCustomFormik
                          name="EstimatedHours"
                          placeholder="Enter Estimated No. of Hours"
                          type="number"
                        />
                      </div>{" "}
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Incentive Associated With Task"}
                        />
                        <InputCustomFormik
                          name="Incentive"
                          placeholder="Enter Incentive Amount"
                          type="number"
                        />
                      </div>{" "}
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={"Target Achievement Percent for Incentive"}
                        />
                        <InputCustomFormik
                          name="TargetAchievement"
                          disabled={true}
                          placeholder="Enter Achievement Percent"
                          type="number"
                        />
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={" Task Supporting Document"}
                        />
                        <FileUploadFormikMulti
                          name={"upload"}
                          placeholder={"Choose file"}
                          isEdit={
                            data.length !== 6 && details?.attachments !== null
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div>
                        <LabelCustom
                          fontWeight={"500"}
                          labelName={" Task Description"}
                        />
                        <TextAreaFormik
                          maxLength={500}
                          placeholder="Please Enter Task Description"
                          style={{ height: "90px" }}
                          name={"Description"}
                        />
                      </div>
                    </div>
                    <div className="createFootCust">
                      <div>
                        <div
                          onClick={returnPage}
                          className="button secondaryButton"
                        >
                          Cancel
                        </div>
                      </div>
                      <div>
                        <button className="button primaryButton" type="submit">
                          {data.length !== 6 ? "Update Task" : "Assign Task"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};
