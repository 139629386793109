import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import "./FormComponent.css";
import { TextError } from "./TextError";
const TextAreaFormik = (props) => {
  const { name, placeholder, formikStyle, borderBlack } = props;
  const [selected, setSelected] = useState(false);
  return (
    <div className="formikComponentContainer" style={{ width: formikStyle }}>
      <Field
        as="textarea"
        style={{ minWidth: "120px", height: "80px", minHeight: "40px" }}
        {...props}
        onFocus={() => { setSelected(true) }}
        onBlur={() => { setSelected(false) }}
        className={borderBlack && !selected ? "input-longtext1" : "input-longtext"}
        name={name}
        placeholder={placeholder}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
export { TextAreaFormik };
