/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import "./Services.css";
import RoleIcon from "../../Assets/RoleIcon.svg";
import DesignationIcon from "../../Assets/DesignationIcon.svg";
import CompanyExperienceIcon from "../../Assets/CompanyExperienceIcon.svg";
import ExperienceIcon from "../../Assets/ExperienceIcon.svg";
import LocationIcon from "../../Assets/LocationIcon.svg";
import DepartmentIcon from "../../Assets/DepartmentIcon.svg";
import EditPencilIcon from "../../Assets/EditPencilIcon.svg";
import FacebookIcon from "../../Assets/FacebookIcon.svg";
import LinkedinIcon from "../../Assets/LinkedinIcon.svg";
import TwitterIcon from "../../Assets/TwitterIcon.svg";
import { MyProfile } from "./Components";
import HomeDashboardBackground from "../../Assets/HomeDashboardBackground.svg";
import RewardIconOrange from "../../Assets/RewardIconOrange.svg";
import InfoIcon from "../../Assets/InfoIcon.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import TempIcon from "../../Assets/TempIcon.svg";
import {
  ServiceAttendance,
  ServiceLeave,
  ServiceRequisition,
  ServicePlanning,
  ServicePolicies,
  ServiceRegularisation,
  ServiceLateComing,
  ServiceEarlyGoing,
  ServiceCompOffs,
  ServiceHoliday,
  ServiceOutdoor,
  ServiceProject,
  ServiceTask,
  ServiceTimesheet,
  ServiceHRDesk,
  ServiceAdminFacility,
  ServiceLoanAdvance,
  ServiceDeputation,
  ServiceRelocation,
  ServiceResignation,
  ServiceDueClearance,
  ServiceSalary,
  ServiceDeclaration,
  ServiceSubmission,
  ServiceInternalJobs,
  ServiceReferral,
  ServiceGoalSettings,
  ServiceAppraisals,
  ServiceRewards,
  ServiceLearning,
  ServiceTraining,
  ServiceGatePass,
  ServiceMeetingRoom,
  ServiceVehicle,
  ServiceFood,
  ServiceHospitality,
  ServiceVisitors,
  ServiceSendCourier,
  ServiceReceiveCourier,
  ServiceContacts,
  ServiceKeys,
  ServiceITDesk,
  ServiceException,
  ServiceTravelBooking,
  ServiceHotelBooking,
  ServiceTravelAdvance,
  ServiceReimbursement,
  ServiceFilesFolders,
  ServiceHouseKeeping,
} from "./SidebarComponents";
import { getRequest } from "../../Services/axios";
import { showToast } from "../../Features";
import { useDispatch } from "react-redux";
import AttendanceRegIcon from "../../Assets/AttendanceRegIcon.svg";
import EarlyGoingIcon from "../../Assets/EarlyGoingIcon.svg";
import LateComingIcon from "../../Assets/LateComingIcon.svg";
import OutdoorIcon from "../../Assets/OutdoorIcon.svg";
import HrDeskIcon from "../../Assets/HrDeskIcon.svg";
import LoanAdvanceIcon from "../../Assets/LoanAdvanceIcon.svg";
import DeputationIcon from "../../Assets/DeputationIcon.svg";
import LeavesIcon from "../../Assets/LeavesIcon.svg";
import ITHelpDeskIcon from "../../Assets/ITHelpDeskIcon.svg";
import ExceptionRequestsIcon from "../../Assets/ExceptionRequestsIcon.svg";
import AdminFacilityReqIcon from "../../Assets/AdminFacilityReqIcon.svg";
import RelocationIcon from "../../Assets/RelocationIcon.svg";
import TravelAdvanceIcon from "../../Assets/TravelAdvanceIcon.svg";
import PoliciesIcon from "../../Assets/PoliciesIcon.svg";
import TimeAttendancepic from "../../Assets/TimeAttendancepic.svg";
import TravelBookingIcon from "../../Assets/TravelBookingIcon.svg";
import HotelBookingIcon from "../../Assets/HotelBookingIcon.svg";
import ReimbursementIcon from "../../Assets/ReimbursementIcon.svg";
import RequisitionIcon from "../../Assets/RequisitionIcon.svg";
import OvertimeIcon from "../../Assets/OvertimeIcon.svg";
import CompoffIcon from "../../Assets/CompoffIcon.svg";
import HolidayIcon from "../../Assets/HolidayIcon.svg";
import ProfileAvatar from "../../Assets/ProfileAvatar.svg";
import ResignationIcon from "../../Assets/ResignationIcon.svg";
// import DueClearanceIcon from "../../Assets/DueClearanceIcon.svg";
// import SalaryIcon from "../../Assets/SalaryIcon.svg";
// import DeclarationIcon from "../../Assets/DeclarationIcon.svg";
// import SubmissionIcon from "../../Assets/SubmissionIcon.svg";
// import InternalJobsIcon from "../../Assets/InternalJobsIcon.svg";
// import ReferralIcon from "../../Assets/ReferralIcon.svg";
import GoalsIcon from "../../Assets/GoalsIcon.svg";
// import AppraisalsIcon from "../../Assets/AppraisalsIcon.svg";
import RewardsIcon from "../../Assets/RewardsIcon.svg";
import AssetIcon from "../../Assets/AssetIcon.svg";
import SkillsIcon from "../../Assets/SkillsIcon.svg";
import CompetenciesIcon from "../../Assets/CompetenciesIcon.svg";
// import LearningIcon from "../../Assets/LearningIcon.svg";
// import TrainingIcon from "../../Assets/TrainingIcon.svg";
import GatePassIcon from "../../Assets/GatePassIcon.svg";
import MeetingRoomIcon from "../../Assets/MeetingRoomIcon.svg";
import CompanyCarIcon from "../../Assets/CompanyCarIcon.svg";
import CompanyFoodIcon from "../../Assets/CompanyFoodIcon.svg";
import CompanyHospitalityIcon from "../../Assets/CompanyHospitalityIcon.svg";
import VisitorsIcon from "../../Assets/VisitorsIcon.svg";
import SendCourierIcon from "../../Assets/SendCourierIcon.svg";
import ReceiveCourierIcon from "../../Assets/ReceiveCourierIcon.svg";
import ContactsIcon from "../../Assets/ContactsIcon.svg";
import HouseKeepingIcon from "../../Assets/HouseKeepingIcon.svg";
import { useSelector } from "react-redux";
import { ServiceAsset } from "./SidebarComponents/ServiceAsset";
import { ServiceProblem } from "../ITSMMaster/ITDeskComponents/ServiceProblem";
import { Popup } from "../../Components/FormComponent/PopupComponent/Popup";
import { PopProfile } from "../../Components/FormComponent/PopupComponent/PopProfile";
import { ProfileDetails } from "./ProfileDetails";
import { ServiceMyCompetency } from "./SidebarComponents/ServiceMyCompetency";
import { ServiceMySkill } from "./SidebarComponents/ServiceMySkill";
import { ServiceMySkillCreate } from "./SidebarComponents/ServiceMySkillCreate";
import { ServiceMySkillDetails } from "./SidebarComponents/ServiceMySkillDetails";
import { ServiceMyCompetencyCreate } from "./SidebarComponents/ServiceMyCompetencyCreate";
import { ServiceMyCompetencyDetails } from "./SidebarComponents/ServiceMyCompetencyDetails";

import { ServiceOvertime } from "./SidebarComponents/ServiceOvertime";

const sidebarData = [
  {
    id: 1,
    icon: TempIcon,
    name: "My Profile",
    active: "profileActive",
    menu: "HRMS",
    navigate: "/services/profile",
  },
  {
    id: 2,
    icon: RequisitionIcon,
    name: "Requisition",
    active: "requisitionActive",
    menu: "HRMS",
    navigate: "/services/requisition",
  },
  {
    id: 3,
    icon: TempIcon,
    name: "Requisition Planning",
    active: "planningActive",
    menu: "HRMS",
    navigate: "/services/planning",
  },
  {
    id: 4,
    icon: PoliciesIcon,
    name: "Company Policies",
    active: "policiesActive",
    menu: "HRMS",
    navigate: "/services/policies",
  },
  {
    id: 5,
    icon: TimeAttendancepic,
    name: "My Attendance",
    active: "attendanceActive",
    menu: "HRMS",
    navigate: "/services/attendance",
  },
  {
    id: 6,
    icon: AttendanceRegIcon,
    name: "Regularisation",
    active: "regularisationActive",
    menu: "HRMS",
    navigate: "/services/regularisation",
  },
  {
    id: 7,
    icon: LateComingIcon,
    name: "Late Coming",
    active: "lateComingActive",
    menu: "HRMS",
    navigate: "/services/latecoming",
  },
  {
    id: 8,
    icon: EarlyGoingIcon,
    name: "Early Going",
    active: "earlyGoingActive",
    menu: "HRMS",
    navigate: "/services/earlygoing",
  },
  {
    id: 9,
    icon: LeavesIcon,
    name: "Leave",
    active: "leavesActive",
    menu: "HRMS",
    navigate: "/services/leaves",
  },
  {
    id: 10,
    icon: CompoffIcon,
    name: "Comp Off",
    active: "compoffsActive",
    menu: "HRMS",
    navigate: "/services/compoffs",
  },
  {
    id: 55,
    icon: OvertimeIcon,
    name: "Over Time",
    active: "overtimeActive",
    menu: "HRMS",
    navigate: "/services/overtime",
  },
  {
    id: 11,
    icon: HolidayIcon,
    name: "Holiday",
    active: "holidayActive",
    menu: "HRMS",
    navigate: "/services/holiday",
  },
  {
    id: 12,
    icon: OutdoorIcon,
    name: "Out Door",
    active: "outdoorsActive",
    menu: "HRMS",
    navigate: "/services/outdoor",
  },
  {
    id: 13,
    icon: TempIcon,
    name: "Projects",
    active: "projectActive",
    menu: "HRMS",
    navigate: "/services/project",
  },
  {
    id: 14,
    icon: TempIcon,
    name: "Tasks",
    active: "taskActive",
    menu: "HRMS",
    navigate: "/services/task",
  },
  {
    id: 15,
    icon: TempIcon,
    name: "Timesheet",
    active: "timesheetActive",
    menu: "HRMS",
    navigate: "/services/timesheet",
  },
  {
    id: 16,
    icon: HrDeskIcon,
    name: "HR Desk",
    active: "hrDeskActive",
    menu: "HRMS",
    navigate: "/services/hrdesk",
  },
  {
    id: 17,
    icon: AdminFacilityReqIcon,
    name: "Facilities",
    active: "adminfacilityActive",
    menu: "HRMS",
    navigate: "/services/adminfacility",
  },
  {
    id: 18,
    icon: LoanAdvanceIcon,
    name: "Loan & Advance",
    active: "loanAdvanceActive",
    menu: "HRMS",
    navigate: "/services/loanadvance",
  },
  {
    id: 19,
    icon: DeputationIcon,
    name: "Deputation",
    active: "deputationActive",
    menu: "HRMS",
    navigate: "/services/deputation",
  },
  {
    id: 20,
    icon: RelocationIcon,
    name: "Relocation",
    active: "relocationActive",
    menu: "HRMS",
    navigate: "/services/relocation",
  },
  {
    id: 21,
    icon: ResignationIcon,
    name: "Resignation",
    active: "resignationActive",
    menu: "HRMS",
    navigate: "/services/resignation",
  },
  {
    id: 22,
    icon: TempIcon,
    name: "Due Clearance",
    active: "dueClearanceActive",
    menu: "ITSM",
    navigate: "/services/dueclearance",
  },
  {
    id: 23,
    icon: TempIcon,
    name: "Salary",
    active: "salaryActive",
    menu: "HRMS",
    navigate: "/services/salary",
  },
  {
    id: 24,
    icon: TempIcon,
    name: "Declaration",
    active: "declarationActive",
    menu: "HRMS",
    navigate: "/services/declaration",
  },
  {
    id: 25,
    icon: TempIcon,
    name: "Submission",
    active: "submissionActive",
    menu: "HRMS",
    navigate: "/services/submission",
  },
  {
    id: 26,
    icon: TempIcon,
    name: "Internal Jobs",
    active: "internalJobsActive",
    menu: "HRMS",
    navigate: "/services/internaljobs",
  },
  {
    id: 27,
    icon: TempIcon,
    name: "Referral",
    active: "referralsActive",
    menu: "HRMS",
    navigate: "/services/referral",
  },
  {
    id: 28,
    icon: GoalsIcon,
    name: "Goals",
    active: "goalSettingsActive",
    menu: "PMS",
    navigate: "/services/goalsettings",
  },
  {
    id: 29,
    icon: TempIcon,
    name: "Appraisals",
    active: "appraisalsActive",
    menu: "HRMS",
    navigate: "/services/appraisals",
  },
  {
    id: 30,
    icon: RewardsIcon,
    name: "Rewards",
    active: "rewardsActive",
    menu: "HRMS",
    navigate: "/services/rewards",
  },
  {
    id: 31,
    icon: TempIcon,
    name: "Skills",
    active: "skillsActive",
    menu: "HRMS",
    navigate: "/services/skills",
  },
  {
    id: 32,
    icon: TempIcon,
    name: "Competencies",
    active: "competenciesActive",
    menu: "HRMS",
    navigate: "/services/competencies",
  },
  {
    id: 33,
    icon: TempIcon,
    name: "Learning",
    active: "learningActive",
    menu: "HRMS",
    navigate: "/services/learning",
  },
  {
    id: 34,
    icon: TempIcon,
    name: "Training",
    active: "trainingActive",
    menu: "HRMS",
    navigate: "/services/training",
  },
  {
    id: 35,
    icon: GatePassIcon,
    name: "Gate Pass",
    active: "gatePassActive",
    menu: "Office",
    navigate: "/services/gatepass",
  },
  {
    id: 36,
    icon: MeetingRoomIcon,
    name: "Meeting Room",
    active: "meetingRoomActive",
    menu: "Office",
    navigate: "/services/meetingroom",
  },
  {
    id: 37,
    icon: CompanyCarIcon,
    name: "Company Car",
    active: "vehicleActive",
    menu: "Office",
    navigate: "/services/companycar",
  },
  {
    id: 38,
    icon: CompanyFoodIcon,
    name: "Company Food",
    active: "foodActive",
    menu: "Office",
    navigate: "/services/food",
  },
  {
    id: 39,
    icon: CompanyHospitalityIcon,
    name: "Company Hospitality",
    active: "hospitalityActive",
    menu: "Office",
    navigate: "/services/hospitality",
  },
  {
    id: 40,
    icon: VisitorsIcon,
    name: "Visitors",
    active: "visitorsActive",
    menu: "Office",
    navigate: "/services/visitors",
  },
  {
    id: 41,
    icon: SendCourierIcon,
    name: "Send Courier",
    active: "sendcourierActive",
    menu: "Office",
    navigate: "/services/sendcourier",
  },
  {
    id: 54,
    icon: ReceiveCourierIcon,
    name: "Receive Courier",
    active: "receivecourierActive",
    menu: "Office",
    navigate: "/services/receivecourier",
  },
  {
    id: 42,
    icon: ContactsIcon,
    name: "Contacts",
    active: "contactsActive",
    menu: "Office",
    navigate: "/services/contacts",
  },
  {
    id: 43,
    icon: TempIcon,
    name: "Keys",
    active: "keysActive",
    menu: "Office",
    navigate: "/services/keys",
  },
  {
    id: 53,
    icon: HouseKeepingIcon,
    name: "House Keeping",
    active: "housekeepingActive",
    menu: "Office",
    navigate: "/services/housekeeping",
  },
  {
    id: 44,
    icon: ITHelpDeskIcon,
    name: "IT Desk Ticket",
    active: "itDeskActive",
    menu: "ITSM",
    navigate: "/services/itdesk",
  },
  {
    id: 45,
    icon: ExceptionRequestsIcon,
    name: "Exception",
    active: "exceptionActive",
    menu: "ITSM",
    navigate: "/services/exception",
  },
  {
    id: 46,
    icon: TravelBookingIcon,
    name: "Travel Booking",
    active: "travelBookingActive",
    menu: "Travel",
    navigate: "/services/travelbooking",
  },
  {
    id: 47,
    icon: HotelBookingIcon,
    name: "Hotel Booking",
    active: "hotelBookingActive",
    menu: "Travel",
    navigate: "/services/hotelbooking",
  },
  {
    id: 48,
    icon: TravelAdvanceIcon,
    name: "Travel Advance",
    active: "travelAdvanceActive",
    menu: "Travel",
    navigate: "/services/traveladvance",
  },
  {
    id: 49,
    icon: ReimbursementIcon,
    name: "Reimbursement",
    active: "reimbursementActive",
    menu: "Travel",
    navigate: "/services/reimbursement",
  },
  {
    id: 50,
    icon: TempIcon,
    name: "Files & Folders",
    active: "filesFoldersActive",
    menu: "HRMS",
    navigate: "/services/filesfolders",
  },
  {
    id: 51,
    icon: AssetIcon,
    name: "Asset",
    active: "assetActive",
    menu: "ITSM",
    navigate: "/services/asset",
  },
  {
    id: 57,
    icon: SkillsIcon,
    name: "Skills",
    active: "assetActive",
    menu: "L&D",
    navigate: "/services/myskill",
  },
  {
    id: 56,
    icon: CompetenciesIcon,
    name: "Competency",
    active: "assetActive",
    menu: "L&D",
    navigate: "/services/mycompetency",
  },
];

const Services = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const [s, setS] = useState("");
  const [searchParam] = useState(["name"]);
  const [idActive, setIdActive] = useState(0);
  const dispatch = useDispatch();
  const [bannerList, setBannerList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [teamCardData, setTeamCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);
  const togglePopup = (id) => {
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const [profileData, setTeamProfileData] = useState([]);
  const getTeamProfileData = async () => {
    try {
      setSelId(LoggedInUser?.id);
      setLoading(true);
      const result = await getRequest(
        `Settings/GetProfileCompletionData?Id=${LoggedInUser?.id ?? selId}`
      );
      setTeamProfileData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const educationDetailsData = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Education Details"
  );
  const employeeProfile = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Employee Profile"
  );
  const experienceDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Experience Details"
  );
  const personalDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Personal Details"
  );
  const currentAddress = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Current Address"
  );
  const paymentDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Payment Details"
  );
  const permanentAddress = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Permanent Address"
  );
  const emergencyContact = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Emergency Contact Details"
  );
  const organisationDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Organisation Details"
  );
  const identificationDetails = profileData?.employeeProfileCompletion?.filter(
    (item) => item?.section === "Identification Details"
  );
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(s.toLowerCase()) > -1
        );
      });
    });
  }
  const getTeamCardData = async () => {
    try {
      const result = await getRequest("Settings/GetBannerData");
      setTeamCardData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("services")) {
      getTeamCardData();
    }
  }, []);

  const clickHandler = (item) => {
    setIdActive(item.id);
    navigate(item.navigate);
    // setActive(prev => !prev);
  };
  const isReportingManager = () => {
    if (LoggedInUser?.isReportingManager) {
      setIsManager(true);
    }
  };

  useEffect(() => {
    isReportingManager();
    getProfileDetails();
  }, [LoggedInUser]);

  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("services")) getProfileData();
    if (currentpath.includes("profile")) {
      setIdActive(1);
    } else if (currentpath.includes("requisition")) {
      setIdActive(2);
    } else if (currentpath.includes("planning")) {
      setIdActive(3);
    } else if (currentpath.includes("policies")) {
      setIdActive(4);
    } else if (currentpath.includes("attendance")) {
      setIdActive(5);
    } else if (currentpath.includes("regularisation")) {
      setIdActive(6);
    } else if (currentpath.includes("latecoming")) {
      setIdActive(7);
    } else if (currentpath.includes("earlygoing")) {
      setIdActive(8);
    } else if (currentpath.includes("leave")) {
      setIdActive(9);
    } else if (currentpath.includes("compoffs")) {
      setIdActive(10);
    } else if (currentpath.includes("holiday")) {
      setIdActive(11);
    } else if (currentpath.includes("outdoor")) {
      setIdActive(12);
    } else if (currentpath.includes("project")) {
      setIdActive(13);
    } else if (currentpath.includes("task")) {
      setIdActive(14);
    } else if (currentpath.includes("timesheet")) {
      setIdActive(15);
    } else if (currentpath.includes("hrdesk")) {
      setIdActive(16);
    } else if (currentpath.includes("adminfacility")) {
      setIdActive(17);
    } else if (currentpath.includes("loanadvance")) {
      setIdActive(18);
    } else if (currentpath.includes("deputation")) {
      setIdActive(19);
    } else if (currentpath.includes("relocation")) {
      setIdActive(20);
    } else if (currentpath.includes("resignation")) {
      setIdActive(21);
    } else if (currentpath.includes("dueclearance")) {
      setIdActive(22);
    } else if (currentpath.includes("salary")) {
      setIdActive(23);
    } else if (currentpath.includes("declaration")) {
      setIdActive(24);
    } else if (currentpath.includes("submission")) {
      setIdActive(25);
    } else if (currentpath.includes("internaljobs")) {
      setIdActive(26);
    } else if (currentpath.includes("referral")) {
      setIdActive(27);
    } else if (currentpath.includes("goalsettings")) {
      setIdActive(28);
    } else if (currentpath.includes("appraisals")) {
      setIdActive(29);
    } else if (currentpath.includes("rewards")) {
      setIdActive(30);
    } else if (currentpath.includes("skills")) {
      setIdActive(31);
    } else if (currentpath.includes("competencies")) {
      setIdActive(32);
    } else if (currentpath.includes("learning")) {
      setIdActive(33);
    } else if (currentpath.includes("training")) {
      setIdActive(34);
    } else if (currentpath.includes("gatepass")) {
      setIdActive(35);
    } else if (currentpath.includes("meetingroom")) {
      setIdActive(36);
    } else if (currentpath.includes("companycar")) {
      setIdActive(37);
    } else if (currentpath.includes("food")) {
      setIdActive(38);
    } else if (currentpath.includes("hospitality")) {
      setIdActive(39);
    } else if (currentpath.includes("visitors")) {
      setIdActive(40);
    } else if (currentpath.includes("sendcourier")) {
      setIdActive(41);
    } else if (currentpath.includes("contacts")) {
      setIdActive(42);
    } else if (currentpath.includes("keys")) {
      setIdActive(43);
    } else if (currentpath.includes("itdesk")) {
      setIdActive(44);
    } else if (currentpath.includes("exception")) {
      setIdActive(45);
    } else if (currentpath.includes("travelbooking")) {
      setIdActive(46);
    } else if (currentpath.includes("hotelbooking")) {
      setIdActive(47);
    } else if (currentpath.includes("traveladvance")) {
      setIdActive(48);
    } else if (currentpath.includes("reimbursement")) {
      setIdActive(49);
    } else if (currentpath.includes("filesfolders")) {
      setIdActive(50);
    } else if (currentpath.includes("asset")) {
      setIdActive(51);
    } else if (currentpath.includes("problem")) {
      setIdActive(52);
    } else if (currentpath.includes("housekeeping")) {
      setIdActive(53);
    } else if (currentpath.includes("receivecourier")) {
      setIdActive(54);
    } else if (currentpath.includes("myskill")) {
      setIdActive(57);
    } else if (currentpath.includes("mycompetency")) {
      setIdActive(56);
    } else if (currentpath.includes("overtime")) {
      setIdActive(55);
    } else {
      navigate("/services/profile");
    }
  }, [location.pathname]);
  const getProfileDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Employees/Profile/${LoggedInUser?.id}`);
      setProfileList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");

      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const [featureMenu, setFeatureMenu] = useState(null);
  const { menuModuleGroup } = useSelector((state) => state.menu);
  useEffect(() => {
    if (menuModuleGroup) {
      setFeatureMenu(menuModuleGroup);
    }
  }, [menuModuleGroup]);

  const getActiveStatus = (approvalItem) => {
    let isActive = featureMenu
      ?.find((x) => x[0]?.menuName === approvalItem?.menu)
      ?.find((x) => x.featureName === approvalItem?.name)
      ? featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.featureName === approvalItem?.name)?.isFeatureActive
      : featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.subMenuName === approvalItem?.name)?.isSubMenuActive;
    let displayName = featureMenu
      ?.find((x) => x[0]?.menuName === approvalItem?.menu)
      ?.find((x) => x.featureName === approvalItem?.name)
      ? featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.featureName === approvalItem?.name)
          ?.featureDisplayName
      : featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.subMenuName === approvalItem?.name)
          ?.subMenuDisplayName;
    return { isActive, displayName };
  };
  return (
    <div className="pageContainer">
      <div
        className={`homeDashboardContainer hideDashboard ${
          idActive === 1 && "viewDashboard"
        }`}
      >
        <div className="profileImgHolder">
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarBadge">
            {teamCardData?.selfProfileCompletionPercentage}%
            {/* <img src={InfoIcon} alt="Info" className="infoIcon" /> */}
          </div>
          <div
            className="avatarContainer"
            onClick={() => {
              getTeamProfileData();
              togglePopup();
            }}
            style={{ cursor: "pointer" }}
          >
            <CircularProgressbarWithChildren
              value={teamCardData?.selfProfileCompletionPercentage}
              text={`${teamCardData?.selfProfileCompletionPercentage}%`}
              strokeWidth={5}
              styles={buildStyles({
                textColor: "transparent",
                pathColor: "#1296B0",
                trailColor: "#D8F0F2",
                rotation: 0.37,
                strokeLinecap: "round",
              })}
            >
              {bannerList?.Image ? (
                <img
                  src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.Image}`}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              ) : (
                <img
                  src={ProfileAvatar}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              )}
            </CircularProgressbarWithChildren>
          </div>
        </div>

        <div className="homeGreetingContainer">
          <h3>{bannerList?.Name}</h3>
          <h4>{bannerList?.Role}</h4>
          <div className="horizontalLinePrimary"></div>
          {/* <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div> */}
        </div>

        <div className="dashboardRight">
          <div className="dashboardServices">
            <div className="dashboardServicesContainer">
              <img
                src={RoleIcon}
                alt="Role"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Role</p>
                <p className="dashboardServicesName">{bannerList?.Role}</p>
              </div>
            </div>
            <div className="dashboardServicesContainer">
              <img
                src={DepartmentIcon}
                alt="Department"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Department</p>
                <p className="dashboardServicesName">
                  {bannerList?.Department}
                </p>
              </div>
            </div>
            <div className="dashboardServicesContainer">
              <img
                src={CompanyExperienceIcon}
                alt="CompanyExperience"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Company Experience</p>
                <p className="dashboardServicesName">
                  {bannerList?.CompanyExperience
                    ? bannerList?.CompanyExperience !== ""
                      ? bannerList?.CompanyExperience
                      : "---"
                    : "---"}
                </p>
              </div>
            </div>
          </div>
          <div className="dashboardServices">
            <div className="dashboardServicesContainer">
              <img
                src={DesignationIcon}
                alt="Designation"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Designation</p>
                <p className="dashboardServicesName">
                  {bannerList?.Designations}
                </p>
              </div>
            </div>
            <div className="dashboardServicesContainer">
              <img
                src={LocationIcon}
                alt="Location"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Location</p>
                <p className="dashboardServicesName">
                  {profileList?.locationName
                    ? profileList?.locationName
                    : bannerList?.Location}
                </p>
              </div>
            </div>
            <div className="dashboardServicesContainer">
              <img
                src={ExperienceIcon}
                alt="Experience"
                className="dashboardServicesIcon"
              />
              <div className="verticalLineGray" />
              <div className="dashboardServicesType">
                <p className="dashboardServicesHeading">Overall Experience</p>
                <p className="dashboardServicesName">
                  {bannerList?.OverallExperience
                    ? bannerList?.OverallExperience !== ""
                      ? bannerList?.OverallExperience
                      : "---"
                    : "---"}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="editPencil">
          <img src={EditPencilIcon}
            alt="Edit Pencil"
            className="editPencilIcon" />
        </div> */}
          {/* <div className="socialIconContainer">
          <img src={TwitterIcon} alt="Twitter" className="socialIcon" />
          <img src={LinkedinIcon} alt="Linkedin" className="socialIcon" />
          <img src={FacebookIcon} alt="Facebook" className="socialIcon" />
        </div> */}
        </div>
      </div>

      <div
        className={`servicesContainer newHeight ${
          idActive === 1 && "sameHeight"
        }`}
      >
        {location.pathname
          .toLowerCase()
          .includes("services/myskill/create") && <ServiceMySkillCreate />}
        {location.pathname
          .toLowerCase()
          .includes("services/myskill/details") && <ServiceMySkillDetails />}
        {location.pathname
          .toLowerCase()
          .includes("services/mycompetency/create") && (
          <ServiceMyCompetencyCreate />
        )}
        {location.pathname
          .toLowerCase()
          .includes("services/mycompetency/details") && (
          <ServiceMyCompetencyDetails />
        )}
        <div className="servicesSidebar">
          <div
            className="chatListSearchContainerService"
            style={{
              position: "fixed",
              zIndex: "6",
              width: "21.35%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <input
              type="search"
              id="search-form"
              className="chatListSearch"
              placeholder="Search"
              value={s}
              onChange={(e) => setS(e.target.value)}
              // onClick={clickHandler}
            />
          </div>
          <div style={{ padding: "28px" }}></div>

          {search(sidebarData)?.map((item, key) => {
            let itemDetails = getActiveStatus(item);

            if (item?.name === "Problem") {
              itemDetails.isActive = true;
            }
            if (
              itemDetails.isActive ||
              item.name === "My Profile" ||
              item.name === "Asset"
            )
              return (
                <div
                  key={key}
                  className={`menuOptions ${
                    item.id === idActive ? "menuOptionsActive" : ""
                  }`}
                  onClick={() => {
                    clickHandler(item);
                  }}
                >
                  <div className="verticalLine"></div>
                  <img src={item.icon} alt="Icon" />
                  <label>{itemDetails?.displayName ?? item.name}</label>
                </div>
              );
            else return null;
          })}
        </div>
        <div className="servicesContentHolder">
          {idActive === 1 ? (
            <MyProfile
              loading={loading}
              profileList={profileList}
              isManager={isManager}
            />
          ) : null}
          {idActive === 2 ? <ServiceRequisition /> : null}
          {idActive === 3 ? (
            <ServicePlanning newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 4 ? (
            <ServicePolicies newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 5 ? (
            <ServiceAttendance newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 6 ? (
            <ServiceRegularisation title={"Regularisation"} />
          ) : null}
          {idActive === 7 ? <ServiceLateComing title={"Late Coming"} /> : null}
          {idActive === 8 ? <ServiceEarlyGoing title={"Early Going"} /> : null}
          {idActive === 9 ? <ServiceLeave title={"Leave"} /> : null}
          {idActive === 10 ? <ServiceCompOffs title={"Comp Offs"} /> : null}
          {idActive === 11 ? (
            <ServiceHoliday newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 12 ? (
            <ServiceOutdoor newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 13 ? (
            <ServiceProject newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 14 ? (
            <ServiceTask newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 15 ? <ServiceTimesheet /> : null}
          {idActive === 16 ? (
            <ServiceHRDesk title={"Hr Desk"} newHeight={{ height: "61rem" }} />
          ) : null}
          {idActive === 17 ? (
            <ServiceAdminFacility title={"Facilities"} />
          ) : null}
          {idActive === 18 ? (
            <ServiceLoanAdvance title={"Loan & Advance"} />
          ) : null}
          {idActive === 19 ? (
            <ServiceDeputation
              title={"Deputation"}
              newHeight={{ height: "61rem" }}
            />
          ) : null}
          {idActive === 20 ? (
            <ServiceRelocation
              title={"Relocation"}
              newHeight={{ height: "61rem" }}
            />
          ) : null}
          {idActive === 21 ? (
            <ServiceResignation
              newHeight={{ height: "62rem" }}
              title="resignation"
            />
          ) : null}
          {idActive === 22 ? (
            <ServiceDueClearance newHeight={{ height: "62rem" }} />
          ) : null}
          {idActive === 23 ? <ServiceSalary title="Salary" /> : null}
          {idActive === 24 ? <ServiceDeclaration title="Declaration" /> : null}
          {idActive === 25 ? <ServiceSubmission title="Submission" /> : null}
          {idActive === 26 ? (
            <ServiceInternalJobs title="InternalJobs" />
          ) : null}
          {idActive === 27 ? <ServiceReferral title="Referral" /> : null}
          {idActive === 28 ? (
            <ServiceGoalSettings title="Goal Settings" />
          ) : null}
          {idActive === 29 ? <ServiceAppraisals title="Appraisals" /> : null}
          {idActive === 30 ? <ServiceRewards title="Rewards" /> : null}
          {idActive === 33 ? <ServiceLearning title="Learning" /> : null}
          {idActive === 34 ? <ServiceTraining title="Training" /> : null}
          {idActive === 35 ? <ServiceGatePass title="Gate Pass" /> : null}
          {idActive === 36 ? <ServiceMeetingRoom title="Meeting Room" /> : null}
          {idActive === 37 ? <ServiceVehicle title="Company Car" /> : null}
          {idActive === 38 ? <ServiceFood title="Food" /> : null}
          {idActive === 39 ? <ServiceHospitality title="Hospitality" /> : null}
          {idActive === 40 ? <ServiceVisitors title="Visitors" /> : null}
          {idActive === 41 ? <ServiceSendCourier title="Send Courier" /> : null}
          {idActive === 42 ? <ServiceContacts title="Contacts" /> : null}
          {idActive === 43 ? <ServiceKeys title="Keys" /> : null}
          {idActive === 44 ? <ServiceITDesk title="ITDesk" /> : null}
          {idActive === 45 ? <ServiceException title="Exception" /> : null}
          {idActive === 46 ? (
            <ServiceTravelBooking title="Travel Booking" />
          ) : null}
          {idActive === 47 ? (
            <ServiceHotelBooking title="Hotel Booking" />
          ) : null}
          {idActive === 48 ? (
            <ServiceTravelAdvance title="Travel Advance" />
          ) : null}
          {idActive === 49 ? (
            <ServiceReimbursement title="Reimbursement" />
          ) : null}
          {idActive === 50 ? (
            <ServiceFilesFolders title="Files & Folders" />
          ) : null}
          {idActive === 51 ? <ServiceAsset title="My Asset" /> : null}
          {idActive === 52 ? <ServiceProblem title="My Asset" /> : null}
          {idActive === 53 ? (
            <ServiceHouseKeeping title="My HouseKeeping" />
          ) : null}
          {idActive === 54 ? (
            <ServiceReceiveCourier title="Receive Courier" />
          ) : null}
          {idActive === 57 ? <ServiceMySkill title="My Skills" /> : null}
          {idActive === 56 ? (
            <ServiceMyCompetency title="My Competency" />
          ) : null}
          {idActive === 55 ? <ServiceOvertime title="Overtime" /> : null}
        </div>
      </div>
      {isOpen && (
        <PopProfile
          content={
            <>
              <ProfileDetails
                loading={loading}
                getProfileData={getProfileData}
                minHeigh={"400px"}
                educationDetailsData={educationDetailsData}
                personalDetails={personalDetails}
                currentAddress={currentAddress}
                permanentAddress={permanentAddress}
                organisationDetails={organisationDetails}
                identificationDetails={identificationDetails}
                paymentDetails={paymentDetails}
                emergencyContact={emergencyContact}
                employeeProfile={employeeProfile}
                handleClose={togglePopup}
                experienceDetails={experienceDetails}
                bannerList={bannerList}
                ProfileAvatar={ProfileAvatar}
                profilePercentage={
                  teamCardData?.selfProfileCompletionPercentage
                }
              />
            </>
          }
        />
      )}
    </div>
  );
};

export { Services };
