import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Timeline } from "antd";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
const DetailsHotelBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [dashboard, setDashboard] = useState(true);

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);
  const [summary, setSummary] = useState(false);
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setSelId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      setSummary(result?.data?.summary);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.color,
              result.data?.summary?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Hotel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "Export"
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Export"
      ) {
        await getRequest(`TravelHospitalityRequests/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`TravelHospitalityRequests/HRApprove/${id}`);
      } else {
        await postRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
    getDash();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const getDash = async () => {
    try {
      const result = await getRequest("HrHelpdesks/Dashboard");

      setDashboard(result.data);
    } catch (error) {
    } finally {
    }
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Hotel Booking Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 && data != null && (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm">Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Reference Number"}
                        Details={data?._request?.requestNumber}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(data?.status)?.split(
                                ","
                              )?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {data?.status}
                          </div>
                        }
                      />
                      {data?._request?.isLinkedTravelHotel && (
                        <Details
                          detailTitle={"Travel Mode"}
                          Details={data?._request?.mode}
                        />
                      )}
                      <Details
                        detailTitle={"Request Type"}
                        Details={data?._request?.type}
                      />
                      <Details
                        detailTitle={"Travel Type"}
                        Details={data?.hoteldetail[0]?.travellingType}
                      />
                      {!data?._request?.isLinkedTravelHotel && (
                        <Details
                          detailTitle={"Budget"}
                          Details={
                            data?._request?.budget !== null
                              ? data?._request?.budget
                              : "-"
                          }
                        />
                      )}
                      {data?._request?.isLinkedTravelHotel && (
                        <Details
                          detailTitle={"Travel Budget"}
                          Details={
                            data?._request?.budget !== null
                              ? data?._request?.budget
                              : "-"
                          }
                        />
                      )}
                      <Details
                        detailTitle={"Previous Final Budget"}
                        Details={
                          data?.total_Actual_travel_and_hotel_budget !== null
                            ? data?.total_Actual_travel_and_hotel_budget
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Actual Final Budget"}
                        Details={
                          data?.total_final_budget !== null
                            ? data?.total_final_budget
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Created On"}
                        Details={
                          data?._request?.createdOn
                            ? format(
                                new Date(data?._request?.createdOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Is Linked To Travel?"}
                        Details={
                          data?._request?.isLinkedTravelHotel ? "Yes" : "No"
                        }
                      />
                      <Details
                        detailTitle={"Last Updated On"}
                        Details={
                          data?._request?.lastUpdatedOn
                            ? format(
                                new Date(data?._request?.lastUpdatedOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />
                    </div>
                    <div className="headingForm" style={{ width: "100%" }}>
                      Hotel Booking Details{" "}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#EFF9FA",
                        borderRadius: "12px",
                        padding: "0px 0px 10px 0px",
                      }}
                    >
                      <div className="detailInner">
                        {data?.hoteldetail?.map((person, index) => (
                          <div>
                            <div
                              // className="headingForm"
                              style={{
                                width: "100%",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                fontSize: "15px",
                                padding: "4px",
                                display: "flex",
                                alignItems: "center",
                                color: "#1296b0",
                                height: "24px",
                                margin: "10px 0px 9px 0px",
                              }}
                            >
                              Hotel Booked #{index + 1}
                            </div>
                            <div
                              style={{
                                // borderBottom: "1px solid #E6E6E6",
                                margin: "10px 0px",
                              }}
                              className="detailInner"
                            >
                              <Details
                                detailTitle={"Hotel Category "}
                                Details={data?.category_name_hotel}
                              />
                              <Details
                                detailTitle={"Check In Time"}
                                Details={
                                  person?.checkInTime
                                    ? format(
                                        new Date(person?.checkInTime),
                                        "dd/MM/yyyy hh:mm a"
                                      )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Location"}
                                Details={person?.location}
                              />
                              <Details
                                detailTitle={"Check Out Time"}
                                Details={
                                  person?.checkOutTime
                                    ? format(
                                        new Date(person?.checkOutTime),
                                        "dd/MM/yyyy hh:mm a"
                                      )
                                    : "-"
                                }
                              />
                              <Details
                                detailTitle={"Preferred Hotel"}
                                Details={person?.preferHotel}
                              />
                              {data?._request?.isLinkedTravelHotel && (
                                <Details
                                  detailTitle={"Budget"}
                                  Details={person?.budget}
                                />
                              )}
                              {data?._request?.isLinkedTravelHotel && (
                                <Details
                                  detailTitle={"Booking Note"}
                                  Details={person?.bookingNote}
                                />
                              )}
                            </div>
                            <div style={{ padding: "0px 12px" }}>
                              <div
                                // className="headingForm"
                                style={{
                                  width: "100%",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#1296b0",
                                  height: "24px",
                                  margin: "10px 0px 9px 0px",
                                }}
                              >
                                {data?._request?.type === "Self"
                                  ? "Employee's"
                                  : data?._request?.type === "Group"
                                  ? "Employee's"
                                  : data?._request?.type === "Vendor"
                                  ? "Vendor's"
                                  : data?._request?.type === "Guest"
                                  ? "Guest's"
                                  : data?._request?.type === "Candidate" &&
                                    "Candidate's"}{" "}
                                Details
                              </div>
                              <div
                                style={{
                                  overflowY: "auto",
                                }}
                              >
                                <table
                                  className="TravelTableMultiIn"
                                  style={{
                                    overflow: "auto",
                                    width: "100%",
                                    // height: "197px",
                                  }}
                                >
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        S.no{" "}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        {data?._request?.type === "Self"
                                          ? "Employee's"
                                          : data?._request?.type === "Group"
                                          ? "Employee's"
                                          : data?._request?.type === "Vendor"
                                          ? "Vendor's"
                                          : data?._request?.type === "Guest"
                                          ? "Guest's"
                                          : data?._request?.type ===
                                              "Candidate" && "Candidate's"}{" "}
                                        Name{" "}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Phone No.{" "}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Email{" "}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Adults{" "}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          textAlign: "start",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Children{" "}
                                      </td>
                                    </tr>
                                  </thead>
                                  {data?.hotelemployee
                                    ?.filter(
                                      (items) =>
                                        items?.hotelDetailId === person?.id
                                    )
                                    ?.map((item, indexW) => (
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {indexW + 1}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {item?.name}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {item?.phoneNumber}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {item?.emailId}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {item?.adults}
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid black",
                                            textAlign: "start",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {item?.children}
                                        </td>
                                      </tr>
                                    ))}
                                </table>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {data?._request?.isLinkedTravelHotel && (
                      <>
                        <div className="headingForm" style={{ width: "100%" }}>
                          Travel Journey Details
                        </div>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#EFF9FA",
                            borderRadius: "12px",
                            padding: "0px 0px 10px 0px",
                          }}
                        >
                          <div className="detailInner">
                            {data?.travellingdetails?.map((person, index) => (
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  // className="headingForm"
                                  style={{
                                    width: "100%",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    padding: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#1296b0",
                                    height: "24px",
                                    margin: "10px 0px 9px 0px",
                                  }}
                                >
                                  Travel Journey #{index + 1}
                                </div>
                                <div
                                  style={{
                                    // borderBottom: "1px solid #E6E6E6",
                                    margin: "10px 0px",
                                  }}
                                  className="detailInner"
                                >
                                  <Details
                                    detailTitle={"Travelling Date"}
                                    Details={person?.fromDate}
                                  />
                                  <Details
                                    detailTitle={"Form Location"}
                                    Details={person?.pickupLocation}
                                  />
                                  <Details
                                    detailTitle={"Booking Note"}
                                    Details={person?.bookingnote}
                                  />
                                  <Details
                                    detailTitle={"To Location"}
                                    Details={person?.dropLocation}
                                  />
                                </div>
                                <div style={{ padding: "0px 12px" }}>
                                  <div
                                    // className="headingForm"
                                    style={{
                                      width: "100%",
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                      padding: "4px",
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#1296b0",
                                      height: "24px",
                                      margin: "10px 0px 9px 0px",
                                    }}
                                  >
                                    {data?._request?.type === "Self"
                                      ? "Employee's Details"
                                      : data?._request?.type === "Group"
                                      ? "Employee's Details"
                                      : data?._request?.type === "Vendor"
                                      ? "Vendor's Details"
                                      : data?._request?.type === "Guest"
                                      ? "Guest's Details"
                                      : data?._request?.type === "Candidate" &&
                                        "Candidate's Details"}
                                  </div>
                                  <div
                                    style={{
                                      maxHeight: "300px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <table
                                      className="TravelTableMultiIn"
                                      style={{
                                        overflow: "auto",
                                        width: "100%",
                                        // height: "197px",
                                      }}
                                    >
                                      <thead
                                        style={{
                                          backgroundColor:
                                            "var(--primary-hover)",
                                        }}
                                      >
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            S.no{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            {data?._request?.type === "Self"
                                              ? "Employee's"
                                              : data?._request?.type === "Group"
                                              ? "Employee's"
                                              : data?._request?.type ===
                                                "Vendor"
                                              ? "Vendor's"
                                              : data?._request?.type === "Guest"
                                              ? "Guest's"
                                              : data?._request?.type ===
                                                  "Candidate" &&
                                                "Candidate's"}{" "}
                                            Name{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            Phone No.{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            Email{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            Age{" "}
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            Gender{" "}
                                          </td>
                                        </tr>
                                      </thead>
                                      {data?.travelemployees
                                        ?.filter(
                                          (items) =>
                                            items?.travellingDetailId ===
                                            person?.id
                                        )
                                        ?.map((item, indexW) => (
                                          <tr>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {indexW + 1}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.name}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.phoneNumber}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.emailId}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.age}
                                            </td>
                                            <td
                                              style={{
                                                border: "1px solid black",
                                                textAlign: "start",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {item?.gender}
                                            </td>
                                          </tr>
                                        ))}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?._request?.employee?.name} ${data?._request?.employee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?._request?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?._request?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?._request?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?._request?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={data?._request?.employee?.personalPhoneNumber}
                      />
                      <Details
                        detailTitle={"Location"}
                        Details={data?._request?.employee?.locationName}
                      />
                    </div>

                    {/* {data?.bookinglist?.length > 0 && (
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{ backgroundColor: "var(--primary-hover)" }}
                        >
                          <tr>
                            <th width="3%">S No.</th>
                            <th width="3%">Option</th>
                            <th width="3%">Attachment</th>
                            <th width="3%">amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.bookinglist?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.remarks}</td>
                                <td>
                                  {item?.attachment?.includes("|") ? (
                                    item?.attachment?.split("|").map((item) => {
                                      return (
                                        <div>
                                          {" "}
                                          <a
                                            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item}`}
                                            target="blank"
                                          >
                                            {item}
                                          </a>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <a
                                      href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item?.attachment}`}
                                      target="blank"
                                    >
                                      {item?.attachment}
                                    </a>
                                  )}
                                </td>
                                <td>{item?.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}

                    <div style={{ padding: "0 12px" }}>
                      {data.type !== "Self" &&
                      data.type !== "Group" &&
                      data.type !== "Candidate" ? (
                        <>
                          <table
                            className="tabless tabless-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <th width="3%">Sl. No.</th>
                                <th width="3%">Name</th>
                                <th width="3%">Phone Number</th>
                                <th width="3%">Email</th>
                                {data.mode !== "Cab" && (
                                  <>
                                    <th width="3%">Gender</th>
                                    <th width="3%">Age</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data != null &&
                                data?.items != null &&
                                data?.items.length > 0 &&
                                data?.items?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.name}</td>
                                      <td>{item?.phoneNumber}</td>
                                      <td>{item?.emailId}</td>
                                      <td>{item?.gender}</td>
                                      <td>{item?.age}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      ) : data?.type === "Group" ? (
                        <>
                          <div
                            className="headingForm"
                            style={{ margin: "0px 0px 24px 0px" }}
                          >
                            Booking Details
                          </div>
                          <table
                            className="tabless tabless-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <th width="3%">Sl. No.</th>
                                <th width="3%">Name</th>
                                <th width="3%">Phone Number</th>
                                <th width="3%">Email</th>
                                <th width="3%">Gender</th>
                                <th width="3%">Age</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data != null &&
                                data?.employeeItems != null &&
                                data?.employeeItems.length > 0 &&
                                data?.employeeItems?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.selectedItem?.text}</td>
                                      <td>{item?.phoneNumber}</td>
                                      <td>{item?.emailId}</td>
                                      <td>{item?.gender}</td>
                                      <td>{item?.age}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        ""
                      )}
                    </div> */}

                    <div
                      className="createFootDetails"
                      style={{ background: "white !important" }}
                    >
                      {summary?.actionlist?.length > 0
                        ? summary?.actionlist.map((item) => {
                            if (
                              item.type === "Approve" ||
                              item?.type === "Reject" ||
                              item?.type === "Release"
                            )
                              return (
                                <div>
                                  <div
                                    className="button secondaryButton"
                                    onClick={() =>
                                      clickHandlerFunction(
                                        item?.id,
                                        item?.type,
                                        item?.color
                                      )
                                    }
                                    style={{
                                      background: item?.color,
                                      color: "white",
                                    }}
                                  >
                                    {item?.type}
                                  </div>
                                </div>
                              );
                            else return <></>;
                          })
                        : null}{" "}
                    </div>
                    {isOpen && (
                      <Popup
                        popupHeading={typeAction}
                        content={
                          <>
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url="TravelHospitalityRequests"
                      requestNumber={data?.requestNumber}
                      module={"Travel and Hospitality Requests"}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Travel and Hospitality Requests"}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { DetailsHotelBooking };
