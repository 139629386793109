import React, { useEffect, useState } from "react";
import CandidateProfileBackground from "../../../Assets/CandidateProfileBackground.svg";
import InfoIcon from "../../../Assets/InfoIcon.svg";
import ProfileAvatar from "../../../Assets/ProfileAvatar.svg";
import MedalIcon from "../../../Assets/MedalIcon.svg";
import purplearrow from "../../../Assets/purplearrow.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import LearningSummaryIcon from "../../../Assets/LearningSummaryIcon.svg";
import LearningProgressIcon from "../../../Assets/LearningProgressIcon.svg";
import LearningCategoryIcon from "../../../Assets/LearningCategoryIcon.svg";
import { CourseContainer } from "./CourseContainer";
import { getRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { Loader } from "../../../Components/FormComponent/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Nodata from "../../../Assets/Nodata.svg";

const MyLearning = () => {
  const [teamCardData, setTeamCardData] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const [categoryDetailsform, setCategoryDetailsform] = useState({});
  const [bannerList, setBannerList] = useState();
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getTeamCardData = async () => {
    try {
      const result = await getRequest("Settings/GetBannerData");
      setTeamCardData(result?.data?.data);
      // console.log('result.data', result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getBannerDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LMSController/Banner`);
      // console.log("LMSController", result);
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getCoursesCategoryDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LMSController/coursescategory`);
      // console.log("LMSControllercoursescategory", result);
      setCategoryDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  // console.log("categoryDetailsform", categoryDetailsform);

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("/ld/learning/mylearning"))
      getBannerDetails();
    getCoursesCategoryDetails();
    getProfileData();
    getTeamCardData();
  }, [location.pathname]);

  const courseDataCollected =
    categoryDetailsform?.categoryCompletionPercentages?.map((item, index) => ({
      category: item?.category,
      percentageCompletion: item?.percentageCompletion,
      chapterCount: categoryDetailsform?.categoryCounts[index]?.chapterCount,
      courseCount: categoryDetailsform?.categoryCounts[index]?.courseCount,
      totalPlaybackSeconds:
        categoryDetailsform?.categoryDurations[index]?.totalPlaybackSeconds,
      totalDuration:
        categoryDetailsform?.categoryDurations[index]?.totalDuration,
      // remainingPercentage:
      //   categoryDetailsform?.categoryRemainingPercentages[index]
      //     ?.remainingPercentage,
    }));

  const dataContent = categoryDetailsform?._data?.reduce(
    (accumulator, currentItem) => {
      const categoryExists = accumulator.some(
        (item) => item.category === currentItem.category
      );
      if (!categoryExists) {
        accumulator.push(currentItem);
      }

      return accumulator;
    },
    []
  );
  const secstotimeformat = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
    const remainingSeconds = totalSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    let seconds = remainingSeconds % 60;
    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  };
  // console.log("courseDataCollected", courseDataCollected);
  // console.log("dataContent", dataContent);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ overflowY: "auto", overflowX: "hidden" }}>
          {/* <div
            className="timelineContainer candidateProfileContainer"
            style={{
              height: "18rem",
              overflowY: "hidden",
              width: "97%",
              margin: "30px",
            }}
          > */}
          <div className="candidateProfileHeaderSection1">
            <div className="candidateProfileHeaderld1">
              <div className="candidateProfileHeaderDiv1">
                <div className="profileImgHolderld">
                  <img
                    src={CandidateProfileBackground}
                    alt="Profile"
                    className="profileImgHolderbackground candidateProfilePicHolderLD"
                  />
                  <div className="avatarBadgeld">
                    {teamCardData?.selfProfileCompletionPercentage}%
                    {/* <img src={InfoIcon} alt="Info" className="infoIcon" /> */}
                    {/* <img src={MedalIcon} alt="Info" className="medalClass" /> */}
                  </div>
                  <div className="avatarContainerld">
                    <CircularProgressbarWithChildren
                      value={teamCardData?.selfProfileCompletionPercentage}
                      text={`${teamCardData?.selfProfileCompletionPercentage}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "transparent",
                        pathColor: "#1296B0",
                        trailColor: "#D8F0F2",
                        rotation: 0.37,
                        strokeLinecap: "round",
                      })}
                    >
                      {bannerList?.Image ? (
                        <img
                          src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.Image}`}
                          alt={"Profile"}
                          className="imageInsideProgressBar"
                        />
                      ) : (
                        <img
                          src={ProfileAvatar}
                          alt={"Profile"}
                          className="imageInsideProgressBar"
                        />
                      )}
                    </CircularProgressbarWithChildren>
                  </div>
                </div>

                <div className="homeGreetingContainerld">
                  <h3
                    className="titleColor h3marginld"
                    style={{ marginLeft: "0px" }}
                  >
                    Hey,
                  </h3>
                  <h3 className="h3marginld" style={{ marginLeft: "0px" }}>
                    {bannerList ? bannerList?.Name : "-"}
                  </h3>
                  <h5 className="h4marginld">
                    {detailsform?._data
                      ? detailsform?._data[0]?.personNumber
                      : "-"}
                  </h5>
                  <div
                    className="horizontalLinePrimary"
                    style={{ marginTop: "10px" }}
                  ></div>
                  <h4 className="h4marginld">
                    {bannerList ? bannerList?.Designations : "-"}
                  </h4>
                </div>
              </div>

              <div className="mylearningdetailssection">
                <div className="mylearningdetailssubsection1">
                  <div className="summaryBox1">
                    <div className="learningLeft">
                      <div className="learningImage">
                        <img
                          src={LearningSummaryIcon}
                          alt={LearningSummaryIcon}
                        />
                      </div>
                      <div
                        className="learningHeading"
                        style={{ color: "#F04E30" }}
                      >
                        <div
                          className="horizontalLine"
                          style={{
                            backgroundColor: "#FB7158",
                            width: "2rem",
                            marginBottom: "7px",
                          }}
                        ></div>
                        <span className="learningfont">LEARNING SUMMARY</span>
                      </div>
                    </div>
                    <div className="learningCount learningCountdiv">
                      <div className="countHolder">
                        <span className="countLabel">Total</span>
                        <span
                          className="learningCount"
                          style={{ color: "#FB7158" }}
                        >
                          {detailsform ? detailsform?.total : "-"}
                        </span>
                      </div>
                      <div
                        className="timesheetVerticalLineld"
                        style={{ margin: "0 6px" }}
                      ></div>

                      <div className="countHolder">
                        <span className="countLabel">In Progress</span>
                        <span className="learningCount">
                          {detailsform ? detailsform?.started : "-"}
                        </span>
                      </div>

                      <div
                        className="timesheetVerticalLineld"
                        style={{ margin: "0 6px" }}
                      ></div>

                      <div
                        className="countHolder"
                        style={{ marginRight: "10px" }}
                      >
                        <span className="countLabel">Completed</span>
                        <span className="learningCount">
                          {detailsform ? detailsform?.completed : "-"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="summaryBox2">
                    <div className="learningLeft">
                      <div className="learningImage">
                        <img
                          src={LearningCategoryIcon}
                          alt={LearningCategoryIcon}
                        />
                      </div>
                      <div
                        className="learningHeading"
                        style={{ color: "#5BC4BF" }}
                      >
                        <div
                          className="horizontalLine"
                          style={{
                            backgroundColor: "#5BC4BF",
                            width: "2rem",
                            marginBottom: "7px",
                          }}
                        ></div>
                        <span>LEARNING CATEGORY</span>
                      </div>
                    </div>
                    <div
                      className="learningCount"
                      style={{ justifyContent: "space-evenly", width: "100%" }}
                    >
                      <div className="countHolder">
                        <span className="countLabel">Mandatory</span>
                        <span
                          className="learningCount"
                          style={{ color: "#5BC4BF" }}
                        >
                          {detailsform ? detailsform?.mandatory : "-"}
                        </span>
                      </div>
                      <div
                        className="timesheetVerticalLine"
                        style={{ margin: "0 6px" }}
                      ></div>

                      <div className="countHolder">
                        <span className="countLabel">Self Learning</span>
                        <span className="learningCount">
                          {detailsform ? detailsform?.selflearning : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mylearningdetailssubsection2">
                  <div className="learningProgressBox">
                    <div className="learningLeft">
                      <div className="learningImage">
                        <img
                          src={LearningProgressIcon}
                          alt={LearningProgressIcon}
                        />
                      </div>

                      <div
                        className="learningHeading"
                        style={{ color: "#8A88ED" }}
                      >
                        <div
                          className="horizontalLine"
                          style={{
                            backgroundColor: "#8A88ED",
                            width: "2rem",
                            marginBottom: "7px",
                          }}
                        ></div>
                        <span>LEARNING PROGRESS</span>
                      </div>
                    </div>

                    <div className="learningCount learningCountdiv">
                      <div className="countHolder">
                        <span className="countLabel">
                          Assigned Hours of Learning
                        </span>
                        <span
                          className="learningCount"
                          style={{ color: "#8A88ED" }}
                        >
                          {}
                          {detailsform
                            ? detailsform?.totalDuration
                              ? secstotimeformat(detailsform?.totalDuration)
                              : ""
                            : "-"}
                        </span>
                      </div>
                      <div className="timesheetVerticalLineld1"></div>

                      <div className="countHolder">
                        <span className="countLabel">
                          Completed Hours of Learning
                        </span>
                        <span className="learningCount">
                          {detailsform
                            ? detailsform?.totalprogress
                              ? secstotimeformat(detailsform?.totalprogress)
                              : ""
                            : "-"}
                        </span>
                      </div>

                      <div className="timesheetVerticalLineld1"></div>

                      <div
                        className="countHolder"
                        style={{ paddingRight: "9px" }}
                      >
                        <span className="countLabel">Of Completed</span>
                        <span
                          className="learningCount"
                          style={{ color: "#F71D1D" }}
                        >
                          {detailsform
                            ? `${
                                detailsform?.percentageCompletion
                                  ? detailsform?.percentageCompletion.toFixed(1)
                                  : 0
                              }%`
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/ld/learning/mylearning/mylist")}
                    className="learningProgressBox2"
                  >
                    <div className="learningProgressBox2font">
                      Learning Assessment Results
                    </div>
                    <div className="learningProgressBox2arrow">
                      <svg
                        className="imgwhitearrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                      >
                        <path
                          d="M0.742676 5.03223C0.328462 5.03223 -0.00732422 5.36801 -0.00732422 5.78223C-0.00732422 6.19644 0.328462 6.53223 0.742676 6.53223V5.03223ZM17.6973 6.31256C17.9902 6.01966 17.9902 5.54479 17.6973 5.2519L12.9244 0.478926C12.6315 0.186032 12.1566 0.186032 11.8637 0.478926C11.5708 0.771819 11.5708 1.24669 11.8637 1.53959L16.1063 5.78223L11.8637 10.0249C11.5708 10.3178 11.5708 10.7926 11.8637 11.0855C12.1566 11.3784 12.6315 11.3784 12.9244 11.0855L17.6973 6.31256ZM0.742676 6.53223H17.167V5.03223H0.742676V6.53223Z"
                          fill="#ffffff"
                        />
                      </svg>
                      <img
                        className="imgpurplearrow"
                        src={purplearrow}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="" style={{}}> */}
          <div className="coursesContainer">
            <div className="coursesHeading">
              Courses ({courseDataCollected?.length})
            </div>
            {courseDataCollected ? (
              <div className="coursesContent">
                {courseDataCollected?.map((category, index) => (
                  <CourseContainer
                    backgroundStyles={
                      index % 2 === 0
                        ? { backgroundColor: "var(--primary)" }
                        : { backgroundColor: "#FFEEF2" }
                    }
                    styles={
                      index % 2 === 0
                        ? { color: "var(--primary)", borderColor: "#D8F0F2" }
                        : { color: "#DE64A1", borderColor: "#FFEEF2" }
                    }
                    indexEven={index % 2 === 0 ? "first" : "second"}
                    name={category?.category}
                    courses={category?.courseCount}
                    chapters={category?.chapterCount}
                    categoryId={dataContent[index]?.id}
                    isMandatory={dataContent[index]?.type === "Mandantory"}
                    percentage={category?.percentageCompletion}
                    isCompleted={category?.percentageCompletion === 100}
                    totalDuration={category?.totalDuration}
                    key={index}
                    imgName={
                      categoryDetailsform?.categoryCounts[index]?.attachment
                    }
                    remainingDuration={
                      category?.totalDuration - category?.totalPlaybackSeconds <
                      0
                        ? 0
                        : category?.totalDuration -
                          category?.totalPlaybackSeconds
                    }
                    courseNumber={index + 1}
                  />
                ))}
              </div>
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Data Found"}</h4>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export { MyLearning };
