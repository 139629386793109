import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Loader } from "../../../../Components/FormComponent/Loader";

export const GetOpenTask = () => {
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil((detailsform?.length || 0) / itemsPerPage);

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest("Settings/GetOpenTask");
      setDetailsform(result?.data?.data?.task);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedData = detailsform?.slice(startIndex, endIndex);
  return (
    <div className="tableScrollContainer">
      <div className="tableContainer">
        <table className="responsive-table tableHeaderRow">
          {/* Table Header */}
          <thead>
            <tr>
              <th>S no.</th>
              <th>Request Number</th>
              <th>Description</th>
              <th>Title</th>
              <th>Status</th>
              <th>Deadline</th>
              <th>Priority</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody className="scrollable-body">
            {slicedData?.map((item, index) => {
              return (
                <tr key={item?.id}>
                  <td>{index + 1}</td>
                  <td>{item?.requestNumber}</td>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "150px", // Set a maximum width for the cell
                    }}
                  >
                    {item?.description ? item?.description : "-"}
                  </td>
                  <td>{item?.title ? item?.title : "-"}</td>
                  <td>
                    {" "}
                    <div
                      style={{
                        backgroundColor: getStatusColor(item?.status)?.split(
                          ","
                        )?.[0],
                        color: getStatusColor(item?.status)?.split(",")?.[1],
                        cursor: "default",
                        padding: "0px 6px",
                        borderRadius: "4px",
                      }}
                    >
                      {item?.status}
                    </div>
                  </td>
                  <td>
                    {item?.endsOn
                      ? format(new Date(item?.endsOn), "dd/MM/yyyy ")
                      : "-"}
                  </td>
                  <td>{item?.priority ? item?.priority : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="horizontalLine"></div>
      <div className="tableFooterContainer">
        <label>Total Record Count: </label>
        <span>{detailsform?.length}</span>
        <label>Records Per Page</label>
        {currentPage > 1 ? (
          <IconButton
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            color="primary"
            className="pageChangerIcon pageChangerIconActive"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        ) : (
          <IconButton disabled color="primary" className="pageChangerIcon">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        )}
        {currentPage} - {totalPages}
        {totalPages > currentPage ? (
          <IconButton
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            color="primary"
            className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        ) : (
          <IconButton
            disabled
            color="primary"
            className={`pageChangerIcon pageChangerIconNext`}
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                stroke="#BFBFBF"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        )}
      </div>
    </div>
  );
};
