/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CheckboxFormiks,
  Loader,
} from "../../../../../Components/FormComponent/Index";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { Details } from "../../DetailsComp/Details";

function InitiateOnboarding({ id, handleClose, getListData }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [initialValue, setInitialValue] = useState({ 0: "" });
  const dispatch = useDispatch();
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Candidate/InitiateOnboardingView/${id}`);
      setData(result?.data?.data);
      let initialValues = {};
      if (result?.data?.data?.selectedOnboardingRequiredDocuments) {
        result?.data?.data?.selectedOnboardingRequiredDocuments?.map((item) => {
          initialValues[`RD${item?.documentId}`] = true;
          initialValues[`RD${item?.documentId}isMandatory`] = item.isMandatory;
          return null;
        });
      } else {
        result?.data?.data?.onboardingRequiredDocuments?.map((item) => {
          initialValues[`RD${item?.id}`] = true;
          initialValues[`RD${item?.id}isMandatory`] = true;
          return null;
        });
      }
      if (result?.data?.data?.selectedOnboardingRequiredForms) {
        result?.data?.data?.selectedOnboardingRequiredForms?.map((item) => {
          initialValues[`RF${item?.formId}`] = true;
          initialValues[`RF${item?.formId}isMandatory`] = item.isMandatory;
          return null;
        });
      } else {
        result?.data?.data?.onboardingRequiredForms?.map((item) => {
          initialValues[`RF${item?.id}`] = true;
          initialValues[`RF${item?.id}isMandatory`] = true;
          return null;
        });
      }
      setInitialValue(initialValues);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
      handleClose();
    } finally {
      setLoading(false);
    }
  };
  const initiateOnboarding = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Candidate/InitiateOnboarding", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      handleClose();
      getListData();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Initiate Onboarding`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="detailsEReq" style={{ flexDirection: "column" }}>
          <div className="detailInner">
            <Details
              style={{ width: "100%" }}
              detailTitle={"Name"}
              Details={data?.candidate?.name}
            />
            <Details
              style={{ width: "100%" }}
              detailTitle={"Email Id"}
              Details={data?.candidate?.email}
            />
            <Details
              style={{ width: "100%" }}
              detailTitle={"Phone Number"}
              Details={data?.candidate?.phoneNumber}
            />
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(values) => {
              let formValues = { ...values };
              Object.keys(formValues).forEach(function (key) {
                if (formValues[key] !== true) {
                  delete formValues[key];
                }
              });
              let trueKeys = Object.keys(formValues);
              const isMandatoryArrayRD = [];
              const isMandatoryArrayRF = [];
              const finalArrayRD = [];
              const finalArrayRF = [];
              trueKeys.map((item) => {
                if (item.includes("isMandatory")) {
                  let tempkey = item.slice(2);
                  let indexI = tempkey.indexOf("i");
                  let finalKey = tempkey.slice(0, indexI);
                  if (item.includes("RF")) {
                    isMandatoryArrayRF.push(finalKey);
                  } else {
                    isMandatoryArrayRD.push(finalKey);
                  }
                } else {
                  let tempkey = item.slice(2);
                  if (item.includes("RF")) {
                    finalArrayRF.push(tempkey);
                  } else {
                    finalArrayRD.push(tempkey);
                  }
                }
                return null;
              });
              let docArray = [];
              let formArray = [];
              finalArrayRD.map((item) => {
                docArray.push({
                  documentId: item,
                  mandatory: isMandatoryArrayRD.includes(item),
                  checked: true,
                });
                return null;
              });
              finalArrayRF.map((item) => {
                formArray.push({
                  formId: item,
                  mandatory: isMandatoryArrayRF.includes(item),
                  checked: true,
                });
                return null;
              });
              let finalReqData = {
                CandidateId: data?.candidate?.id,
                documents: docArray,
                forms: formArray,
              };
              initiateOnboarding(finalReqData);
            }}
          >
            {(formik) => (
              <Form>
                <div>
                  <div className="headingForm">Required Forms</div>
                  <div>
                    {data &&
                      data?.onboardingRequiredForms?.map((item) => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              <CheckboxFormiks
                                name={`RF${item?.id}`}
                                labelName={item?.title}
                              />
                            </div>
                            {formik.values[`RF${item?.id}`] && (
                              <div style={{ flex: 2 }}>
                                <CheckboxFormiks
                                  name={`RF${item?.id}isMandatory`}
                                  labelName={"Is Mandatory"}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className="headingForm">Required Documents</div>
                  <div>
                    {data &&
                      data?.onboardingRequiredDocuments?.map((item) => {
                        return (
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              <CheckboxFormiks
                                name={`RD${item?.id}`}
                                labelName={item?.name}
                              />
                            </div>
                            {formik.values[`RD${item?.id}`] && (
                              <div style={{ flex: 2 }}>
                                <CheckboxFormiks
                                  name={`RD${item?.id}isMandatory`}
                                  labelName={"Is Mandatory"}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div
                  className="createFootCust"
                  style={{ backgroundColor: "white" }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    Initiate
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default InitiateOnboarding;
