import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyCarComp } from "./FormComp/CreateCompanyCarComp";
import { confirmAlert } from "react-confirm-alert";
import { format } from "date-fns";
import { CreateVendorComp } from "./FormComp/CreateVendorComp";
import { useSelector } from "react-redux";

const CreateCompanyCar = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Company Car`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [detailsform, setDetailsform] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [initialValues, setInitialValues] = useState({
    time: detailsform?._request?.time
      ? new Date(`12/12/12 ${detailsform?._request?.time}`)
      : "",
    dropTime: detailsform?._request?.dropTime
      ? new Date(`12/12/12 ${detailsform?._request?.dropTime}`)
      : "",
  });

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // const formname = formValues?.type === "Self" ? LoggedInUser?.name : "";

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `CompanyCarController/Details?Id=${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);
  useEffect(() => {
    if (formValues?.type === "Self") {
      setInitialValues({
        type: detailsform ? detailsform?._request?.type : "Self",
        name: detailsform ? detailsform?._request?.name : LoggedInUser.name,
        designation: detailsform
          ? detailsform?._request?.designation
          : LoggedInUser?.designation,
        phoneNumber: detailsform
          ? detailsform?._request?.phoneNumber
          : LoggedInUser?.personalPhoneNumber,
        email: detailsform ? detailsform?._request?.email : LoggedInUser?.email,
        date: detailsform?._request?.date
          ? new Date(detailsform?._request?.date)
          : "",
        // time: detailsform ? detailsform?._request?.time : "",
        time: detailsform?._request?.time
          ? new Date(`12/12/12 ${detailsform?._request?.time}`)
          : "",
        pickupLocation: detailsform
          ? detailsform?._request?.pickupLocation
          : "",
        dropLocation: detailsform ? detailsform?._request?.dropLocation : "",
        // dropTime: detailsform ? detailsform?._request?.dropTime : "",
        dropTime: detailsform?._request?.dropTime
          ? new Date(`12/12/12 ${detailsform?._request?.dropTime}`)
          : "",
        numberOfPeople: detailsform
          ? detailsform?._request?.numberOfPeople.toString()
          : "",
        guestType: detailsform ? detailsform?._request?.guestType : "",
        guestOf: detailsform ? detailsform?._request?.guestOf : null,
        company: detailsform ? detailsform?._request?.company : "",
        purpose: detailsform ? detailsform?._request?.purpose : "",
        description: detailsform ? detailsform?._request?.description : "",
        remarks: detailsform ? detailsform?._request?.remarks : "",
        isDriverRequired: detailsform
          ? detailsform?._request?.isDriverRequired === true
            ? "Yes"
            : "No"
          : "",
      });
    } else {
      setInitialValues({
        type: detailsform ? detailsform?._request?.type : "",
        name: detailsform ? detailsform?._request?.name : "",
        designation: detailsform ? detailsform?._request?.designation : "",
        phoneNumber: detailsform ? detailsform?._request?.phoneNumber : "",
        email: detailsform ? detailsform?._request?.email : "",
        date: detailsform?._request?.date
          ? new Date(detailsform?._request?.date)
          : "",
        // time: detailsform ? detailsform?._request?.time : "",
        time: detailsform?._request?.time
          ? new Date(`12/12/12 ${detailsform?._request?.time}`)
          : "",
        pickupLocation: detailsform
          ? detailsform?._request?.pickupLocation
          : "",
        dropLocation: detailsform ? detailsform?._request?.dropLocation : "",
        // dropTime: detailsform ? detailsform?._request?.dropTime : "",
        dropTime: detailsform?._request?.dropTime
          ? new Date(`12/12/12 ${detailsform?._request?.dropTime}`)
          : "",
        numberOfPeople: detailsform
          ? detailsform?._request?.numberOfPeople.toString()
          : "",
        guestType: detailsform ? detailsform?._request?.guestType : "",
        guestOf: detailsform ? detailsform?._request?.guestOf : null,
        company: detailsform ? detailsform?._request?.company : "",
        purpose: detailsform ? detailsform?._request?.purpose : "",
        description: detailsform ? detailsform?._request?.description : "",
        remarks: detailsform ? detailsform?._request?.remarks : "",
        isDriverRequired: detailsform
          ? detailsform?._request?.isDriverRequired === true
            ? "Yes"
            : "No"
          : "",
      });
    }
  }, [formValues?.type]);
  // useEffect(() => {

  // }, [
  //   formValues?.type === "Vendor" ||
  //     formValues?.type === "Guest" ||
  //     formValues?.type === "",
  // ]);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getValidationSchema = () => {
    if (formValues) {
      if (formValues.type === "Self") return SelfSchema;
      else if (formValues.type === "Guest") return GroupSchema;
      else if (formValues.type === "Vendor") return VendorSchema;
      else return SelfSchema;
    }
  };
  const SelfSchema = Yup.object({
    name: Yup.string()
      .matches(/^\S.*$/, "Name should not start with spaces")
      .min(2, "Name must be of minimum 2 characters")
      .max(149, "Name must be less than 150 characters")
      .required("Enter The Name"),
    type: Yup.string().required("Please Select Type"),

    designation: Yup.string()
      .matches(/^\S.*$/, "Designation should not start with spaces")
      .min(2, "Designation must be of minimum 2 characters")
      .max(149, "Designation must be less than 150 characters")
      .nullable(),

    phoneNumber: Yup.number()
      .typeError("Mobile Number must be a number")
      .required("Please Enter Phone Number"),
    email: Yup.string()
      .matches(/^\S.*$/, "Email should not start with spaces")
      .email("Please Enter Valid Email")
      .max(319, "Email must be less than 320 characters")
      .nullable(),

    date: Yup.string().required("Enter The Date"),
    time: Yup.string().required("Enter The Time"),
    pickupLocation: Yup.string()
      .min(2, "Pickup Location must be of minimum 2 characters")
      .max(149, "Pickup Location must be less than 150 characters")
      .matches(/^\S.*$/, "Pickup Location should not start with spaces")
      .required("Enter The Pickup Location"),
    dropLocation: Yup.string()
      .min(2, "Drop Location must be of minimum 2 characters")
      .max(149, "Drop Location must be less than 150 characters")
      .matches(/^\S.*$/, "Drop Location should not start with spaces")
      .required("Enter The Drop Location"),
    dropTime: Yup.string()
      .test(
        "is-greater",
        "Drop Time must be greater than Pickup Time",
        function (value) {
          const startDate = formValues?.time;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      )
      .required("Enter The Drop Time"),
    numberOfPeople: Yup.string().required("Select The Number Of People"),
    purpose: Yup.string().required("Select The Purpose"),
    description: Yup.string()
      // .matches(/^\S.*$/, "Description should not start with spaces")
      .max(499, "Description must be less than 500 characters")
      .notRequired()
      .nullable(),
    remarks: Yup.string()
      // .matches(/^\S.*$/, "Remarks should not start with spaces")
      .max(499, "Remarks must be less than 500 characters")
      .notRequired()
      .nullable(),
    isDriverRequired: Yup.string().required(
      "Select Whether Driver Is Required"
    ),
  });

  const VendorSchema = Yup.object({
    name: Yup.string()
      .matches(/^\S.*$/, "Name should not start with spaces")
      .min(2, "Name must be of minimum 2 characters")
      .max(149, "Name must be less than 150 characters")
      .required("Enter The Name"),
    type: Yup.string().required("Please Select Type"),

    designation: Yup.string()
      .matches(/^\S.*$/, "Designation should not start with spaces")
      .min(2, "Designation must be of minimum 2 characters")
      .max(149, "Designation must be less than 150 characters")
      .nullable(),

    phoneNumber: Yup.number()
      .typeError("Mobile Number must be a number")
      .required("Please Enter Phone Number"),
    email: Yup.string()
      .matches(/^\S.*$/, "Email should not start with spaces")
      .email("Please Enter Valid Email")
      .max(319, "Email must be less than 320 characters")
      .nullable(),

    date: Yup.string().required("Enter The Date"),
    time: Yup.string().required("Enter The Time"),
    pickupLocation: Yup.string()
      .min(2, "Pickup Location must be of minimum 2 characters")
      .max(149, "Pickup Location must be less than 150 characters")
      .matches(/^\S.*$/, "Pickup Location should not start with spaces")
      .required("Enter The Pickup Location"),
    dropLocation: Yup.string()
      .min(2, "Drop Location must be of minimum 2 characters")
      .max(149, "Drop Location must be less than 150 characters")
      .matches(/^\S.*$/, "Drop Location should not start with spaces")
      .required("Enter The Drop Location"),
    dropTime: Yup.string()
      .test(
        "is-greater",
        "Drop Time must be greater than Pickup Time",
        function (value) {
          const startDate = formValues?.time;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      )
      .required("Enter The Drop Time"),
    numberOfPeople: Yup.string().required("Select The Number Of People"),
    purpose: Yup.string().required("Select The Purpose"),
    description: Yup.string()
      .matches(/^\S.*$/, "Description should not start with spaces")
      .max(499, "Description must be less than 500 characters")
      .notRequired()
      .nullable(),
    remarks: Yup.string()
      .matches(/^\S.*$/, "Remarks should not start with spaces")
      .max(499, "Remarks must be less than 500 characters")
      .notRequired()
      .nullable(),
    isDriverRequired: Yup.string().required(
      "Select Whether Driver Is Required"
    ),
    company: Yup.string().required("Enter The Company"),
  });
  const GroupSchema = Yup.object({
    name: Yup.string()
      .matches(/^\S.*$/, "Name should not start with spaces")
      .min(2, "Name must be of minimum 2 characters")
      .max(149, "Name must be less than 150 characters")
      .required("Enter The Name"),
    type: Yup.string().required("Please Select Type"),

    designation: Yup.string()
      .matches(/^\S.*$/, "Designation should not start with spaces")
      .min(2, "Designation must be of minimum 2 characters")
      .max(149, "Designation must be less than 150 characters")
      .nullable(),

    phoneNumber: Yup.number()
      .typeError("Mobile Number must be a number")
      .required("Please Enter Phone Number"),
    email: Yup.string()
      .matches(/^\S.*$/, "Email should not start with spaces")
      .email("Please Enter Valid Email")
      .max(319, "Email must be less than 320 characters")
      .nullable(),

    date: Yup.string().required("Enter The Date"),
    time: Yup.string().required("Enter The Time"),
    pickupLocation: Yup.string()
      .min(2, "Pickup Location must be of minimum 2 characters")
      .max(149, "Pickup Location must be less than 150 characters")
      .matches(/^\S.*$/, "Pickup Location should not start with spaces")
      .required("Enter The Pickup Location"),
    dropLocation: Yup.string()
      .min(2, "Drop Location must be of minimum 2 characters")
      .max(149, "Drop Location must be less than 150 characters")
      .matches(/^\S.*$/, "Drop Location should not start with spaces")
      .required("Enter The Drop Location"),
    dropTime: Yup.string()
      .test(
        "is-greater",
        "Drop Time must be greater than Pickup Time",
        function (value) {
          const startDate = formValues?.time;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      )
      .required("Enter The Drop Time"),
    numberOfPeople: Yup.string().required("Select The Number Of People"),
    purpose: Yup.string().required("Select The Purpose"),
    description: Yup.string()
      .matches(/^\S.*$/, "Description should not start with spaces")
      .max(499, "Description must be less than 500 characters")
      .notRequired()
      .nullable(),
    remarks: Yup.string()
      .matches(/^\S.*$/, "Remarks should not start with spaces")
      .max(499, "Remarks must be less than 500 characters")
      .notRequired()
      .nullable(),
    isDriverRequired: Yup.string().required(
      "Select Whether Driver Is Required"
    ),

    guestType: Yup.string().required("Select The Guest Type"),
    guestOf: Yup.string().required("Select The Guest Of Whom"),
    company: Yup.string().required("Enter The Company"),
  });

  const onSubmit = (values) => {
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const payload = {
      id: data ? data : 0,
    };
    if (values) {
      payload["type"] = values.type;
      payload["name"] = values.name;
      payload["designation"] = values.designation;
      payload["phoneNumber"] = values?.phoneNumber?.toString();
      payload["email"] = values.email;
      payload["date"] =
        `${format(new Date(values.date), "yyyy-MM-dd")}` +
        "T" +
        `${format(new Date(values.time), "HH:mm:ss")}`;
      payload["time"] = format(new Date(values.time), "hh:mm a");
      payload["pickupLocation"] = values.pickupLocation;
      payload["dropLocation"] = values.dropLocation;
      payload["dropTime"] = format(new Date(values.dropTime), "hh:mm a");
      payload["numberOfPeople"] = values.numberOfPeople;
      payload["purpose"] = values.purpose;
      payload["description"] = values.description;
      payload["remarks"] = values.remarks;
      payload["isDriverRequired"] =
        values.isDriverRequired === "Yes" ? true : false;

      if (values.type === "Vendor" || values.type === "Guest") {
        payload["guestType"] = values.guestType;
        payload["guestOf"] = values.guestOf;
        payload["company"] = values.company;
      }
    }
    if (data) Edit(payload, "CompanyCarController/Edit");
    else create(payload, "CompanyCarController/Create");
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={
        data ? "Edit Company Car Request" : "Create Company Car Request"
      }
      ButtonLabel={
        data ? "Edit Company Car Request" : "Create Company Car Request"
      }
      onSubmit={onSubmit}
      styles={{ width: "80%", maxWidth: "80%" }}
      chooseFormValues={chooseFormValues}
      validationSchema={getValidationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateVendorComp
          data={detailsform}
          formValues={formValues}
          editID={data ? true : false}
        />
        // <CreateCompanyCarComp
        //   data={detailsform}
        //   formValues={formValues}
        //   editTrue={data ? true : false}
        // />
      }
    />
  );
};

export { CreateCompanyCar };
