import React, { useEffect, useState } from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";

const ReimbursementformTypes = ({
  typeUpdtReceipt = false,
  disabled = false,
  ExpenseTypesSelected,
  index,
  formValues,
}) => {
  const yesNoOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  const [employeeList, setEmployeeList] = useState([]);

  const dispatch = useDispatch();
  const [categoryOption, setCategoryOption] = useState();

  const getFormDetails = async (nam) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Getcategory?mode=${nam}`
      );

      let findCategory = result.data?.results?.map((d, i) => {
        return { label: d?.text, value: d?.id };
      });
      setCategoryOption(findCategory);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getTypeData = async (nam, catid) => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategorytype?mode=${nam}&categoryid=${catid}`
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({
          label: item.text,
          value: item.id ? Number(item.id) : null,
        });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (!disabled) {
      if (ExpenseTypesSelected) {
        formValues.Expenselist[index].categoryid = "";
        formValues.Expenselist[index].type = "";
        setEmployeeList([]);
        getFormDetails(ExpenseTypesSelected);
      }
    }
  }, [ExpenseTypesSelected]);
  useEffect(() => {
    if (!disabled) {
      if (formValues?.Expenselist[index]?.categoryid && ExpenseTypesSelected) {
        formValues.Expenselist[index].type = "";
        setEmployeeList([]);
        getTypeData(
          ExpenseTypesSelected,
          formValues?.Expenselist[index]?.categoryid
        );
      }
    }
  }, [formValues?.Expenselist[index]?.categoryid]);

  return (
    <>
      {(formValues?.Expenselist[index]?.ExpenseTypes === "Taxi" ||
        formValues?.Expenselist[index]?.ExpenseTypes === "Self Vehicle") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`Expenselist[${index}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"From Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`Expenselist[${index}].fromlocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"To Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`Expenselist[${index}].tolocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                options={categoryOption}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.Expenselist[index]?.ExpenseTypes === "Self Vehicle"
                  ? "Vehicle Type"
                  : "Type"
              }
              mandatory={true}
            />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`Expenselist[${index}].type`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].type`}
                options={employeeList}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Total KM"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].totalkm`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Cost per KM"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.Expenselist[index]?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  disabled={disabled}
                  name={`Expenselist[${index}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik name={`Expenselist[${index}].Upload`} />
              )}
            </div>
          )}
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`Expenselist[${index}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
      {(formValues?.Expenselist[index]?.ExpenseTypes === "Food" ||
        formValues?.Expenselist[index]?.ExpenseTypes === "Gift" ||
        formValues?.Expenselist[index]?.ExpenseTypes === "Gadget") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`Expenselist[${index}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                options={categoryOption}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.Expenselist[index]?.ExpenseTypes === "Food"
                  ? "Total Days"
                  : "Total Items"
              }
              mandatory={true}
            />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={
                formValues?.Expenselist[index]?.ExpenseTypes === "Food"
                  ? `Expenselist[${index}].totaldays`
                  : `Expenselist[${index}].totalitems`
              }
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom
              labelName={
                formValues?.Expenselist[index]?.ExpenseTypes === "Food"
                  ? "Cost per day"
                  : "Cost per item"
              }
              mandatory={true}
            />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.Expenselist[index]?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  disabled={disabled}
                  name={`Expenselist[${index}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik name={`Expenselist[${index}].Upload`} />
              )}
            </div>
          )}
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`Expenselist[${index}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
      {(formValues?.Expenselist[index]?.ExpenseTypes === "Metro" ||
        formValues?.Expenselist[index]?.ExpenseTypes === "Local Train" ||
        formValues?.Expenselist[index]?.ExpenseTypes === "Local Bus") && (
        <>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Date"} mandatory={true} />
            <DatePickerFormiks
              disabled={disabled}
              name={`Expenselist[${index}].Date`}
              minDate={new Date()}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"From Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`Expenselist[${index}].fromlocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"To Location"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              name={`Expenselist[${index}].tolocation`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Category"} mandatory={true} />
            {disabled && (
              <InputCustomFormik
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                placeholder={"Enter"}
              />
            )}
            {!disabled && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].categoryid`}
                options={categoryOption}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Total tickets"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].totaltickets`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Cost per ticket"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].cost`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Claim Amount"} mandatory={true} />

            <InputCustomFormik
              disabled={disabled}
              type="number"
              name={`Expenselist[${index}].Amount`}
              placeholder={"Enter"}
            />
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Is Receipt Availabe ?"} mandatory={true} />
            {!typeUpdtReceipt && (
              <SelectForm
                disabled={disabled}
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
            {typeUpdtReceipt && (
              <SelectForm
                name={`Expenselist[${index}].IsReceiptAvailable`}
                options={yesNoOptions}
              />
            )}
          </div>
          {formValues?.Expenselist[index]?.IsReceiptAvailable === "Yes" && (
            <div className="reimbursementTablediv">
              <LabelCustom labelName={"Receipt"} mandatory={true} />
              {!typeUpdtReceipt && (
                <FileUploadFormik
                  disabled={disabled}
                  name={`Expenselist[${index}].Upload`}
                />
              )}
              {typeUpdtReceipt && (
                <FileUploadFormik name={`Expenselist[${index}].Upload`} />
              )}
            </div>
          )}
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Reason"} mandatory={true} />
            {!typeUpdtReceipt && (
              <TextAreaFormik
                disabled={disabled}
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
            {typeUpdtReceipt && (
              <TextAreaFormik
                name={`Expenselist[${index}].Justification`}
                placeholder={"Enter Reason"}
              />
            )}
          </div>
          <div className="reimbursementTablediv">
            <LabelCustom labelName={"Remarks"} mandatory={true} />
            <TextAreaFormik
              disabled={disabled}
              name={`Expenselist[${index}].Remarks`}
              placeholder={"Enter Remarks"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ReimbursementformTypes;
