import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { UpdateProfociency } from "./FormComp/UpdateProfociency";

const ServiceMySkillDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [UpdateIsOpen, setUpdateIsOpen] = useState(false);

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState("");
  const toggleUpdatePopup = () => {
    setUpdateIsOpen((prev) => !prev);
  };
  const togglePopup = (id, type, color) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `SkillsEmployeeController/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data?.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionList.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionList[0]?.id,
              approve,
              result.data?.summary?.actionList[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionList[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark, ProficiencyLevel) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`SkillsEmployeeController/Cancel/?ID=${id}`);
      } else if (type === "Approve") {
        await getRequest(`SkillsEmployeeController/Approve?Id=${id}`);
      } else if (type === "Reject") {
        await getRequest(
          `SkillsEmployeeController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Update") {
        await postRequest(
          `SkillsEmployeeController/UpdateSkill?ID=${id}&ProficiencyLevel=${ProficiencyLevel}`
        );
      } else {
        await getRequest(`SkillsEmployeeController/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  // console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // console.log("selectedId",selectedId);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>My Skill Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.requests?.requestNumber}
                          />

                          <Details
                            detailTitle={"Employee"}
                            Details={
                              data?.requests?.employee?.nameWithPersonNumber
                            }
                          />

                          <Details
                            detailTitle={"Department"}
                            Details={data?.department}
                          />
                          <Details
                            detailTitle={"Skill Set"}
                            Details={data?.skillset}
                          />
                          <Details
                            detailTitle={"Skill"}
                            Details={data?.skill}
                          />
                          <Details
                            detailTitle={"Proficiency Level"}
                            Details={data?.requests?.proficiencyLevel}
                          />
                          <Details
                            detailTitle={"Attachment"}
                            Details={
                              <a
                                target="_blank"
                                href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/SkillsEmployee/${data?.requests?.attachment}`}
                              >
                                {data?.requests?.attachment}
                              </a>
                            }
                          />
                          <Details
                            detailTitle={"Description"}
                            Details={data?.requests?.description}
                          />
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    data?._statusLabel
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    data?._statusLabel
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?._statusLabel}
                              </div>
                            }
                          />
                          <Details
                            detailTitle={"Created On"}
                            Details={format(
                              new Date(
                                data?.requests?.createdOn?.replace("T", " ")
                              ),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={format(
                              new Date(
                                data?.requests?.lastUpdatedOn?.replace("T", " ")
                              ),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                        </>
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.requests?.employee?.name} ${data?.requests?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.requests?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?.requests?.employee?.designation}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?.requests?.employee?.department}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.requests?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?.requests?.employee?.personalPhoneNumber
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.requests?.employee?.locationName}
                        />
                      </div>
                      {data?.actions ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.actions?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel" &&
                                  item.type !== "Update"
                                    ? togglePopup(
                                        item?.id,
                                        item?.type,
                                        item?.color
                                      )
                                    : item.type === "Update"
                                    ? toggleUpdatePopup()
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {UpdateIsOpen && (
                        <Popup
                          popupHeading={"Update Proficiency"}
                          content={
                            <>
                              <UpdateProfociency
                                loading={loading}
                                data={PostApRejHol}
                                type={data?.actions[0]?.type}
                                id={data?.actions[0]?.id}
                                color={data?.actions[0]?.color}
                                handleClose={toggleUpdatePopup}
                              />
                            </>
                          }
                          handleClose={toggleUpdatePopup}
                        />
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          module={"Skills"}
                          requestNumber={data?.requests?.requestNumber}
                        />
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={"Skills"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ServiceMySkillDetails };
