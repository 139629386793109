/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useEffect } from "react";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import * as Yup from "yup";
import AddEarningPopup from "../../../PayrollMaster/Components/PayrollTemplateComponents/AddEarningPopup";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import { InputForm } from "../../../../Components/FormComponent/InputForm";
import { GroupObjectBy } from "../../../../Utilities/GroupObjectBy";
function RevisionForm({ templateId, eId, detailsForm }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [template, setTemplate] = useState([]);

  const [earningGroup, setEarningGroup] = useState([]);
  const [reimbursementGroup, setreimbursementGroup] = useState([]);
  const [deductionGroup, setDeductionGroup] = useState([]);
  const [selectedEarningGroup, setSelectedEarningGroup] = useState([]);
  const [selectedreimbursementGroup, setSelectedreimbursementGroup] = useState(
    []
  );
  const [selectedDeductionGroup, setSelectedDeductionGroup] = useState([]);
  const [initialValues, setInitialValues] = useState({
    SalaryRevisionType: "Revise CTC by Percentage",
    SalaryRevisionValue: "",
    RevisedSalaryPayoutMonth: "",
    RevisedSalaryEffectiveFrom: "",
    annualCTC: detailsForm.length ? detailsForm[0]?.costToCompanyAnnual : "",
    RevisedCTC: detailsForm.length ? detailsForm[0]?.costToCompanyAnnual : "",
    templateID: templateId,
    earnings: {
      Basic: {
        Type: "Basic",
        CalculationType: "Percentage of CTC",
        DisplayName: "Basic",
      },
    },
    reimbursements: {},
    deductions: {},
  });
  const [formValues, setFormValues] = useState(initialValues);
  const [basicMonthlyAmount, setBasicMonthlyAmount] = useState(20000);
  const getDropdownData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/EarningDropdown?state=true&forESI=No"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "earningName");
        setEarningGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getMonthlyYearlyValueReimb = (reimbursement) => {
    if (!reimbursement) return {};
    let calcParam = reimbursement.maximumReimbursementAmount
    let monthlyValue = parseFloat(calcParam);
    let yearlyValue = parseFloat(calcParam) * 12;
    if (reimbursement.perDayOrPerMonth === "PerDay") {
      monthlyValue = parseFloat(calcParam) * 30;
      yearlyValue = monthlyValue * 12;
    } else if (reimbursement.perDayOrPerMonth === "PerMonth") {
      monthlyValue = parseFloat(calcParam);
      yearlyValue = monthlyValue * 12;
    } else if (reimbursement.perDayOrPerMonth === "OneTime") {
      monthlyValue = parseFloat(calcParam) / 12;
      yearlyValue = parseFloat(calcParam);
    }
    return {
      Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
      Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
    };
  };
  const getDropdownDeductionData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/DeductionDropdown?state=true"
      );
      if (result) {
        let tempDeductionGroup = GroupObjectBy(result?.data?.summary);
        setDeductionGroup(tempDeductionGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const returnPage = () => {
    navigate(-1);
  };
  const getDropdownReimbData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/reimbursmentDropdown?state=true"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "name");
        setreimbursementGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDropdownDeductionData()
    getDropdownData();
    getDropdownReimbData();
  }, []);

  // Helper function to calculate Monthly Amoun

  const populateEarningsFromEmployeeSalaryDetails = (EmployeeSalaryDetails) => {
    const earnings = {};
    const reimbursements = {};
    const deductions = {};
    const dd = [];
    const rr = [];
    const deduc = [];
    setSelectedEarningGroup([]);
    setSelectedreimbursementGroup([]);
    setSelectedDeductionGroup([]);
    EmployeeSalaryDetails.forEach((earning) => {
      if (earning.salaryComponents === "Earnings") {
        dd.push(earning.type);
        if (earning.type === "Fixed Allowance") {
          const key = earning.type;
          earnings[key] = {
            templateId: earning.templateId,
            id: earning.id,
            Type: earning.type,
            Monthly: earning.monthlyAmount,
            Yearly: earning.annualAmount,
            CalculationType: "Fixed",
            DisplayName: earning.displayName,
            CalculationValue: "0",
          };
        } else {
          const key = earning.type;
          earnings[key] = {
            Type: earning.type,
            CalculationType: earning.calculationType,
            DisplayName: earning.displayName,
            Monthly: earning.monthlyAmount,
            Yearly: earning.annualAmount,
            templateId: earning.templateId,
            id: earning.id,
            CalculationValue: earning.calculationValue?.toString(),
          };
        }
      } else if (earning.salaryComponents === "Reimbursements") {
        rr.push(earning.displayName);
        const key = earning.displayName;
        reimbursements[key] = {
          Type: earning.type,
          name: earning.displayName,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          perDayOrPerMonth: earning.perDayOrPerMonth,
          maximumReimbursementAmount: earning?.calculationValue?.toString(),
        };
      }
      else if (earning.salaryComponents === "Deductions") {
        deduc.push(earning.type);
        deductions[earning.type] = {
          Type: earning.type,
          CalculationType: earning.calculationType,
          calculationType: earning.calculationType,
          DisplayName: earning.type,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          CalculationValue: earning.calculationValue?.toString(),
          isEmployerContributionInCTC: earning.isEmployerContributionInCTC,
        };
      }
    });

    const filteredDD = dd.filter((type) => {
      return type !== "Fixed Allowance";
    });

    setSelectedEarningGroup((prevSelectedEarningGroup) => [
      ...prevSelectedEarningGroup,
      ...filteredDD,
    ]);
    setSelectedreimbursementGroup((prevSelectedReimbursementGroup) => [
      ...prevSelectedReimbursementGroup,
      ...rr,
    ]);
    setSelectedDeductionGroup((prevSelectedDeductionGroup) => [
      ...prevSelectedDeductionGroup,
      ...deduc,
    ]);

    const updatedFormValues = {
      ...formValues,
      earnings: earnings,
      reimbursements: reimbursements,
      deductions: deductions
    };

    setInitialValues(updatedFormValues);
  };
  const getMonthlyYearlyValueDeduction = (deduction) => {
    let name;
    if (deduction?.type === "EPF Employer Contribution") {
      name = "epf";
    } else {
      name = "esi";
    }
    if (formValues?.earnings['Dearness Allowance']?.Yearly !== undefined) {

      let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
      const monthlyValue = parseFloat(
        ((formValues?.earnings?.Basic?.Yearly + formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100 / 12
      );
      const yearlyValue = parseFloat(((formValues?.earnings?.Basic?.Yearly + formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      }
    } else {
      let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
      const monthlyValue = parseFloat(
        (formValues?.earnings?.Basic?.Yearly * calcParam) / 100 / 12
      );
      const yearlyValue = parseFloat((formValues?.earnings?.Basic?.Yearly * calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      }
    }
  };


  useEffect(() => {
    if (detailsForm && detailsForm?.length) {
      populateEarningsFromEmployeeSalaryDetails(detailsForm);
    }
  }, [detailsForm]);

  const getMonthlyYearlyValue = (earning) => {
    let calcParam =
      earning?.earningType === "Custom Allowance"
        ? formValues?.earnings[earning?.nameInPayslip]?.CalculationValue
        : formValues?.earnings[earning?.earningName]?.CalculationValue;

    if (earning?.calculationType === "Percentage of Basic") {

      const monthlyValue =
        (parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100)) / 12;
      const yearlyValue =
        parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    } else if (earning?.calculationType === "Percentage of CTC") {
      const monthlyValue =
        (parseFloat(formValues.RevisedCTC) * (parseFloat(calcParam) / 100)) /
        12;
      const yearlyValue =
        parseFloat(formValues.RevisedCTC) * (parseFloat(calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    } else {
      const monthlyValue = parseFloat(calcParam);
      const yearlyValue = parseFloat(calcParam) * 12;

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    }
  };

  useEffect(() => {
    let earning = earningGroup?.["Basic"]?.[0];
    let calcParam = formValues?.earnings["Basic"]?.CalculationValue;
    if (earning?.calculationType !== "Flat Amount") {
      if (
        isNaN(
          parseFloat(formValues?.RevisedCTC) * (parseFloat(calcParam) / 100)
        )
      )
        setBasicMonthlyAmount(0);
      else {
        setBasicMonthlyAmount(
          parseFloat(formValues?.RevisedCTC) * (parseFloat(calcParam) / 100)
        );
      }
    } else if (isNaN(parseFloat(calcParam))) {
      setBasicMonthlyAmount(0);
    } else {
      setBasicMonthlyAmount(calcParam);
    }
  }, [formValues?.RevisedCTC, formValues]);


  const [totalYearlyEarnings, setTotalYearlyEarnings] = useState(0);
  const [totalMonthlyEarnings, setTotalMonthlyEarnings] = useState(0);

  useEffect(() => {
    // Initialize total yearly and monthly earnings to 0
    let yearlyTotal = 0;
    let monthlyTotal = 0;



    for (const key in formValues?.earnings) {

      const earning = formValues?.earnings[key];
      if (earning.Yearly > 0) {
        if (earning?.Yearly) {
          yearlyTotal += earning?.Yearly;
        }
        if (earning?.Monthly) {
          monthlyTotal += earning?.Monthly;
        }
      }
    }

    for (const key in formValues?.reimbursements) {
      const reimbursement = formValues?.reimbursements[key];
      if (reimbursement?.maximumReimbursementAmount) {
        if (reimbursement.perDayOrPerMonth === "PerDay") {
          yearlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount) * 12 * 30;
          monthlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount) * 30;
        } else if (reimbursement.perDayOrPerMonth === "OneTime") {
          yearlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount);
          monthlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount) / 12;
        } else if (reimbursement.perDayOrPerMonth === "PerMonth") {
          yearlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount) * 12;
          monthlyTotal += parseFloat(reimbursement?.maximumReimbursementAmount);
        }
      }
    }

    for (const key in formValues?.deductions) {
      const deduction = formValues?.deductions[key];
      if (deduction?.CalculationValue && deduction?.isEmployerContributionInCTC) {
        if (deduction?.calculationType == "Percentage Of Basic" && formValues?.earnings.Basic.Yearly) {
          yearlyTotal += parseFloat(formValues?.earnings.Basic.Yearly) * parseFloat(deduction.CalculationValue) / 100;
          monthlyTotal += parseFloat(formValues?.earnings.Basic.Yearly) * parseFloat(deduction.CalculationValue) / 100 / 12;
        } else if (deduction?.calculationType === "Percentage Of CTC") {
          yearlyTotal += parseFloat(formValues?.annualCTC) * parseFloat(deduction.CalculationValue) / 100;
          monthlyTotal += parseFloat(formValues?.annualCTC) * parseFloat(deduction.CalculationValue) / 100 / 12;
        } else if (deduction?.calculationType === "Percentage Of (Basic + DA)") {
          if (formValues?.earnings?.Basic?.Yearly && formValues?.earnings["Dearness Allowance"]?.Yearly) {
            yearlyTotal += ((formValues?.earnings?.Basic?.Yearly + formValues?.earnings["Dearness Allowance"]?.Yearly) * parseFloat(deduction.CalculationValue) / 100);
            monthlyTotal += ((formValues?.earnings?.Basic?.Yearly + formValues?.earnings["Dearness Allowance"]?.Yearly) * parseFloat(deduction.CalculationValue) / 100 / 12);
          } else if (formValues?.earnings?.Basic?.Yearly) {
            yearlyTotal += ((formValues?.earnings?.Basic?.Yearly) * parseFloat(deduction.CalculationValue) / 100);
            monthlyTotal += ((formValues?.earnings?.Basic?.Yearly) * parseFloat(deduction.CalculationValue) / 100 / 12);
          }
        } else {
          yearlyTotal += parseFloat(deduction?.CalculationValue);
          monthlyTotal += parseFloat(deduction?.CalculationValue) / 12;
        }
      }
    }


    setTotalYearlyEarnings(formValues?.RevisedCTC - yearlyTotal);
    setTotalMonthlyEarnings(formValues?.RevisedCTC / 12 - monthlyTotal);
  }, [basicMonthlyAmount, formValues?.earnings]);

  useEffect(() => {
    const percentage = parseFloat(Number(formValues?.SalaryRevisionValue));
    const annualCTC = detailsForm[0]?.costToCompanyAnnual || 0;

    if (
      !isNaN(
        percentage &&
        formValues?.SalaryRevisionValue !== 0 &&
        formValues?.SalaryRevisionValue !== ""
      )
    ) {
      const revisedCTC = annualCTC + (annualCTC * percentage) / 100;
      setInitialValues((prevValues) => ({
        ...prevValues,
        RevisedCTC: revisedCTC,
        annualCTC: annualCTC,
        SalaryRevisionValue: Number(formValues?.SalaryRevisionValue),
      }));
    }
  }, [formValues?.SalaryRevisionValue]);

  const createPageData = async (values) => {
    for (let i = 0; i < values.EmployeeSalaryDetails.length; i++) {
      if (values.EmployeeSalaryDetails[i].MonthlyAmount) values.EmployeeSalaryDetails[i].MonthlyAmount = parseInt(values.EmployeeSalaryDetails[i].MonthlyAmount)
    }
    try {
      setLoading(true);
      await postRequest(`PreparePayroll/SalaryRevise`, values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };


  const getMonthlyValueDeduction = (deductionType, rom) => {
    if (!deductionType) return 0;
    if (deductionType.calculationType === "Percentage Of Basic") {
      if (formValues?.earnings.Basic.Yearly) {
        return ((parseFloat(formValues?.earnings.Basic.Yearly) * parseFloat(formValues?.deductions[deductionType?.Type]?.CalculationValue) / 100) / 12);
      }
      else return 0;
    } else if (deductionType.Type === "ESI Employee Contribution" || deductionType.Type === "ESI Employer Contribution") {
      return formValues?.annualCTC != "" ? formValues?.annualCTC * deductionType.CalculationValue / 100 / 12 : 0;
    } else if (deductionType.Type === "EPF Employee Contribution" || deductionType.Type === "EPF Employer Contribution") {
      if (formValues?.earnings?.Basic?.Yearly && formValues?.earnings["Dearness Allowance"]?.Yearly) {
        return ((formValues?.earnings?.Basic?.Yearly + formValues?.earnings["Dearness Allowance"]?.Yearly) * parseFloat(deductionType.CalculationValue) / 100 / 12);
      } else if (formValues?.earnings?.Basic?.Yearly) {
        return ((formValues?.earnings?.Basic?.Yearly) * parseFloat(deductionType.CalculationValue) / 100 / 12);
      } else return 0;
    }
    return formValues?.deductions[deductionType?.Type]?.CalculationValue / 12;
  };

  const getYearlyValueDeduction = (deductionType) => {
    if (!deductionType) return 0;
    if (deductionType.calculationType === "Percentage Of Basic") {
      if (formValues?.earnings.Basic.Yearly) {
        return (parseFloat(formValues?.earnings.Basic.Yearly) * formValues?.deductions[deductionType?.Type]?.CalculationValue / 100);
      } else return 0;
    } else if (deductionType.Type === "ESI Employee Contribution" || deductionType.Type === "ESI Employer Contribution") {
      return formValues?.annualCTC != "" ? formValues?.annualCTC * deductionType.CalculationValue / 100 : 0;
    } else if (deductionType.Type === "EPF Employee Contribution" || deductionType.Type === "EPF Employer Contribution") {
      if (formValues?.earnings?.Basic?.Yearly && formValues?.earnings["Dearness Allowance"]?.Yearly) {
        return ((formValues?.earnings?.Basic?.Yearly + formValues?.earnings["Dearness Allowance"]?.Yearly) * parseFloat(deductionType.CalculationValue) / 100);
      } else if (formValues?.earnings?.Basic?.Yearly) {
        return ((formValues?.earnings?.Basic?.Yearly) * parseFloat(deductionType.CalculationValue) / 100);
      } else return 0;
    }
    return formValues?.deductions[deductionType?.Type]?.CalculationValue;
  };


  return (
    <div
      style={{

        width: "100%",
        backgroundColor: "var(--form-background)",
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object({
          RevisedCTC: Yup.string().when("SalaryRevisionType", {
            is: (val) => val === "Enter New CTC Amount Below",
            then: () => Yup.string().required("required").nullable(),
          }),
          SalaryRevisionValue: Yup.string().when("SalaryRevisionType", {
            is: (val) => val === "Revise CTC by Percentage",
            then: () => Yup.string().required("required").nullable(),
          }),
          RevisedSalaryEffectiveFrom: Yup.string().required("Please enter the Date"),
          RevisedSalaryPayoutMonth: Yup.string().required("Please enter the Date"),
        })}
        onSubmit={(values) => {
          const modifiedValues = { ...values };
          for (const key in modifiedValues.earnings) {
            const earning = modifiedValues.earnings[key];
            if (earning.Monthly) {
              earning.MonthlyAmount = Number(earning.Monthly.toFixed(0));
            }
            if (earning.Yearly) {
              earning.AnnualAmount = Number(earning.Yearly.toFixed(0));
            }
          }

          // Create the payload in the desired format

          const payload = {
            templateId: detailsForm ? templateId : 0,
            PayrollEmployeeId: eId,
            RevisedCTC: formValues?.RevisedCTC,
            SalaryRevisionValue: formValues?.SalaryRevisionValue,
            SalaryRevisionType: formValues?.SalaryRevisionType,
            RevisedMonthlyAmount: formValues?.RevisedCTC
              ? (formValues?.RevisedCTC / 12).toFixed(0)
              : "",
            CurrentMonthlyAmount: (modifiedValues.annualCTC / 12).toFixed(0),
            CurrentCTC: modifiedValues.annualCTC.toFixed(0),
            // AnnualCTC: modifiedValues.annualCTC,
            EmployeeSalaryDetails: Object.keys(modifiedValues.earnings).map(
              (key) => ({
                ...modifiedValues.earnings[key],
                IsEnable: true,
                SalaryComponents: "Earnings",
              })
            ),
          };



          for (const key in values.deductions) {
            const deduction = values.deductions[key];
            deduction.MonthlyAmount = getMonthlyValueDeduction(deduction, "sameer");
            deduction.Monthly = deduction.MonthlyAmount;
            deduction.AnnualAmount = getYearlyValueDeduction(deduction);
            deduction.Yearly = deduction.AnnualAmount;
            payload.EmployeeSalaryDetails.push({
              ...deduction,
              IsEnable: true,
              SalaryComponents: "Deductions",
              DisplayName: deduction.Type,
              isEmployerContributionInCTC: deduction.isEmployerContributionInCTC
            });
          }
          for (const key in values.reimbursements) {
            const reimbursement = values.reimbursements[key];

            reimbursement.MonthlyAmount = getMonthlyYearlyValueReimb(reimbursement).Monthly;
            reimbursement.Monthly = reimbursement.MonthlyAmount;
            reimbursement.AnnualAmount = getMonthlyYearlyValueReimb(reimbursement).Yearly
            reimbursement.Yearly = reimbursement.AnnualAmount;


            payload.EmployeeSalaryDetails.push({
              ...reimbursement,
              IsEnable: true,
              SalaryComponents: "Reimbursements",
              CalculationType: "Flat Amount",
              DisplayName: reimbursement?.name,
              calculationValue: reimbursement?.maximumReimbursementAmount,
            });
          }
          for (let i = 0; i < payload.EmployeeSalaryDetails.length; i++) {
            if (payload.EmployeeSalaryDetails[i].Type === "Fixed Allowance") {
              payload.EmployeeSalaryDetails[i].MonthlyAmount = totalMonthlyEarnings;
              payload.EmployeeSalaryDetails[i].AnnualAmount = totalYearlyEarnings;
              payload.EmployeeSalaryDetails[i].Yearly = totalYearlyEarnings;
              payload.EmployeeSalaryDetails[i].Monthly = totalMonthlyEarnings;
            }
          }
          if (totalMonthlyEarnings <= 0) {
            dispatch(
              showToast({
                text: "Fixed Allowance Cannot be negative",
                severity: "error",
              })
            );
          } else {

            createPageData(payload);

          }

        }}
      >
        {({ values }) => {
          setFormValues(values);
          return (
            <Form style={{}}>
              <div>
                <LabelCustom
                  clas="rebo"
                  labelName={"Select the Salary Revision type"}
                  mandatory={true}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <RadioFormik
                      name="SalaryRevisionType"
                      options={[
                        {
                          label: "Revise CTC by Percentage",
                          value: "Revise CTC by Percentage",
                        },
                      ]}
                      // value={ formValues?.SalaryRevisionValue ? detailsForm.annualCTC+(detailsForm.annualCTC*formValues?.SalaryRevisionValue/100):''}
                      placeholder="Enter revised CtC in  %"
                    />
                    <InputCustomFormik
                      borderBlack={true}
                      name="SalaryRevisionValue"
                      disabled={
                        formValues?.SalaryRevisionType ===
                          "Enter New CTC Amount Below"
                          ? true
                          : false
                      }
                      type="number"
                      placeholder=" Enter revised CTC % "
                    />
                    %
                  </span>
                </div>
                <RadioFormik
                  name="SalaryRevisionType"
                  options={[
                    {
                      label: "Enter New CTC Amount Below",
                      value: "Enter New CTC Amount Below",
                    },
                  ]}
                  placeholder="Enter template name"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "30px",
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <LabelCustom labelName={"Revised Annual CTC"} />
                    <InputCustomFormik
                      borderBlack={true}
                      disabled={
                        formValues?.SalaryRevisionType ===
                          "Revise CTC by Percentage"
                          ? true
                          : false
                      }
                      name="RevisedCTC"
                      type="number"
                      placeholder=" Enter New CTC Amount (in ₹)"
                    />
                    <span>Per Year</span>
                  </span>
                </div>
              </div>
              <div style={{}} className="horizontalLine"></div>
              <div style={{ height: 'fit-content' }}>
                <table style={{ width: "calc(100% - 24px)", margin: "12px" }}>
                  <thead style={{ textAlign: "left" }}>
                    <tr className="header-row template-table-header">
                      <th>Salary Component</th>
                      <th>
                        Calculation Type{" "}
                        <span style={{ paddingLeft: "32px" }}>Description</span>
                      </th>
                      <th>Monthly Amount</th>
                      <th>Annual Amount</th>
                      <th style={{ paddingRight: "18px" }}></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {selectedEarningGroup?.map((earningType, index) => {
                      let earningTypeGroup = earningGroup[earningType];

                      let itemArray;
                      let item;
                      if (earningTypeGroup) {
                        itemArray = earningTypeGroup.filter(
                          (x) =>
                            x.earningName ===
                            formValues?.earnings?.[earningType]?.Type
                        );
                        item = itemArray[0];
                      }
                      if (item) {
                        formValues.earnings[earningType].Monthly =
                          getMonthlyYearlyValue(item).Monthly;
                        formValues.earnings[earningType].Yearly =
                          getMonthlyYearlyValue(item).Yearly;
                      }
                      let options = [];
                      earningGroup[earningType]?.map((earning) => {
                        return options.push({
                          label: earning?.earningName,
                          value: earning?.earningName,
                        });
                      });
                      if (typeof item === "undefined") return <></>;
                      else
                        return (
                          <tr key={index}>
                            <td>
                              <LabelCustom labelName={item?.earningName} />
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item?.calculationType === "Flat Amount" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        borderBlack={true}
                                        name={`earnings.${earningType}.CalculationValue`}
                                        disabled={true}
                                      />
                                    </div>
                                    <LabelCustom labelName="Flat Amount" />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        borderBlack={true}
                                        name={`earnings.${earningType}.CalculationValue`}
                                        disabled={true}
                                      />
                                    </div>
                                    <LabelCustom
                                      labelName={
                                        earningType === "Basic"
                                          ? "% of CTC"
                                          : "% of Basic"
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValue(
                                    item
                                  ).Monthly?.toFixed(0)}
                                />
                                <InputCustomFormik
                                  borderBlack={true}
                                  value={getMonthlyYearlyValue(item).Monthly}
                                  name={`earnings.${earningType}.Monthly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                />
                              </div>
                            </td>
                            <td>
                              <LabelCustom
                                labelName={getMonthlyYearlyValue(
                                  item
                                ).Yearly?.toFixed(0)}
                              />
                              <InputCustomFormik
                                borderBlack={true}
                                value={getMonthlyYearlyValue(item).Yearly}
                                name={`earnings.${earningType}.Yearly`}
                                type="hidden"
                                step="0.01"
                                disabled
                              />
                            </td>

                          </tr>
                        );
                    })}

                    <tr>
                      <td>
                        <LabelCustom labelName="Fixed" />
                      </td>
                      <td style={{ padding: "0 28px" }}>{"-"}</td>
                      <td>
                        <div style={{ maxWidth: "164px" }}>
                          <LabelCustom
                            labelName={totalMonthlyEarnings.toFixed(0)}
                          />
                        </div>
                      </td>
                      <td>
                        <LabelCustom
                          labelName={totalYearlyEarnings.toFixed(0)}
                        />
                      </td>
                    </tr>
                    {selectedreimbursementGroup.length ? (
                      <tr className="dashed-bottom">
                        <th
                          colSpan="4"
                          className="header-row"
                          style={{ padding: "12px" }}
                        >
                          Reimbursement
                        </th>
                      </tr>
                    ) : (
                      <></>
                    )}
                    {selectedreimbursementGroup?.map(
                      (reimbursementType, index) => {
                        let reimbursementTypeGroup =
                          reimbursementGroup[reimbursementType];
                        let itemArray;
                        let item;
                        if (reimbursementTypeGroup) {
                          itemArray = reimbursementTypeGroup.filter(
                            (x) =>
                              x.name ===
                              formValues?.reimbursements?.[
                                reimbursementType
                              ]?.Type
                          );
                          item = itemArray[0];
                        }

                        if (item) {
                          formValues.reimbursements[
                            reimbursementType
                          ].Monthly =
                            getMonthlyYearlyValueReimb(item).Monthly;
                          formValues.reimbursements[
                            reimbursementType
                          ].Yearly =
                            getMonthlyYearlyValueReimb(item).Yearly;
                        }
                        let options = [];
                        reimbursementGroup[reimbursementType]?.map(
                          (reimbursement) => {
                            return options.push({
                              label: reimbursement?.name,
                              value: reimbursement?.name,
                            });
                          }
                        );

                        return (
                          <tr key={index}>
                            <td>
                              {/* {reimbursementTypeGroup?.length > 1 ? (
                                <SelectForm
                                  options={options}
                                  name={`reimbursements.${reimbursementType}.name`}
                                />
                              ) : ( */}
                              <LabelCustom labelName={reimbursementType} />
                              {/* )} */}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ maxWidth: "164px" }}>
                                    <InputCustomFormik
                                      borderBlack={true}
                                      disabled
                                      name={`reimbursements.${reimbursementType}.maximumReimbursementAmount`}
                                    />
                                  </div>
                                  <LabelCustom labelName="Flat Amount" />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValueReimb(
                                    formValues.reimbursements[reimbursementType]
                                  ).Monthly?.toFixed(2)}
                                />
                                {/* <InputCustomFormik
                                borderBlack={true}
                                  value={
                                    getMonthlyYearlyValueReimb(item).Monthly
                                  }
                                  name={`reimbursements.${reimbursementType}.Monthly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                /> */}
                              </div>
                            </td>
                            <td>
                              <LabelCustom
                                labelName={getMonthlyYearlyValueReimb(
                                  formValues.reimbursements[reimbursementType]

                                ).Yearly?.toFixed(2)}
                              />
                              {/* <InputCustomFormik
                              borderBlack={true}
                                value={
                                  getMonthlyYearlyValueReimb(item).Yearly
                                }
                                name={`reimbursements.${reimbursementType}.Yearly`}
                                type="hidden"
                                step="0.01"
                                disabled
                              /> */}
                            </td>

                          </tr>
                        );
                      }
                    )}
                    {selectedDeductionGroup?.length ?
                      <tr className="dashed-bottom">
                        <th
                          colSpan="4"
                          className="header-row"
                          style={{ padding: "12px" }}
                        >
                          Deductions
                        </th>
                      </tr> : <></>}
                    {selectedDeductionGroup?.map((deductionType, index) => {
                      let deductionTypeGroup =
                        deductionGroup[deductionType];
                      let itemArray;
                      let item;
                      if (deductionTypeGroup) {
                        itemArray = deductionTypeGroup.filter(
                          (x) =>
                            x.type ===
                            formValues?.deductions?.[deductionType]?.Type
                        );
                        item = itemArray[0];
                      }

                      if (item) {
                        formValues.deductions[deductionType].Monthly =
                          getMonthlyYearlyValueDeduction(item).Monthly;
                        formValues.deductions[deductionType].Yearly =
                          getMonthlyYearlyValueDeduction(item).Yearly;
                      }
                      let options = [];
                      deductionGroup[deductionType]?.map((deduction) => {
                        return options.push({
                          label: deduction?.type,
                          value: deduction?.type,
                        });
                      });

                      return (
                        <tr key={index}>
                          <td>
                            <div style={{ flexDirection: "row", display: "flex" }}>
                              <LabelCustom labelName={deductionType} />
                              {!formValues?.deductions?.[deductionType]?.isEmployerContributionInCTC ? <div style={{ color: "red", marginLeft: -10, marginTop: 10 }}>**</div> : null}
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ maxWidth: "164px" }}>
                                  <InputCustomFormik
                                    borderBlack={true}
                                    disabled={true}
                                    name={`deductions.${deductionType}.CalculationValue`}
                                  />
                                </div>
                                <LabelCustom labelName={formValues?.deductions[deductionType]?.CalculationType} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <LabelCustom
                                labelName={getMonthlyValueDeduction(
                                  formValues?.deductions[deductionType]
                                )?.toFixed(2)}
                              />
                              {/* <InputCustomFormik
                              borderBlack={true}
                                value={
                                  getMonthlyYearlyValueDeduction(item)
                                    .Monthly
                                }
                                name={`deductions.${deductionType}.Monthly`}
                                type="hidden"
                                step="0.01"
                                disabled
                              /> */}
                            </div>
                          </td>
                          <td>
                            <LabelCustom
                              labelName={getYearlyValueDeduction(
                                formValues?.deductions[deductionType]
                              )}
                            />
                            {/* <InputCustomFormik
                            borderBlack={true}
                              value={
                                getMonthlyYearlyValueDeduction(item).Yearly
                              }
                              name={`deductions.${deductionType}.Yearly`}
                              type="hidden"
                              step="0.01"
                              disabled
                            /> */}
                          </td>

                        </tr>
                      );
                    })}
                    <tr style={{ marginTop: 10, marginBottom: 10 }}>
                      <th colSpan="4">
                        <div style={{ padding: 15, borderRadius: 10, backgroundColor: "#FFF4E5", marginTop: 10, marginBottom: 10 }}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ color: "red", marginRight: 5 }}>**</div>
                            <div style={{ color: "black", fontWeight: 400, fontSize: 14 }}>Note: There will be subject to deduction as per support policy on behalf of the liable benefits.</div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table
                style={{
                  width: "calc(100% - 24px)",
                  margin: "12px",
                  border: "1px dotted black",
                  height: '47px'
                }}
              >
                <tbody>
                  <tr
                    className="header-row"
                    style={{
                      backgroundColor: "white",
                      textAlign: "left",
                      paddingLeft: "4px",
                      display: "flex",
                    }}
                  >
                    <th style={{ flex: 359 }}>Cost to Company</th>
                    <th style={{ flex: 498 }}></th>
                    <th style={{ flex: 283 }}>{`₹${isNaN(parseFloat(formValues?.RevisedCTC) / 12)
                      ? 0
                      : (parseFloat(formValues?.RevisedCTC) / 12).toFixed(0)
                      }`}</th>

                    <th style={{ flex: 333 }}>{`₹${isNaN(parseFloat(formValues?.RevisedCTC))
                      ? 0
                      : parseFloat(formValues?.RevisedCTC).toFixed(0)
                      }`}</th>
                  </tr>
                </tbody>
              </table>
              <div style={{ paddingLeft: "12px", marginTop: "24px", height: '285px' }}>
                <LabelCustom
                  clas="rebo"
                  labelName={"Payout Preferences"}
                  mandatory={true}
                />
                <div>
                  <LabelCustom labelName={"Revised Salary Effective From"} />
                  <DatePickerFormiks borderBlack={true} name="RevisedSalaryEffectiveFrom" />
                </div>
                <div>
                  <LabelCustom labelName={"Payout Month"} />
                  <DatePickerFormiks borderBlack={true} name="RevisedSalaryPayoutMonth" />
                </div>
              </div>
              <div
                className="horizontalLine"
                style={{ marginTop: "36px" }}
              ></div>
              <div
                style={{
                  position: "absolute",
                  bottom: -11,
                  right: 0,
                  display: "flex",
                  background: "var(--form-background)",
                  justifyContent: "flex-end",
                  width: "100%",
                  margin: "12px",
                }}
              >
                <button
                  className="button secondaryButton"
                  type="button"
                  onClick={returnPage}
                >
                  Cancel
                </button>
                <button className="button primaryButton" type="submit">
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RevisionForm;
