import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import * as Yup from "yup";
// import { Loader } from "../Loader";
import { useEffect } from "react";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { Loader } from "../../../../Components/FormComponent/Loader";
export const UpdateProfociency = ({
  data,
  id,
  type,
  color,
  handleClose,
  loading,
  alistname,
}) => {
  const proficiencyLevel = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];
  const validationSchema = Yup.object({
    ProficiencyLevel: Yup.string().required(
      "Please Select Updated Proficiency Level"
    ),
  });
  const onSubmit = async (values) => {
    // console.log("first values values values", values);
    data(type, id, " values.Remarks", values.ProficiencyLevel);
    handleClose();
  };

  useEffect(() => {
    document.title = `PeopleSol - Proficiency Level`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ ProficiencyLevel: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <LabelCustom
                labelName={"Update Proficiency Level"}
                mandatory={true}
              />
              <SelectForm
                placeholder={"Select Proficiency Level"}
                name="ProficiencyLevel"
                options={proficiencyLevel}
              />
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: color, color: "white" }}
                >
                  {type == "RejectAll" ? "Reject All" : type}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
