import React, { useState } from "react";
import { IconButton } from "@mui/material";
import "./AuthForm.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../../Services";
import VisibilityOffIcon from "../../Assets/VisibilityOffIcon.svg";
import VisibilityIcon from "../../Assets/VisibilityIcon.svg";
import LoginArrow from "../../Assets/LoginArrow.svg";
import * as Yup from "yup";
import { useEffect } from "react";

const initialValues = {
  email: "",
  password: "",
};

const LoginForm = () => {
  useEffect(()=>{
    document.title = `PeopleSol - Login`;
    return () => {
      document.title = 'PeopleSol';
    };
  })
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { theme } = useSelector((state) => state.theme);
  const { loading } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("password");
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        email: Yup.string().required("Email/Username required"),
        password: Yup.string().required("Password required"),
      }),
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: (values, action) => {
        if (!loading) {
          let loginType = "UserId";
          if (values.email.includes("@") && values.email.includes(".")) {
            loginType = "EmailId";
          }
          if (loginType === "UserId") {
            LoginUser(
              { UserId: values.email, Password: values.password },
              action,
              navigate,
              dispatch
            );
          } else {
            LoginUser(
              { EmailId: values.email, Password: values.password },
              action,
              navigate,
              dispatch
            );
          }
        }
      },
    });

  return (
    <form color="primary" className="auth-form" onSubmit={handleSubmit}>
      <p className="loginText">Employee Login</p>
      <div className="password-container">
        <input
          value={values.email}
          className="loginInput"
          label="Email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Employee ID/Email ID"
        />
        {errors.email && touched.email ? (
          <p className="loginError" style={{ margin: "-10px 10px" }}>
            {errors.email}
          </p>
        ) : null}
      </div>

      <div className="password-container">
        <input
          value={values.password}
          className="loginInput"
          label="password"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          type={password}
          placeholder="Enter Password"
        />
        {errors.password && touched.password ? (
          <p className="loginError" style={{ margin: "-10px 10px" }}>
            {errors.password}
          </p>
        ) : null}

        <div className="password-icons">
          {password === "password" ? (
            <IconButton color="primary" onClick={() => setPassword("text")}>
              <img
                src={VisibilityIcon}
                alt="ShowPassword"
                className="visibilityIcon"
              />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={() => setPassword("password")}>
              <img
                src={VisibilityOffIcon}
                alt="HidePassword"
                className="visibilityIcon"
              />
            </IconButton>
          )}
        </div>
      </div>
      {/* <p className="ForgotText cursor" onClick={()=>navigate('/forgotpassword')}>Forgot Password?</p> */}
      <button className="loginButton" type="submit">
        {loading ? <p>Loading...</p> : <p>Login to PeopleSol</p>}
        <img src={LoginArrow} alt="LoginArrow" className="loginButtonArrow" />
      </button>
      <div className="forgotPwd">
      <span 
      onClick={()=>{
        navigate("/forgotpassword")
      }}>Forgot Password</span>
      </div>
    </form>
  );
};

export { LoginForm };
