import { Formik, Form } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { InputCustom } from "../../../../../Components/FormComponent/InputCustom";
import { useEffect } from "react";

const Proioroption = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const status = [
  { label: "Resolved", value: "Resolved" },
  { label: "New Implementation", value: "New Implementation" },
  { label: "Assigned", value: "Assigned" },
  { label: "In Progress", value: "In Progress" },
  { label: "Information Requested", value: "Information Requested" },
  { label: "Request for Procurement", value: "Request for Procurement" },
  { label: "Hold", value: "Hold" },
  { label: "ReOpen", value: "ReOpen" },
];
const UpdateStatus = ({ data, id, assId, assIgniID, prestatus, priority }) => {
  //console.log("priority",priority)
  const onSubmit = async (values) => {
    const value = {
      ...values,
      TicketId: id,
      AssgineID: assIgniID ? assIgniID : assId?.assigineeId,
    };
    data(value);
  };
  useEffect(() => {
    document.title = `PeopleSol - Update Status`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <Formik
      initialValues={{
        status: prestatus,
        Priority: priority ? priority : assId?.priority,
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div>
            <div>
              <LabelCustom labelName={"Current Status"} />
              <InputCustom disabled value={prestatus} />
            </div>
            <div>
              <LabelCustom labelName={"Update Status"} mandatory={true} />
              <SelectForm
                name="status"
                options={status}
                placeholder="Select Status"
              />
            </div>
            <div>
              <LabelCustom labelName={"Update Priority"} mandatory={true} />
              <SelectForm
                name="Priority"
                options={Proioroption}
                placeholder="Select Priority"
              />
            </div>
          </div>
          <div className="createFootCust">
            <div>
              <div
                className="button secondaryButton"
                onClick={() =>
                  formik.resetForm({ values: { status: "", Priority: "" } })
                }
              >
                Reset
              </div>
            </div>
            <div>
              <button className="button primaryButton" type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { UpdateStatus };
