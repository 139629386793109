import React, { useState } from "react";
import StatusFeature from "./StatusFeature";

export const StatusUpdate = () => {
  const tempData = [
    {
      Name: "Ahaan",
      Role: "Jr Frontend Developer",
      LastMsgTime: "5:30PM",
      lastMsg: "Hello, Jenny how are you",
      Img: "https://picsum.photos/40/40",
      Status: true,
      msgs: [],
      seen:true
    },
    {
      Name: "Kapil Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "I am waiting for your reply",
      Img: "https://picsum.photos/35/35",
      Status: true,
      msgs: [],
      seen:[true,false,false,false]
    },

    {
      Name: "Rahul Singh",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "okay, see you soon!",
      Img: "https://picsum.photos/35/35",
      Status: true,
      msgs: [],
      seen:true
    },
    {
      Name: "Raju Baba",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
      seen:true
    },
    {
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
      seen:true
    },
    {
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
       seen:[true,false,false,false]
    },
    {
      Name: "Devrat Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
       seen:[true,false,false,false]
    },
    {
      Name: "Devrat Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
       seen:[true,false,false,false]
    },
    {
      Name: "Ram Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Status: true,
       seen:[true,false,false,false]
    },
  ];
  const [chatList, setChatList] = useState(tempData);
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [activeChatIndex, setActiveChatIndex] = useState(0);
  function showStatus(status) {
    if (status === "online") {
      return "greenDot";
    } else if (status === "busy") {
      return "redDot";
    } else if (status === "away") {
      return "yellowDot";
    }
  }
  const [statuses, setStatuses] = useState(chatList.map(chat => ({ id: chat.id, seen: chat.seen })));

  const handleStatusClick = (statusId) => {
      const updatedStatuses = statuses.map(status =>
          status.id === statusId ? { ...status, seen: true } : status
      );
      setStatuses(updatedStatuses);
  };
  return (
    <>
    
      <div>
        <p style={{ fontWeight: "500", fontSize: "14px" }} className="spacePad">
          Status
        </p>
        <div className="newChatContainer">
          <div onClick={() => {}} className="newChatListContentContainer">
            <div className="newChatActiveImage">
              <img src={"https://picsum.photos/35/35"} alt="Profile" />
              <div className={"addIm"}>
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.89634" cy="9.5582" r="8.61582" fill="#1296B0" />
                  <path
                    d="M13.009 9.49642L8.70175 9.49642M8.70175 9.49642L4.39453 9.49642M8.70175 9.49642L8.70175 5.18848M8.70175 9.49642L8.70175 13.8043"
                    stroke="white"
                    stroke-width="1.17488"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="newChatPostMainTextContainer">
              <label className="chatListName">{"My Status"}</label>
              <label className="chatListRole" style={{color:'#A1A1A1',fontSize:'10px',fontWeight:400}}>{"Tap to add status update"}</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p
          className="spacePad"
          style={{ color: "#555555", fontWeight: "500", fontSize: "12px" }}
        >
          Recent Updates
        </p>
        <div className="newChatContainer">
        {chatList.map((chat, index) => (
                <div key={chat.id} className="newChatListContentContainer">
                    <div className="status-wrapper">
                        <div className={`newChatActiveImage status-item ${statuses[index].seen ? 'seen' : 'unseen'}`} onClick={() => handleStatusClick(chat.id)}>
                            <img src={chat.Img} alt="Profile" />
                        </div>
                    </div>
                    <div className="newChatPostMainTextContainer" style={{ position: 'relative', gap: '2px' }}>
                        <label className="chatListName">{chat.Name}</label>
                        <label className="chatListRole" style={{ fontWeight: 400, color: 'black', fontSize: '11px' }}>{chat.Role}</label>
                    </div>
                    <label className="statusTime">{chat?.LastMsgTime}</label>
                </div>
            ))}
        </div>
      </div>
    </>
  );
};
