import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversationList: [],
  activeMessageList: [],
  contactList: [],
  blockedContacts: [],
  bookMarkedMessages: [],
  currentChatDetails: {},
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setConversationList: (state, action) => {
      state.conversationList = action.payload;
    },
    setActiveMessageList: (state, action) => {
      state.activeMessageList = action.payload;
    },
    addNewActiveMessage: (state, action) => {
      state.activeMessageList.push(action.payload);
    },
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setBlockedContacts: (state, action) => {
      state.blockedContacts = action.payload;
    },
    setBookMarkedMessages: (state, action) => {
      state.bookMarkedMessages = action.payload;
    },
    setCurrentChatDetails: (state, action) => {
      state.currentChatDetails = action.payload;
    },
  },
});

export const {
  setConversationList,
  setActiveMessageList,
  addNewActiveMessage,
  setContactList,
  setBlockedContacts,
  setBookMarkedMessages,
  setCurrentChatDetails,
} = ChatSlice.actions;
export default ChatSlice.reducer;
