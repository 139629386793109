import React, { useRef } from "react";
import { useState } from "react";
import "./NewGroup.css";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import GroupProfileIcon from "../../../../Assets/GroupProfileIcon.svg";
import { useEffect } from "react";

function NewGroup(props) {
  const tempData = [
    {
      userId: "1234567890",
      Name: "Ahaan",
      Role: "Junior Frontend Developer",
      LastMsgTime: "5:30PM",
      lastMsg: "Hello, Jenny how are you",
      Img: "https://picsum.photos/40/40",
      Active: "online",
      msgs: [],
    },
    {
      userId: "1234567891",
      Name: "Kapil Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "I am waiting for your reply",
      Img: "https://picsum.photos/35/35",
      Active: "away",
      msgs: [],
    },
    {
      userId: "1234567892",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "Okay, Sure!",
      Img: "https://picsum.photos/35/35",
      Active: "online",
      msgs: [],
    },
    {
      userId: "1234567893",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "I will send it",
      Img: "https://picsum.photos/35/35",
      Active: "away",
      msgs: [],
    },
    {
      userId: "1234567894",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      lastMsg: "okay, see you soon!",
      Img: "https://picsum.photos/35/35",
      Active: "busy",
      msgs: [],
    },
    {
      userId: "1234567895",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
    {
      userId: "1234567896",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "busy",
    },
    {
      userId: "1234567897",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "away",
    },
    {
      userId: "1234567898",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "busy",
    },
    {
      userId: "1234567899",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "away",
    },
    {
      userId: "1234567880",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "busy",
    },
    {
      userId: "1234567881",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "busy",
    },
    {
      userId: "1234567882",
      Name: "Anuj Yadav",
      Role: "Frontend Developer",
      LastMsgTime: "3:30PM",
      Img: "https://picsum.photos/35/35",
      Active: "online",
    },
  ];
  const [chatList, setChatList] = useState(tempData);
  const [newGroupList, setNewGroupList] = useState(true);
  const [activeGroupCreation, setActiveGroupCreation] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [newGroupProfilePic, setNewGroupProfilePic] = useState(
    "https://picsum.photos/40/40"
  );
  const [newGroupProfileName, setNewGroupProfileName] = useState("");
  const [profileNameInputActive, setProfileNameInputActive] = useState(false);
  const [toggleGroupProfile, setToggleGroupProfile] = useState(false);
  const [totalMember, setTotalMember] = useState(selectedParticipant.length);
  const [profileImg, setProfileImg] = useState(null);

  const inputFile = useRef(null);

  useEffect(()=>{
    document.title = `PeopleSol - New Group`;
    return () => {
      document.title = 'PeopleSol';
    };
  })


  useEffect(() => {
   // console.log(inputFile);
  }, [inputFile]);
 // console.log(inputFile);

 // console.log(selectedParticipant);
  function showStatus(status) {
    if (status === "online") {
      return "greenDot";
    } else if (status === "busy") {
      return "redDot";
    } else if (status === "away") {
      return "yellowDot";
    }
  }
  function newGroupCreated() {
    props.createNewGroupChat({
      userId: "1234567258",
      Name: newGroupProfileName,
      Img: newGroupProfilePic,
      Active: "online",
      type: "group",
      totalMember: totalMember,
      participants: selectedParticipant,
      msgs: [],
    });
    props.setNewGroup(false);
    setActiveGroupCreation(false);
    setNewGroupList(false);
  }

  return (
    <div className="newGroupContainer">
      {newGroupList && (
        <>
          <div className="newGroupSearchContainer">
            <div className="selectedParticipantContainer">
              {selectedParticipant.map((item, index) => {
                return (
                  <div className="selectChipContainer">
                    <div
                      className="chipImg"
                      style={{
                        backgroundImage: `url(${item.Img})`,
                      }}
                    ></div>
                    <label className="chipLabel">{item.Name}</label>
                    <svg
                      onClick={() => {
                        let tempList = JSON.parse(
                          JSON.stringify(selectedParticipant)
                        );
                        tempList = tempList.filter((selectedValue) => {
                          return (
                            Number(selectedValue.userId) !== Number(item.userId)
                          );
                        });
                        setSelectedParticipant(tempList);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                    >
                      <line
                        x1="8.11133"
                        y1="1.68654"
                        x2="1.44905"
                        y2="8.34882"
                        stroke="#555555"
                        stroke-linecap="round"
                      />
                      <line
                        x1="0.5"
                        y1="-0.5"
                        x2="9.92189"
                        y2="-0.5"
                        transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.67139 0.979431)"
                        stroke="#555555"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                );
              })}
            </div>
            <div className="chatListSearchContainer">
              {" "}
              <input
                type="text"
                className="chatListSearch"
                placeholder="Search"
              />
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
                <line
                  x1="8.70711"
                  y1="9"
                  x2="12"
                  y2="12.2929"
                  stroke="#1296B0"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="newChatListContainer">
            {chatList.map((chat, index) => (
              <div
                onClick={() => {
                  let searchSelectedParticipant = selectedParticipant?.find(
                    (d) => d?.userId === chat?.userId
                  );
                  if (!searchSelectedParticipant)
                    setSelectedParticipant([...selectedParticipant, chat]);
                }}
                className="newChatListContentContainer"
              >
                <div className="newChatActiveImage">
                  <img src={chat.Img} alt="Profile" />
                  <div className={showStatus(chat.Active)}></div>
                </div>
                <div className="newChatPostMainTextContainer">
                  <label className="chatListName">{chat.Name}</label>
                  <label className="chatListRole">{chat.Role}</label>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedParticipant.length > 1 && (
        <div
          className="groupCreationNxtBtn"
          onClick={() => {
            setActiveGroupCreation(true);
            setNewGroupList(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
          >
            <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
            <path
              d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
              stroke="white"
              stroke-width="1.10154"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      )}
      {activeGroupCreation && (
        <Popup
          firstClassName="newGroupCreationPopupBox1"
          secondClassName="newGroupCreationPopupBox2"
          popupHeading={"Add Group Participant "}
          content={
            <>
              <div className="newGroupCreationContainer">
                <div className="newGroupImageContainer">
                  <div className="newGroupImageWrapper">
                    <div className="profileIconImg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="155"
                        height="154"
                        viewBox="0 0 155 154"
                        fill="none"
                      >
                        <g opacity="0.4">
                          <circle
                            cx="77.1895"
                            cy="77.0684"
                            r="76.9316"
                            transform="rotate(-90 77.1895 77.0684)"
                            fill="url(#paint0_radial_60_9411)"
                          />
                        </g>
                        <g opacity="0.4">
                          <circle
                            cx="77.2061"
                            cy="77.0963"
                            r="63.3691"
                            transform="rotate(90 77.2061 77.0963)"
                            fill="url(#paint1_radial_60_9411)"
                          />
                        </g>
                        <g opacity="0.4">
                          <circle
                            cx="77.2051"
                            cy="77.0969"
                            r="46.7725"
                            transform="rotate(90 77.2051 77.0969)"
                            fill="url(#paint2_radial_60_9411)"
                          />
                        </g>
                        <defs>
                          <radialGradient
                            id="paint0_radial_60_9411"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                          >
                            <stop
                              offset="0.568025"
                              stop-color="white"
                              stop-opacity="0.2"
                            />
                            <stop offset="1" stop-color="#13A9C7" />
                          </radialGradient>
                          <radialGradient
                            id="paint1_radial_60_9411"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                          >
                            <stop
                              offset="0.568025"
                              stop-color="white"
                              stop-opacity="0.2"
                            />
                            <stop offset="1" stop-color="#13A9C7" />
                          </radialGradient>
                          <radialGradient
                            id="paint2_radial_60_9411"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                          >
                            <stop
                              offset="0.531188"
                              stop-color="white"
                              stop-opacity="0.2"
                            />
                            <stop offset="1" stop-color="#13A9C7" />
                          </radialGradient>
                        </defs>
                      </svg>
                      <div className="newGroupCreationImg">
                        <div
                          className="newGroupProfileImg"
                          style={{
                            // backgroundImage: `url(${inputFile && inputFile?.current?.files && inputFile?.current?.files?.length>0? new FileReader().readAsDataURL(inputFile?.current?.files[0])?.result : ""})`,
                            backgroundImage: `url(${
                              profileImg === null
                                ? GroupProfileIcon
                                : URL.createObjectURL(profileImg)
                            })`,
                          }}
                          id="newGroupProfileImg"
                        ></div>
                      </div>
                      <div
                        className="newGroupCreationCameraIcon"
                        onClick={() => {
                          document
                            .querySelector("#newGroupProfileImgFile")
                            .click();
                        }}
                      >
                        <input
                          type="file"
                          id="newGroupProfileImgFile"
                          onChange={(e) => {
                           // console.log(e.target);
                           // console.log(e.currentTarget);
                            if (e.currentTarget.files.length > 0) {
                              setProfileImg(e.currentTarget.files[0]);
                            } else {
                              setProfileImg(null);
                            }
                          }}
                          style={{ display: "none" }}
                          multiple
                          accept=".jpg,.png,.gif,.jpeg"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="30"
                          viewBox="0 0 29 30"
                          fill="none"
                        >
                          <circle
                            cx="14.4892"
                            cy="14.8879"
                            r="14.2138"
                            fill="#1296B0"
                          />
                          <circle
                            cx="17.842"
                            cy="13.3865"
                            r="0.412346"
                            fill="white"
                          />
                          <circle
                            cx="14.4905"
                            cy="16.3486"
                            r="2.99882"
                            stroke="white"
                            stroke-width="1.11962"
                          />
                          <path
                            d="M10.0234 10.4073V10.9671H10.5832V10.4073H10.0234ZM18.9549 10.4073H18.3951V10.9671H18.9549V10.4073ZM10.5832 10.1871C10.5832 9.39489 11.2254 8.75265 12.0177 8.75265V7.63303C10.6071 7.63303 9.46357 8.77654 9.46357 10.1871H10.5832ZM10.5832 10.4073V10.1871H9.46357V10.4073H10.5832ZM9.24383 10.9671H10.0234V9.84746H9.24383V10.9671ZM7.31079 12.9001C7.31079 11.8325 8.17624 10.9671 9.24383 10.9671V9.84746C7.55789 9.84746 6.19117 11.2142 6.19117 12.9001H7.31079ZM7.31079 19.0909V12.9001H6.19117V19.0909H7.31079ZM9.24383 21.0239C8.17624 21.0239 7.31079 20.1585 7.31079 19.0909H6.19117C6.19117 20.7768 7.55789 22.1435 9.24383 22.1435V21.0239ZM19.7345 21.0239H9.24383V22.1435H19.7345V21.0239ZM21.6675 19.0909C21.6675 20.1585 20.8021 21.0239 19.7345 21.0239V22.1435C21.4204 22.1435 22.7871 20.7768 22.7871 19.0909H21.6675ZM21.6675 12.9001V19.0909H22.7871V12.9001H21.6675ZM19.7345 10.9671C20.8021 10.9671 21.6675 11.8325 21.6675 12.9001H22.7871C22.7871 11.2142 21.4204 9.84746 19.7345 9.84746V10.9671ZM18.9549 10.9671H19.7345V9.84746H18.9549V10.9671ZM18.3951 10.1871V10.4073H19.5147V10.1871H18.3951ZM16.9606 8.75265C17.7528 8.75265 18.3951 9.39489 18.3951 10.1871H19.5147C19.5147 8.77654 18.3712 7.63303 16.9606 7.63303V8.75265ZM12.0177 8.75265H16.9606V7.63303H12.0177V8.75265Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="imgText ">
                        <span>
                          <input
                            type="text"
                            placeholder="Type Group Name"
                            onChange={(e) => {
                              setNewGroupProfileName(e.currentTarget.value);
                            }}
                            readOnly = {profileNameInputActive}
                          />
                          {profileNameInputActive &&
                          <svg
                          onClick={()=>{setProfileNameInputActive(false)}}
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <line
                              x1="7.0243"
                              y1="12.1146"
                              x2="12.0204"
                              y2="12.1146"
                              stroke={newGroupProfileName === ""? "grey":"#1296B0"}
                              stroke-width="1.00856"
                              stroke-linecap="round"
                            />
                            <path
                              d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444"
                              stroke={newGroupProfileName === ""? "grey":"#1296B0"}
                              stroke-width="1.00856"
                            />
                          </svg>}
                          {!profileNameInputActive && <svg
                          onClick={()=>{setProfileNameInputActive(false)}}
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <line
                              x1="7.0243"
                              y1="12.1146"
                              x2="12.0204"
                              y2="12.1146"
                              stroke={newGroupProfileName === ""? "grey":"#1296B0"}
                              stroke-width="1.00856"
                              stroke-linecap="round"
                            />
                            <path
                              d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444"
                              stroke={newGroupProfileName === ""? "grey":"#1296B0"}
                              stroke-width="1.00856"
                            />
                          </svg>}
                        </span>
                        <hr className="newGroupHrLine"></hr>
                        <p>{selectedParticipant.length} Participants</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="newGroupParticipantListContainer">
                  {selectedParticipant?.map((item, index) => {
                    return (
                      <div className="newGroupLstWithDeleteIcon">
                        <div className="newGroupListRowContainer">
                        <div
                          
                          onClick={() => {}}
                          className="newChatListContentContainer"
                        >
                          <div className="newChatActiveImage">
                            <img src={item.Img} alt="Profile" />
                          </div>
                          <div className="newChatPostMainTextContainer">
                            <label className="chatListName">{item.Name}</label>
                            <label className="chatListRole">{item.Role}</label>
                          </div>
                        </div>
                        <div>
                          <svg
                            onClick={() => {
                              let tempItem = JSON.parse(
                                JSON.stringify(selectedParticipant)
                              );
                              tempItem = tempItem.filter(
                                (d) =>
                                  selectedParticipant[index]?.userId !==
                                  d?.userId
                              );
                              setSelectedParticipant([...tempItem]);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                          >
                            <circle
                              cx="13.9741"
                              cy="13.4956"
                              r="13"
                              fill="#FFF2F0"
                            />
                            <path
                              d="M9.35889 11.0924L10.3124 18.4194C10.4222 19.2631 11.141 19.8944 11.9919 19.8944H15.9566C16.8074 19.8944 17.5262 19.2631 17.636 18.4194L18.5896 11.0924"
                              stroke="#F71D1D"
                              stroke-linecap="round"
                            />
                            <line
                              x1="8.67432"
                              y1="9.31686"
                              x2="19.274"
                              y2="9.31686"
                              stroke="#F71D1D"
                              stroke-linecap="round"
                            />
                            <path
                              d="M11.998 9.41261V8.78978C11.998 7.85444 12.7563 7.09619 13.6916 7.09619H14.2561C15.1915 7.09619 15.9497 7.85444 15.9497 8.78978V9.41261"
                              stroke="#F71D1D"
                            />
                          </svg>
                        </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="groupCreationNxtBtn" onClick={newGroupCreated}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                >
                  <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                  <path
                    d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                    stroke="white"
                    stroke-width="1.10154"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </>
          }
          handleClose={() => {
            setActiveGroupCreation(false);
            setNewGroupList(true);
          }}
        />
      )}
      
    </div>
  );
}

export default NewGroup;