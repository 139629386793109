import React, { useState, useEffect } from "react";
import ItsmdeskPic from "../../Assets/ItsmdeskPic.svg";
import ItsmexceptionPic from "../../Assets/ItsmexceptionPic.svg";
import ItsmchangePic from "../../Assets/ItsmchangePic.svg";
import ItsmassetPic from "../../Assets/ItsmassetPic.svg";
import ItsmreportsKPIPic from "../../Assets/ItsmreportsKPIPic.svg";
import VendorIt from "../../Assets/VendorIt.svg";
import Problems from "../../Assets/Problems.svg";
import "./ITSM.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ItDeskTicketTab } from "./Components/ItDeskTicketTab";
import { CreateItDeskTicket } from "./Components/ItDeskComponent/CreateItDeskTicket";
import { ExceptionTab } from "./Components/ExceptionTab";
import { CreateException } from "./Components/ExceptionComponent/CreateException";
import { AssetTab } from "./Components/AssetTab";
import { CreateAsset } from "./Components/AssetComponent/CreateAsset";
import { ChangeTab } from "./Components/ChangeTab";
import { ChangeDetails } from "./Components/ChangeComponent/ChangeDetails";
import { CreateChangeIt } from "./Components/ChangeComponent/CreateChangeIt";
import { DetailsItDeskTicket } from "./Components/ItDeskComponent/DetailsItDeskTicket";
import { DetailsException } from "./Components/ExceptionComponent/DetailsException";
import { DetailsProblem } from "./Components/ItDeskComponent/DetailsProblem";
import { DetailsItDeskMyTicket } from "./Components/ItDeskComponent/DetailsItDeskMyTicket";
import { useSelector } from "react-redux";
import { DetailPageAsset } from "./Components/AssetComponent/DetailPageAsset";
import { DetailsPagemaintenanceAsset } from "./Components/AssetComponent/DetailsPagemaintenanceAsset";
import { DetailPageSurrenderAsset } from "./Components/AssetComponent/DetailPageSurrenderAsset";
import { ProblemIt } from "./Components/ItDeskComponent/ProblemIt";
import { Vendors } from "./Components/ItDeskComponent/Vendors";
const ITSM = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  const [activeSubmodule, setActiveSubmodule] = useState("itdeskticket");
  const [activeFeature, setActiveFeature] = useState("");
  const [mainActive, setMainActive] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [surrenderActive, setSurrenderActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const [detailsProblemVisible, setDetailsProblemVisible] = useState(false);
  const [detailsItdeskTicketVisible, setDetailsItdeskTicketVisible] =
    useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "itdeskticket") return ItsmdeskPic;
    else if (subMenuName === "exception") return ItsmexceptionPic;
    else if (subMenuName === "problem") return Problems;
    else if (subMenuName === "change") return ItsmchangePic;
    else if (subMenuName === "asset") return ItsmassetPic;
    else if (subMenuName === "reportskpi") return ItsmreportsKPIPic;
    else if (subMenuName === "vendors") return VendorIt;
  };
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "itsm"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setMainActive(false);
    setFeedback(false);
    setSurrenderActive(false);
    setDetailsItdeskTicketVisible(false);
    setDetailsProblemVisible(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("itsm")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "itsm");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 2] === "ticketdetail") {
          setDetailsItdeskTicketVisible(true);
        } else if (splitArray[submoduleIndex + 2] === "problem") {
          setDetailsProblemVisible(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 2] === "maintenence") {
          setMainActive(true);
        }
         else if (splitArray[submoduleIndex + 2] === "feedbackdetails") {
          setFeedback(true);
        }
         else if (splitArray[submoduleIndex + 2] === "surrender") {
          setSurrenderActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  // useEffect(() => {
  //   document.title = `PeopleSol - ITSM`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);
  return (
    <div className="moduleContainer">
      {activeSubmodule === "itdeskticket" && (
        <>
          {createActive && (
            <CreateItDeskTicket title={"Create IT Desk Ticket"} />
          )}
          {detailsActive && <DetailsItDeskTicket />}
          {detailsItdeskTicketVisible && <DetailsItDeskMyTicket />}
          {feedback && <DetailsItDeskTicket />}
        </>
      )}
      {activeSubmodule === "exception" && (
        <>
          {createActive && <CreateException title={"Create Exception"} />}
          {detailsActive && <DetailsException />}
        </>
      )}
      {activeSubmodule === "change" && (
        <>
          {createActive && <CreateChangeIt title={"Create IT Desk Ticket"} />}
          {detailsActive && <ChangeDetails />}
        </>
      )}
      {activeSubmodule === "asset" && (
        <>
          {createActive && <CreateAsset title={"Create Asset"} />}
          {detailsActive && <DetailPageAsset />}
          {mainActive && <DetailsPagemaintenanceAsset />}
          {surrenderActive && <DetailPageSurrenderAsset />}
        </>
      )}
      {activeSubmodule === "problem" && (
        <>
  
          {detailsActive &&<DetailsProblem title={"Problem"} />}

        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/itsm/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {activeSubmodule === "itdeskticket" ? <ItDeskTicketTab /> : null}
        {activeSubmodule === "problem" ? <ProblemIt /> : null}
        {activeSubmodule === "exception" ? <ExceptionTab /> : null}
        {activeSubmodule === "asset" ? <AssetTab /> : null}
        {activeSubmodule === "change" ? <ChangeTab /> : null}
        {activeSubmodule === "vendors" ? <Vendors /> : null}
      </div>
    </div>
  );
};

export { ITSM };
