import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { ReqCategoryForm } from "./Form/ReqCategoryForm";
import { reqCategorySchema } from "./RequestCategorySchemas";
import { ServiceCategoryForm } from "./Form/ServiceCategoryForm";
import { useEffect } from "react";

const initialvalue = {
  name: "",
};

const CreateServiceCategory = (props) => {
  const { state } = useLocation();
  
  const data = state?.selectedRecord;

  const dispatch = useDispatch();

  const postFormData = async (values) => {
    try {
  
      const result = await postRequest(
        "HRHelpdeskServiceCategories/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage()
    } catch (error) {}
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Service Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskServiceCategories/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage()
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data?.Id,
      isActive:true
    };

    const create = {
      ...values,
      isActive:true
    };
    
    data ? Edit(edit) : postFormData(create);


   ;
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/servicecategory");
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Service Category':'Create Service Category'}
      FormHeading={data?'Edit Service Category':'Create Service Category'}
      validationSchema={reqCategorySchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: data?.Name,
            }
          : 
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ServiceCategoryForm data={data}/>}    />
  );
};

export { CreateServiceCategory };



