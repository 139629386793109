import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyFoodComp } from "./FormComp/CreateCompanyFoodComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { format } from "date-fns";
import { CreateReceiveCourierComp } from "./FormComp/CreateReceiveCourierComp";
import { CreateHouseKeepingComp } from "./FormComp/CreateHouseKeepingComp";

const CreateHouseKeeping = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create House Keeping`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState({});

  const data1 = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(`HouseKeepingTicket/Details/${data1}`);
      // console.log("HouseKeepingTicket Requestsdetails", result);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  // console.log("detailsform uiyhdfsig", detailsform);
  useEffect(() => {
    if (data1) getFormDetails();
  }, []);

  const Create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("HouseKeepingTicket/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("HousekeepingTicket/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const initialvalue = {
    // employeeID: detailsform?.employeeId ? detailsform?.employeeId : "",
    // date: detailsform?.date ? new Date(detailsform?.date) : "",
    typeID:
      data1 && detailsform?.summary?._tickets?.typeID
        ? detailsform?.summary?._tickets?.typeID
        : "",
    locationID:
      data1 && detailsform?.summary?._tickets?.locationID
        ? detailsform?.summary?._tickets?.locationID
        : "",
    contactNo: data1 ? detailsform?.summary?._tickets?.contactNo : "",
    description: data1 ? detailsform?.summary?._tickets?.description : "",
    severity: data1 ? detailsform?.summary?._tickets?.severity : "",
    priority: data1 ? detailsform?.summary?._tickets?.priority : "",
    deadline:
      data1 && detailsform?.summary?._tickets?.deadline
        ? new Date(detailsform?.summary?._tickets?.deadline)
        : "",
    attachment: data1
      ? detailsform?.summary?._tickets?.attachment === null
        ? ""
        : `Housekeeping/${detailsform?.summary?._tickets?.attachment}`
      : "",
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];

  // console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    typeID: data1
      ? Yup.string().required("Please select Ticket Type ")
      : Yup.array()
          .min(1, "Please select Ticket Type ")
          .required("Please select Ticket Type "),
    locationID: Yup.string().required("Please select Location "),
    contactNo: Yup.number()
      .typeError("Contact Number must be a number")
      .required("Please Enter Contact Number"),
    description: Yup.string()
      // .matches(/^\S.*$/, "Description should not start with spaces")
      .max(500, "Description must be less than 500 characters")
      .required("Please Enter Description"),
    severity: Yup.string().required("Please select Severity"),
    priority: Yup.string().required("Please select Priority"),
    // deadline: Yup.string().required("Please select Deadline"),
    // attachment: Yup.mixed()
    //   .notRequired()
    //   .nullable()
    //   .test("Fichier taille", "File Size should be less than 2 mb", (value) => {
    //     return (
    //       !value ||
    //       (value && value.size <= 2048 * 2048) ||
    //       typeof value !== "object"
    //     );
    //   })
    //   .test(
    //     "format",
    //     "File Format is Incorrect",
    //     (value) =>
    //       !value ||
    //       (value && SUPPORTED_FORMATS.includes(value.type)) ||
    //       typeof value !== "object"
    //   ),
  });
  const onSubmit = (values) => {
    // debugger;
    // console.log("valuessgd", values);
    const formData = new FormData();
    formData.append("id", data1 ? detailsform?.summary?._tickets?.id : 0);
    data1
      ? values?.typeID
          ?.split(",")
          ?.map((item, index) => formData.append(`TypeIds[${index}]`, item))
      : values?.typeID?.map((item, index) =>
          formData.append(`TypeIds[${index}]`, item)
        );
    formData.append("locationID", values?.locationID);
    formData.append("contactNo", values?.contactNo);
    formData.append("description", values?.description);
    formData.append("severity", values?.severity);
    formData.append("priority", values?.priority);
    if (values?.attachment) {
      formData.append("attachment", values?.attachment?.name);
    }
    if (values?.deadline) {
      formData.append(
        "deadline",
        format(values?.deadline, "yyyy-MM-dd'T'HH:mm:ss")
      );
    }
    if (values?.attachment) {
      if (typeof values?.attachment === "object")
        formData.append("upload", values?.attachment);
    }
    data1 ? Edit(formData) : Create(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={
        data1 ? "Edit House Keeping Request" : "Create House Keeping Request"
      }
      ButtonLabel={
        data1 ? "Edit House Keeping Request" : "Create House Keeping Request"
      }
      onSubmit={onSubmit}
      chooseFormValues={chooseFormValues}
      styles={{ maxWidth: "60%", width: "60%" }}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateHouseKeepingComp
          data={detailsform}
          edit={data1}
          formValues={formValues}
        />
      }
    />
  );
};

export { CreateHouseKeeping };
