import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { format } from "date-fns";
const DetailsPagePayConfig = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const [isOpen, setIsOpen] = useState(false);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PayConfig/Details/${selectedId}`);
      setEventtData(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getFormDetails();
    return () => {};
  }, []);
  console.log("ev", eventtData);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Pay Config Details</h3>
          <div>
            <IconButton onClick={() => closeDetails()} title="close">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7579 0.56897L0.757935 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 0.56897L12.7579 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div
          className="tabularMasterDetailsPageBody"
          style={{ height: "100%", overflow: "auto" }}
        >
          <>
            {eventtData?.data ? (
              loading ? (
                <Loader />
              ) : (
                <>
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" ,gap:'0px'}}
                  >
                    <div className="detailInner">
                      <div className="dataTableRowContainer">
                        <div className="dataTableRow">
                          <label className="dataTableRowCell">
                            Pay Frequency
                          </label>
                          <label className="dataTableRowCell">:</label>
                          <label className="dataTableRowCell">
                            {"Every month (Monthly)"}
                          </label>
                        </div>
                        {eventtData?.data.calculateMonthlySalaryBasedOnValue >
                          0 && (
                          <div className="dataTableRow">
                            <label className="dataTableRowCell">
                              Number of Working Days
                            </label>
                            <label className="dataTableRowCell">:</label>
                            <label className="dataTableRowCell">
                              {
                                eventtData?.data
                                  .calculateMonthlySalaryBasedOnValue
                              }
                            </label>
                          </div>
                        )}
                        <div className="dataTableRow">
                          <label className="dataTableRowCell">Pay Day</label>
                          <label className="dataTableRowCell">:</label>
                          <label className="dataTableRowCell">{`${eventtData?.data.payYourEmployeesOn}`}</label>
                        </div>
                        <div className="dataTableRow">
                          <label className="dataTableRowCell">
                            First Pay Period
                          </label>
                          <label className="dataTableRowCell">:</label>
                          <label className="dataTableRowCell">{`${eventtData?.data.firstPayrollFrom}`}</label>
                        </div>
                        <div className="dataTableRow">
                          <label className="dataTableRowCell">
                            First Pay Date
                          </label>
                          <label className="dataTableRowCell">:</label>
                          <label className="dataTableRowCell">
                            {eventtData?.data?.paidOn &&
                              format(
                                new Date(eventtData?.data?.paidOn),
                                "dd/MM/yyyy"
                              )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {eventtData?.summary?.employeeName.length > 0 ? (
                      <>
                        <div className="headingForm">
                          Mapped Employee Details
                        </div>
                        <div className="detailInner">
                          <div style={{ width: "100%" }}>
                            <table
                              className="tabless tabless-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead
                                style={{
                                  backgroundColor: "var(--primary-hover)",
                                }}
                              >
                                <tr>
                                  <td>S.no</td>
                                  <td>Employee Name</td>
                                </tr>
                              </thead>
                              <tbody>
                                {eventtData?.summary?.employeeName?.map(
                                  (item, index) => {
                                    return (
                                      <tr className="trStyle">
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )
            ) : (
              <div className="noDatastyle">
                <img
                  src={Nodata}
                  alt="No data"
                  style={{ height: "150px", width: "250px" }}
                />
                <h4>{"No Data Found"}</h4>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
export { DetailsPagePayConfig };
