import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
function PersonalDetails({
  genderOptions,
  maritalOptions,
  relationshipOptions,
  formValues,
  dispatch,
  employee,
  yesNoOptions,
}) {
  const addYears = (date, years) => {
    date.setFullYear(date.getFullYear() + years);

    return date;
  };
  useEffect(() => {
    document.title = `PeopleSol - Personal Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="headingForm">Personal Details</div>
      <div className="formcustom threeColumn">
        {employee && (
          <>
            <div>
              <LabelCustom labelName={"Personal Email Id"} />
              <InputCustomFormik
                name="personalEmail"
                type={"email"}
                placeholder={"Enter Personal Email"}
              />
            </div>
            <div>
              <LabelCustom
                labelName={"Personal Mobile Number"}
                mandatory={true}
              />
              <InputCustomFormik
                maxLength={10}
                name="PersonalPhoneNumber"
                step=".001"
                placeholder={"Enter Personal Mobile Number"}
              />
            </div>
          </>
        )}
        <div>
          <LabelCustom labelName={"Date Of Birth"} />
          <DatePickerFormiks
            name="Dob"
            placeholder={"Select Date Of Birth"}
            maxDate={addYears(new Date(), -18)}
          />
        </div>
        <div>
          <LabelCustom labelName={"Gender"} />
          <SelectForm name="Gender" options={genderOptions} />
        </div>
        <div>
          <LabelCustom labelName={"Religion"} />
          <InputCustomFormik
            maxLength={150}
            name="religion"
            type={"text"}
            placeholder={"Enter Religion"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Nationality"} />
          <InputCustomFormik
            maxLength={150}
            name="nationality"
            type={"text"}
            placeholder={"Enter Nationality"}
          />
        </div>

        {/* <div>
          <LabelCustom labelName={"Birth Country"} />
          <InputCustomFormik
            maxLength={150}
            name="birthCountry"
            type={"text"}
            placeholder={"Enter Birth Country"}
          />
        </div>
        
        <div>
          <LabelCustom labelName={"State"} />
          <SelectForm name="state" options={stateOptions} />
        </div> */}

        <div>
          <LabelCustom labelName={"Language"} />
          <InputCustomFormik
            maxLength={150}
            name="language"
            type={"text"}
            placeholder={"Enter Language"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Marital Status"} />
          <SelectForm
            name="maritalStatus"
            options={maritalOptions}
            placeholder={"Select marital status"}
          />
        </div>
        {formValues?.maritalStatus === "Married" && (
          <div>
            <LabelCustom labelName={"Wedding Date"} />
            <DatePickerFormiks
              name="dom"
              placeholder={"Select Wedding Date"}
              maxDate={new Date()}
            />
          </div>
        )}
        {/* <div>
          <LabelCustom labelName={"Highest Qualification"} />
          <InputCustomFormik
            maxLength={150}
            name="highestQualification"
            type={"text"}
            placeholder={"Enter Highest Qualification"}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Overall Experience ( in years )"} />
          <InputCustomFormik
            maxLength={150}
            name="previousExperience"
            step=".001"
            placeholder={"Enter Experience (e.g. 1.5)"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Current Annual Salary ( in Lakhs )"} />
          <InputCustomFormik
            maxLength={5}
            name="currentSalary"
            step=".001"
            placeholder={"Enter Current Annual Salary"}
          />
        </div>
        {employee && (
          <div>
            <LabelCustom labelName={"Group Health Insurance"} />
            <SelectForm
              name="groupHealthInsurance"
              options={yesNoOptions}
              placeholder={" Select Group Health Insurance "}
            />
          </div>
        )}

        {/* <div>
          <LabelCustom labelName={"Additional Information"} />
          <TextAreaFormik
            maxLength={500}
            name="additionalInfo"
            placeholder={" Enter Additional Information "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Skill Set"} />
          <TextAreaFormik
            maxLength={500}
            name={"skillSet"}
            placeholder={" Enter Skill Set "}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Profile Photo"} />
          <FileUploadFormik name="Photo" />
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Size: 2 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Format: JPG, JPEG, PNG
          </span>
        </div>
        <div>
          <LabelCustom labelName={"Current Offer Letter"} />
          <FileUploadFormik name="offerLetter" />
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Size: 2 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Format: PDF, Doc
          </span>
        </div>
      </div>
      {formValues?.maritalStatus === "Married" && (
        <>
          <div className="headingForm">Spouse Details</div>
          <div className="formcustom threeColumn">
            <div>
              <LabelCustom labelName={"Spouse Name"} />
              <InputCustomFormik
                maxLength={150}
                name="spouseName"
                type={"text"}
                placeholder={"Enter Name"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Spouse Mobile Number"} />
              <InputCustomFormik
                minLength={10}
                maxLength={10}
                name="spouseMobileNumber"
                step=".001"
                placeholder={"Enter Mobile Number"}
              />
            </div>
            {/* <div>
              <LabelCustom labelName={"Spouse Working?"} />
              <SelectForm
                name={"spouseWorking"}
                options={yesNoOptions}
                placeholder={" Select  "}
              />
            </div>
            <div>
              <LabelCustom labelName={"Occupation"} />
              <InputCustomFormik
                maxLength={150}
                name="occupation"
                type={"text"}
                placeholder={"Enter Occupation"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Company Name"} />
              <InputCustomFormik
                maxLength={150}
                name="companyName"
                type={"text"}
                placeholder={"Enter Company Name"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Office Number"} />
              <InputCustomFormik
                minLength={10}
                maxLength={10}
                name="officePhoneNumber"
                step=".001"
                placeholder={"Enter Office Number"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Office Address"} />
              <TextAreaFormik
                maxLength={500}
                name={"officeAddress"}
                placeholder={" Enter Office Address "}
              />
            </div> */}
            <div>
              <LabelCustom labelName={"No. Of Children "} />
              <InputCustomFormik
                maxLength={2}
                name={"noOfchildrens"}
                type="number"
                           step="0.01"
                placeholder={"Enter number of children"}
              />
            </div>
          </div>
        </>
      )}
      <div className="headingForm">Emergency Contact Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Person Name"} />
          <InputCustomFormik
            maxLength={150}
            name="emergencyContactName"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Relationship"} />
          <SelectForm
            menuPlacement="top"
            options={relationshipOptions}
            name={`contactPersonRelationship`}
          />
        </div>

        <div>
          <LabelCustom labelName={"Emergency Mobile Number"} />
          <InputCustomFormik
            minLength={10}
            maxLength={10}
            name="emergencyContactNumber"
            type="number"
                           step="0.01"
            placeholder={"Enter Mobile Number"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"House Phone Number"} />
          <InputCustomFormik
            minLength={10}
            maxLength={10}
            name="contactPersonPhoneNumber"
            type="number"
                           step="0.01"
            placeholder={"Enter House Phone Number"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Office Number"} />
          <InputCustomFormik
            minLength={10}
            maxLength={10}
            name="contactPersonOfficeNumber"
            type="number"
                           step="0.01"
            placeholder={"Enter Office Number"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Address"} />
          <TextAreaFormik
            maxLength={500}
            name={"contactPersonAddress"}
            placeholder={" Enter Office Address "}
          />
        </div> */}
      </div>

      <div className="headingForm">Dependent Details</div>
      <div>
        <FieldArray name="dependentList">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { dependentList } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Name"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Date Of Birth"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Relationship"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Gender"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {dependentList?.map((dependentLists, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          maxLength={150}
                          type="text"
                          name={`dependentList[${index}].name`}
                          placeholder={"Enter dependent name"}
                        />
                      </StyledTableCell>
                      {/* 3rd column */}
                      {/* 4th column */}
                      <StyledTableCell>
                        <DatePickerFormiks
                          name={`dependentList[${index}].dob`}
                          maxDate={new Date()}
                        />
                      </StyledTableCell>
                      {/* 5th column */}
                      <StyledTableCell>
                        <SelectForm
                          menuPlacement="top"
                          options={relationshipOptions}
                          name={`dependentList[${index}].relationship`}
                        />
                      </StyledTableCell>
                      {/* 6th column */}
                      <StyledTableCell>
                        <SelectForm
                          menuPlacement="top"
                          name={`dependentList[${index}].gender`}
                          options={genderOptions}
                        />
                      </StyledTableCell>
                      {/* 7th column */}
                      {/* 8th column */}
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            dependentList.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              name: "",
                              permanentAddress: "",
                              dob: "",
                              relationship: "",
                              gender: "",
                              martialStatus: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
}

export { PersonalDetails };
