import React from "react";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";

function CommunityNotificationItem({
  item,
  setSelectedParticipant,
  selectedParticipant,
  storedEmployeeId,
  setStoredEmployeeId,
  setAllEmployeeList,
  allEmployeeList,
}) {
  return (
    <div
      onClick={() => {
        let searchSelectedParticipant = selectedParticipant?.find(
          (d) => d?.id === item?.id
        );
        if (!searchSelectedParticipant)
          setSelectedParticipant([...selectedParticipant, item]);
        let z = JSON.parse(JSON.stringify(storedEmployeeId));
        z.push(item?.id);
        setStoredEmployeeId(z);
        let tempList = JSON.parse(JSON.stringify(allEmployeeList));
        tempList = tempList.filter((selectedValue) => {
          return Number(selectedValue.id) !== Number(item.id);
        });
        setAllEmployeeList(tempList);
      }}
      className="communityAllEmployeeListContainer"
    >
      <div className="newChatActiveImage">
        <div
          className={`${
            item?.image ? "socialProfilePicContainer" : "socialCustomProfilePic"
          }`}
          style={{
            width: "50px",
            height: "50px",
            margin: "0",
          }}
        >
          {!item?.image ? (
            getUserInitials(getDisplayName(item?.name))
          ) : (
            <img
              className="commentProfilePic"
              onClick={() => {
                // handleUserProfileClick(d?.id);
              }}
              src={
                process.env.REACT_APP_.BASE_URL + profileImages + item?.image
              }
              alt="Profile"
              onError={(e) => {
                e.target.src = ProfileAvatar;
              }}
            />
          )}
        </div>
      </div>
      <div className="newChatPostMainTextContainer">
        <label className="chatListName">{getDisplayName(item?.name)}</label>
        <label className="chatListRole">{item?.jobRoal}</label>
        <label className="chatListRole">{item?.location}</label>
      </div>
    </div>
  );
}

export default CommunityNotificationItem;
