import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";
import { showToast } from "../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { useSelector } from "react-redux";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { Form, Formik } from "formik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import * as Yup from "yup";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Update } from "@mui/icons-material";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { format } from "date-fns";
const opt = [
  { label: "Solution Possible", value: "Solution Possible" },
  {
    label: "Both Solution or Workaround not Possible",
    value: "Both Solution or Workaround not Possible",
  },
  { label: "Workaround Possible", value: "Workaround Possible" },
];
const option = [
  { label: "Change is Required", value: "Change is Required" },
  { label: "Change is Not Required", value: "Change is Not Required" },
];
const kedb = [
  { label: "Resolved", value: "Resolved" },
  { label: "Not Resolved", value: "Not Resolved" },
];
const DetailsProblem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [employeeList, setEmployeeList] = useState([]);
  const [val, setVal] = useState("");
  // let currentUrl = location?.pathname;
  const closeDetails = () => {
    returnPage();
  };
  useEffect(() => {
    document.title = `PeopleSol - IT Desk Ticket`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetailsProblem = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `problemRequests/details?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "problemrequests/GetProblemRequestOwners"
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      // problemrequests/GetProblemRequestOwners
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getDetailsProblem();
    getEmployeeData();
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("problemrequests/RCAUpdate", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetailsProblem();
    }
  };
  const createKedbData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "problemrequests/KEDBUpdate",
        values
      );
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getDetailsProblem();
    }
  };
  const updateProblem = async (values, id) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `problemrequests/ITOPSTeamUpdate?Id=${id}&ITOpsEmployeeID=${values}`
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
      getDetailsProblem();
    }
  };
  const updateReq = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `problemrequests/SolutionStatusUpdate/?ID=${selectedId}&Status=${values}`
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
      getDetailsProblem();
    }
  };
  const createKedbNotRes = async (id) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `problemrequests/NotResolvedsubmit/?Id=${id}`
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
      getDetailsProblem();
    }
  };
  const OnSubmit = async (values) => {
    updateProblem(values.ITOpsEmployeeID, selectedId);
  };
  const SubmitSolution = async (values) => {
    const Create = {
      Status: val?.key !== "" ? val.key : values?.Status,
    };
    if (
      values.Status === "Change is Required" ||
      values.key === "Change is Required" ||
      values.Status === "Solution Possible"
    ) {
      navigate(-1);
      setTimeout(
        () =>
          navigate(
            `/itsm/change/create/${Number(data?.problemRequest?.problemOwnerEmployeeId)}`,
            {
              state: { selectedId },
            }
          ),
        30
      );
    } else updateReq(Create.Status);
  };
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("ID", selectedId);
    if (typeof values?.RCADocument == "object")
      formData.append("RCADocument", values?.RCADocument);
    formData.append("Description", values?.Description);

    createPageData(formData);
  };
  const onSubmitKed = async (values) => {
    const formData = new FormData();
    formData.append("ID", selectedId);
    formData.append("solutionImplement", values?.solutionImplement);
    formData.append("KEDBDescription", values?.KEDBDescription);
    if (typeof values?.KEDBDocument == "object") formData.append("KEDBDocument", values?.KEDBDocument);
    if (values.KEDBDescription === "") {
      createKedbNotRes(selectedId);
    } else createKedbData(formData);
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];

  const validationSchema = Yup.object({
    Description: Yup.string()
      .max(499, "Must be less than 500 characters")
      .required("Please Enter  Description"),
    RCADocument: Yup.mixed()
      .nullable()
      .required("RCA document is a required field")
      .test("Fichier taille", "File Size should be less than 2 mb", (value) => {
        return (
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
        );
      })
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          (value && SUPPORTED_FORMATS.includes(value.type)) ||
          typeof value !== "object"
      ),
  });

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Problem Request Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="detailsEReq" style={{ flexDirection: "column" }}>
              {LoggedInUser?.id ===
                data?.problemRequest?.problemOwnerEmployeeId &&
                data?.problemRequest?.status === "Requested" && (
                  <div>
                    <div className="headingForm">RCA Update</div>
                    <Formik
                      initialValues={{ Description: "", RCADocument: "" }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(formik) => {
                        <Form>
                          <div>
                            <LabelCustom
                              labelName={"RCA Description"}
                              mandatory={true}
                            />
                            <TextAreaFormik
                              maxlength="500"
                              style={{ height: "100px" }}
                              name="Description"
                              placeholder={" Enter Description"}
                            />
                          </div>
                          <div>
                            <LabelCustom labelName={"RCA Document"} />
                            <FileUploadFormik name="RCADocument" />
                            <div
                              style={{
                                color: "var(--primary)",
                                fontSize: "14px",
                                marginLeft: "13px",
                              }}
                            >
                              Supporting document must be in pdf,jpeg,png format
                            </div>
                          </div>
                          <div>
                            <button
                              style={{ padding: " 5px 21px" }}
                              className="btnTime"
                              // onClick={() =>
                              //   formik.resetForm({ values: { Comment: "" } })
                              // }
                              type="reset"
                            >
                              Reset
                            </button>
                            <button
                              className="button primaryButton"
                              type="submit"
                              style={{ color: "white" }}
                            >
                              Submit
                            </button>
                          </div>
                        </Form>;
                      }}
                    </Formik>
                  </div>
                )}
              <div className="detailInner">
                <Details
                  detailTitle={"Request Number"}
                  Details={data?.problemRequest?.requestNumber}
                />
                <Details
                  detailTitle={"Problem Owner"}
                  Details={data?.problemRequest?.problemOwnerEmployee?.name}
                />

                <Details
                  detailTitle={"Description"}
                  Details={data?.problemRequest?.description}
                />
                <Details
                  detailTitle={"Priority"}
                  Details={data?.problemRequest?.priority}
                />
                <Details
                  detailTitle={"Business Impact"}
                  Details={data?.problemRequest?.businessImpact}
                />
                <Details
                  detailTitle={"Technology  Impact"}
                  Details={data?.problemRequest?.technologyImpact}
                />
                <Details
                  detailTitle={"Cost Impact"}
                  Details={data?.problemRequest?.costImpact}
                />
                {data?.problemRequest?.problemRequestIncidents?.length > 0 && (
                  <Details
                    detailTitle={"Incident ticket(s)"}
                    Details={data?.problemRequest?.problemRequestIncidents?.map(
                      (item) => {
                        return (
                          <>
                            <div>{item?.incidentTicket?.type?.name}</div>
                          </>
                        );
                      }
                    )}
                  />
                )}
                <Details
                  detailTitle={"Status"}
                  Details={
                    <div
                      style={{
                        backgroundColor: getStatusColor(
                          data?.changeRequest != null ? (
                            <span>Change Request Created : {""}</span>
                          ) : (
                            data?.problemRequest?.status
                          )
                        )?.split(",")?.[0],
                        color: getStatusColor(
                          data?.changeRequest != null ? (
                            <span>Change Request Created : {""}</span>
                          ) : (
                            data?.problemRequest?.status
                          )
                        )?.split(",")?.[1],
                        cursor: "default",
                        padding: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      {data?.changeRequest != null ? (
                        <span>Change Request Created : {""}</span>
                      ) : (
                        data?.problemRequest?.status
                      )}
                    </div>
                  }
                />

                {/* <Details
                  detailTitle={"Created On"}
                  Details={data?.problemRequest?.createdOn?.replace("T", " ")}
                />
                <Details
                  detailTitle={"Last Updated On"}
                  Details={data?.problemRequest?.lastUpdatedOn?.replace(
                    "T",
                    " "
                  )}
                /> */}
                <Details
                  detailTitle={"Created On"}
                  Details={format(
                    new Date(data?.problemRequest?.createdOn),
                    "dd/MM/yyyy hh:mm a"
                  )}
                />
                <Details
                  detailTitle={"Last Updated On"}
                  Details={format(
                    new Date(data?.problemRequest?.lastUpdatedOn),
                    "dd/MM/yyyy hh:mm a"
                  )}
                />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                background: "var(--form-background)",
                margin: "24px",
                bordeRadius: " 5px",
              }}
            >
              <div>
                {LoggedInUser?.id ===
                  data?.problemRequest?.problemOwnerEmployeeId &&
                  data?.problemRequest?.status === "Requested" &&
                  data?.problemRequest?.rcaDescription === null && (
                    <div>
                      <div className="headingForm">IT Ops Update</div>
                      <Formik
                        initialValues={{ ITOpsEmployeeID: "" }}
                        validationSchema={Yup.object().shape({
                          ITOpsEmployeeID: Yup.string().required(
                            "Please Select Employee"
                          ),
                        })}
                        onSubmit={OnSubmit}
                      >
                        <Form>
                          <div>
                            <LabelCustom
                              labelName={"IT OPS Team Member"}
                              mandatory={true}
                            />
                            <SelectForm
                              name="ITOpsEmployeeID"
                              options={employeeList}
                            />
                          </div>
                          <div className="createFootCust">
                            <button
                              className="button primaryButton"
                              type="submit"
                              style={{ color: "white" }}
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  )}
              </div>
              <div>
                {LoggedInUser?.id === data?.problemRequest?.itopsEmployeeId &&
                  data?.problemRequest?.rcaDescription === null && (
                    <div>
                      <div className="headingForm">RCA Update</div>
                      <Formik
                        initialValues={{ Description: "", RCADocument: "" }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        <Form>
                          <div>
                            <LabelCustom
                              labelName={"RCA Description"}
                              mandatory={true}
                            />
                            <TextAreaFormik
                              maxlength="500"
                              style={{ height: "100px" }}
                              name="Description"
                              placeholder={" Enter Description"}
                            />
                          </div>
                          <div>
                            <LabelCustom labelName={"RCA Document"}  mandatory={true}/>
                            <FileUploadFormik name="RCADocument" />
                            <div
                              style={{
                                color: "var(--primary)",
                                fontSize: "14px",
                                marginLeft: "13px",
                              }}
                            >
                              Supporting document must be in pdf,jpeg,png format
                            </div>
                          </div>
                          <div className="createFootCust">
                            <button
                              className="button primaryButton"
                              type="submit"
                              style={{ color: "white" }}
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  )}
                {LoggedInUser?.id ===
                  data?.problemRequest?.problemOwnerEmployeeId &&
                  data?.problemRequest?.status === "RCA Updated" &&
                  data?.problemRequest?.rcaDescription !== null && (
                    <div>
                      <div className="headingForm">Update Request</div>
                      <Formik
                        initialValues={{
                          Status: "Solution Possible",
                          key: "",
                        }}
                        validationSchema={Yup.object().shape({
                          key: Yup.string().when("Status", {
                            is: (val) => val === "Workaround Possible",
                            then: () =>
                              Yup.string()
                                .required("Status Required")
                                .nullable(),
                          }),
                        })}
                        onSubmit={SubmitSolution}
                      >
                        {(formik) => {
                          setVal(formik.values);
                          return (
                            <Form>
                              <div>
                                <RadioFormik name="Status" options={opt} />
                              </div>
                              {formik?.values?.Status ===
                                "Workaround Possible" && (
                                <div>
                                  <SelectForm name="key" options={option} />
                                </div>
                              )}
                              <div className="createFootCust">
                                <button
                                  className="button primaryButton"
                                  type="submit"
                                  style={{ color: "white" }}
                                >
                                  {(formik?.values?.Status ===
                                    "Solution Possible" ||
                                    formik?.values?.Status ===
                                      "Change is Required") &&
                                    "Raise CR"}
                                  {formik?.values?.Status ===
                                    "Workaround Possible" &&
                                    formik?.values?.key ===
                                      "Change is Required" &&
                                    "Raise CR"}
                                  {formik?.values?.Status ===
                                    "Workaround Possible" &&
                                    formik?.values?.key == "" &&
                                    "Submit"}
                                  {formik?.values?.Status ==
                                    "Both Solution or Workaround not Possible" &&
                                    "Awaiting Solution"}
                                  {formik?.values?.key ===
                                    "Change is Not Required" && formik?.values?.Status ===
                                    "Workaround Possible" &&  "Submit"}
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}
                {LoggedInUser?.id === data?.problemRequest?.itopsEmployeeId &&
                  data?.problemRequest?.status === "Change is Not Required" && (
                    <div>
                      <div className="headingForm">Update Request</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <LabelCustom labelName={"Update The Status"} />

                        <button
                          className="button primaryButton"
                          onClick={() => updateReq("Closed")}
                          style={{ color: "white" }}
                        >
                          Closed
                        </button>
                      </div>
                    </div>
                  )}
                {LoggedInUser?.id ===
                  data?.problemRequest?.problemOwnerEmployeeId &&
                  data?.problemRequest?.status === "Closed" &&
                  data?.problemRequest?.kedbDescription == null && (
                    <div>
                      <div className="headingForm">KEDB Update</div>
                      <Formik
                        initialValues={{
                          KEDBDescription: "",
                          KEDBDocument: "",
                          solutionImplement: "",
                        }}
                        validationSchema={Yup.object().shape({
                          KEDBDescription: Yup.string().when(
                            "solutionImplement",
                            {
                              is: (val) => val === "Resolved",
                              then: () =>
                                Yup.string()
                                  .required("KEDBDescription is Required")
                                  .nullable(),
                            }
                          ),
                          KEDBDocument: Yup.mixed()
                            .nullable()
                            .when("solutionImplement", {
                              is: (val) => val === "Resolved",
                              then: () =>
                                Yup.mixed()
                                  .required("KEDBDocument is Required")
                                  .nullable(),
                            }),
                        })}
                        onSubmit={onSubmitKed}
                      >
                        {({ values }) => {
                          return (
                            <Form>
                              <div>
                                <SelectForm
                                  options={kedb}
                                  name="solutionImplement"
                                />
                              </div>
                              {values?.solutionImplement === "Resolved" && (
                                <div>
                                  <div>
                                    <LabelCustom
                                      labelName={"KEDB Description"}
                                      mandatory={true}
                                    />
                                    <TextAreaFormik
                                      style={{ height: "100px" }}
                                      maxLength="500"
                                      name="KEDBDescription"
                                    />
                                  </div>
                                  <div>
                                    <LabelCustom labelName={"KEDB Document"} />
                                    <FileUploadFormik name="KEDBDocument" />
                                    <div
                                      style={{
                                        color: "var(--primary)",
                                        fontSize: "14px",
                                        marginLeft: "13px",
                                      }}
                                    >
                                      Supporting document must be in
                                      pdf,jpeg,png format
                                    </div>
                                  </div>
                                </div>
                              )}
                              {(values?.solutionImplement === "Resolved" ||
                                values?.solutionImplement ===
                                  "Not Resolved") && (
                                <div className="createFootCust">
                                  <button
                                    className="button primaryButton"
                                    type="submit"
                                    style={{ color: "white" }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  )}
                {LoggedInUser?.id ===
                  data?.problemRequest?.problemOwnerEmployeeId &&
                  data?.problemRequest?.status === "Solution Not Resolved" &&
                  data?.problemRequest?.relatedChangeRequestId === null && (
                    <div>
                      <div className="headingForm">Update Request</div>
                      <div style={{ textAlign: "center" }}>
                        <button
                          className="button primaryButton"
                          onClick={() => {
                            navigate(-1);
                            setTimeout(
                              () =>
                                navigate(
                                  `/itsm/change/create/${id}/${data?.problemRequest?.problemOwnerEmployeeId}`
                                ),
                              30
                            );
                          }}
                        >
                          Raise CR
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsProblem };
