import React from "react";
import "./StarredMessages.css";
import { useCallback } from "react";
import { useEffect } from "react";

function StarredMessages(props) {

  const tempStarredData = [
    {
      msgTime: "5:30PM",
      msgDate: "02/07/2023",
      read: false,
      starredMsg: ["Hello, Jenny how are you","no! not yet","yes, why not"],
      profilePic: "https://picsum.photos/35/35",
      img: ["https://picsum.photos/35/35","https://picsum.photos/40/40"],
    },

    {
      msgTime: "5:30PM",
      msgDate: "02/05/2022",
      read: false,
      starredMsg:["Hello, Jenny how are you mxzbvieu iasugf iUGADY I8gyed7 i4eur8b  qywtq7g hqage6w"],
      profilePic: "https://picsum.photos/40/40",
    },
  ];
  const starredMsgHandler = useCallback((e) => {
    let moreActivity = document.querySelector(".starredMessagesContainer");
    if (moreActivity && !moreActivity?.contains(e.target)) {
      props.setStarredMessages(false);
    }
  }, []);


  useEffect(()=>{
    document.title = `PeopleSol - Starred Messages`;
    return () => {
      document.title = 'PeopleSol';
    };
  })


  useEffect(() => {
    document.addEventListener("mouseup", starredMsgHandler);
    return () => {
      document.removeEventListener("mouseup", starredMsgHandler);
    };
  }, [starredMsgHandler]);
 
  return (
    <>

      <div className="starredMessagesMainWrapper">
        {tempStarredData
          .slice(0)
          .reverse()
          .map((chat, index) => (
            <div className="allStarredMsg">
              <div className="profilePicContainer">
                <img
                  className="profilePic"
                  src={chat.profilePic? chat.profilePic : "https://picsum.photos/40/40"}
                  alt="Profile"
                />
                <span>{chat.msgTime}</span>
              </div>
              <div className="chatMsg">
                <div className="starredMsgListContainer">
                {chat.starredMsg && !chat.img && (
                  <div className="starredTextContainer">
                      {chat?.starredMsg?.map((item, index)=>{
                        return  (
                        <div className="chatMsgTextContainer">
                        <label>{item}</label>
                        <div className="starSVG">
                          <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="10"
                          viewBox="0 0 11 10"
                          fill="none"
                        >
                          <path
                            d="M4.85898 0.398096C5.03281 -0.101975 5.74003 -0.101975 5.91385 0.398096L6.70298 2.66831C6.77966 2.88891 6.98553 3.03849 7.21903 3.04325L9.62199 3.09222C10.1513 3.103 10.3698 3.77561 9.94796 4.09545L8.03271 5.54749C7.8466 5.68859 7.76796 5.93061 7.8356 6.15415L8.53158 8.45463C8.68488 8.96137 8.11273 9.37706 7.67817 9.07467L5.70536 7.70186C5.51365 7.56846 5.25918 7.56846 5.06748 7.70186L3.09466 9.07467C2.6601 9.37706 2.08795 8.96137 2.24126 8.45463L2.93724 6.15415C3.00487 5.93061 2.92623 5.68859 2.74012 5.54749L0.824873 4.09545C0.402992 3.77561 0.621534 3.103 1.15084 3.09222L3.5538 3.04325C3.7873 3.03849 3.99318 2.88891 4.06986 2.66831L4.85898 0.398096Z"
                            fill="#F7941D"
                          />
                        </svg>
                        </div>
                      </div>)
                      })}
                   
                  </div>
                )}
                {chat.img && (
                  <div className="chatMsgImgContainer">
                    {chat?.img?.map((item, index)=>{
           
                      return (
                      <div className="starredImgIcon">
                        <img src={item} alt="imgMsg" />
                        <div className="starSVG">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="10"
                            viewBox="0 0 11 10"
                            fill="none"
                          >
                            <path
                              d="M4.85898 0.398096C5.03281 -0.101975 5.74003 -0.101975 5.91385 0.398096L6.70298 2.66831C6.77966 2.88891 6.98553 3.03849 7.21903 3.04325L9.62199 3.09222C10.1513 3.103 10.3698 3.77561 9.94796 4.09545L8.03271 5.54749C7.8466 5.68859 7.76796 5.93061 7.8356 6.15415L8.53158 8.45463C8.68488 8.96137 8.11273 9.37706 7.67817 9.07467L5.70536 7.70186C5.51365 7.56846 5.25918 7.56846 5.06748 7.70186L3.09466 9.07467C2.6601 9.37706 2.08795 8.96137 2.24126 8.45463L2.93724 6.15415C3.00487 5.93061 2.92623 5.68859 2.74012 5.54749L0.824873 4.09545C0.402992 3.77561 0.621534 3.103 1.15084 3.09222L3.5538 3.04325C3.7873 3.03849 3.99318 2.88891 4.06986 2.66831L4.85898 0.398096Z"
                              fill="#F7941D"
                            />
                          </svg>
                      </div>
                    </div>)
                    })}
                  </div>
                )}
                </div>
                <div className="dateTimeBluetickContainer">
                  <div className="dateTimeWrapper">
                    <label className="chatMsgDateTime">{chat.msgDate}</label>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                      <path d="M0.837891 1.35876L3.74683 4.26746L0.837891 7.17639" stroke="#BFBFBF" stroke-width="0.75" stroke-linecap="round"/>
                      </svg>
                    </div>
                  </div>
                 <div className="blueTickWrapper">
                 <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                  >
                    <path
                      d="M11.8517 0.938965L6.3485 6.41131L5.70605 5.76885"
                      stroke="#1296B0"
                      stroke-linecap="round"
                    />
                    <path
                      d="M1.02344 4.36524L3.15791 6.41131L8.66111 0.938965"
                      stroke="#1296B0"
                      stroke-linecap="round"
                    />
                  </svg>
                 </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default StarredMessages;
