import React, { useEffect } from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";

export const CandidateDoc = ({}) => {
  useEffect(() => {
    document.title = `PeopleSol - Candidate Docs`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Document "}mandatory={true} />
        <FileUploadFormik name="upload"/>
        <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Size: 2 MB</span>
       <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Format: PDF, Doc</span>
      </div>
      <div>
        <LabelCustom labelName={"Type"} mandatory={true}/>
        <InputCustomFormik
        
        maxLength={150}
        selectBoxOptions="Canada;Denmark;Finland;Germany;Mexico"
          name="Type"
          type={"text"}
          placeholder={"Enter Type of Document"}
        />
      </div>
    </div>
  );
};
