import React from "react";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { WorkFlowRolesForm } from "./Form/WorkFlowRolesForm";
import { schema } from "./Schemas/Index";
import { useEffect } from "react";

export const CreateWorkflowRoles = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = React.useState([]);
  const dispatch = useDispatch();
  const initialvalue = {
    name: data ? detail?.name : "",
    associatedEmployeeId: data ? detail?.associatedEmployeeId : "",
  };
  //console.log("detail?.associatedEmployeeId",detail?.associatedEmployeeId)
  const postFormData = async (values) => {
    try {
      const result = await postRequest("WorkflowRoles/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Workflow Roles`;
    return () => {
      document.title = "PeopleSol";
    };
  });

  const getDetails = async () => {
    try {
      const result = await getRequest(`WorkflowRoles/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest("WorkflowRoles/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
    };
    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <div>
      <FormCustomFormik
        ButtonLabel={data ? "Update WorkFlow Roles" : "Create WorkFlow Roles"}
        FormHeading={data ? "Edit WorkFlow Roles" : "Create WorkFlow Roles"}
        propsDiv={<WorkFlowRolesForm data={detail} editID={data} />}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialvalue={initialvalue}
        returnPage={returnPage}
      />
    </div>
  );
};
