import React, { useCallback, useEffect, useState } from 'react'
import "./BlockLists.css";
import { confirmAlert } from 'react-confirm-alert';

function BlockLists(props) {
    const tempData = [
        {
          userId: "1234567890",
          Name: "Ahaan",
          Role: "Junior Frontend Developer",
          LastMsgTime: "5:30PM",
          lastMsg: "Hello, Jenny how are you",
          Img: "https://picsum.photos/40/40",
          Active: "online",
          msgs: [],
        },
        {
          userId: "1234567891",
          Name: "Kapil Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          lastMsg: "I am waiting for your reply",
          Img: "https://picsum.photos/35/35",
          Active: "away",
          msgs: [],
        },
        {
          userId: "1234567892",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          lastMsg: "Okay, Sure!",
          Img: "https://picsum.photos/35/35",
          Active: "online",
          msgs: [],
        },
        {
          userId: "1234567893",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          lastMsg: "I will send it",
          Img: "https://picsum.photos/35/35",
          Active: "away",
          msgs: [],
        },
        {
          userId: "1234567894",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          lastMsg: "okay, see you soon!",
          Img: "https://picsum.photos/35/35",
          Active: "busy",
          msgs: [],
        },
        {
          userId: "1234567895",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
        {
          userId: "1234567896",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "busy",
        },
        {
          userId: "1234567897",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "away",
        },
        {
          userId: "1234567898",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "busy",
        },
        {
          userId: "1234567899",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "away",
        },
        {
          userId: "1234567880",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "busy",
        },
        {
          userId: "1234567881",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "busy",
        },
        {
          userId: "1234567882",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
        {
          userId: "1234567882",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
        {
          userId: "1234567882",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
        {
          userId: "1234567882",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
        {
          userId: "1234567882",
          Name: "Anuj Yadav",
          Role: "Frontend Developer",
          LastMsgTime: "3:30PM",
          Img: "https://picsum.photos/35/35",
          Active: "online",
        },
      ];
    const [blockContactList, setBlockContactList]=useState(tempData);
    function unblockMember(index){
        
    }

      const blockListsHandler = useCallback((e) => {
        let moreActivity = document.querySelector(".blockListsContainer");
        if (moreActivity && !moreActivity?.contains(e.target)) {
            props.setBlockContactList(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("mouseup", blockListsHandler);
        return () => {
          document.removeEventListener("mouseup", blockListsHandler);
        };
      }, [blockListsHandler]);
     
  return (
    <div className='blockListsContainer'>
        <div className='blockListsWrapper' style={{height:"100%"}}>
      
            <div className="chatListContainer">
                {blockContactList.map((chat, index) => (
                  <div
                    onClick={() => {
                     
                    }}
                    className="chatListWrapper"
                  >
                    <div className="chatListLeftcontent">
                      <div className="activeImage">
                        <div
                          className={`activeImageContainer ${
                            !chat.Img ? "customProfilePic" : ""
                          }`}
                          style={
                            chat.Img
                              ? { backgroundImage: `url(${chat.Img})` }
                              : {}
                          }
                        >
                          {!chat.Img
                            ? chat.Name.split(" ")
                                .slice(0, 2)
                                .map((item) => {
                                  return item.charAt(0).toUpperCase();
                                })
                                .join("")
                            : null}
                        </div>
                        {chat.type === "group" && (
                          <div className="groupDot">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="23"
                              viewBox="0 0 22 23"
                              fill="none"
                            >
                              <circle
                                cx="11.0099"
                                cy="11.5154"
                                r="10.6623"
                                fill="#1296B0"
                              />
                              <path
                                d="M5.70232 11.9426C6.70914 11.9426 7.55698 11.107 7.55698 10.1146C7.55698 9.12228 6.70914 8.28662 5.70232 8.28662C4.6955 8.28662 3.84766 9.12228 3.84766 10.1146C3.84766 11.1592 4.6955 11.9426 5.70232 11.9426Z"
                                stroke="white"
                                stroke-width="0.845452"
                              />
                              <path
                                d="M2.62891 15.2402C2.62891 13.5689 4.00665 11.9429 5.70235 11.9429C6.42891 11.9429 7.09711 12.2414 7.62356 12.7141"
                                stroke="white"
                                stroke-width="0.845452"
                                stroke-linecap="round"
                              />
                              <path
                                d="M11.2669 11.2942C12.4604 11.2942 13.4654 10.3036 13.4654 9.12725C13.4654 7.95092 12.4604 6.96033 11.2669 6.96033C10.0734 6.96033 9.06836 7.95092 9.06836 9.12725C9.06836 10.3655 10.0734 11.2942 11.2669 11.2942Z"
                                stroke="white"
                                stroke-width="0.845452"
                              />
                              <path
                                d="M7.62305 15.2396V14.8845C7.62305 12.9033 9.25623 11.2936 11.2663 11.2936C13.2764 11.2936 14.9096 13.2584 14.9096 15.2396"
                                stroke="white"
                                stroke-width="0.845452"
                                stroke-linecap="round"
                              />
                              <path
                                d="M16.8309 11.9426C15.8241 11.9426 14.9762 11.107 14.9762 10.1146C14.9762 9.12228 15.8241 8.28662 16.8309 8.28662C17.8377 8.28662 18.6855 9.12228 18.6855 10.1146C18.6855 11.1592 17.8377 11.9426 16.8309 11.9426Z"
                                stroke="white"
                                stroke-width="0.845452"
                              />
                              <path
                                d="M19.9043 15.2402C19.9043 13.5689 18.5265 11.9429 16.8309 11.9429C16.1043 11.9429 15.4361 12.2414 14.9096 12.7141"
                                stroke="white"
                                stroke-width="0.845452"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="allChatListDisplayContainer">
                        <label className="chatListName">{chat.Name}</label>
                        <label className="chatListRole">{chat.Role}</label>
                        <label className="chatListlastMsg">
                          {chat?.lastMsg?.length > 20
                            ? chat?.lastMsg?.substring(0, 20) + "..."
                            : chat?.lastMsg}
                        </label>
                      </div>
                    </div>
                    <div className="chatListRightContent">
                     <div className='unblockIcon' 
                            onClick={()=>{
                                confirmAlert({
                                    title: `Unblock ${chat.Name}`,
                                    message: "",
                                    buttons: [
                                      {
                                        label: "Cancel",
                                        onClick: () => {},
                                      },
                                      {
                                        label: "Unblock",
                                        onClick: () => {
                                            let tempList=blockContactList;
                                            tempList=tempList.filter((d,i)=>{ return d.userId !== blockContactList[index].userId});
                                           // console.log(tempList);
                                           // console.log(blockContactList[index].Name);
                                            setBlockContactList(tempList);
                                            // props.setBlockContactList(true);
                                        },
                                      },
                                    ],
                                    overlayClassName: "deleteMsgConfirmAlert",
                                  });
                                }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <ellipse cx="11.6476" cy="11.2168" rx="11.0001" ry="11" fill="#1296B0"/>
                        <path d="M8.13477 10.0455V8.87448C8.13477 6.93639 8.7203 5.36133 11.648 5.36133C14.5756 5.36133 15.1612 6.93639 15.1612 8.87448V10.0455M11.648 15.0225C12.0362 15.0225 12.4085 14.8683 12.683 14.5937C12.9576 14.3192 13.1118 13.9469 13.1118 13.5587C13.1118 13.1704 12.9576 12.7981 12.683 12.5236C12.4085 12.2491 12.0362 12.0949 11.648 12.0949C11.2597 12.0949 10.8874 12.2491 10.6129 12.5236C10.3384 12.7981 10.1841 13.1704 10.1841 13.5587C10.1841 13.9469 10.3384 14.3192 10.6129 14.5937C10.8874 14.8683 11.2597 15.0225 11.648 15.0225Z" stroke="white" stroke-width="1.12862" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.5755 17.0717H8.72014C6.37801 17.0717 5.79248 16.4862 5.79248 14.1441V12.973C5.79248 10.6309 6.37801 10.0454 8.72014 10.0454H14.5755C16.9176 10.0454 17.5031 10.6309 17.5031 12.973V14.1441C17.5031 16.4862 16.9176 17.0717 14.5755 17.0717Z" stroke="white" stroke-width="1.12862" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    </div>
                  </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default BlockLists