import React from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { useEffect } from "react";

const CompanyCompUpload = ({ HeadText, BodyText, editActive }) => {
  const editAllowedList = [
    "organizationName",
    "contactPersonDesignation",
    "contactPersonPhoneNumber",
    "contactPersonEmailId",
    "contactPersonName",
    "companyPhoneNumber",
    "companyPhoneNumber",
    "companyEmailId",
    "companyName",
    "companyAddress",
    "companyCity",
    "companyCountry",
    "companyFavIcon",
    "companyIndustry",
    "companyLogo",
    "companyState",
    "companyWebsite",
    "companyZip",
  ];
  useEffect(() => {
    document.title = `PeopleSol - Company Upload `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  function camelToCapital() {
    var result = HeadText.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  function canEdit(HeadText) {
    return editAllowedList.includes(HeadText);
  }
  return (
    <div className="jobDetailContainer companyDetailContainer">
      <div className="verticalLine"></div>
      <div>
        <label>{camelToCapital()}</label>
        {editActive && <span style={{ color: "red" }}> *</span>}
      </div>
      {editActive && canEdit(HeadText) ? (
        <div style={{ paddingBottom: "12px" }}>
          <FileUploadFormik name={HeadText} />
        </div>
      ) : (
        <a
          href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${BodyText}`}
          target="blank"
        >
          {BodyText}
        </a>
      )}
    </div>
  );
};

export default CompanyCompUpload;
