import React, { useState, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { isLoading, showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { WebcamCapture } from "./WebcamCapture";
import WebcamOrFileFormik from "./WebcamOrFileFormik";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

const opt = [{ label: "select", value: null }];

const purposeOptions = [
  { label: "Official", value: "Official" },
  { label: "Personal", value: "Personal" },
];

export const MarkEntryComp = ({
  isDetails,
  data,
  initialvalue,
  selId,
  handleClose,
  detailsform,
  ilistid,
  getManageVisitorsData,
  getDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const returnPage = () => {
    handleClose();
  };
  console.log("formValues formValues", formValues);
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];
  const IMAGE_SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    // "application/pdf",
    // "application/doc",
  ];
  const validationSchema = Yup.object({
    inTime: Yup.string().required("Please enter the Time"),
    vehicleNumber: Yup.string()
      .matches(/^\S.*$/, "Vehicle Number should not start with spaces")
      .required("Please enter the Vehicle Number"),

    Visitlist: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^\S.*$/, "Name should not start with spaces")
          .required("Please enter Name"),
        phoneNumber: Yup.number()
          .typeError("Phone Number must be a number")
          .required("Please enter the Phone Number"),
        upload: Yup.mixed()
          .required("Please Upload File")
          .test(
            "Fichier taille",
            "File Size should be less than 1 mb",
            (value) => {
              return (
                !value ||
                (value && value.size <= 1024 * 1024) ||
                typeof value !== "object"
              );
            }
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value ||
              (value && SUPPORTED_FORMATS.includes(value.type)) ||
              typeof value !== "object"
          ),
      })
    ),
  });
  useEffect(() => {
    document.title = `PeopleSol - Mark Entry`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "VisitorsController/MarkEntry",
        values
      );
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(showToast({ text: result.data.message, severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      isDetails ? getDetails() : getManageVisitorsData();
      handleClose();
    }
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("id", detailsform ? detailsform?._visits?.id : 0);
    formData.append("vehicleNumber", values?.vehicleNumber);
    formData.append("purpose", detailsform?._visits?.purpose);
    formData.append("vehicleType", detailsform?._visits?.vehicleType);

    values?.Visitlocationlist.forEach(function (object, index) {
      formData.append(
        `Visitlocationlist[${index}].inTime`,
        format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
      );
    });

    values?.Visitlist.forEach(function (object, index) {
      formData.append(
        `Visitlist[${index}].id`,
        detailsform?.visitingpeople[index]?.id
      );
      formData.append(`Visitlist[${index}].name`, object.name);
      formData.append(`Visitlist[${index}].phoneNumber`, object.phoneNumber);
      formData.append(`Visitlist[${index}].bloodGroup`, object.bloodGroup);
      if (typeof object?.upload === "object")
        formData.append(
          `Visitlist[${index}].upload`,
          object.upload ? object.upload : ""
        );
      formData.append(
        `Visitlist[${index}].attachment`,
        object.upload.name ? object.upload.name : ""
      );

      if (typeof object?.photoUpload === "object")
        formData.append(
          `Visitlist[${index}].photoUpload`,
          object?.photoUpload ? object?.photoUpload : ""
        );
      formData.append(
        `Visitlist[${index}].photo`,
        object?.photoUpload?.name ? object?.photoUpload?.name : ""
      );
    });
    // detailsform?._visits?.Visitlocationlist.forEach(function (object, index) {
    //   formData.append(`Visitlocationlist[${index}].name`, object.name);
    //   formData.append(
    //     `Visitlocationlist[${index}].phoneNumber`,
    //     object.phoneNumber
    //   );
    //   formData.append(
    //     `Visitlocationlist[${index}].bloodGroup`,
    //     object.bloodGroup
    //   );
    //   if (typeof object?.upload === "object")
    //     formData.append(
    //       `Visitlocationlist[${index}].upload`,
    //       object.upload
    //     );
    //   if (typeof object?.photoUpload === "object")
    //     formData.append(`Visitlocationlist[${index}].photoUpload`, object.photoUpload);
    // });
    // console.log("detailsform?._visits?.Visitlocationlist", detailsform);
    // detailsform?._visits?.Visitlocationlist.map(
    //   (item, index) => (
    //     formData.append(
    //       `Visitlist[${index}].locationGroupId`,
    //       item.locationGroupId
    //     ),
    //     formData.append(`Visitlist[${index}].visitId`, item.visitId),
    //     formData.append(`Visitlist[${index}].locationId`, item.locationId),
    //     formData.append(`Visitlist[${index}].employeeId`, item.employeeId),
    //     formData.append(`Visitlist[${index}].visitingTime`, item.visitingTime)
    //   )
    // );

    Create(formData);
  };
  useEffect(() => {
    if (!isDetails) data(ilistid);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            enableReinitialize
            initialValues={initialvalue}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, errors }) => {
              chooseFormValues && chooseFormValues(values);
              return (
                <Form>
                  <div className="formcustom twoColumn">
                    <div>
                      <LabelCustom labelName={"In Time"} mandatory={true} />
                      <TimeCustomFormik
                        disabled={true}
                        name="inTime"
                        placeholder="Select In Time"
                      />
                    </div>
                    <div>
                      <LabelCustom
                        labelName={"Vehicle Number"}
                        mandatory={true}
                      />
                      <InputCustomFormik
                        name="vehicleNumber"
                        type={"text"}
                        placeholder={"Please Enter Vehicle Number"}
                      />
                    </div>

                    <div className="gridItemVisitor" style={{ width: "100%" }}>
                      {/* <div className="headingForm">Who is coming?</div> */}
                      <div className="repeatedSectionContainer">
                        {/* <LabelCustom labelName={"Details"} /> */}
                        <FieldArray name="Visitlist">
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values } = form;
                            const { Visitlist } = values;
                            return (
                              <StyledTableCont>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom labelName={"S. No."} />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Who is coming?"}
                                          mandatory={true}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Phone Number"}
                                          mandatory={true}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Blood Group"}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"ID Proof"}
                                          mandatory={true}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom
                                          labelName={"Upload Photo"}
                                          // mandatory={true}
                                        />
                                      </StyledTableCell>

                                      {/* <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        <LabelCustom labelName={"Add/Remove"} />
                                      </StyledTableCell> */}
                                    </TableRow>
                                  </TableHead>
                                  {Visitlist?.map((_, index) => (
                                    <TableBody key={index}>
                                      {/* 1st column */}
                                      <StyledTableCell align="center">
                                        {index + 1}
                                      </StyledTableCell>
                                      {/* 2nd column */}
                                      <StyledTableCell>
                                        <InputCustomFormik
                                          name={`Visitlist[${index}].name`}
                                          type={"text"}
                                          placeholder={"Please Enter The Name"}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        <InputCustomFormik
                                          placeholder={
                                            "Please Enter The Phone Number"
                                          }
                                          maxLength={10}
                                          name={`Visitlist[${index}].phoneNumber`}
                                          step=".001"
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        <InputCustomFormik
                                          name={`Visitlist[${index}].bloodGroup`}
                                          type={"text"}
                                          placeholder={
                                            "Please enter the Blood Group"
                                          }
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        <FileUploadFormik
                                          name={`Visitlist[${index}].upload`}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        <WebcamOrFileFormik
                                          formValues={formValues}
                                          name={`Visitlist[${index}].photoUpload`}
                                          index={index}
                                          Visitlist={Visitlist}
                                        />
                                      </StyledTableCell>
                                    </TableBody>
                                  ))}
                                </Table>
                              </StyledTableCont>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>
                  </div>

                  <div className="createFootCust">
                    <button
                      type="button"
                      className="button secondaryButton"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="button primaryButton"
                      type="submit"
                      style={{ background: "var(--primary)", color: "white" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};
