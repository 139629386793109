import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CandidateVideo } from "./Forms/CandidateVideo";
import * as Yup from "yup";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";

const initialvalue = {
  title: "",
  type: "",
  youTubeVideoUrl: "",
  files: null,
};
const CreateOnboardingVideos = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Onboarding Video`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(null);
  const [changeButton, setchangeButton] = useState(false);
  const [messaged, setMessage] = useState(false);
  const [videoFileData, setVideoFileData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // console.log("first data", data);
  const isVideoSelected = () => {
    // debugger;
    if (!formValues?.SelectVideo) {
      setMessage(true);
      return true;
    } else if (formValues?.SelectVideo === "url" && !formValues?.videoUrl) {
      setMessage(true);
      return true;
    } else if (formValues?.SelectVideo === "video" && !formValues?.files) {
      setMessage(true);
      return true;
    } else {
      setMessage(false);
      return false;
    }
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const handleVideoError = () => {
    // if (e) {
    confirmAlert({
      customUI: () => {
        return (
          <div className="videowarningPopup">
            <div className="videowarning">Warning!!!</div>
            <div className="videowarningcontent">
              Invalid URL. Please verify it or check for Ad Blockers or Faulty
              extensions.
            </div>
          </div>
        );
      },
    });
    // }
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "CorporateTrainingMaterials/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage1();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "CorporateTrainingMaterials/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage1();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  // const VideoValidationSchema = () => {
  //   // isVideoSelected();
  //   // if(!formValues?.SelectVideo){

  //   if (!formValues?.SelectVideo === "video") {
  //     return VideoSchema2;
  //   } else return VideoSchema;
  // };
  const VideoSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title must be less than 150 characters")
      .required("Please Enter Title"),
    type: Yup.string().required("Please Enter Type"),
    // youTubeVideoUrl: Yup.string().max(149,"Url must be less than 150 characters")
    // .matches(
    //   /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    //   "Enter a valid video URL with http or https"
    // )
    // .required("Please Enter Video URL"),
  });
  // const videoFormats = [
  //   ".mp4",
  //   ".avi",
  //   ".mov",
  //   ".wmv",
  //   ".flv",
  //   ".mkv",
  //   ".webm",
  //   ".mpeg",
  //   ".mpg",
  //   ".m4v",
  //   ".3gp",
  //   ".ogg",
  //   ".ogv",
  //   ".vob",
  //   ".gifv",
  //   ".asf",
  // ];

  // const VideoSchema2 = Yup.object({
  //   title: Yup.string()
  //     .max(149, "Title must be less than 150 characters")
  //     .required("Please Enter Title"),
  //   type: Yup.string().required("Please Enter Type"),
  //   files: Yup.mixed().test(
  //     "is-video-selected",
  //     "A video selection is required",
  //     function (value) {
  //       console.log("first valuevalue", value);
  //       isVideoSelected();
  //     }
  //   ),
  // });
  // console.log("first formValues formValues", formValues);
  // console.log("first changeButton changeButton", changeButton);
  // console.log("first videoFileData videoFileData", videoFileData);
  const onSubmit = async (values) => {
    // debugger;
    if (isVideoSelected()) return;
    else if (
      !values?.videoUrl?.includes("https://www.youtube.com/") &&
      !values?.videoUrl?.includes("https://media.solunxt.com/") &&
      values?.videoUrl?.length > 0
    ) {
      handleVideoError();
    } else if (values?.SelectVideo === "video" && !changeButton) {
      dispatch(
        showToast({
          text: "Please Upload the File First",
          severity: "error",
        })
      );
    } else {
      const edit = {
        ...values,
        Id: data?.Id,
        youTubeVideoUrl:
          values?.SelectVideo === "url"
            ? values?.videoUrl
            : changeButton
            ? videoFileData?.url
            : "",
      };

      const create = {
        ...values,
        youTubeVideoUrl:
          values?.SelectVideo === "url"
            ? values?.videoUrl
            : changeButton
            ? videoFileData?.url
            : "",
      };

      data ? Edit(edit) : postFormData(create);
      returnPage1();
    }
  };

  const navigate = useNavigate();
  // const returnPage = () => {
  //   navigate("/hrmsmaster/onboarding/onboardingvideos");
  // };
  const returnPage = () => {
    if (changeButton) {
      dispatch(
        showToast({
          text: "Please Delete the Uploaded File",
          severity: "error",
        })
      );
    } else {
      navigate("/hrmsmaster/onboarding/onboardingvideos");
    }
  };
  const returnPage1 = () => {
    navigate("/hrmsmaster/onboarding/onboardingvideos");
  };
  // console.log("first changeButton changeButton changeButton", changeButton);
  return (
    <>
      {" "}
      <FormCustomFormik
        ButtonLabel={data ? "Edit" : "Create"}
        FormHeading={
          data ? "Edit Onboarding Videos" : "Create Onboarding Videos"
        }
        validationSchema={VideoSchema}
        chooseFormValues={chooseFormValues}
        onSubmit={onSubmit}
        changeButton={changeButton}
        initialvalue={
          data
            ? {
                ...initialvalue,
                title: data.Title,
                type: data.Type,
                SelectVideo: "url",
                videoUrl: data.YouTubeVideoUrl,
                youTubeVideoUrl: data.YouTubeVideoUrl,
              }
            : initialvalue
        }
        returnPage={returnPage}
        propsDiv={
          <CandidateVideo
            data={data ? true : false}
            formValues={formValues}
            changeButton={changeButton}
            setchangeButton={setchangeButton}
            messaged={messaged}
            videoFileData={videoFileData}
            setVideoFileData={setVideoFileData}
          />
        }
      />
    </>
  );
};

export { CreateOnboardingVideos };
