import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { TimelineHospitality } from "../../HRMS/Components/DetailsComp/TimelineHospitality";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { HouseKeepingTicketAssign } from "../Components/HouseKeepingTicketAssign";
import { FeedbackHouseKeeping } from "../Components/FeedbackHouseKeeping";
import { ApprovePopup } from "../../../Components/FormComponent/PopupComponent/ApprovePopup";

const DetailsPageHouseKeeping = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");

  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    //debugger;
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState("");
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [typeAll, setTypeAll] = useState("");

  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `HouseKeepingTicket/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      // console.log("result details HouseKeepingTicket", result);
      setData(result.data?.summary);
      // if (approve) {
      //   if (LoggedInUser?.id === value2 && result.data?.actionlist.length) {
      //     if (approve === "reject" || approve === "hold") {
      //       togglePopup(
      //         result.data?.actionlist[0]?.id,
      //         approve,
      //         result.data?.actionlist[0]?.color
      //       );
      //     } else
      //       PostApRejHol(approve, result.data?.actionlist[0]?.id, "Remark");
      //   }
      // }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - House Keeping Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  // console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const PostApRejHol = async (
    type,
    id,
    Remark,
    feedback,
    Assignee,
    Description
  ) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Release" ||
        type === "Closed" ||
        type === "Hold" ||
        type === "Release"
      ) {
        await getRequest(`HousekeepingTicket/${type}/${id}`);
      } else if (type === "Cancel") {
        await postRequest(`HousekeepingTicket/${type}/${id}`);
      } else if (type === "confirm") {
        await getRequest(
          `HousekeepingTicket/confirm/${id}?feedback=${feedback}`
        );
      } else if (type === "Re Open") {
        await getRequest(`HousekeepingTicket/ReOpen/${id}`);
      } else if (type === "Assign") {
        await postRequest(
          `HousekeepingTicket/TicketAssignee?Id=${id}&Assignee=${Assignee}&Description=${Description}`
        );
      }
      // else if (type === "FeedBack") {
      //   await getRequest(
      //     `HousekeepingTicket/FeedBack?ID=${id}&feedback=${feedback}`
      //   );
      // }
      else if (type === "Resolved") {
        await getRequest(`HousekeepingTicket/Resolved/${id}`);
      } else {
        await getRequest(`HousekeepingTicket/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>House Keeping Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm">Request Details</div>
                    <div className="detailInner">
                      <>
                        <Details
                          detailTitle={"Ticket Number"}
                          Details={data?._tickets?.ticketNumber}
                        />
                        <Details
                          detailTitle={"Ticket Type "}
                          Details={data?.ticketType ?? "-"}
                        />
                        <Details
                          detailTitle={"Location "}
                          Details={data?.location ?? "-"}
                        />
                        <Details
                          detailTitle={"Contact No"}
                          Details={data?._tickets?.contactNo}
                        />
                        <Details
                          detailTitle={"Description"}
                          Details={data?._tickets?.description}
                        />
                        <Details
                          detailTitle={"Severity"}
                          Details={data?._tickets?.severity}
                        />
                        <Details
                          detailTitle={"Priority"}
                          Details={data?._tickets?.priority}
                        />
                        <Details
                          detailTitle={"Deadline"}
                          Details={
                            data?._tickets?.deadline
                              ? format(
                                  new Date(
                                    data?._tickets?.deadline?.replace("T", " ")
                                  ),
                                  "dd/MM/yyyy hh:mm a"
                                )
                              : ""
                          }
                        />
                        <Details
                          detailTitle={"Current Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?._tickets?.status
                                )?.split(",")?.[0],
                                color: getStatusColor(
                                  data?._tickets?.status
                                )?.split(",")?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?._tickets?.status}
                            </div>
                          }
                        />
                        <Details
                          detailTitle={"Attachment "}
                          // Details={`Housekeeping/${data?.attachment}`}
                          Details={
                            <a
                              href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Housekeeping/${data?._tickets?.attachment}`}
                              target="blank"
                            >
                              {data?._tickets?.attachment}
                            </a>
                          }
                        />

                        <Details
                          detailTitle={"Created On"}
                          Details={format(
                            new Date(
                              data?._tickets?.created_On?.replace("T", " ")
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Ticket Assigned To"}
                          Details={
                            data?.ticketAssignedPersion
                              ? data?.ticketAssignedPersion?.name
                              : "-"
                          }
                        />
                        <Details
                          detailTitle={"Last Updated On"}
                          Details={format(
                            new Date(
                              data?._tickets?.lastUpdated_On?.replace("T", " ")
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />

                        {/* <Details
                          detailTitle={"Created On"}
                          Details={data?.data?.createdOn.replace("T", " ")}
                        />
                        <Details
                          detailTitle={"Last Updated On"}
                          Details={data?.data?.lastUpdatedOn.replace("T", " ")}
                        /> */}
                      </>
                    </div>
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?._tickets?.employee?.name} ${data?._tickets?.employee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?._tickets?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?._tickets?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?._tickets?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?._tickets?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={data?._tickets?.employee?.personalPhoneNumber}
                      />

                      <Details
                        detailTitle={"Location"}
                        Details={data?._tickets?.employee?.locationName}
                      />
                    </div>
                    {data?.actionlist ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.actionlist?.map((item) => (
                          <div>
                            <div
                              className="button secondaryButton"
                              onClick={() => {
                                item.type !== "Cancel" &&
                                item.type !== "Closed" &&
                                item.type !== "Hold" &&
                                item.type !== "Release" &&
                                item.type !== "Re Open" &&
                                item.type !== "Resolved"
                                  ? togglePopup(
                                      item?.type,
                                      item?.color,
                                      item?.id
                                    )
                                  : PostApRejHol(item?.type, item?.id);
                              }}
                              style={{
                                background: item?.color,
                                color: "white",
                              }}
                            >
                              {item?.type}
                            </div>
                          </div>
                        ))}
                        {isOpen && (
                          <Popup
                            popupHeading={
                              typeAction === "Assign"
                                ? "Ticket Assign"
                                : typeAction
                            }
                            content={
                              typeAction === "Assign" ? (
                                <HouseKeepingTicketAssign
                                  data={PostApRejHol}
                                  type={typeAction}
                                  id={selId}
                                  color={colorAction}
                                  handleClose={togglePopup}
                                />
                              ) : (
                                typeAction === "confirm" && (
                                  <FeedbackHouseKeeping
                                    loading={loading}
                                    data={PostApRejHol}
                                    type={typeAction}
                                    id={selId}
                                    color={"red"}
                                    handleClose={togglePopup}
                                  />
                                )
                              )
                            }
                            handleClose={togglePopup}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <Loader />
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimelineHospitality
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={"Housekeeping Ticket"}
                        requestNumber={data?._tickets?.ticketNumber}
                      />
                      {/* <Comments id={selectedId} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Housekeeping Ticket"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageHouseKeeping };
