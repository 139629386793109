import React, {useState,useEffect} from "react";
import { getRequest } from '../../../../Services/axios';
import { showToast } from '../../../../Features';
import { useDispatch } from 'react-redux';
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";

const opt=[
  {label:"select",value:null}
]



export const CreateTypesComp = ({data}) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Types`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

    const [typeList, setTypeList] = useState([]);
    const dispatch = useDispatch();

      const getFacilityCategoriesData = async () => {
        try {
            
            const result = await getRequest('FacilityCategories/GetFacilityCategories');
            let listData = [];
            result.data.map((item) => { 
                listData.push({ label: item.name, value: item.id })
            })
            setTypeList(listData);
            
        } catch (error) {
            dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
        } finally {
        }
      }

      useEffect(() => {
        getFacilityCategoriesData();
      }, []);

  return (
    <div>
      <div>
      <LabelCustom labelName={"Facility Category"} mandatory={true} />
        <SelectForm 
            name="facilityCategoryId"
            options={typeList} 
            placeholder={"Select Facility Category"}
            values={
              data
                ? typeList.filter(
                    (options) => options.label === data?.categoryName
                  )
                : opt
            }
  

            />
      </div>
      <div>
        <LabelCustom labelName={"Facility Type"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Facility Type"}
        />

      </div>
    
    </div>
  );
};
